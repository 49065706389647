import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'
import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'
import { Global } from '@emotion/react'
import { ChakraProvider } from '@chakra-ui/react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import Chrome from './components/Chrome'
import DirtyFormAlertProvider from './components/DirtyFormAlert'
import SubdomainProvider from './components/Subdomain'
import PrintableProvider from './components/Printable'
import LoadingPage from './components/LoadingPage'
import Routes from './routes'
import reportWebVitals from './reportWebVitals'
import { configureStore } from './redux'
import GlobalStyle from './styles'
import 'react-datepicker/dist/react-datepicker.css'

const {store, persistor} = configureStore()

const container = document.getElementById('root')
const root = createRoot(container!)
// @ts-ignore
root.render((
  <Provider store={store}>
    <React.StrictMode>
      <SubdomainProvider>{
        ({isLoading, theme}) => !isLoading && (
          <ChakraProvider theme={theme}>
            <Global styles={GlobalStyle}/>
            <PersistGate persistor={persistor} loading={<LoadingPage/>}>
              <Router>
                <PrintableProvider>
                  <DirtyFormAlertProvider>
                    <Chrome>
                      <Routes />
                    </Chrome>
                  </DirtyFormAlertProvider>
                </PrintableProvider>
              </Router>
            </PersistGate>
          </ChakraProvider>
        )
      }</SubdomainProvider>
    </React.StrictMode>
  </Provider>
))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
