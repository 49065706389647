import {extendTheme, StyleConfig, StyleFunctionProps, theme as baseTheme} from '@chakra-ui/react'
import { Theme as ThemeType } from '@chakra-ui/theme'
import { mode } from '@chakra-ui/theme-tools'
import { getBrandPalette, grays, reds, textPalette, toastColors, white } from './Colors'
import { Model as Subdomain } from './Subdomain'


export type BrandedTheme = DeepPartial<ThemeType> & {
  logoUrl?: string
  memberLeadName?: string
}

export const createTheme = (subdomain?: Subdomain) => {
  // docs: https://chakra-ui.com/docs/theming/customize-theme
  // default component styles: https://github.com/chakra-ui/chakra-ui/tree/master/packages/theme/src/components
  const brandPrimary = getBrandPalette(subdomain?.primaryColor)
  const brandSecondary = getBrandPalette(subdomain?.secondaryColor || '#D14398')
  const components : Record<string, StyleConfig> = {
    Button: {
      baseStyle: {
        fontWeight: '500',
        borderRadius: '3px',
        cursor: 'pointer',
      },
      defaultProps: {
        colorScheme: 'brand',
      },
      variants: {
        purpleOutline: (props: StyleFunctionProps) => {
          const baseOutline = baseTheme.components.Button.variants?.outline(props) || {_active: {}}
          const bg = grays.input
          return {
            ...baseOutline,
            bg,
            _active: { ...baseOutline._active, bg, borderColor: brandPrimary[300], borderWidth: '2px' },
          }
        },
        transparentPurpleOutline: (props: StyleFunctionProps) => {
          const baseOutline = baseTheme.components.Button.variants?.outline(props) || {_active: {}}
          return {
            ...baseOutline,
            bg: 'transparent',
            _hover: {...baseOutline._active, bg: grays.light},
          }
        },
        transparentRedOutline: (props: StyleFunctionProps) => {
          const baseOutline = baseTheme.components.Button.variants?.outline(props) || {_hover: {}, _active: {}}
          const bg = 'transparent'
          const color = toastColors.error
          const borderColor = toastColors.error
          return {
            ...baseOutline,
            color,
            borderColor,
            bg,
            _hover: { ...baseOutline._active, bg: grays.buttonHover },
            _active: {  ...baseOutline._active, bg: grays.light, borderColor: toastColors.error, borderWidth: '2px' },
          }
        },
        redLink: (props: StyleFunctionProps) => {
          const baseOutline = baseTheme.components.Button.variants?.link(props) || {}
          return {
            ...baseOutline,
            color: toastColors.error,
          }
        },
      },
    },
    FormLabel: {
      baseStyle: {
        fontSize: '12px',
        fontWeight: 'normal',
      },
    },
    FormError: {
      baseStyle: (props) => ({
        text: {
          color: mode('red.500', 'red.300')(props),
          mt: 2,
          fontSize: '12px',
        },
        icon: {
          mr: '0.5em',
          color: mode('red.500', 'red.300')(props),
        },
      }),
    },
    Input: {
      variants: {
        outline: {
          field: { bg: grays.input, borderRadius: '3px' },
        },
      },
    },
    Textarea: {
      variants: {
        outline: {
          field: { bg: grays.input, borderRadius: '3px' },
        },
      },
    },
    Link: {
      baseStyle: {
        color: brandPrimary[500],
        _hover: {
          color: brandPrimary[300],
          textDecoration: 'none',
        } as any,
      },
    },
  }
  const ChakraThemeExtensions: BrandedTheme = {
    logoUrl: subdomain?.logo,
    colors: {
      brand: brandPrimary,
      brandSecondary,
      red: reds,
    } as any,
    components,
    styles: {
      global: {
        'html, body, #root': {
          color: textPalette.normal,
          fontFamily: 'Gotham',
          fontStyle: 'normal',
          height: '100%',
          backgroundColor: grays.background,
        },
        '.chakra-menu__menuitem:focus': {
          border: '0px',
          borderColor: `${brandPrimary[300]}`,
          boxShadow: `0 0 0 1px ${brandPrimary[300]} inset`,
        },
        '.chakra-checkbox__control:not([data-checked])': {
          backgroundColor: white, // ensure all empty checkbox background default to white
        },
        'input[type="time"]::-webkit-calendar-picker-indicator': {
          background: 'none',
          display: 'none', // hide chrome clock on time inputs
        },
        '.react-datepicker-popper': {
          position: 'absolute',
          zIndex: '10000 !important', // override default z-index of 1
        },
      },
    },
  }

  return extendTheme(ChakraThemeExtensions)
}
