import React from 'react'
import {Route, Switch} from 'react-router-dom'
import {PrivacyAndTos} from '../../constants/RoutePaths'
import PrivacyAndTosComponent from './PrivacyAndTos'

const Public: React.FC = () => {
  return (
    <Switch>
      <Route path={PrivacyAndTos()} component={PrivacyAndTosComponent} exact />
    </Switch>
  )
}

export default Public