import React, { useContext, useEffect, useState } from 'react'
import * as ReactRedux from 'react-redux'
import * as Yup from 'yup'
import { Link } from 'react-router-dom'
import { FiAlertTriangle, FiCalendar, FiClock, FiLock, FiSave, FiXCircle, FiChevronLeft } from 'react-icons/fi'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { RouteComponentProps, useParams } from 'react-router'
import { useHistory } from 'react-router-dom'
import { StoreState } from '../../redux'
import {
  getMany as getMeetingPurposes,
} from '../../redux/meetingPurposes/actions'
import {
  getMany as getUsers,
} from '../../redux/users/actions'
import {
  create as createTeamMeeting,
  getOne as getTeamMeeting,
  update as updateTeamMeeting,
} from '../../redux/teamMeetings/actions'
import { textPalette, white } from '../../constants/Colors'
import { DATE_FORMAT, TIME_FORMAT, getIsoDateFromString, parseDate, yupTransformDate } from '../../utils/Forms'
import {
  Box,
  Button,
  Checkbox,
  Container,
  Flex,
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Spacer,
  Stack,
  Text,
  Textarea,
  useTheme,
  Center,
  Spinner,
} from '@chakra-ui/react'
import { Field, FieldInputProps, Form, Formik, FormikContextType } from 'formik'
import { Context as DirtyFormAlertContext } from '../../components/DirtyFormAlert'
import { isDone, isError, isLoading } from '../../redux/AsyncState'
import isEmpty from 'lodash.isempty'
import * as RoutePaths from '../../constants/RoutePaths'
import { useToast } from '../../hooks/Toast'
import useCustomSelectOption, { OTHER_VALUE, getOptionValues, getValidationSchema } from '../../hooks/useCustomSelectOption'
import useFormikContext from '../../hooks/useFormikContext'
import DatePicker from '../../components/DatePicker'
import { getCodeMapForTime } from '../../utils/BusinessLogic'
import { differenceInMinutes, format as formatDate, parseISO, setMinutes } from 'date-fns'
import FileUpload from '../../components/FileUpload'
import { uploadFile } from '../../redux/axios'
import { privateFileUrl } from '../../utils/Routing'

const ROW_SPACING = '48px'
const COMBINED_DATETIME_FORMAT = `${DATE_FORMAT}-${TIME_FORMAT}`
const DEFAULT_TOTAL_TIME = 30

const FormFields = {
  attendeeIds: 'attendeeIds',
  endTime: 'endTime',
  meetingPurposes: 'meetingPurposes',
  note: 'note',
  otherMeetingPurpose: 'otherMeetingPurpose',
  startDate: 'startDate',
  startTime: 'startTime',
  treatingPhysician: 'treatingPhysician',
  noteFile: 'noteFile',
} as const

const initialValues = {
  [FormFields.attendeeIds]: [] as string[],
  [FormFields.endTime]: formatDate(setMinutes(new Date(), DEFAULT_TOTAL_TIME), TIME_FORMAT),
  [FormFields.meetingPurposes]: [] as string[],
  [FormFields.note]: '',
  [FormFields.otherMeetingPurpose]: '',
  [FormFields.startDate]: formatDate(new Date(), DATE_FORMAT),
  [FormFields.startTime]: formatDate(setMinutes(new Date(), 0), TIME_FORMAT),
  [FormFields.treatingPhysician]: '',
  [FormFields.noteFile]: undefined,
}

const ValidationSchema = Yup.object().shape({
  ...getValidationSchema({ customField: FormFields.treatingPhysician, selectionField: FormFields.attendeeIds }),
  ...getValidationSchema({ customField: FormFields.otherMeetingPurpose, selectionField: FormFields.meetingPurposes }),
  [FormFields.endTime]: Yup.date().transform((v1, v2) => yupTransformDate(v1, v2, TIME_FORMAT))
    .typeError('Must be 24-hour "HH:MM" format')
    .required('Must be a valid time'),
  [FormFields.note]: Yup.string().nullable().required('Must not be empty'),
  [FormFields.startDate]: Yup.date().transform((v1, v2) => yupTransformDate(v1, v2))
    .typeError('Must be MM/DD/YYYY format')
    .required('Must not be empty'),
  [FormFields.startTime]: Yup.date().transform((v1, v2) => yupTransformDate(v1, v2, TIME_FORMAT))
    .typeError('Must be 24-hour "HH:MM" format')
    .required('Must be a valid time'),
})

type Values = typeof initialValues;
type ValueTypes = Values[keyof Values];
type FormType = FormikContextType<Values>
type FieldPropType = {field: FieldInputProps<ValueTypes>; form: FormType}
type ContainerProps = RouteComponentProps<any> & { goBack: () => any }
type MemberNoteDetailFormProps = ContainerProps & { form: FormType; isSubmitting: Boolean; uploadProgress: number }
type RouteParams = {id: string; memberId: string}

const getTimeParamsFromValues = ({endTime, startDate, startTime}: Pick<Values, 'endTime' | 'startDate' | 'startTime'>) => {
  const startedAt = getIsoDateFromString(`${startDate}-${startTime}`, {
    format: COMBINED_DATETIME_FORMAT,
  })
  if (startedAt == null) return {}

  const startedAtDate = parseISO(startedAt)
  const endedAt = getIsoDateFromString(`${startDate}-${endTime}`, {
    format: COMBINED_DATETIME_FORMAT,
    nextAfter: startedAtDate,
  })

  return {
    endedAt,
    startedAt,
    diffMinutes: endedAt == null ? 0 : differenceInMinutes(parseISO(endedAt), startedAtDate),
  }
}

const getCodesForTime = (minutes: number) => {
  const codeMap = getCodeMapForTime(minutes)

  return Object.keys(codeMap).reduce((memo, k) => {
    const nCodes = codeMap[k]
    const newPart = nCodes === 1 ? k : `${k} x ${nCodes}`
    return memo.length === 0 ? newPart : `${memo} + ${newPart}`
  }, '')
}

export const useHooks = () => {
  const dispatch = useDispatch()
  const toast = useToast()
  const { setDirtyFormAlert } = useContext(DirtyFormAlertContext)
  const { id, memberId } = useParams<RouteParams>()
  const form = useFormikContext<Values>()
  const history = useHistory()
  const [isMounted, setIsMounted] = useState(false)
  const [totalTime, setTotalTime] = useState(DEFAULT_TOTAL_TIME)
  const [fileUrl, setFileUrl] = useState('')
  const [filename, setFilename] = useState('')
  const [filetype, setFiletype] = useState('')
  const {
    initializeOptions: initializeAttendees,
    isOtherInvalid: isInvalidOtherAttendee,
    isOtherOptionSelected: isOtherAttendeeSelected,
    onClickOption: onClickAttendee,
  } = useCustomSelectOption({
    customField: FormFields.treatingPhysician,
    selectionField: FormFields.attendeeIds,
  })
  const {
    initializeOptions: initializePurposes,
    isOtherInvalid: isInvalidOtherPurpose,
    isOtherOptionSelected: isOtherPurposeSelected,
    onClickOption: onClickPurpose,
  } = useCustomSelectOption({
    customField: FormFields.otherMeetingPurpose,
    selectionField: FormFields.meetingPurposes,
  })
  const isNew = RoutePaths.NEW_RECORD_ID === id

  const {
    allusers,
    creatingState,
    isStoreLoading,
    meetingPurposeRecords,
    teamMeeting,
    updatingState,
  } = useSelector(({ meetingPurposes, users, teamMeetings }: StoreState) => ({
    allusers: users.records,
    creatingState: teamMeetings.creatingState,
    isStoreLoading: isLoading(users.loadingState, teamMeetings.loadingState, meetingPurposes.loadingState),
    meetingPurposeRecords: meetingPurposes.records,
    teamMeeting: isNew ? undefined : teamMeetings.records[id],
    updatingState: teamMeetings.updatingState,
  }), shallowEqual)

  const popularPurposeNames = Object.values(meetingPurposeRecords).map(({name}) => name)
  const member = allusers[memberId]
  const advocates = member == null ? [] : member.advocateIds!.map((aId) => allusers[aId])
  const primaryCareProviders = member == null ? [] : member.primaryCareProviderIds!.map((pcpId) => allusers[pcpId])
  const surrogates = member == null ? [] : member.surrogateIds!.concat(member.alternateSurrogateIds!).map((sId) => allusers[sId])

  const isPageLoading = !isMounted || isStoreLoading || member == null
  const isUpdatingError = isError(updatingState)
  const isUpdatingDone = isDone(updatingState)
  const isCreatingError = isError(creatingState)
  const isCreatingDone = isDone(creatingState)

  useEffect(() => {
    setIsMounted(true)
  }, [])

  useEffect(() => {
    if (isPageLoading || teamMeeting == null) return

    const newTimeValues = {
      [FormFields.endTime]: formatDate(parseISO(teamMeeting.endedAt), TIME_FORMAT) as any,
      [FormFields.startDate]: formatDate(parseISO(teamMeeting.startedAt), DATE_FORMAT) as any,
      [FormFields.startTime]: formatDate(parseISO(teamMeeting.startedAt), TIME_FORMAT) as any,
    }
    const { diffMinutes = 0 } = getTimeParamsFromValues(newTimeValues)

    setTotalTime(diffMinutes)
    setFileUrl(teamMeeting.noteFile)
    setFilename(teamMeeting.noteFileFilename)
    setFiletype(teamMeeting.noteFileType)
    form.reinitialize({
      ...newTimeValues,
      [FormFields.attendeeIds]: teamMeeting.attendeeIds,
      [FormFields.note]: teamMeeting.note,
    })
    initializeAttendees({
      allOptions: teamMeeting.attendeeIds,
      initialSelection: teamMeeting.attendeeIds.concat(teamMeeting.treatingPhysicianName || '').filter(Boolean),
    })
    initializePurposes({
      allOptions: popularPurposeNames,
      initialSelection: teamMeeting.meetingPurposeNames,
    })
  }, [isPageLoading])

  useEffect(() => {
    if (!isNew) {
      dispatch(getTeamMeeting(id))
    }

    dispatch(getUsers({ teamUserId: memberId }))
    dispatch(getMeetingPurposes())
  }, [id])

  useEffect(() => {
    if (!isMounted) return

    if (isUpdatingError || isCreatingError) {
      form.setSubmitting(false)
      toast({
        description: 'Changes were not saved; try again.',
        status: 'error',
      })
    }
  }, [isUpdatingError, isCreatingError])

  useEffect(() => {
    if (!isMounted) return

    if (isUpdatingDone) {
      setDirtyFormAlert(false,  () => history.push(RoutePaths.MemberNotes(member.id)))
      toast({
        description: 'Note updated successfully.',
        status: 'success',
      })
    }
  }, [isUpdatingDone])

  useEffect(() => {
    if (!isMounted) return

    if (isCreatingDone) {
      setDirtyFormAlert(false,  () => history.push(RoutePaths.MemberNotes(member.id)))
      toast({
        description: 'Note created successfully.',
        status: 'success',
      })
    }
  }, [isCreatingDone])

  useEffect(() => {
    setDirtyFormAlert(form.dirty)
  }, [form.dirty])

  const onChangeTime = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()

    const { name, value } = e.currentTarget
    form.setFieldValue(name, value)

    const { diffMinutes = 0 } = getTimeParamsFromValues({...form.values, [name]: value})
    setTotalTime(diffMinutes)
  }

  return {
    advocates,
    form,
    history,
    isInvalidOtherAttendee,
    isInvalidOtherPurpose,
    isNew,
    isOtherAttendeeSelected,
    isOtherPurposeSelected,
    isPageLoading,
    member,
    onChangeTime,
    onClickAttendee,
    onClickPurpose,
    popularPurposeNames,
    primaryCareProviders,
    surrogates,
    totalTime,
    fileUrl,
    filename,
    filetype,
  }
}

const MemberNoteDetailForm: React.FC<MemberNoteDetailFormProps> = (props) => {
  const {
    advocates,
    form,
    history,
    isInvalidOtherAttendee,
    isInvalidOtherPurpose,
    isNew,
    isOtherAttendeeSelected,
    isOtherPurposeSelected,
    isPageLoading,
    member,
    onChangeTime,
    onClickAttendee,
    onClickPurpose,
    popularPurposeNames,
    primaryCareProviders,
    surrogates,
    totalTime,
    fileUrl,
    filename,
    filetype,
  } = useHooks()

  const { colors: { brand } } = useTheme()

  if (isPageLoading || props.isSubmitting)
    return (
      <Center paddingTop='50px'>
        {
          props.isSubmitting &&
          <>
            <Text fontSize='12px' marginRight='-40px'>{`${props.uploadProgress}%`}</Text>
          </>
        }
        <Spinner size='xl'/>
      </Center>
    )
  return (
    <Container maxW='1100px' mt='132px' mb='10vh'>
      <Box as={Link} to={RoutePaths.MemberNotes(member.id)} fontWeight={500} color={brand[500]}>
        <Icon as={FiChevronLeft} display='inline' marginRight='6px'/>
          Back
      </Box>
      <Flex align='center' h='80px' mt='28px'>
        <Text fontSize='42px' fontWeight='500'>{isNew ? 'New' : 'Edit'} Progress Note</Text>
        <Spacer />
        <Button
          mr={4} borderRadius='3px'
          fontSize='16px'
          lineHeight='24px'
          variant='transparentRedOutline'
          leftIcon={<FiXCircle />}
          onClick={() => history.goBack()}
        >Cancel</Button>
        <Button
          type='submit'
          borderRadius='3px'
          fontSize='16px'
          lineHeight='24px'
          leftIcon={<FiSave />}
          onClick={() => {
            form.submitForm()}
          }
        >Save</Button>
      </Flex>

      <Box width='825px' mt={4}>
        <Form>
          <Stack direction='row' spacing={4} width='100%' position='relative' zIndex='100'>
            <Field name={FormFields.startDate}>
              {({ field }: FieldPropType) => (
                <FormControl isInvalid={!isEmpty(form.errors.startDate) && form.touched.startDate}>
                  <FormLabel htmlFor={field.name}>Date</FormLabel>
                  <InputGroup size='lg'>
                    <DatePicker
                      field={field}
                      initialDate={new Date()}
                      inputFormatter={parseDate}
                      inputProps={{size: 'lg'}}
                      placeholderText='MM/DD/YYYY'
                      showMonthDropdown
                    />
                    <InputRightElement><Icon as={FiCalendar} color={textPalette.lightest}/></InputRightElement>
                  </InputGroup>
                  <FormErrorMessage><FormErrorIcon as={FiAlertTriangle} />{form.errors.startDate}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name={FormFields.startTime}>
              {({ field }: FieldPropType) => (
                <FormControl isInvalid={!isEmpty(form.errors.startTime) && form.touched.startTime}>
                  <FormLabel htmlFor={field.name}>Start Time</FormLabel>
                  <InputGroup size='lg'>
                    <Input {...field} id={field.name} type='time' onChange={onChangeTime}/>
                    <InputRightElement><Icon as={FiClock} color={textPalette.lightest}/></InputRightElement>
                  </InputGroup>
                  <FormErrorMessage>{form.errors.startTime}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name={FormFields.endTime}>
              {({ field }: FieldPropType) => (
                <FormControl isInvalid={!isEmpty(form.errors.endTime) && form.touched.endTime}>
                  <FormLabel htmlFor={field.name}>End Time</FormLabel>
                  <InputGroup size='lg'>
                    <Input {...field} id={field.name} type='time' onChange={onChangeTime}/>
                    <InputRightElement><Icon as={FiClock} color={textPalette.lightest}/></InputRightElement>
                  </InputGroup>
                  <FormErrorMessage>{form.errors.endTime}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </Stack>
          <Stack direction='row' spacing={4} width='100%' mt={ROW_SPACING}>
            <FormControl>
              <FormLabel htmlFor='totalTime'>Total Time</FormLabel>
              <InputGroup size='lg'>
                <Input isDisabled id='totalTime' value={`${totalTime} min`} />
                <InputRightElement>
                  <Icon as ={FiLock} color={textPalette.lightest}/>
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <FormControl>
              <FormLabel htmlFor='cptCodes'>CPT Code(s)</FormLabel>
              <InputGroup size='lg'>
                <Input isDisabled id='cptCodes' value={getCodesForTime(totalTime)} />
                <InputRightElement>
                  <Icon as ={FiLock} color={textPalette.lightest}/>
                </InputRightElement>
              </InputGroup>
            </FormControl>
            <Box w='100%' />
          </Stack>
          <Field name={FormFields.note}>
            {({ field }: FieldPropType) => (
              <FormControl  mt={ROW_SPACING} isInvalid={!isEmpty(form.errors.note) && form.touched.note as any}>
                <FormLabel fontSize='16px' color={textPalette.light} htmlFor={field.name}>Summarize today’s conversation:</FormLabel>
                <Textarea {...field} bg={white} minH='160px' id={field.name} placeholder='Today we spoke about...'/>
                <FormErrorMessage><FormErrorIcon as={FiAlertTriangle} />{form.errors.note}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name={FormFields.attendeeIds}>
            {({ field }: FieldPropType) => (
              <FormControl mt={ROW_SPACING}>
                <FormLabel fontSize='16px' color={textPalette.light}>Who was present?</FormLabel>
                <Checkbox
                  mt='16px'
                  id={field.name + member.id}
                  key={'member' + member.id}
                  colorScheme='brand'
                  display='flex'
                  isChecked={field.value?.includes(member.id)}
                  value={undefined}
                  onChange={() => onClickAttendee(member.id)}>
                  <Text ml='16px' color={textPalette.normal} fontSize='16px' fontWeight='500'>
                    {member.firstName}&nbsp;{member.lastName}&nbsp;(Member)
                  </Text>
                </Checkbox>
                {advocates.map((a) => {
                  return (
                    <Checkbox
                      mt='16px'
                      id={field.name + a.id}
                      key={'advocate' + a.id}
                      colorScheme='brand'
                      display='flex'
                      isChecked={field.value?.includes(a.id)}
                      value={undefined}
                      onChange={() => onClickAttendee(a.id)}>
                      <Text ml='16px' color={textPalette.normal} fontSize='16px' fontWeight='500'>
                        {a.firstName}&nbsp;{a.lastName}&nbsp;(Advocate)
                      </Text>
                    </Checkbox>
                  )
                })}
                {surrogates.map((s) => {
                  return (
                    <Checkbox
                      mt='16px'
                      id={field.name + s.id}
                      key={'Agent' + s.id}
                      colorScheme='brand'
                      display='flex'
                      isChecked={field.value?.includes(s.id)}
                      value={undefined}
                      onChange={() => onClickAttendee(s.id)}>
                      <Text ml='16px' color={textPalette.normal} fontSize='16px' fontWeight='500'>
                        {s.firstName}&nbsp;{s.lastName}&nbsp;(Agent)
                      </Text>
                    </Checkbox>
                  )
                })}
                {primaryCareProviders.map((pcp) => {
                  return (
                    <Checkbox
                      mt='16px'
                      id={field.name + pcp.id}
                      key={'primaryCareProvider' + pcp.id}
                      colorScheme='brand'
                      display='flex'
                      isChecked={field.value?.includes(pcp.id)}
                      value={undefined}
                      onChange={() => onClickAttendee(pcp.id)}>
                      <Text ml='16px' color={textPalette.normal} fontSize='16px' fontWeight='500'>
                        {pcp.firstName}&nbsp;{pcp.lastName}&nbsp;(Primary Care Provider)
                      </Text>
                    </Checkbox>
                  )
                })}
                <Checkbox
                  mt='16px'
                  id={OTHER_VALUE}
                  colorScheme='brand'
                  display='flex'
                  key={OTHER_VALUE}
                  isChecked={isOtherAttendeeSelected}
                  value={undefined}
                  onChange={() => onClickAttendee(OTHER_VALUE)}>
                  <Text ml='16px' color={textPalette.normal} fontSize='16px' fontWeight='500'>
                    Treating Physician
                  </Text>
                </Checkbox>
              </FormControl>
            )}
          </Field>
          <Field name={FormFields.treatingPhysician}>
            {({ field }: FieldPropType) => (
              <FormControl marginTop='0 !important' marginLeft='28px' mt='16px' isInvalid={isInvalidOtherAttendee}>
                <FormLabel htmlFor={field.name} hidden={!isOtherAttendeeSelected}>Physician Name</FormLabel>
                <Input {...field} id={field.name} type='text' maxLength={128} hidden={!isOtherAttendeeSelected}/>
                <FormErrorMessage><FormErrorIcon as={FiAlertTriangle} />{form.errors.attendeeIds || form.errors.treatingPhysician}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name={FormFields.meetingPurposes}>
            {({ field }: FieldPropType) => (
              <FormControl mt={ROW_SPACING}>
                <FormLabel fontSize='16px' color={textPalette.light}>What was the purpose of today’s meeting?</FormLabel>
                {popularPurposeNames.concat(OTHER_VALUE).map((purposeName) => (
                  <Checkbox
                    mt='16px'
                    id={purposeName}
                    colorScheme='brand'
                    display='flex'
                    key={purposeName}
                    isChecked={field.value?.includes(purposeName)}
                    value={undefined}
                    onChange={() => onClickPurpose(purposeName)}>
                    <Text ml='16px' color={textPalette.normal} fontSize='16px' fontWeight='500'>
                      {purposeName}
                    </Text>
                  </Checkbox>
                ))}
              </FormControl>
            )}
          </Field>
          <Field name={FormFields.otherMeetingPurpose}>
            {({ field }: FieldPropType) => (
              <FormControl marginTop='0 !important' marginLeft='28px' mt='16px' isInvalid={isInvalidOtherPurpose}>
                <Input {...field} id={field.name} type='text' maxLength={128} hidden={!isOtherPurposeSelected}/>
                <FormErrorMessage><FormErrorIcon as={FiAlertTriangle} />{form.errors.meetingPurposes || form.errors.otherMeetingPurpose}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Stack direction='row' spacing={2} alignSelf='flex-start' alignItems='center'>
            <Field name={FormFields.noteFile}>
              {({ field }: FieldPropType) => (
                <FormControl mt={ROW_SPACING} isInvalid={!isEmpty(form.errors.noteFile)} width='inherit'>
                  <FileUpload
                    {...field}
                    id={field.name}
                    initialValue={fileUrl !== '' ? privateFileUrl(fileUrl) : ''}
                    filename={filename}
                    filetype={filetype}
                    onChange={file => {
                      form.setFieldValue(FormFields.noteFile, file)
                    }}
                  />
                  <FormErrorMessage><FormErrorIcon as={FiAlertTriangle} />{form.errors.noteFile}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </Stack>
        </Form>
      </Box>
    </Container>
  )
}

const MemberNoteDetailFormContainer: React.FC<ContainerProps> = (props) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const dispatch = ReactRedux.useDispatch()
  const { id, memberId } = useParams<RouteParams>()
  const isNew = RoutePaths.NEW_RECORD_ID === id
  const [uploadProgress, setUploadProgress] = useState(0)
  const onUploadProgress = ({progress}: {progress: number}) => setUploadProgress(progress)

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ValidationSchema}
      onSubmit={({
        attendeeIds,
        meetingPurposes,
        treatingPhysician,
        otherMeetingPurpose,
        noteFile,
        ...values
      }: Values) => {
        setIsSubmitting(true)
        const {
          customValue: customPurposeValue,
          selectedValues: selectedPurposeValues,
        } = getOptionValues(otherMeetingPurpose, meetingPurposes)
        const {
          customValue: customAttendeeValue,
          selectedValues: selectedAttendeeValues,
        } = getOptionValues(treatingPhysician, attendeeIds)
        const {
          endedAt,
          startedAt,
        } = getTimeParamsFromValues(values)

        const baseParams = {
          attendeeIds: selectedAttendeeValues,
          endedAt,
          meetingPurposes: [customPurposeValue, ...selectedPurposeValues].filter(Boolean),
          treatingPhysicianName: customAttendeeValue || null, // set to null if undefined to ensure any previous value is cleared
          startedAt,
        }
        setUploadProgress(0)

        if (isNew) {
          uploadFile(noteFile, onUploadProgress).then(noteFileId => {
            dispatch(createTeamMeeting({ ...values, memberId, noteFile: noteFileId, ...baseParams  }))
          })
          return
        }
        uploadFile(noteFile, onUploadProgress).then(noteFileId => {
          dispatch(updateTeamMeeting({ ...values, noteFile: (noteFileId || null), ...baseParams }, id))
        })

      }}
    >{(formik) => <MemberNoteDetailForm {...props} isSubmitting={isSubmitting} uploadProgress={uploadProgress} form={formik} />}</Formik>
  )
}

export default MemberNoteDetailFormContainer
