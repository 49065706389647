import React, { useContext, useEffect, useState } from 'react'
import * as ReactRedux from 'react-redux'
import * as Yup from 'yup'
import { ChildFormProps as ContainerProps, formValueDTO } from '.'
import { update as updateDirective } from '../../../../redux/directives/actions'
import { Context as DirtyFormAlertContext } from '../../../../components/DirtyFormAlert'
import { Field, FieldInputProps, Form, Formik, FormikContextType } from 'formik'
import { isError, isLoading } from '../../../../redux/AsyncState'
import { StoreState } from '../../../../redux'
import 'react-international-phone/style.css'
import { CountrySelector, usePhoneInput } from 'react-international-phone'

import {
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  Select,
  Stack,
  Text,
  ChakraProvider,
} from '@chakra-ui/react'
import { textPalette, yellows } from '../../../../constants/Colors'
import { FiSave, FiPlus, FiX } from 'react-icons/fi'
import isEmpty from 'lodash.isempty'
import { isPhoneValid } from '../../../../utils/Forms'
import Countries from '../../../../constants/Countries'
import { useSelector } from 'react-redux'
import { AppointSurrogateFormFields as FormFields, AppointSurrogateFormErrorFields as ErrorField, HealthcarePt1Steps } from '../../../../constants/Directive'
import { getAllLoadedUsers, getAppointSurrogateFormFields  } from '../../../../redux/directives/selector'
import MissingFieldsBox from '../MissingFieldsBox'

enum PlaceHolders {
  pronouns = 'He/Him or She/Her or They/Them'
}

Yup.addMethod(Yup.string, 'phone', function (errorMessage) {
  return this.test('test-phone-no', errorMessage, function (value) {
    const { path, createError } = this

    return (
      isPhoneValid(String(value)) ||
      createError({ path, message: errorMessage })
    )
  })
})

const fullNameValidation = Yup.string().required(ErrorField.fullNameError)
const emailValidation = Yup.string().required(ErrorField.emailError).email('Must be a valid email')
//@ts-ignore
const phoneValidation = Yup.string().phone('Phone number is not valid')
const alternateFullNameValidation = Yup.string().required(ErrorField.alternateFullNameError)
const alternateEmailValidation = Yup.string().required(ErrorField.alternateEmailError).email('Must be a valid email')
//@ts-ignore
const secondPhoneValidation = Yup.string().phone('Phone number is not valid')
const secondFullNameValidation = Yup.string().required(ErrorField.secondAlternateFullNameError)
const secondEmailValidation = Yup.string().required(ErrorField.secondAlternateEmailError).email('Must be a valid email')
//@ts-ignore
const alternatePhoneValidation = Yup.string().phone('Phone number is not valid')


export const ValidationSchema = Yup.object().shape({
  [FormFields.fullName]: fullNameValidation,
  [FormFields.email]: emailValidation,
  [FormFields.phone]: phoneValidation,
  ...[
    [FormFields.alternateFullName, alternateFullNameValidation],
    [FormFields.alternateEmail, alternateEmailValidation],
    [FormFields.alternatePhone, alternatePhoneValidation],
  ].reduce((memo, [fieldName, validation]) => ({
    ...memo,
    [fieldName as any]: Yup.string().when([FormFields.hasAlternate], {
      is: (hasAlternate) => hasAlternate,
      then: validation as any,
    }),
  }), {}),
  ...[
    [FormFields.secondAlternatePhone, secondPhoneValidation],
    [FormFields.secondAlternateFullName, secondFullNameValidation],
    [FormFields.secondAlternateEmail, secondEmailValidation],
  ].reduce((memo, [fieldName, validation]) => ({
    ...memo,
    [fieldName as any]: Yup.string().when([FormFields.hasSecondAlternate], {
      is: (hasSecondAlternate) => hasSecondAlternate,
      then: validation as any,
    }),
  }), {}),
})

const initialValues = {
  [FormFields.fullName]: '',
  [FormFields.pronouns]: '',
  [FormFields.memberRelationship]: '',
  [FormFields.email]: '',
  [FormFields.phone]: '',
  [FormFields.streetAddress]: '',
  [FormFields.city]: '',
  [FormFields.state]: '',
  [FormFields.country]: '',
  [FormFields.zipCode]: '',
  [FormFields.hasAlternate]: false as any,
  [FormFields.hasSecondAlternate]: false as any,
  [FormFields.alternateFullName]: '',
  [FormFields.alternatePronouns]: '',
  [FormFields.alternateMemberRelationship]: '',
  [FormFields.alternateEmail]: '',
  [FormFields.alternatePhone]: '',
  [FormFields.alternateStreetAddress]: '',
  [FormFields.alternateCity]: '',
  [FormFields.alternateState]: '',
  [FormFields.alternateCountry]: '',
  [FormFields.alternateZipCode]: '',
  [FormFields.secondAlternateFullName]: '',
  [FormFields.secondAlternatePronouns]: '',
  [FormFields.secondAlternateMemberRelationship]: '',
  [FormFields.secondAlternateEmail]: '',
  [FormFields.secondAlternatePhone]: '',
  [FormFields.secondAlternateStreetAddress]: '',
  [FormFields.secondAlternateCity]: '',
  [FormFields.secondAlternateState]: '',
  [FormFields.secondAlternateCountry]: '',
  [FormFields.secondAlternateZipCode]: '',
}

type Values = typeof initialValues;
type ValueTypes = Values[keyof Values];
type FormType = FormikContextType<Values>
type FieldPropType = { field: FieldInputProps<ValueTypes>; form: FormType }
export type AppointSurrogateFormProps = ContainerProps & { form: FormType }

const RelationshipSelect = ({field, style}: {field: FieldInputProps<any>; style: any}) => (
  <Select style={style} {...field} placeholder='Select' size='lg' id={field.name}>
    <option>Father</option>
    <option>Mother</option>
    <option>Spouse</option>
    <option>Partner</option>
    <option>Sibling</option>
    <option>Child</option>
    <option>Friend</option>
    <option>Cousin</option>
    <option>Other Family Member</option>
    <option>Other Professional</option>
  </Select>
)

export const useHooks = ({ form, directive }: AppointSurrogateFormProps) => {
  const { setDirtyFormAlert } = useContext(DirtyFormAlertContext)
  const { shallowEqual } = ReactRedux

  const {
    errorData,
    updatingState,
  } = useSelector(({ directives }: StoreState) => ({
    errorData: directives.errorData,
    updatingState: directives.updatingState,
    records: directives.records,
  }), shallowEqual)

  const allLoadedUsers = useSelector((state: StoreState) => getAllLoadedUsers(state))
  const [hasOrClickedAlternate, setHasOrClickedAlternate] = useState<boolean>(false)
  const [hasOrClickedSecondAlternate, setHasOrClickedSecondAlternate] = useState<boolean>(false)
  const [errors, setErrors] = useState<string[]>()
  const member = allLoadedUsers[directive.memberId]
  const sId = member?.surrogateIds![0]
  const aId = member?.alternateSurrogateIds![0]
  const bId = member?.alternateSurrogateIds![1]
  const surrogate = allLoadedUsers[sId]
  const alternateSurrogate = allLoadedUsers[aId]
  const secondAlternateSurrogate = allLoadedUsers[bId]
  const isUpdating = isLoading(updatingState)

  const appointSurrogateFormFields = useSelector(getAppointSurrogateFormFields)

  const setAlternateSurrogateFlags = (value) => {
    setHasOrClickedAlternate(value)
    form.setFieldValue(FormFields.hasAlternate, value)
  }

  const setSecondAlternateSurrogateFlags = (value) => {
    setHasOrClickedSecondAlternate(value)
    form.setFieldValue(FormFields.hasSecondAlternate, value)
  }

  useEffect(() => {
    const currentForm = {
      values: {
        ...form.values,
        ...appointSurrogateFormFields,
      },
    }

    setAlternateSurrogateFlags(alternateSurrogate != null)
    setSecondAlternateSurrogateFlags(secondAlternateSurrogate != null)
    form.resetForm(currentForm)

    if(member?.healthcarePt1DirectiveStepsCompleted && member?.healthcarePt1DirectiveStepsCompleted >= HealthcarePt1Steps.APPOINT_SURROGATE) {
      try {
        ValidationSchema.validateSync(currentForm.values, {abortEarly: false})
        setErrors([])
      } catch (err: any) {
        setErrors(err.errors)
      }
    }
  }, [alternateSurrogate, secondAlternateSurrogate, directive, surrogate, appointSurrogateFormFields])


  const isUpdateError = isError(updatingState)

  useEffect(() => {
    if (!isUpdateError) return

    const errorModel = Object.keys(errorData)[0]
    if (errorModel == null) return

    const errorModelData = errorData[errorModel]

    if (typeof errorModelData === 'object' && errorModelData.email != null) {
      form.setFieldError(
        errorModel === 'surrogate' ? FormFields.email : FormFields.alternateEmail,
        `Email ${errorModelData.email.join(', ')}`,
      )
    }

    form.setSubmitting(false)
  }, [errorData, isUpdateError])

  useEffect(() => {
    setDirtyFormAlert({ blockSearchChanges: true, isDirty: form.dirty })
  }, [form.dirty])

  return {
    isUpdating,
    setHasOrClickedAlternate,
    hasOrClickedAlternate,
    errors,
    setAlternateSurrogateFlags,
    setSecondAlternateSurrogateFlags,
    hasOrClickedSecondAlternate,
  }
}

const AppointSurrogateForm: React.FC<AppointSurrogateFormProps> = (props) => {
  const { isUpdating, hasOrClickedAlternate, hasOrClickedSecondAlternate, errors, setAlternateSurrogateFlags, setSecondAlternateSurrogateFlags } = useHooks(props)
  const { continueLater, currentStep, form, totalSteps } = props

  const hasError = (errorType: string) => errors?.includes(errorType) ? { borderColor: yellows.missingInfo } : {}

  const phoneInput = usePhoneInput({
    defaultCountry: 'us',
    value: form.values['phone'],
    onChange: (data) => {
      form.values['phone'] = data.phone
    },
  })

  const alternatePhoneInput = usePhoneInput({
    defaultCountry: 'us',
    value: form.values['alternatePhone'],
    onChange: (data) => {
      form.values['alternatePhone'] = data.phone
    },
  })

  const secondAlternatePhoneInput = usePhoneInput({
    defaultCountry: 'us',
    value: form.values['secondAlternatePhone'],
    onChange: (data) => {
      form.values['secondAlternatePhone'] = data.phone
    },
  })

  return (
    <>
      <Form>
        <Stack align='center' spacing='20px' maxWidth='540px'>
          <Text fontSize='16px' color={textPalette.light}>Step {currentStep} of {totalSteps}</Text>
          <Text fontSize='26px'>Naming my Healthcare Agent</Text>
          {errors && errors?.length > 0 && <MissingFieldsBox errors={errors}/>}
          <Text textAlign='left' color={textPalette.light}>
            A health care agent is someone I elect to make health care decisions in the circumstance I am unable to speak for myself.   This could be due to advanced illness, end of life, or a sudden and unexpected accident or health event. A medical provider makes the determination when I lose the capacity to make my own health care decisions. Until that happens, I maintain autonomy over my health care decisions.
          </Text>
          <Text textAlign='left' color={textPalette.light}>

            A health care agent can be a family member or friend who understands and respects my wishes, values, and goals, is willing to accept the responsibility of this role, and handles stressful situations well. The agent must be over the age of 18 (19 in AL and NE; 21 in CO) and cannot be my current healthcare provider or an employee of that provider unless we are legally related (blood, marriage, or domestic partnership).
          </Text>
          <Text textAlign='left' color={textPalette.light}>
          When I am unable to speak for myself, I appoint ________________ (including any alternate agents) to make health care decisions for me (including any alternate agents).
          </Text>
          <Field name={FormFields.fullName}>
            {({ field }: FieldPropType) => (
              <FormControl isInvalid={!isEmpty(form.errors.fullName) && form.touched.fullName}>
                <FormLabel htmlFor={field.name}>Full Name</FormLabel>
                <Input {...field} id={field.name} size='lg'  />
                <FormErrorMessage>{form.errors.fullName}</FormErrorMessage>
              </FormControl>
            )}
          </Field>

          <Field name={FormFields.pronouns}>
            {({ field }: FieldPropType) => (
              <FormControl isInvalid={!isEmpty(form.errors.pronouns) && form.touched.pronouns}>
                <FormLabel htmlFor={field.name}>Pronouns</FormLabel>
                <Input {...field} id={field.name} placeholder={PlaceHolders.pronouns} size='lg'  />
                <FormErrorMessage>{form.errors.pronouns}</FormErrorMessage>
              </FormControl>
            )}
          </Field>

          <Field name={FormFields.memberRelationship}>
            {({ field }: FieldPropType) => (
              <FormControl>
                <FormLabel htmlFor={field.name}>Relationship of my Healthcare Agent to me</FormLabel>
                <RelationshipSelect style={hasError(ErrorField.memberRelationshipError)} field={field}/>
                <FormErrorMessage>{form.errors.memberRelationship}</FormErrorMessage>
              </FormControl>
            )}
          </Field>

          <Flex direction='row' w='100%' mt='17px'>
            <Field name={FormFields.phone}>
              {({ field }: FieldPropType) => (
                <FormControl isInvalid={!isEmpty(form.errors.phone) && form.touched.phone} pr='16px'>
                  <FormLabel htmlFor={field.name}>Phone</FormLabel>
                  <div style={{display: 'flex'}} >
                    <ChakraProvider>
                      <CountrySelector
                        selectedCountry={phoneInput.country.iso2}
                        onSelect={(country) => phoneInput.setCountry(country.iso2)}
                        renderButtonWrapper={({ children, rootProps }) => (
                          <Button {...rootProps} variant='outline' px='4px' size='lg'>
                            {children}
                          </Button>
                        )}
                      />
                    </ChakraProvider>
                    <Input
                      placeholder='Phone number'
                      type='tel'
                      style={hasError(ErrorField.phoneError)}
                      size='lg'
                      value={phoneInput.inputValue}
                      onChange={phoneInput.handlePhoneValueChange}
                      ref={phoneInput.inputRef}
                    />
                  </div>
                  <FormErrorMessage>{form.errors.phone}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name={FormFields.email}>
              {({ field }: FieldPropType) => (
                <FormControl isInvalid={!isEmpty(form.errors.email) && form.touched.email} >
                  <FormLabel htmlFor={field.name}>Email</FormLabel>
                  <Input  style={hasError(ErrorField.emailError)} {...field} id={field.name} size='lg' />
                  <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </Flex>

          <Field name={FormFields.streetAddress}>
            {({ field }: FieldPropType) => (
              <FormControl isInvalid={!isEmpty(form.errors.streetAddress) && form.touched.streetAddress}>
                <FormLabel htmlFor={field.name}>Street Address</FormLabel>
                <Input style={hasError(ErrorField.streetAddressError)}{...field} id={field.name} size='lg' />
                <FormErrorMessage>{form.errors.streetAddress}</FormErrorMessage>
              </FormControl>
            )}
          </Field>

          <Flex direction='row' w='100%' mt='17px'>
            <Field name={FormFields.city}>
              {({ field }: FieldPropType) => (
                <FormControl isInvalid={!isEmpty(form.errors.city) && form.touched.city} pr='16px'>
                  <FormLabel htmlFor={field.name}>City</FormLabel>
                  <Input style={hasError(ErrorField.cityError)} {...field} id={field.name} size='lg' />
                  <FormErrorMessage>{form.errors.city}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name={FormFields.state}>
              {({ field }: FieldPropType) => (
                <FormControl isInvalid={!isEmpty(form.errors.state) && form.touched.state}>
                  <FormLabel htmlFor={field.name}>State/Province</FormLabel>
                  <Input style={hasError(ErrorField.stateError)} {...field} id={field.name} size='lg' />
                  <FormErrorMessage>{form.errors.state}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </Flex>

          <Flex direction='row' w='100%' mt='17px'>
            <Field name={FormFields.country}>
              {({ field }: FieldPropType) => (
                <FormControl isInvalid={!isEmpty(form.errors.country) && form.touched.country} pr='16px'>
                  <FormLabel htmlFor={field.name}>Country</FormLabel>
                  <Select style={hasError(ErrorField.countryError)} {...field} size='lg' placeholder='Select' id={field.name}>
                    {Object.keys(Countries).map(key => (
                      <option
                        aria-selected='true'
                        key={key}
                        value={key}
                      >{Countries[key]}</option>
                    ))}
                  </Select>
                  <FormErrorMessage>{form.errors.country}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name={FormFields.zipCode}>
              {({ field }: FieldPropType) => (
                <FormControl isInvalid={!isEmpty(form.errors.zipCode) && form.touched.zipCode}>
                  <FormLabel htmlFor={field.name}>ZIP/Postal Code</FormLabel>
                  <Input style={hasError(ErrorField.zipCodeError)} {...field} id={field.name} size='lg' />
                  <FormErrorMessage>{form.errors.zipCode}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </Flex>

          {hasOrClickedAlternate ? <>
            <Text textAlign='left' color={textPalette.light}>
            If my chosen Agent is unable or unwilling to be my Healthcare Agent, I trust and appoint ________________ to be my Alternate Healthcare Agent instead.
            </Text>
            <Field name={FormFields.alternateFullName}>
              {({ field }: FieldPropType) => (
                <FormControl isInvalid={!isEmpty(form.errors.alternateFullName) && form.touched.alternateFullName}>
                  <FormLabel htmlFor={field.name}>Healthcare Agent Name</FormLabel>
                  <Input style={hasError(ErrorField.alternateFullNameError)} {...field} id={field.name} size='lg' />
                  <FormErrorMessage>{form.errors.alternateFullName}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name={FormFields.alternatePronouns}>
              {({ field }: FieldPropType) => (
                <FormControl isInvalid={!isEmpty(form.errors.alternatePronouns) && form.touched.alternatePronouns}>
                  <FormLabel htmlFor={field.name}>Pronouns</FormLabel>
                  <Input style={hasError(ErrorField.alternatePronounsError)} {...field} placeholder={PlaceHolders.pronouns} id={field.name} size='lg' />
                  <FormErrorMessage>{form.errors.alternatePronouns}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name={FormFields.alternateMemberRelationship}>
              {({ field }: FieldPropType) => (
                <FormControl isInvalid={!isEmpty(form.errors.alternateMemberRelationship) && form.touched.alternateMemberRelationship}>
                  <FormLabel htmlFor={field.name}>Relationship of my Healthcare Agent to me</FormLabel>
                  <RelationshipSelect style={hasError(ErrorField.alternateMemberRelationshipError)} field={field}/>
                  <FormErrorMessage>{form.errors.alternateMemberRelationship}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Flex direction='row' w='100%' mt='17px'>
              <Field name={FormFields.alternatePhone}>
                {({ field }: FieldPropType) => (
                  <FormControl isInvalid={!isEmpty(form.errors.alternatePhone) && form.touched.alternatePhone} pr='16px'>
                    <FormLabel htmlFor={field.name}>Phone</FormLabel>
                    <div style={{display: 'flex'}} >
                      <ChakraProvider>
                        <CountrySelector
                          selectedCountry={alternatePhoneInput.country.iso2}
                          onSelect={(country) => alternatePhoneInput.setCountry(country.iso2)}
                          renderButtonWrapper={({ children, rootProps }) => (
                            <Button {...rootProps} variant='outline' px='4px' size='lg'>
                              {children}
                            </Button>
                          )}
                        />
                      </ChakraProvider>
                      <Input
                        placeholder='Phone number'
                        type='tel'
                        style={hasError(ErrorField.phoneError)}
                        size='lg'
                        value={alternatePhoneInput.inputValue}
                        onChange={alternatePhoneInput.handlePhoneValueChange}
                        ref={alternatePhoneInput.inputRef}
                      />
                    </div>
                    <FormErrorMessage>{form.errors.alternatePhone}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name={FormFields.alternateEmail}>
                {({ field }: FieldPropType) => (
                  <FormControl isInvalid={!isEmpty(form.errors.alternateEmail) && form.touched.alternateEmail}>
                    <FormLabel htmlFor={field.name}>Email</FormLabel>
                    <Input style={hasError(ErrorField.alternateEmailError)} {...field} id={field.name} size='lg' />
                    <FormErrorMessage>{form.errors.alternateEmail}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </Flex>

            <Field name={FormFields.alternateStreetAddress}>
              {({ field }: FieldPropType) => (
                <FormControl isInvalid={!isEmpty(form.errors.alternateStreetAddress) && form.touched.alternateStreetAddress}>
                  <FormLabel htmlFor={field.name}>Street Address</FormLabel>
                  <Input style={hasError(ErrorField.alternateStreetAddressError)} {...field} id={field.name} size='lg' />
                  <FormErrorMessage>{form.errors.alternateStreetAddress}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Flex direction='row' w='100%' mt='17px'>
              <Field name={FormFields.alternateCity}>
                {({ field }: FieldPropType) => (
                  <FormControl isInvalid={!isEmpty(form.errors.alternateCity) && form.touched.alternateCity} pr='16px'>
                    <FormLabel htmlFor={field.name}>City</FormLabel>
                    <Input style={hasError(ErrorField.alternateCityError)} {...field} id={field.name} size='lg' />
                    <FormErrorMessage>{form.errors.alternateCity}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name={FormFields.alternateState}>
                {({ field }: FieldPropType) => (
                  <FormControl isInvalid={!isEmpty(form.errors.alternateState) && form.touched.alternateState}>
                    <FormLabel htmlFor={field.name}>State/Province</FormLabel>
                    <Input style={hasError(ErrorField.alternateStateError)} {...field} id={field.name} size='lg' />
                    <FormErrorMessage>{form.errors.alternateState}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </Flex>

            <Flex direction='row' w='100%' mt='17px'>
              <Field name={FormFields.alternateCountry}>
                {({ field }: FieldPropType) => (
                  <FormControl isInvalid={!isEmpty(form.errors.alternateCountry) && form.touched.alternateCountry} pr='16px'>
                    <FormLabel htmlFor={field.name}>Country</FormLabel>
                    <Select style={hasError(ErrorField.alternateCountryError)} {...field} size='lg' placeholder='Select' id={field.name}>
                      {Object.keys(Countries).map(countryCode => (
                        <option
                          aria-selected='true'
                          key={countryCode}
                          value={countryCode}
                        >{Countries[countryCode]}</option>
                      ))}
                    </Select>
                    <FormErrorMessage>{form.errors.alternateCountry}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name={FormFields.alternateZipCode}>
                {({ field }: FieldPropType) => (
                  <FormControl isInvalid={!isEmpty(form.errors.alternateZipCode) && form.touched.alternateZipCode}>
                    <FormLabel htmlFor={field.name}>Zip/Postal Code</FormLabel>
                    <Input style={hasError(ErrorField.alternateZipCodeError)} {...field} id={field.name} size='lg' />
                    <FormErrorMessage>{form.errors.alternateZipCode}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </Flex>
            { !hasOrClickedSecondAlternate &&
              <>
                <Button
                  isLoading={isUpdating}
                  leftIcon={<Icon as={FiX} height='20px' width='20px' />}
                  onClick={() => setAlternateSurrogateFlags(false)}
                  variant='redLink'>
                Remove Alternate Agent
                </Button>
              </>
            }
          </> : (
            <Button
              isLoading={isUpdating}
              leftIcon={<Icon as={FiPlus} height='20px' width='20px' />}
              onClick={() => setAlternateSurrogateFlags(true)}
              variant='link'>
            Add Alternate Agent (Optional)
            </Button>
          )}

          {hasOrClickedSecondAlternate ? <>
            <Text textAlign='left' color={textPalette.light}>
            If my chosen Agent is unable or unwilling to be my Healthcare Agent, I trust and appoint ________________ to be my Second Alternate Healthcare Agent instead.
            </Text>
            <Field name={FormFields.secondAlternateFullName}>
              {({ field }: FieldPropType) => (
                <FormControl isInvalid={!isEmpty(form.errors.secondAlternateFullName) && form.touched.secondAlternateFullName}>
                  <FormLabel htmlFor={field.name}>Healthcare Agent Name</FormLabel>
                  <Input style={hasError(ErrorField.secondAlternateFullNameError)} {...field} id={field.name} size='lg' />
                  <FormErrorMessage>{form.errors.secondAlternateFullName}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name={FormFields.secondAlternatePronouns}>
              {({ field }: FieldPropType) => (
                <FormControl isInvalid={!isEmpty(form.errors.secondAlternatePronouns) && form.touched.secondAlternatePronouns}>
                  <FormLabel htmlFor={field.name}>Pronouns</FormLabel>
                  <Input style={hasError(ErrorField.secondAlternatePronounsError)} {...field} placeholder={PlaceHolders.pronouns} id={field.name} size='lg' />
                  <FormErrorMessage>{form.errors.secondAlternatePronouns}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name={FormFields.secondAlternateMemberRelationship}>
              {({ field }: FieldPropType) => (
                <FormControl isInvalid={!isEmpty(form.errors.secondAlternateMemberRelationship) && form.touched.secondAlternateMemberRelationship}>
                  <FormLabel htmlFor={field.name}>Relationship of my Healthcare Agent to me</FormLabel>
                  <RelationshipSelect style={hasError(ErrorField.secondAlternateMemberRelationshipError)} field={field}/>
                  <FormErrorMessage>{form.errors.secondAlternateMemberRelationship}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Flex direction='row' w='100%' mt='17px'>
              <Field name={FormFields.secondAlternatePhone}>
                {({ field }: FieldPropType) => (
                  <FormControl isInvalid={!isEmpty(form.errors.secondAlternatePhone) && form.touched.secondAlternatePhone} pr='16px'>
                    <FormLabel htmlFor={field.name}>Phone</FormLabel>
                    <div style={{display: 'flex'}} >
                      <ChakraProvider>
                        <CountrySelector
                          selectedCountry={secondAlternatePhoneInput.country.iso2}
                          onSelect={(country) => secondAlternatePhoneInput.setCountry(country.iso2)}
                          renderButtonWrapper={({ children, rootProps }) => (
                            <Button {...rootProps} variant='outline' px='4px' size='lg'>
                              {children}
                            </Button>
                          )}
                        />
                      </ChakraProvider>
                      <Input
                        placeholder='Phone number'
                        type='tel'
                        style={hasError(ErrorField.phoneError)}
                        size='lg'
                        value={secondAlternatePhoneInput.inputValue}
                        onChange={secondAlternatePhoneInput.handlePhoneValueChange}
                        ref={secondAlternatePhoneInput.inputRef}
                      />
                    </div>
                    <FormErrorMessage>{form.errors.secondAlternatePhone}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name={FormFields.secondAlternateEmail}>
                {({ field }: FieldPropType) => (
                  <FormControl isInvalid={!isEmpty(form.errors.secondAlternateEmail) && form.touched.secondAlternateEmail}>
                    <FormLabel htmlFor={field.name}>Email</FormLabel>
                    <Input style={hasError(ErrorField.secondAlternateEmailError)} {...field} id={field.name} size='lg' />
                    <FormErrorMessage>{form.errors.secondAlternateEmail}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </Flex>

            <Field name={FormFields.secondAlternateStreetAddress}>
              {({ field }: FieldPropType) => (
                <FormControl isInvalid={!isEmpty(form.errors.secondAlternateStreetAddress) && form.touched.secondAlternateStreetAddress}>
                  <FormLabel htmlFor={field.name}>Street Address</FormLabel>
                  <Input style={hasError(ErrorField.secondAlternateStreetAddressError)} {...field} id={field.name} size='lg' />
                  <FormErrorMessage>{form.errors.secondAlternateStreetAddress}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Flex direction='row' w='100%' mt='17px'>
              <Field name={FormFields.secondAlternateCity}>
                {({ field }: FieldPropType) => (
                  <FormControl isInvalid={!isEmpty(form.errors.secondAlternateCity) && form.touched.secondAlternateCity} pr='16px'>
                    <FormLabel htmlFor={field.name}>City</FormLabel>
                    <Input style={hasError(ErrorField.secondAlternateCityError)} {...field} id={field.name} size='lg' />
                    <FormErrorMessage>{form.errors.secondAlternateCity}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name={FormFields.secondAlternateState}>
                {({ field }: FieldPropType) => (
                  <FormControl isInvalid={!isEmpty(form.errors.secondAlternateState) && form.touched.secondAlternateState}>
                    <FormLabel htmlFor={field.name}>State/Province</FormLabel>
                    <Input style={hasError(ErrorField.secondAlternateStateError)} {...field} id={field.name} size='lg' />
                    <FormErrorMessage>{form.errors.secondAlternateState}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </Flex>

            <Flex direction='row' w='100%' mt='17px'>
              <Field name={FormFields.secondAlternateCountry}>
                {({ field }: FieldPropType) => (
                  <FormControl isInvalid={!isEmpty(form.errors.secondAlternateCountry) && form.touched.secondAlternateCountry} pr='16px'>
                    <FormLabel htmlFor={field.name}>Country</FormLabel>
                    <Select style={hasError(ErrorField.secondAlternateCountryError)} {...field} size='lg' placeholder='Select' id={field.name}>
                      {Object.keys(Countries).map(countryCode => (
                        <option
                          aria-selected='true'
                          key={countryCode}
                          value={countryCode}
                        >{Countries[countryCode]}</option>
                      ))}
                    </Select>
                    <FormErrorMessage>{form.errors.secondAlternateCountry}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Field name={FormFields.secondAlternateZipCode}>
                {({ field }: FieldPropType) => (
                  <FormControl isInvalid={!isEmpty(form.errors.secondAlternateZipCode) && form.touched.secondAlternateZipCode}>
                    <FormLabel htmlFor={field.name}>Zip/Postal Code</FormLabel>
                    <Input style={hasError(ErrorField.secondAlternateZipCodeError)} {...field} id={field.name} size='lg' />
                    <FormErrorMessage>{form.errors.secondAlternateZipCode}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </Flex>
            <Button
              isLoading={isUpdating}
              leftIcon={<Icon as={FiX} height='20px' width='20px' />}
              onClick={() => setSecondAlternateSurrogateFlags(false)}
              variant='redLink'>
            Remove Second Alternate Agent
            </Button>
          </> : (
            hasOrClickedAlternate &&
            <>
              <Button
                isLoading={isUpdating}
                leftIcon={<Icon as={FiPlus} height='20px' width='20px' />}
                onClick={() => setSecondAlternateSurrogateFlags(true)}
                variant='link'>
              Add Second Alternate Agent (Optional)
              </Button>
            </>
          )}

          <Stack direction='row' width='100%'>
            <Button width='100%' isLoading={isUpdating} type='submit'>
            Next
            </Button>
          </Stack>
          <Center width='100%'>
            <Button
              isLoading={isUpdating}
              leftIcon={<Icon as={FiSave} height='20px' width='20px' />}
              onClick={() => continueLater(form, true)}
              variant='link'>
            Save &amp; Continue Later
            </Button>
          </Center>
        </Stack>
      </Form>
    </>
  )
}

const AppointSurrogateFormContainer: React.FC<ContainerProps> = (props) => {
  const { shallowEqual } = ReactRedux
  const dispatch = ReactRedux.useDispatch()
  const { currentStep, directive } = props


  const {
    member,
  } = useSelector(({ users }: StoreState) => ({
    allLoadedUsers: users.records,
    member: users.records[directive?.memberId],
    records: users.records,
  }), shallowEqual)

  const submitAction = (values) => {
    const directiveParams = formValueDTO(values, member, currentStep, directive  )
    dispatch(updateDirective(directiveParams, directive.id))
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ValidationSchema}
      onSubmit={(values) => submitAction(values)}
    >{(formik) => <AppointSurrogateForm {...props} form={formik} />}</Formik>
  )
}

export default AppointSurrogateFormContainer