import React, { useContext, useEffect } from 'react'
import * as ReactRedux from 'react-redux'
import { Field, FieldInputProps, Form, Formik, FormikContextType } from 'formik'
import {
  Avatar,
  Button,
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
  Text,
} from '@chakra-ui/react'
import * as Yup from 'yup'
import isEmpty from 'lodash.isempty'
import { AiOutlineWarning } from 'react-icons/ai'
import { Context as DirtyFormAlertContext } from '../../../../components/DirtyFormAlert'
import { StoreState } from '../../../../redux'
import { isDone, isError } from '../../../../redux/AsyncState'
import { invite as inviteUser } from '../../../../redux/users/actions'
import { parsePhoneNumber, userNameAttrsFromFullName } from '../../../../utils/Forms'
import { privateFileUrl } from '../../../../utils/Routing'
import { accountNameInitialsGenerator } from '../../../../utils/BusinessLogic'
import { purples, white } from '../../../../constants/Colors'
import Roles from '../../../../constants/Roles'
import { getSubdomainRoute } from '../../../../utils/Routing'

enum FormFields {
  email = 'email',
  fullName = 'fullName',
  phoneNumber = 'phoneNumber',
}

const ValidationSchema = Yup.object().shape({
  [FormFields.email]: Yup.string().nullable().required('Must not be empty').email('Must be a valid email'),
  [FormFields.phoneNumber]: Yup.string().nullable().matches(/^\([0-9]{3}\) [0-9]{3}-[0-9]{4}?$/, 'Must be 10 digits'),
})

const initialValues = {
  [FormFields.email]: '',
  [FormFields.fullName]: '',
  [FormFields.phoneNumber]: '',
}

type Values = typeof initialValues;
type ValueTypes = Values[keyof Values];
type FormType = FormikContextType<Values>
type FieldPropType = {field: FieldInputProps<ValueTypes>; form: FormType}
type ContainerProps = { onCancel: () => any; onError: () => any; onFinish: () => any; subdomain?: string }
type AdminInfoFormProps = ContainerProps & { form: FormType }

// visibleForTesting
export const useHooks = ({ form, onError, onFinish, subdomain }: AdminInfoFormProps) => {
  const { shallowEqual, useSelector } = ReactRedux
  const { setDirtyFormAlert } = useContext(DirtyFormAlertContext)

  const {
    errorData,
    invitingState,
    account,
  } = useSelector(({ accounts: { records }, users }: StoreState) => ({
    errorData: users.errorData,
    invitingState: users.creatingState,
    account: subdomain == null ? undefined : records[subdomain],
  }), shallowEqual)

  const isInviteDone = isDone(invitingState)
  const isInviteError = isError(invitingState)

  useEffect(() => {
    form.resetForm()
  }, [])

  useEffect(() => {
    if (!form.dirty || !isInviteError) return

    if (typeof errorData === 'object' && errorData?.email != null) {
      form.setFieldError(FormFields.email, `Email ${errorData?.email.join(', ')}`)
    }

    onError()
    form.setSubmitting(false)
  }, [errorData, isInviteError])

  useEffect(() => {
    if (form.dirty && isInviteDone) {
      form.setSubmitting(false)
    }
  }, [isInviteDone])

  useEffect(() => {
    if (form.dirty && isInviteDone && !form.isSubmitting) {
      onFinish()
    }
  }, [form.isSubmitting])

  useEffect(() => {
    setDirtyFormAlert(form.dirty)
  }, [form.dirty])

  return { account }
}

const AdminInfoForm: React.FC<AdminInfoFormProps> = (props) => {
  const { account } = useHooks(props)
  const { form, onCancel } = props

  return (
    <Form>
      <Stack align='center' spacing='20px'>
        <Stack direction='row' spacing={2} alignSelf='flex-start' alignItems='center'>
          <Avatar
            size='md'
            getInitials={accountNameInitialsGenerator}
            name={account?.name ?? ''}
            src={privateFileUrl(account?.avatar)}
            color={white}
            bg={purples.avatar}
          />
          <Stack spacing={1}>
            <Text fontSize='16px'>{account?.name}</Text>
            <Text fontSize='12px' color={purples.primary} fontWeight='bold'>{getSubdomainRoute(account?.subdomain ?? '').split('//')[1]}</Text>
          </Stack>
        </Stack>
        <Field name={FormFields.fullName}>
          {({ field }: FieldPropType) => (
            <FormControl isInvalid={!isEmpty(form.errors.fullName) && form.touched.fullName}>
              <FormLabel htmlFor={field.name}>Full Name</FormLabel>
              <Input {...field} id={field.name} type='text' maxLength={32}/>
              <FormErrorMessage><FormErrorIcon as={AiOutlineWarning} />{form.errors.fullName}</FormErrorMessage>
            </FormControl>
          )}
        </Field>
        <Stack direction='row' spacing={4} width='100%'>
          <Field name={FormFields.email}>
            {({ field }: FieldPropType) => (
              <FormControl isInvalid={!isEmpty(form.errors.email) && form.touched.email}>
                <FormLabel htmlFor={field.name}>Email*</FormLabel>
                <Input {...field} id={field.name} type='email'/>
                <FormErrorMessage><FormErrorIcon as={AiOutlineWarning} />{form.errors.email}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name={FormFields.phoneNumber}>
            {({ field }: FieldPropType) => (
              <FormControl isInvalid={!isEmpty(form.errors.phoneNumber) && form.touched.phoneNumber}>
                <FormLabel htmlFor={field.name}>Phone</FormLabel>
                <Input
                  {...field}
                  id={field.name}
                  onChange={({currentTarget}) => {form.setFieldValue(field.name, parsePhoneNumber(currentTarget.value))}}
                  type='text'
                />
                <FormErrorMessage><FormErrorIcon as={AiOutlineWarning} />{form.errors.phoneNumber}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
        </Stack>
        <Stack direction='row' spacing={4} width='100%'>
          <Button width='full' isDisabled={form.isSubmitting} variant='outline' onClick={onCancel}>
            Cancel
          </Button>
          <Button width='full' isLoading={form.isSubmitting} type='submit' onClick={() => form.submitForm()}>
          Invite
          </Button>
        </Stack>
      </Stack>
    </Form>
  )
}

const AdminInfoFormContainer: React.FC<ContainerProps> = (props) => {
  const dispatch = ReactRedux.useDispatch()

  const onSubmit = ({fullName, phoneNumber, ...values}) => {
    const { subdomain: subdomainName } = props
    const data = {
      ...values,
      ...userNameAttrsFromFullName(fullName),
      phoneNumber: phoneNumber.match(/\d/g)?.join(''),
      redirectUrl: getSubdomainRoute(subdomainName ?? ''),
      role: Roles.ADMIN,
      subdomainName,
    }
    dispatch(inviteUser(data))
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ValidationSchema}
      onSubmit={onSubmit}
    >{(formik) => <AdminInfoForm {...props} form={formik} />}</Formik>
  )
}

export default AdminInfoFormContainer
