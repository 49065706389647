import React from 'react'
import ReactPaginate from 'react-paginate'
import {
  Box,
  Center,
  Flex,
  useTheme,
} from '@chakra-ui/react'
import { css } from '@emotion/react'
import { grayBlue, white } from '../constants/Colors'

const paginationStyle = (primaryColor: string) => css`
  .pagination {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    list-style-type: none;
    margin-left: auto;
    margin-right: auto;
  }
  .next, .previous {
    color: ${primaryColor};
    margin: 4px;
  }
  .page {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: ${grayBlue};
    width: 24px;
    border-radius: 3px;
    margin: 4px;
    display: flex;
    justify-content: center;
  }
  .pagination-link--active {
    background: ${primaryColor};
    color: ${white};
    border-radius: 3px;
  }
  .pagination-link {
    height: 24px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }
  .pagination-link--disabled {
    color: ${grayBlue} !important;
    border-radius: 3px;
  }
`
type PaginationProps = React.ComponentProps<typeof Flex> & {
  currentPage: number
  onPageChange: (nextPage) => void
  totalPages: number
}

const Pagination = ({
  currentPage,
  onPageChange,
  totalPages,
}: PaginationProps) => {
  const { colors: { brand } } = useTheme()

  if (totalPages == null || totalPages < 2) return null

  return (
    <Center width='1100px' css={paginationStyle(brand[500])}>
      <Box mt='34px'>
        <ReactPaginate
          activeClassName='pagination-link--active'
          breakClassName='page'
          breakLabel='...'
          containerClassName='pagination'
          disabledClassName='pagination-link--disabled'
          forcePage={currentPage - 1}
          marginPagesDisplayed={3}
          nextLabel='Next'
          nextLinkClassName='pagination-link'
          onPageChange={({selected}) => onPageChange(selected + 1)}
          pageClassName='page'
          pageCount={totalPages}
          pageRangeDisplayed={3}
          previousLabel='Prev'
          previousLinkClassName='pagination-link'
        />
      </Box>
    </Center>
  )
}

export default Pagination
