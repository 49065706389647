import {AccountRoles} from '../constants/Account'
import {flexPallete} from '../constants/Colors'

export const getRoleColor = (role): string => {
  switch (role) {
    case AccountRoles.OWNER:
      return flexPallete.blue
    case AccountRoles.ADMIN:
      return flexPallete.green
    case AccountRoles.ADVOCATE:
      return flexPallete.purple
    case AccountRoles.OBSERVER:
      return flexPallete.red
    default:
      return flexPallete.pink
  }
}