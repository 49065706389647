import React, {useContext, useEffect, useState} from 'react'
import * as ReactRedux from 'react-redux'
import { Field, FieldInputProps, Form, Formik, FormikContextType } from 'formik'
import {
  Button,
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
} from '@chakra-ui/react'
import * as Yup from 'yup'
import isEmpty from 'lodash.isempty'
import { AiOutlineWarning } from 'react-icons/ai'
import { Context as DirtyFormAlertContext } from '../../../components/DirtyFormAlert'
import { StoreState } from '../../../redux'
import AsyncState, { isDone, isError } from '../../../redux/AsyncState'
import {assignTeamMember, clearUserErrors} from '../../../redux/users/actions'
import { parsePhoneNumber, userNameAttrsFromFullName } from '../../../utils/Forms'
import { Model as User } from '../../../constants/User'
import {useToast} from '../../../hooks/Toast'
import {useDispatch} from 'react-redux'

enum FormFields {
  email = 'email',
  fullName = 'fullName',
  phoneNumber = 'phoneNumber',
}

const ValidationSchema = Yup.object().shape({
  [FormFields.email]: Yup.string().required('Must not be empty').email('Must be a valid email'),
  [FormFields.phoneNumber]: Yup.string().matches(/^\([0-9]{3}\) [0-9]{3}-[0-9]{4}?$/, 'Must be 10 digits'),
  [FormFields.fullName]: Yup.string().required('Must not be empty'),
})

const initialValues = {
  [FormFields.email]: '',
  [FormFields.fullName]: '',
  [FormFields.phoneNumber]: '',
}

type Values = typeof initialValues;
type ValueTypes = Values[keyof Values];
type FormType = FormikContextType<Values>
type FieldPropType = {field: FieldInputProps<ValueTypes>; form: FormType}
type ContainerProps = { onError: () => any; onFinish: () => any; teamUserId: string; currentProvider: User }
type ProviderInfoFormProps = ContainerProps & { form: FormType }

// visibleForTesting
export const useHooks = ({ form, onError, onFinish }: ProviderInfoFormProps) => {
  const { shallowEqual, useSelector } = ReactRedux
  const { setDirtyFormAlert } = useContext(DirtyFormAlertContext)

  const {
    errorData,
    invitingState,
  } = useSelector(({ users }: StoreState) => ({
    errorData: users.errorData,
    invitingState: users.creatingState,
  }), shallowEqual)

  const isInviteDone = isDone(invitingState)
  const isInviteError = isError(invitingState)

  useEffect(() => {
    form.resetForm()
  }, [])

  useEffect(() => {
    if (!form.dirty || !isInviteError) return

    if (typeof errorData === 'object' && errorData?.email != null) {
      form.setFieldError(FormFields.email, `Email ${errorData?.email.join(', ')}`)
    }

    onError()
    form.setSubmitting(false)
  }, [errorData, isInviteError])

  useEffect(() => {
    if (form.dirty && isInviteDone) {
      form.setSubmitting(false)
    }
  }, [isInviteDone])

  useEffect(() => {
    if (form.dirty && isInviteDone && !form.isSubmitting) {
      onFinish()
    }
  }, [form.isSubmitting])

  useEffect(() => {
    setDirtyFormAlert(form.dirty)
  }, [form.dirty])
}

const ProviderInfoForm: React.FC<ProviderInfoFormProps> = (props) => {
  const { useSelector } = ReactRedux
  const dispatch = useDispatch()
  const toast = useToast()
  useHooks(props)
  const { form, onFinish } = props

  const {createStatus, errorData} = useSelector(({users}: StoreState) => ({
    createStatus: users.updatingState,
    errorData: users.errorData,
  }))

  const [lastStatus, setLastStatus] = useState(createStatus)
  useEffect(() => {
    if (lastStatus === AsyncState.LOADING && createStatus === AsyncState.DONE) {
      onFinish()
    }
    setLastStatus(createStatus)
  }, [createStatus])

  useEffect(() => {
    if (errorData !== '' && errorData !== null) {
      toast({
        description: errorData as string,
        status: 'error',
      })
      dispatch(clearUserErrors())
      form.setSubmitting(false)
    }
  }, [errorData])

  return (
    <Form>
      <Stack align='center' spacing='20px'>
        <Field name={FormFields.fullName}>
          {({ field }: FieldPropType) => (
            <FormControl isInvalid={!isEmpty(form.errors.fullName) && form.touched.fullName}>
              <FormLabel htmlFor={field.name}>Full Name</FormLabel>
              <Input {...field} id={field.name} type='text' maxLength={32}/>
              <FormErrorMessage><FormErrorIcon as={AiOutlineWarning} />{form.errors.fullName}</FormErrorMessage>
            </FormControl>
          )}
        </Field>
        <Stack direction='row' spacing={4} width='100%'>
          <Field name={FormFields.email}>
            {({ field }: FieldPropType) => (
              <FormControl isInvalid={!isEmpty(form.errors.email) && form.touched.email}>
                <FormLabel htmlFor={field.name}>Email*</FormLabel>
                <Input {...field} id={field.name} type='email'/>
                <FormErrorMessage><FormErrorIcon as={AiOutlineWarning} />{form.errors.email}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name={FormFields.phoneNumber}>
            {({ field }: FieldPropType) => (
              <FormControl isInvalid={!isEmpty(form.errors.phoneNumber) && form.touched.phoneNumber}>
                <FormLabel htmlFor={field.name}>Phone</FormLabel>
                <Input
                  {...field}
                  id={field.name}
                  onChange={({currentTarget}) => {form.setFieldValue(field.name, parsePhoneNumber(currentTarget.value))}}
                  type='text'
                />
                <FormErrorMessage><FormErrorIcon as={AiOutlineWarning} />{form.errors.phoneNumber}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
        </Stack>
        <Button width='full' isLoading={form.isSubmitting} type='submit'>
          Add to Team
        </Button>
      </Stack>
    </Form>
  )
}

const ProviderInfoFormContainer: React.FC<ContainerProps> = (props) => {
  const dispatch = ReactRedux.useDispatch()

  const { teamUserId, currentProvider } = props
  let currentProviderDetails = initialValues
  if (currentProvider !== undefined) {
    const { email, firstName, middleName, lastName, phoneNumber } = currentProvider
    currentProviderDetails = { email, phoneNumber: parsePhoneNumber(phoneNumber) || '', fullName: `${firstName} ${middleName === '' || middleName === undefined ? middleName + ' ' : ''}${lastName}`}
  }

  const onSubmit = ({fullName, phoneNumber: pNumber, ...values}) => {
    const data = {
      ...values,
      ...userNameAttrsFromFullName(fullName),
      phoneNumber: pNumber.match(/\d/g)?.join(''),
      teamRole: 'PRIMARY_CARE_PROVIDER',
    }
    dispatch(assignTeamMember(teamUserId, data))
  }

  return (
    <Formik
      initialValues={currentProviderDetails || initialValues }
      validationSchema={ValidationSchema}
      onSubmit={onSubmit}
    >{(formik) => <ProviderInfoForm {...props} form={formik} />}</Formik>
  )
}

export default ProviderInfoFormContainer
