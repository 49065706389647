import { call, put, race, take, takeEvery } from 'redux-saga/effects'
import isEmpty from 'lodash.isempty'
import ActionIds from '../ActionIds'
import { BaseAction } from '../types'
import {
  create as createApi,
  getOne as getOneApi,
  sendToDocusign as sendToDocusignApi,
  sendPolstToDocusign as sendPolstToDocusignApi,
  createPdf as createPdfApi,
  share as shareApi,
  update as updateApi,
} from './api'
import {
  getMany as getManyUsers,
  getOne as getOneUser,
} from '../users/actions'
import {
  deleteOne as deleteUserApi,
  assignTeamMember as assignTeamMemberApi,
} from '../users/api'

export default function* watch() {
  yield takeEvery(ActionIds.DIRECTIVES_GET_ONE_START, requestOne)
  yield takeEvery(ActionIds.DIRECTIVES_CREATE_START, requestCreate)
  yield takeEvery(ActionIds.DIRECTIVES_UPDATE_START, requestUpdate)
  yield takeEvery(ActionIds.DIRECTIVES_SEND_START, requestSendToDocusign)
  yield takeEvery(ActionIds.DIRECTIVES_POLST_SEND_START, requestSendPolstToDocusignApi)
  yield takeEvery(ActionIds.DIRECTIVES_CREATE_PDF_START, requestCreatePdf)
  yield takeEvery(ActionIds.DIRECTIVES_SHARE_START, requestShare)
  yield takeEvery(ActionIds.DIRECTIVES_GET_MANY_START, requestAll)
}

function* requestOne({payload: id}: BaseAction) {
  const { apiResponse: {response, error}, isCanceled } = yield race({
    apiResponse: call(getOneApi, id),
    isCanceled: take(ActionIds.DIRECTIVES_GET_ONE_CANCEL),
  })
  if (isCanceled) return

  if (error) {
    yield put({type: ActionIds.DIRECTIVES_GET_ONE_FAIL})
    return
  }

  yield put({type: ActionIds.DIRECTIVES_GET_ONE_SUCCESS, payload: response.data})
}

function* requestAll({payload: ids}: BaseAction) {
  for( const id of ids){
    const { apiResponse: {response, error}, isCanceled } = yield race({
      apiResponse: call(getOneApi, id),
      isCanceled: take(ActionIds.DIRECTIVES_GET_MANY_CANCEL),
    })
    if (isCanceled) return
    if (error) {
      yield put({type: ActionIds.DIRECTIVES_GET_MANY_FAIL})
      return
    }
    yield put({type: ActionIds.DIRECTIVES_GET_ONE_SUCCESS, payload: response.data})
  }
  yield put({type: ActionIds.DIRECTIVES_GET_MANY_SUCCESS})
}

function* requestCreate({payload: params}: BaseAction) {
  const{response, error} = yield call(createApi, params)

  if (error) {
    yield put({type: ActionIds.DIRECTIVES_CREATE_FAIL})
    return
  }

  yield put(getOneUser(params.memberId)) // fetch directive user
  yield put({type: ActionIds.DIRECTIVES_CREATE_SUCCESS, payload: response.data})
}

function* requestSendToDocusign({payload: params}: BaseAction) {
  const {error} = yield call(sendToDocusignApi, params)

  if (error) {
    yield put({type: ActionIds.DIRECTIVES_SEND_FAIL})
    return
  }

  yield put(getOneUser(params.memberId)) // reload user
  yield put({type: ActionIds.DIRECTIVES_SEND_SUCCESS})
}

function* requestSendPolstToDocusignApi({payload: params}: BaseAction) {
  const {error} = yield call(sendPolstToDocusignApi, params)

  if (error) {
    yield put({type: ActionIds.DIRECTIVES_POLST_SEND_FAIL})
    return
  }

  yield put(getOneUser(params.memberId)) // reload user
  yield put({type: ActionIds.DIRECTIVES_POLST_SEND_SUCCESS})
}

function* requestCreatePdf({payload: params}: BaseAction) {
  const {response, error} = yield call(createPdfApi, params.memberId, params.signatureLevel, params.redo)

  if (error) {
    yield put({type: ActionIds.DIRECTIVES_CREATE_PDF_FAIL})
    return
  }
  yield put({type: ActionIds.DIRECTIVES_CREATE_PDF_SUCCESS})
  yield put(getOneUser(params.memberId))
  window.location.href = response.data.tempLink
  // window.open(response.data.tempLink, '_blank')
}

function* requestShare({payload: params}: BaseAction) {
  const{response, error} = yield call(shareApi, params)

  if (error) {
    yield put({type: ActionIds.DIRECTIVES_SHARE_FAIL})
    return
  }

  yield put({type: ActionIds.DIRECTIVES_SHARE_SUCCESS, payload: response.data})
}

function* requestUpdate({payload: {id, params}}: BaseAction) {
  const {
    alternateSurrogate: alternateSurrogateParams = {},
    secondAlternateSurrogate: secondAlternateSurrogateParams = {},
    primaryCareProvider: primaryCareProviderParams = {},
    surrogate: surrogateParams = {},
    ...directiveParams
  } = params
  const { memberId, ...otherDirectiveParams } = directiveParams

  const { error: surrogateError } = yield* requestDeleteOrAssignMember(surrogateParams, memberId)
  if (surrogateError != null) {
    yield put({payload: { surrogate: surrogateError.response.data }, type: ActionIds.DIRECTIVES_UPDATE_FAIL})
    return
  }

  const { error: altSurrogateError } = yield* requestDeleteOrAssignMember(alternateSurrogateParams, memberId)
  if (altSurrogateError != null) {
    yield put({payload: { alternateSurrogate: altSurrogateError.response.data }, type: ActionIds.DIRECTIVES_UPDATE_FAIL})
    return
  }

  const { error: seoncdAltSurrogateError } = yield* requestDeleteOrAssignMember(secondAlternateSurrogateParams, memberId)
  if (seoncdAltSurrogateError != null) {
    yield put({payload: { secondAlternateSurrogate: seoncdAltSurrogateError.response.data }, type: ActionIds.DIRECTIVES_UPDATE_FAIL})
    return
  }

  const { error: primaryCareProviderError } = yield* requestDeleteOrAssignMember(primaryCareProviderParams, memberId)
  if (primaryCareProviderError != null) {
    yield put({payload: { primaryCareProvider: primaryCareProviderError.response.data }, type: ActionIds.DIRECTIVES_UPDATE_FAIL})
    return
  }

  // re-fetch member team data
  yield put(getManyUsers({teamUserId: memberId})) // tell users store to fetch member's team of users by id

  const { response, error } = yield call(updateApi, id, otherDirectiveParams)
  if (error != null) {
    yield put({payload: { directive: error.response.data }, type: ActionIds.DIRECTIVES_UPDATE_FAIL})
    return
  }

  yield put({type: ActionIds.DIRECTIVES_UPDATE_SUCCESS, payload: response.data})
}

function* requestDeleteOrAssignMember(params, memberId?: string) {
  if (isEmpty(params) || memberId == null) return {}

  if (params.delete != null) {
    return yield call(deleteUserApi, params.delete, memberId)
  }

  return yield call(assignTeamMemberApi, memberId, params)
}
