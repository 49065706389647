import ActionIds from '../ActionIds'
import AsyncState from '../AsyncState'
import { Model } from '../../constants/HealthPlan'
import { BaseAction, Records } from '../types'

const initialState = {
  loadingState: AsyncState.NOT_STARTED,
  records: {} as Records<Model>,
}

export type StateType = typeof initialState;

export default function reducer(state = initialState, action: BaseAction): StateType {
  switch (action.type) {
    case ActionIds.HEALTH_PLANS_GET_MANY_START:
      return { ...state, loadingState: AsyncState.LOADING }
    case ActionIds.HEALTH_PLANS_GET_MANY_SUCCESS: {
      const users = action.payload.data.map(({attributes}) => attributes) as Model[]
      const { records } = state
      return {
        ...state,
        loadingState: AsyncState.DONE,
        records: users.reduce((memo: Records<Model>, u: Model) => ({...memo, [u.id]: u}), records),
      }
    }
    case ActionIds.HEALTH_PLANS_GET_MANY_FAIL:
      return { ...state, loadingState: AsyncState.ERROR }
    default:
      return state
  }
}
