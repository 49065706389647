import React from 'react'
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom'
import {
  Box,
  Button,
  Stack,
  Text,
} from '@chakra-ui/react'
import Doctors from '../../../components/Images/Doctors'
import * as RoutePaths from '../../../constants/RoutePaths'
import { useToast } from '../../../hooks/Toast'
import {getCurrentSubdomain} from '../../../utils/Routing'

type SuccessProps = { advocateName: string | undefined } & RouteComponentProps<any>
const Success: React.FC<SuccessProps> = (props) => {
  const history = useHistory()
  const toast = useToast()

  const displayToast = () => {
    toast({
      description: `A new profile has been created for ${props.advocateName}.`,
      status: 'success',
      width: '650px',
    })
  }

  return (
    <Stack align='center' spacing='20px' width='540px'>
      <Box as={Doctors} />
      <Text fontSize='42px'>Thanks!</Text>
      <Button
        width='full'
        onClick={() => {
          if (getCurrentSubdomain() === 'app') {
            history.push({pathname: RoutePaths.AccountListPage(), search: 'from=login_or_signup'})
          } else {
            history.push(RoutePaths.Members())
          }

          displayToast()
        }}
      >
        Go To Dashboard
      </Button>
    </Stack>
  )
}

export default withRouter(Success)
