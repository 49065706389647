import { yellows } from '../../constants/Colors'
import * as React from 'react'

const ProgressCircle = () => (
  <svg width={24} height={24} fill='none' xmlns='http://www.w3.org/2000/svg'>
    <mask
      id='a'
      style={{
        maskType: 'alpha',
      }}
      maskUnits='userSpaceOnUse'
      x={1}
      y={1}
      width={22}
      height={22}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M22.707 3.293a1 1 0 0 1 0 1.414l-10 10.01a1 1 0 0 1-1.414 0l-3-3a1 1 0 1 1 1.414-1.414L12 12.595l9.293-9.302a1 1 0 0 1 1.414 0Z'
        fill={yellows.missingInfo}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.63 3.778a9 9 0 0 0-11.46 3.73 1 1 0 1 1-1.733-.998A11 11 0 0 1 16.443 1.95a1 1 0 0 1-.813 1.827Zm6.18 6.182a1 1 0 0 1 1.099.89 11 11 0 0 1-8.12 11.783 1 1 0 1 1-.513-1.934 9 9 0 0 0 6.644-9.64 1 1 0 0 1 .89-1.099Z'
        fill={yellows.missingInfo}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.935 11.665a1 1 0 0 1 1.063.933v.006a1 1 0 1 1-1.995.133v-.008a1 1 0 0 1 .932-1.064Zm1.497 5.632a1 1 0 0 1 1.402.186l.008.01a1 1 0 1 1-1.585 1.219l-.01-.013a1 1 0 0 1 .185-1.402Zm4.379 3.857a1 1 0 0 1 1.268-.625l.007.002a1 1 0 0 1-.643 1.894l-.008-.002a1 1 0 0 1-.624-1.27Z'
        fill={yellows.missingInfo}
      />
    </mask>
    <g mask='url(#a)'>
      <path fill={yellows.missingInfo} d='M0 0h24v24H0z' />
    </g>
  </svg>
)

export default ProgressCircle
