import React from 'react'
import { format as formatDate, isValid as isValidDate, parse, addHours } from 'date-fns'
import { FieldInputProps } from 'formik'
import {
  ReactDatePickerProps,
} from 'react-datepicker'
import ReactDatePicker from 'react-datepicker'
import * as ReactDatePickerImports from 'react-datepicker'
import { Box, Input as ChakraInput, InputProps as ChakraInputProps, useTheme } from '@chakra-ui/react'
import { css } from '@emotion/react'
import { mergeProps } from '@react-aria/utils'
import { DATE_FORMAT } from '../utils/Forms'
import useFormikContext from '../hooks/useFormikContext'

const calendarContainerCss = (bgColor: string) => css`
  .react-datepicker__day--in-range,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--selected,
  .react-datepicker__month-text--in-range,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__month-text--selected,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--keyboard-selected,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-range {
    background-color: ${bgColor}
  }
`

const mainContainerCss = css`
  width: 100%;

  .react-datepicker-wrapper {
    width: 100%;
  }
`

const parseOrNull = (date: string, format: string) => {
  const result = parse(date, format, new Date())
  return isValidDate(result) ? result : null
}

// CalendarContainer cannot be used correctly if directly imported
const ReactCalendarContainer: any = ReactDatePickerImports.CalendarContainer

interface InputProps extends Omit<ChakraInputProps, 'onFocus'> {
  field: FieldInputProps<any>
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(({ field, ...otherProps }, ref) => (
  <ChakraInput
    {...field}
    {...otherProps}
    {...mergeProps(field, otherProps)}
    value={field.value}
    ref={ref}
  />
))

const CalendarContainer: React.FC<any> = ({ children, className }) => {
  const { colors: { brand } } = useTheme()

  return (
    <Box css={calendarContainerCss(brand[300])}><ReactCalendarContainer className={className}>{children}</ReactCalendarContainer></Box>
  )
}

interface DatePickerProps extends Omit<ReactDatePickerProps, 'calendarContainer' | 'customInput' | 'onChange' | 'selected'> {
  field: FieldInputProps<any>
  format?: string
  initialDate?: Date
  inputFormatter?: (value?: string) => string
  inputProps?: Partial<Omit<InputProps, 'onClick'>>
}
const DatePicker: React.FC<DatePickerProps> = ({ field, format = DATE_FORMAT, initialDate, inputFormatter, inputProps, ...otherProps }) => {
  const form = useFormikContext()
  const input = React.useRef<HTMLInputElement>(null)

  return (
    <Box css={mainContainerCss}>
      <ReactDatePicker
        autoComplete='off'
        calendarContainer={CalendarContainer}
        customInput={<Input {...inputProps} field={field} ref={input}/>}
        onChange={_date => null}
        onSelect={date => {
          const utcPlus = addHours(date as Date, 12)
          form.setFieldValue(field.name, formatDate(utcPlus, format))
        }}
        onChangeRaw={({currentTarget}) => {
          if (currentTarget.value != null) {
            const formattedValue = inputFormatter == null ? currentTarget.value : inputFormatter(currentTarget.value)
            form.setFieldValue(field.name, formattedValue)
          }
        }}
        selected={parseOrNull(field.value, format) || initialDate || new Date()}
        {...otherProps}
      />
    </Box>
  )
}

export default DatePicker
