import React from 'react'
import { useTheme } from '@chakra-ui/react'

const Will = (props: React.SVGProps<SVGSVGElement>) => {
  const { colors: { brand, brandSecondary } } = useTheme()

  return (
    <svg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g>
        <path d='M35.8574 8.57141H8.7145C6.74205 8.57141 5.14307 10.1704 5.14307 12.1428V39.2857C5.14307 41.2581 6.74205 42.8571 8.7145 42.8571H35.8574C37.8298 42.8571 39.4288 41.2581 39.4288 39.2857V12.1428C39.4288 10.1704 37.8298 8.57141 35.8574 8.57141Z' fill={brandSecondary[500]}/>
      </g>
      <g>
        <path d='M12.5439 32.8372C11.8187 33.5629 11.8187 34.7392 12.5439 35.4648C13.2749 36.1784 14.4414 36.1784 15.1724 35.4648L22.8631 27.7716C29.4141 27.4318 35.619 24.7245 40.3248 20.1528V20.1528C45.0678 15.2505 47.8052 8.75026 47.9987 1.93077C48.0179 1.41453 47.8213 0.913616 47.4563 0.548226C47.0932 0.17696 46.5891 -0.0217766 46.0705 0.00189756L46.0705 0.00189751C41.7232 0.159425 37.4708 1.31872 33.6448 3.38949C33.3074 3.57374 33.1184 3.94681 33.1692 4.32791L33.8194 9.21512L30.0224 6.66931C29.6701 6.43389 29.2032 6.46676 28.8874 6.74922L28.8874 6.74922C26.8038 8.59963 25.0456 10.7868 23.686 13.2197L23.686 13.2197C22.3224 15.6817 21.3421 18.3373 20.7788 21.0949C20.504 22.4303 20.3234 23.7833 20.2382 25.144L12.5439 32.8372Z' fill={brand[500]}/>
      </g>
    </svg>
  )
}

export default Will
