import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import { useDispatch } from 'react-redux'
import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList, ToastId,
  useToast as useChakraToast,
} from '@chakra-ui/react'
import { Model as User } from '../../../constants/User'
import {createPdf} from '../../../redux/directives/actions'
import FileInput from '../../../components/FileInput'
import {uploadFile} from '../../../redux/axios'
import {update as updateUser} from '../../../redux/users/actions'
import {useToast} from '../../../hooks/Toast'

interface SendButtonProps extends React.PropsWithChildren<unknown> {
  isSending: boolean
  member: User
  isNew: boolean
}

const SendButton: React.FC<SendButtonProps> = ({children, isSending, member, isNew}) => {
  const dispatch = useDispatch()
  const toast = useToast()
  const chakraToast = useChakraToast()

  const [showNewOptions, setShowNewOptions] = useState(isNew)

  useEffect(() => {
    setShowNewOptions(isNew)
  }, [isNew])

  const send = useCallback((signatureLevel: number, redo) => {
    dispatch(createPdf(member.id, signatureLevel, redo))
  }, [dispatch, member])

  const fileInputRef = useRef<HTMLInputElement>(null)
  const showFileSelect = () => {
    const currentInput = fileInputRef?.current
    if (currentInput == null) return undefined

    currentInput.click()
  }

  const newMenuOptions = useMemo(() => [
    {text: 'Notary', action: () => send(1, !isNew), closeOnSelect: true },
    {text: '2 Witnesses', action: () => send(2, !isNew), closeOnSelect: true },
    {text: 'Notary & 2 Witnesses', action: () => send(3, !isNew), closeOnSelect: true },
  ], [member])

  const pendingStepOptions = useMemo(() => [
    {text: 'Restart Authorization', action: () => setShowNewOptions(true), closeOnSelect: false},
    {text: 'Redownload PDF', action: () => send(1, false), closeOnSelect: true},
    {text: 'Upload Completed Document', action: showFileSelect, closeOnSelect: true},
  ], [member])

  const currentOptions = showNewOptions ? newMenuOptions : pendingStepOptions

  const toastData = (description, status) => ({
    description,
    status,
    width: '500px',
  })

  const toastIdRef = React.useRef<ToastId>()
  const onUploadProgress = ({progress}: {progress: number}) => {
    if (toastIdRef.current !== undefined) {
      chakraToast.update(toastIdRef.current, toastData(`${progress}% Uploaded`, 'info'))
    }
  }

  const uploadPdf = (file: File) => {
    toastIdRef.current = toast(toastData('0% Uploaded', 'info'))

    uploadFile(file, onUploadProgress).then(finalPdfId => {
      if (toastIdRef.current !== undefined) {
        chakraToast.update(toastIdRef.current, toastData('Upload Complete', 'success'))
      }
      const data = {
        finalPdf: finalPdfId,
      }
      dispatch(updateUser(data, member.id))
    })
  }

  return (
    <>
      <Menu onClose={() => setShowNewOptions(isNew)}>
        <MenuButton as={Button} type='submit' isLoading={isSending}>
          {children}
        </MenuButton>
        <MenuList>
          {currentOptions.map(({text, action, closeOnSelect}) => (
            <MenuItem key={text} onClick={action} closeOnSelect={closeOnSelect}>{text}</MenuItem>
          ))}
        </MenuList>
      </Menu>
      <FileInput
        id='pdfUpload'
        accept='application/pdf'
        onChange={ async ({file}) => {
          uploadPdf(file)
        }}
        ref={fileInputRef}
      />
    </>
  )
}

export default SendButton
