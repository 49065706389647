import React from 'react'
import {
  Box,
  Flex,
  Icon,
  Image,
  ImageProps,
  Text,
  VStack,
  useTheme,
} from '@chakra-ui/react'
import { FiPhone } from 'react-icons/fi'
import logo from '../assets/Images/logo.svg'
import { grays } from '../constants/Colors'


type CodeCardProps = React.ComponentProps<typeof Flex> & {
  codeUrl: ImageProps['src']
  firstName?: string
  lastName?: string
  middleName?: string
  phoneNumber?: string
  size?: 'modal' | 'print'
}

const CodeCard = ({
  codeUrl,
  firstName,
  lastName,
  middleName,
  phoneNumber,
  size = 'print',
  ...otherProps
}: CodeCardProps) => {
  const { colors: { brand }, logoUrl } = useTheme()
  const baseFontSize = size === 'modal' ? '26px' : '20px'
  const height = size === 'modal' ? '302px' : '2in'
  const width = size === 'modal' ? '528px' : '3.5in'

  return (
    <>
      <Flex
        border={`1px solid ${grays.image}`}
        fontSize={baseFontSize}
        h={height}
        p='2%'
        w={width}
        {...otherProps}>
        <VStack w='50%' flex='1' alignItems='flex-start' justifyContent='space-between'>
          <Image src={logoUrl} fallbackSrc={logo} alt='logo' width='80%' />
          <Box fontSize='.6em' >
            <Icon as={FiPhone} display='inline' marginRight='6px' color={brand[500]}/>
            {phoneNumber}
          </Box>
          <VStack alignItems='flex-start' fontWeight='500' spacing='0'>
            <Text>{`${firstName} ${middleName ? middleName : ''}`}</Text>
            <Text>{lastName}</Text>
          </VStack>
          <Text fontSize={size === 'print' ? '12px' : '18px'} color={grays[600]} >
            Scan QR card for Healthcare Instructions
          </Text>
        </VStack>
        <img src={codeUrl} alt='qr-code' width='50%' />
      </Flex>

    </>

  )
}

export default CodeCard
