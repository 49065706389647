import React, { useEffect, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { StoreState } from '../../redux'
import { isLoading } from '../../redux/AsyncState'
import * as RoutePaths from '../../constants/RoutePaths'
import { getMany as getUsers, update as updateUser } from '../../redux/users/actions'
import { purples, reds, grays, textPalette, white } from '../../constants/Colors'
import Roles from '../../constants/Roles'
import {Model as UserModel} from '../../constants/User'
import {
  Avatar,
  Box,
  Button,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalCloseButton,
  ModalOverlay,
  ModalHeader,
  Text,
  useDisclosure,
  useTheme, Flex,
} from '@chakra-ui/react'
import { QUERY_PARAM_KEYS, getNewSearchString, privateFileUrl } from '../../utils/Routing'
import { FiCheck, FiChevronLeft, FiEdit2, FiMinusCircle, FiPlus, FiSearch } from 'react-icons/fi'
import isEmpty from 'lodash.isempty'
import debounce from 'lodash.debounce'
import { Types as DirectiveTypes } from '../../constants/Directive'
import { parsePhoneNumber } from '../../utils/Forms'
import WarningModal from '../../components/WarningModal'
import LoadingPage from '../../components/LoadingPage'
import InviteProviderModal from './InviteProviderModal'
import { getTeamRole, getFullName } from '../../redux/directives/selector'

function MemberTeam() {
  const history = useHistory()

  const {
    isOpen: isOpenAddModal,
    onOpen: onOpenAddModal,
    onClose: onCloseAddModal,
  } = useDisclosure()
  const {
    isOpen: isOpenRemoveModal,
    onOpen: onOpenRemoveModal,
    onClose: onCloseRemoveModal ,
  } = useDisclosure()
  const dispatch = useDispatch()
  const { colors: { brand } } = useTheme()
  const { id } = useParams<{id: string}>()
  const {
    allLoadedUsers,
    isUpdatingTeam,
    orderedMembers,
    memberLeadName,
  } = useSelector(({users, accounts}: StoreState) => ({
    allLoadedUsers: users.records,
    isUpdatingTeam: isLoading(users.updatingState),
    orderedMembers: users.recentOrderedUserIds.map(memberId => users.records[memberId]),
    memberLeadName: accounts.current?.memberLeadName,
  }), shallowEqual)
  const [newAdvocate, setNewAdvocate] = useState('')
  const [advocateToRemove, setAdvocateToRemove] = useState<UserModel>()
  const [searchTerm, setSearchTerm] = useState('')
  const [inviteProviderModalOpen, setInviteProviderModalOpen] = useState(false)

  const updateSearchTerm = (newValue) => {
    setSearchTerm(newValue)
  }

  const goToStep = (path, stepValue: Number) => history.push({
    pathname: path,
    search: getNewSearchString({
      [QUERY_PARAM_KEYS.STEP]: String(stepValue),
    }),
  })

  const debouncedGetSearchListData = debounce((...args: Parameters<any>) => dispatch(getUsers(...args)), 300)

  useEffect(() => {
    if (!isOpenAddModal) return
    if (isEmpty(searchTerm)) dispatch(getUsers({ roleNames: [Roles.ADVOCATE, Roles.ADMIN] }))
    else if (!isEmpty(searchTerm) && searchTerm.length > 2) {
      debouncedGetSearchListData({ name: searchTerm, roleNames: [Roles.ADVOCATE, Roles.ADMIN] })
    }
  }, [isOpenAddModal, searchTerm])

  useEffect(() => {
    if (!isOpenAddModal) {
      setNewAdvocate('')
    }
  }, [isOpenAddModal])

  useEffect(() => {
    if (!isUpdatingTeam) {
      onCloseAddModal()
      onCloseRemoveModal()
    }
  }, [isUpdatingTeam])

  const member = allLoadedUsers[id]
  const advocates = member == null ? [] : member.advocateIds!.map((aId) => allLoadedUsers[aId])
  const primaryCareProviders = member == null ? [] : member.primaryCareProviderIds!.map((pcpId) => allLoadedUsers[pcpId])
  const surrogate = member == null ? null : allLoadedUsers[member.surrogateIds![0]]
  const alternateSurrogate = member == null ? null : allLoadedUsers[member.alternateSurrogateIds![0]]
  const secondAlternateSurrogate = member == null ? null : allLoadedUsers[member.alternateSurrogateIds![1]]
  const teamMemberIds = member == null ? [] : member.advocateIds!.concat(
    member.alternateSurrogateIds!,
    member.primaryCareProviderIds!,
    member.surrogateIds!,
  )
  const surrogateTeamRole = member == null ? null : getTeamRole(surrogate, member.teamId)
  const alternateTeamRole = member == null ? null : getTeamRole(alternateSurrogate, member.teamId)
  const secondAlternateTeamRole = member == null ? null : getTeamRole(secondAlternateSurrogate, member.teamId)

  useEffect(() => {
    if (id != null) {
      dispatch(getUsers({teamUserId: id}))
    }
  }, [id])

  const setSelectedAdvocate = (advocateId) => {
    setNewAdvocate(advocateId)
  }

  const openRemoveModal = (advocateId) => {
    setAdvocateToRemove(advocateId)
    onOpenRemoveModal()
  }

  const addAdvocate = () => {
    const newTeamMemberIds = teamMemberIds.concat(newAdvocate)
    dispatch(updateUser({
      teamMemberIds: newTeamMemberIds,
    }, member.id))
  }

  const removeAdvocate = () => {
    const newTeamMemberIds = teamMemberIds.filter((currentId) => currentId !== advocateToRemove?.id)
    dispatch(updateUser({
      teamMemberIds: newTeamMemberIds,
    }, member.id))
  }

  if (member == null) return <LoadingPage/>

  return (
    <>
      <Flex direction='column' minHeight='296px' minWidth='1440px' maxWidth='1600px' p='80px 105px 49px 105px' align='left' textAlign='left' marginLeft='auto' marginRight='auto'>
        <Link to={RoutePaths.MemberDetail(member.id)}>
          <Icon as={FiChevronLeft} display='inline' marginRight='6px'/>
          Back
        </Link>
        <Text fontSize='42px' fontWeight='500' lineHeight='44px' marginTop='48px'>{member.firstName}'s Thanacare Team</Text>
        <Box marginTop='48px'>
          <Text fontSize='20px' marginBottom='16px' lineHeight='24px' color={textPalette.light}>
            {memberLeadName}
          </Text>
          <Box display='flex'>
            {advocates.map(advocate =>
              (<Box key={advocate.id} w='255px'  marginRight='30px' padding='24px' bg={white} border={`1px solid ${grays.light}`} textAlign='center' position='relative'>
                <Avatar
                  size='lg'
                  width='120px'
                  marginLeft='auto'
                  marginRight='auto'
                  height='120px'
                  bg={purples.avatar}
                  color={white}
                  name={advocate.firstName + ` ${advocate.lastName}`}
                  src={privateFileUrl(advocate?.avatar)}
                />
                {advocates.length > 1 &&
                  <Button onClick={() => openRemoveModal(advocate)} position='absolute' top='16px' right='16px' m='0' p='0' bg='none' border='none'  h='24px' _hover={{ bg: 'none' }} _active={{ bg: 'none'}} _focus={{ boxShadow: 'none !important' }}>
                    <Icon as={FiMinusCircle}
                      color={reds.primary}
                      width='24px'
                      height='24px' />
                  </Button>
                }
                <Text fontSize='20px' fontWeight='500' lineHeight='24px' mt='24px'>{advocate.namePrefix} {advocate.firstName} {advocate.lastName} {advocate.nameSuffix} </Text>
                <Text mt='4px' fontWeight='400' fontSize='16px' color={textPalette.light}>
                  {advocate.specialty}
                </Text>
                <Text mt='4px' fontWeight='400' fontSize='16px' color={textPalette.light}>
                  {advocate.email}
                </Text>
              </Box>),
            )}
            {advocates.length < 3 &&
              <button onClick={onOpenAddModal}>
                <Box w='255px' height='100%' marginRight='30px' padding='24px' bg={white} border={`1px solid ${grays.light}`} textAlign='center'>
                  <Button w='120px' h='120px' bg={brand[500]} borderRadius='50%' ml='auto' mr='auto'>
                    <Icon as={FiPlus} w='40px' h='40px'/>
                  </Button>
                  <Text fontSize='20px' fontWeight='500' lineHeight='24px' mt='24px'>Assign New {memberLeadName}</Text>
                </Box>
              </button>
            }
          </Box>
        </Box>
        <Box marginTop='48px'>
          <Text fontSize='20px' lineHeight='24px' marginTop='48px' marginBottom='16px' color={textPalette.light}>
            Primary Care Provider
          </Text>
          <Box display='flex'>
            {primaryCareProviders.length === 0 &&
              <button onClick={() => setInviteProviderModalOpen(true)}>
                <Box w='255px' minHeight='304px' marginRight='30px' padding='24px' bg={white} border={`1px solid ${grays.light}`} textAlign='center'>
                  <Button w='120px' h='120px' bg={brand[500]} borderRadius='50%' ml='auto' mr='auto'>
                    <Icon as={FiPlus} w='40px' h='40px'/>
                  </Button>
                  <Text fontSize='20px' fontWeight='500' lineHeight='24px' mt='24px'>Assign a Primary Care Provider</Text>
                </Box>
              </button>
            }
            {primaryCareProviders.map(provider => (
              <Box key={provider?.id} w='255px'  marginRight='30px' padding='24px' bg={white} border={`1px solid ${grays.light}`} textAlign='center' position='relative'>
                <Avatar
                  size='lg'
                  width='120px'
                  marginLeft='auto'
                  marginRight='auto'
                  height='120px'
                  bg={purples.avatar}
                  color={white}
                  name={`${provider?.firstName} ${provider?.lastName}`}
                  src={privateFileUrl(provider?.avatar)}
                />
                <Button
                  position='absolute' top='16px' right='16px' m='0' p='0' bg='none' h='24px' w='24px' _hover={{ bg: 'none' }} _active={{ bg: 'none' }}
                  onClick={() => setInviteProviderModalOpen(true)}>
                  <Icon as={FiEdit2}
                    color={textPalette.light}
                    width='24px'
                    height='24px' />
                </Button>
                <Text fontSize='20px' fontWeight='500' lineHeight='24px' mt='24px'>{provider?.namePrefix} {provider?.firstName} {provider?.lastName} {provider?.nameSuffix} </Text>
                <Text mt='4px' fontWeight='400' fontSize='16px' color={textPalette.light}>
                  {/* TODO: Add providerHospital to user model */}
                        Regions Hospital
                </Text>
                <Text mt='4px' fontWeight='400' fontSize='16px' color={textPalette.light}>
                  {parsePhoneNumber(provider?.phoneNumber)}
                </Text>
                <Text mt='4px' fontWeight='400' fontSize='16px' color={textPalette.light}>
                  {provider?.email}
                </Text>
              </Box>
            ),
            )}
          </Box>
        </Box>
        <Box marginTop='48px'>
          <Text fontSize='20px' lineHeight='24px' marginTop='48px' marginBottom='16px' color={textPalette.light}>
            Agent
          </Text>
          <Box display='flex'>
            {surrogate == null ?
              <button onClick={() => goToStep(RoutePaths.MemberDirective(member.id, DirectiveTypes.HEALTHCARE_PT1_DIRECTIVE), 1)}>
                <Box w='255px' minHeight='304px' marginRight='30px' padding='24px' bg={white} border={`1px solid ${grays.light}`} textAlign='center'>
                  <Button w='120px' h='120px' bg={brand[500]} borderRadius='50%' ml='auto' mr='auto'>
                    <Icon as={FiPlus} w='40px' h='40px'/>
                  </Button>
                  <Text fontSize='20px' fontWeight='500' lineHeight='24px' mt='24px'>Assign a Healthcare Agent</Text>
                </Box>
              </button>
              : <Box key={surrogate?.id} w='255px' marginRight='30px' marginBottom='124px' padding='24px' bg={white} border={`1px solid ${grays.light}`} textAlign='center' position='relative'>
                <Avatar
                  size='lg'
                  width='120px'
                  marginLeft='auto'
                  marginRight='auto'
                  height='120px'
                  bg={purples.avatar}
                  color={white}
                  name={getFullName(surrogateTeamRole)}
                  src={privateFileUrl(surrogate?.avatar)}
                />
                <Button
                  position='absolute' top='16px' right='16px' m='0' p='0' bg='none' h='24px' _hover={{ bg: 'none' }} _active={{ bg: 'none' }}
                  onClick={() => goToStep(RoutePaths.MemberDirective(member.id, DirectiveTypes.HEALTHCARE_PT1_DIRECTIVE), 1)}>
                  <Icon as={FiEdit2}
                    color={textPalette.light}
                    width='24px'
                    height='24px' />
                </Button>
                <Text fontSize='20px' fontWeight='500' lineHeight='24px' mt='24px'> {getFullName(surrogateTeamRole)} </Text>
                <Text mt='4px' fontWeight='400' fontSize='16px' color={textPalette.light}>
                  {surrogateTeamRole.memberRelationship}
                </Text>
                <Text mt='4px' fontWeight='400' fontSize='16px' color={textPalette.light}>
                  {parsePhoneNumber(surrogateTeamRole.phoneNumber)}
                </Text>
                <Text mt='4px' fontWeight='400' fontSize='16px' color={textPalette.light}>
                  {surrogateTeamRole.email}
                </Text>
              </Box>
            }
            {alternateSurrogate == null ? null
              : <Box key={alternateSurrogate?.id} w='255px' marginRight='30px' marginBottom='124px' padding='24px' bg={white} border={`1px solid ${grays.light}`} textAlign='center' position='relative'>
                <Avatar
                  size='lg'
                  width='120px'
                  marginLeft='auto'
                  marginRight='auto'
                  height='120px'
                  bg={purples.avatar}
                  color={white}
                  name={getFullName(alternateTeamRole)}
                  src={privateFileUrl(alternateSurrogate?.avatar)}
                />
                <Button
                  position='absolute' top='16px' right='16px' m='0' p='0' bg='none' h='24px' _hover={{ bg: 'none' }} _active={{ bg: 'none' }}
                  onClick={() => goToStep(RoutePaths.MemberDirective(member.id, DirectiveTypes.HEALTHCARE_PT1_DIRECTIVE), 1)}>
                  <Icon as={FiEdit2}
                    color={textPalette.light}
                    width='24px'
                    height='24px' />
                </Button>
                <Text fontSize='20px' fontWeight='500' lineHeight='24px' mt='24px'>{getFullName(alternateTeamRole)}</Text>
                <Text mt='4px' fontWeight='400' fontSize='16px' color={textPalette.light}>
                  {alternateTeamRole.memberRelationship}
                </Text>
                <Text mt='4px' fontWeight='400' fontSize='16px' color={textPalette.light}>
                  {parsePhoneNumber(alternateTeamRole.phoneNumber)}
                </Text>
                <Text mt='4px' fontWeight='400' fontSize='16px' color={textPalette.light}>
                  {alternateTeamRole.email}
                </Text>
              </Box>
            }
            {secondAlternateSurrogate == null ? null
              : <Box key={secondAlternateSurrogate?.id} w='255px' marginRight='30px' marginBottom='124px' padding='24px' bg={white} border={`1px solid ${grays.light}`} textAlign='center' position='relative'>
                <Avatar
                  size='lg'
                  width='120px'
                  marginLeft='auto'
                  marginRight='auto'
                  height='120px'
                  bg={purples.avatar}
                  color={white}
                  name={getFullName(secondAlternateTeamRole)}
                  src={privateFileUrl(secondAlternateSurrogate?.avatar)}
                />
                <Button
                  position='absolute' top='16px' right='16px' m='0' p='0' bg='none' h='24px' _hover={{ bg: 'none' }} _active={{ bg: 'none' }}
                  onClick={() => goToStep(RoutePaths.MemberDirective(member.id, DirectiveTypes.HEALTHCARE_PT1_DIRECTIVE), 1)}>
                  <Icon as={FiEdit2}
                    color={textPalette.light}
                    width='24px'
                    height='24px' />
                </Button>
                <Text fontSize='20px' fontWeight='500' lineHeight='24px' mt='24px'>{getFullName(secondAlternateTeamRole)} </Text>
                <Text mt='4px' fontWeight='400' fontSize='16px' color={textPalette.light}>
                  {secondAlternateTeamRole.memberRelationship}
                </Text>
                <Text mt='4px' fontWeight='400' fontSize='16px' color={textPalette.light}>
                  {parsePhoneNumber(secondAlternateTeamRole.phoneNumber)}
                </Text>
                <Text mt='4px' fontWeight='400' fontSize='16px' color={textPalette.light}>
                  {secondAlternateTeamRole.email}
                </Text>
              </Box>
            }
          </Box>
        </Box>
      </Flex>
      <Modal
        isCentered
        closeOnOverlayClick={!isUpdatingTeam}
        isOpen={isOpenAddModal}
        motionPreset='slideInRight'
        onClose={onCloseAddModal}>
        <ModalOverlay />
        <ModalContent
          maxWidth='730px'
          h='496px'
          textAlign='center'
        >
          <ModalHeader
            fontWeight='normal'
            fontSize='26px'
            paddingBottom='8px'
            color={textPalette.normal}
          >
            Assign New {memberLeadName}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody paddingTop='0'>
            <Text marginBottom='32px' color={textPalette.light}>
              Select {memberLeadName} to add to this Member's Team
            </Text>
            <Box position='relative' maxWidth='540px' m='auto'>
              <Icon as={FiSearch} w='20px' h='20px' position='absolute' top='14px' left='16px' zIndex='2'/>
              <Input
                onChange={({currentTarget}) => updateSearchTerm(currentTarget.value)}
                value={searchTerm}
                w='540px' h='48px' p='12px 16px 12px 44px'/>
            </Box>
            <Box w='540px' maxHeight='160px' overflow='auto' m='16px auto 44px auto'>
              {orderedMembers && Object.values(orderedMembers)?.map(user => (
                <Box key={user.id} onClick={() => setSelectedAdvocate(user.id)} _hover={{cursor: 'pointer'}} position='relative' maxW='540px' boxSizing='border-box' border={user.id === newAdvocate ? `1px solid ${brand[500]}` : '1px solid transparent' }
                  h='80px' p='12px 16px' display='flex' alignSelf='left' borderRadius='3px'>
                  <Avatar size='sm' w='56px' h='56px' bg={purples.avatar} color={white} name={user.firstName + ` ${user.lastName}`}/>
                  <Box ml='16px' textAlign='left' pt='4px'>
                    <Text fontWeight='500'>{user.firstName} {user.lastName}</Text>
                    <Text>{user.specialty}</Text>
                  </Box>
                  {user.id === newAdvocate &&
                    <Icon as={FiCheck} color={brand[500]} w='24px' h='24px' position='absolute' top='26px' right='32px'/>
                  }
                </Box>),
              )}
            </Box>
            <Button
              disabled={isUpdatingTeam}
              onClick={onCloseAddModal}
              w='252px' h='48px' mr='16px' variant='transparentPurpleOutline'>
              Cancel
            </Button>
            <Button
              isLoading={isUpdatingTeam}
              onClick={addAdvocate}
              w='252px' h='48px'
            >
              Done
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
      <WarningModal
        headerText={`Are you sure you want to remove ${advocateToRemove?.namePrefix} ${advocateToRemove?.firstName} ${advocateToRemove?.lastName} as an Advocate for ${member.firstName} ${member.lastName}?`}
        isLoading={isOpenRemoveModal && isUpdatingTeam}
        isOpen={isOpenRemoveModal}
        onClose={onCloseRemoveModal}
        onConfirm={removeAdvocate}
      >
        The advocate will no longer have access to this member.
      </WarningModal>
      <InviteProviderModal isOpen={inviteProviderModalOpen} onClose={() => setInviteProviderModalOpen(false)} teamUserId={id} currentProvider={primaryCareProviders && primaryCareProviders[0]} />
    </>
  )
}

export default MemberTeam
