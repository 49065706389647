import {
  Store,
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
} from 'redux'
import createSagaMiddleware from 'redux-saga'
import { all, fork } from 'redux-saga/effects'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {
  StateType as AuthStateType,
  reducer as authReducer,
  watch as watchAuth,
} from './auth'
import {
  StateType as DirectivesStateType,
  reducer as directivesReducer,
  watch as watchDirectives,
} from './directives'
import {
  StateType as FilesStateType,
  reducer as filesReducer,
  watch as watchFiles,
} from './files'
import {
  StateType as HealthConcernsStateType,
  reducer as healthConcernsReducer,
  watch as watchHealthConcerns,
} from './healthConcerns'
import {
  StateType as HealthPlansStateType,
  reducer as healthPlansReducer,
  watch as watchHealthPlans,
} from './healthPlans'
import {
  StateType as MeetingPurposesStateType,
  reducer as meetingPurposesReducer,
  watch as watchMeetingPurposes,
} from './meetingPurposes'
import {
  StateType as PartnersStateType,
  reducer as accountsReducer,
  watch as watchPartners,
} from './accounts'
import {
  StateType as MemberListStateType,
  reducer as memberListReducer,
  watch as watchMemberList,
} from './views/memberList'
import {
  StateType as MemberNotesStateType,
  reducer as memberNotesReducer,
  watch as watchMemberNotes,
} from './views/memberNotes'
import {
  StateType as TeamMeetingsStateType,
  reducer as teamMeetingsReducer,
  watch as watchTeamMeetings,
} from './teamMeetings'
import {
  StateType as UsersStateType,
  reducer as usersReducer,
  watch as watchUsers,
} from './users'

const composeEnhancer =
  (window as any)['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] || compose

enum MODULE_NAMES {
  AUTH = 'auth',
  DIRECTIVES = 'directives',
  FILES = 'files',
  HEALTH_CONCERNS = 'healthConcerns',
  HEALTH_PLANS = 'healthPlans',
  MEETING_PURPOSES = 'meetingPurposes',
  MEMBER_LIST = 'memberList',
  MEMBER_NOTES = 'memberNotes',
  ACCOUNTS = 'accounts',
  TEAM_MEETINGS = 'teamMeetings',
  USERS = 'users',
}

// Define Store type for use in connected components
export interface StoreState {
  [MODULE_NAMES.AUTH]: AuthStateType
  [MODULE_NAMES.DIRECTIVES]: DirectivesStateType
  [MODULE_NAMES.FILES]: FilesStateType
  [MODULE_NAMES.HEALTH_CONCERNS]: HealthConcernsStateType
  [MODULE_NAMES.HEALTH_PLANS]: HealthPlansStateType
  [MODULE_NAMES.MEETING_PURPOSES]: MeetingPurposesStateType
  [MODULE_NAMES.MEMBER_LIST]: MemberListStateType
  [MODULE_NAMES.MEMBER_NOTES]: MemberNotesStateType
  [MODULE_NAMES.ACCOUNTS]: PartnersStateType
  [MODULE_NAMES.TEAM_MEETINGS]: TeamMeetingsStateType
  [MODULE_NAMES.USERS]: UsersStateType
}

// Merge application modules' reducers into a single reducer
const rootReducer = combineReducers({
  [MODULE_NAMES.AUTH]: authReducer,
  [MODULE_NAMES.DIRECTIVES]: directivesReducer,
  [MODULE_NAMES.FILES]: filesReducer,
  [MODULE_NAMES.HEALTH_CONCERNS]: healthConcernsReducer,
  [MODULE_NAMES.HEALTH_PLANS]: healthPlansReducer,
  [MODULE_NAMES.MEETING_PURPOSES]: meetingPurposesReducer,
  [MODULE_NAMES.MEMBER_LIST]: memberListReducer,
  [MODULE_NAMES.MEMBER_NOTES]: memberNotesReducer,
  [MODULE_NAMES.ACCOUNTS]: accountsReducer,
  [MODULE_NAMES.TEAM_MEETINGS]: teamMeetingsReducer,
  [MODULE_NAMES.USERS]: usersReducer,
})

const sagaMiddleware = createSagaMiddleware()
const rootSaga = function* root() {
  yield all([
    fork(watchAuth),
    fork(watchDirectives),
    fork(watchFiles),
    fork(watchHealthConcerns),
    fork(watchHealthPlans),
    fork(watchMeetingPurposes),
    fork(watchMemberList),
    fork(watchMemberNotes),
    fork(watchPartners),
    fork(watchTeamMeetings),
    fork(watchUsers),
  ])
}

const persistedReducer = persistReducer(
  {
    key: 'root',
    storage,
    whitelist: [MODULE_NAMES.AUTH],
  },
  rootReducer,
)

let store = null as unknown as Store<StoreState, any>

export const configureStore = () => {
  // Compose all middleware functions into the redux execution chain
  const middleware = [
    sagaMiddleware,
    // other middlewares
  ]
  const composedMiddleware = composeEnhancer(applyMiddleware(...middleware))

  // Combine all reducer states into a single redux store and export for use with react-router
  store = createStore(persistedReducer, composedMiddleware)

  sagaMiddleware.run(rootSaga)

  return {
    persistor: persistStore(store),
    store,
  }
}

export const getStore = () => store
