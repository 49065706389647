import { call, put, race, take, takeEvery, takeLatest } from 'redux-saga/effects'
import ActionIds from '../ActionIds'
import { BaseAction } from '../types'
import {
  create as createApi, getCurrent,
  getMany as getManyApi,
  getOne as getOneApi,
  update as updateApi,
} from './api'

export default function* watch() {
  yield takeLatest(ActionIds.ACCOUNTS_GET_MANY_START, requestMany)
  yield takeEvery(ActionIds.ACCOUNTS_GET_ONE_START, requestOne)
  yield takeEvery(ActionIds.ACCOUNTS_CREATE_START, requestCreate)
  yield takeEvery(ActionIds.ACCOUNTS_UPDATE_START, requestUpdate)
  yield takeEvery(ActionIds.ACCOUNTS_GET_CURRENT_START, requestCurrent)
}

function* requestMany({payload: params}: BaseAction) {
  const { apiResponse: {response, error}, isCanceled } = yield race({
    apiResponse: call(getManyApi, params),
    isCanceled: take(ActionIds.ACCOUNTS_GET_MANY_CANCEL),
  })
  if (isCanceled) return

  if (error) {
    yield put({type: ActionIds.ACCOUNTS_GET_MANY_FAIL})
    return
  }

  yield put({type: ActionIds.ACCOUNTS_GET_MANY_SUCCESS, payload: response.data})
}

function* requestOne({payload: id}: BaseAction) {
  const { apiResponse: {response, error}, isCanceled } = yield race({
    apiResponse: call(getOneApi, id),
    isCanceled: take(ActionIds.ACCOUNTS_GET_ONE_CANCEL),
  })
  if (isCanceled) return

  if (error) {
    yield put({type: ActionIds.ACCOUNTS_GET_ONE_FAIL})
    return
  }

  yield put({type: ActionIds.ACCOUNTS_GET_ONE_SUCCESS, payload: response.data})
}

function* requestCurrent() {
  const { apiResponse: {response, error}, isCanceled } = yield race({
    apiResponse: call(getCurrent),
    isCanceled: take(ActionIds.ACCOUNTS_GET_CURRENT_CANCEL),
  })
  if (isCanceled) return

  if (error) {
    yield put({type: ActionIds.ACCOUNTS_GET_CURRENT_FAIL})
    return
  }

  yield put({type: ActionIds.ACCOUNTS_GET_CURRENT_SUCCESS, payload: response.data})
}

function* requestUpdate({payload: {id, params}}: BaseAction) {
  const{response, error} = yield call(updateApi, id, params)

  if (error) {
    yield put({type: ActionIds.ACCOUNTS_UPDATE_FAIL})
    return
  }
  yield put({type: ActionIds.ACCOUNTS_GET_CURRENT_START})
  yield put({type: ActionIds.ACCOUNTS_UPDATE_SUCCESS, payload: response.data})
}

function* requestCreate({payload: params}: BaseAction) {
  const{response, error} = yield call(createApi, params)

  if (error) {
    yield put({type: ActionIds.ACCOUNTS_CREATE_FAIL})
    return
  }

  yield put({type: ActionIds.ACCOUNTS_CREATE_SUCCESS, payload: response.data})
}
