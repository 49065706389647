import { useState } from 'react'
import * as Yup from 'yup'
import isEmpty from 'lodash.isempty'
import useFormikContext from './useFormikContext'

export const NONE_VALUE = 'None'
export const OTHER_VALUE = 'Other'

interface HookOptions {
  allowNoneOption?: boolean
  customField: string
  selectionField: string
}

export const getValidationSchema = ({customField, selectionField}: HookOptions) => ({
  [customField]: Yup.string().when(selectionField, {
    is: (value?: string[]) => value?.includes(OTHER_VALUE),
    then: Yup.string().nullable().required('Must not be empty'),
  }),
  [selectionField]: Yup.array().min(1, 'Must choose at least one'),
})

export const getOptionValues = (
  customValue: string,
  selectedValues: string[],
) => (selectedValues.includes(NONE_VALUE) ? [] : selectedValues).reduce((memo, value) => ({
  customValue: value === OTHER_VALUE ? customValue : memo.customValue,
  selectedValues: value === OTHER_VALUE ? memo.selectedValues : memo.selectedValues.concat(value),
}), {
  customValue: undefined as string | undefined,
  selectedValues: [] as string[],
})

interface InitialParams {
  allOptions: string[]
  initialSelection: string[]
}

const useCustomSelectOption = ({allowNoneOption = false, customField, selectionField}: HookOptions) => {
  const [selectedOptions, setSelectedOptions] = useState({})
  const form = useFormikContext<any>()

  const onClickOption = (value) => {
    const isSelected = selectedOptions[value]
    const isNoneValue = value === NONE_VALUE
    const newSelection = (allowNoneOption && isNoneValue) ? {[NONE_VALUE]: true} : {...selectedOptions, [NONE_VALUE]: false, [value]: !isSelected}
    form.setFieldValue(
      selectionField,
      Object.keys(newSelection).reduce((memo, key) => memo.concat(newSelection[key] ? key : []), [] as string[]),
    )
    setSelectedOptions(newSelection)
  }

  const initializeOptions = ({allOptions, initialSelection}: InitialParams) => {
    let otherCustomOption = ''
    const nonCustomSelections = initialSelection.reduce((memo, name) => {
      if (allOptions.includes(name)) return memo.concat(name)

      otherCustomOption = name
      return memo.includes(OTHER_VALUE) ? memo : memo.concat(OTHER_VALUE)
    }, [] as string[])

    const isOtherSelected = otherCustomOption.length > 0
    const isNoneSelected = allowNoneOption && !isOtherSelected && nonCustomSelections.length === 0

    form.reinitialize({
      [customField]: otherCustomOption,
      [selectionField]: isNoneSelected ? [NONE_VALUE] : nonCustomSelections,
    })

    const newSelection = nonCustomSelections.length === 0 ? {
      [NONE_VALUE]: isNoneSelected,
      [OTHER_VALUE]: isOtherSelected,
    } : {
      ...nonCustomSelections.reduce((memo, name) => ({...memo, [name]: true}), {}),
      [NONE_VALUE]: false,
      [OTHER_VALUE]: isOtherSelected,
    }
    setSelectedOptions(newSelection)
  }

  return {
    initializeOptions,
    isOtherInvalid: form.touched[customField] && !(isEmpty(form.errors[selectionField]) && isEmpty(form.errors[customField])),
    isOtherOptionSelected: selectedOptions[OTHER_VALUE] === true,
    onClickOption,
    selectedOptions,
  }
}

export default useCustomSelectOption
