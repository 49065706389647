import React, { useCallback, useMemo, useState } from 'react'
import { Redirect, RouteComponentProps } from 'react-router'
import { Container } from '@chakra-ui/react'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import PaymentForm from './PaymentForm'
import { PaymentRedirect } from './PaymentRedirect'
import { useToast } from '../../../hooks/Toast'
import { useSelector } from 'react-redux'
import { StoreState } from '../../../redux'
import { AccountAdminPaymentPage } from '../../../constants/RoutePaths'

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ?? '',
)

type PaymentProps = RouteComponentProps;

const Payment: React.FC<PaymentProps> = () => {
  const toast = useToast()
  const [isPolling, setIsPolling] = useState(false)

  const account = useSelector((state: StoreState) => state.accounts.current)
  const shouldRedirectToAccountPaymentSettingsPage = useMemo(
    () =>
      account.stripeSubscriptionStatus &&
      ['active', 'trialing'].includes(
        account.stripeSubscriptionStatus,
      ),
    [],
  )

  const handleSuccess = useCallback(() => {
    // after stripe has created the subscription, we still need to wait for the webhook to update the subscription status
    setIsPolling(true)

    toast({
      title: 'Payment Success',
      description:
        'Your subscription is now active! You will be redirected shortly.',
      status: 'success',
      duration: 3000,
    })
  }, [])

  return (
    <Container paddingTop='124px'>
      {shouldRedirectToAccountPaymentSettingsPage && (
        <Redirect to={AccountAdminPaymentPage()} />
      )}
      <Elements
        stripe={stripePromise}
        options={{
          mode: 'subscription',
          amount: 2000,
          currency: 'usd',
          paymentMethodTypes: ['card'],
        }}
      >
        <PaymentForm onSuccess={handleSuccess} />
      </Elements>

      {isPolling && <PaymentRedirect />}
    </Container>
  )
}

export default Payment
