import { css } from '@emotion/react'
import emotionNormalize from 'emotion-normalize'
import Fonts from './assets/Fonts'
import {white} from './constants/Colors'

export default css`
    ${emotionNormalize}
    ${Fonts}

    @media print {
      background: ${white};
    }
  }
`
