import React from 'react'
import {
  Flex,
  Spacer,
  HStack,
  Stack,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  Icon, Tag, TagLabel,
} from '@chakra-ui/react'
import { FiPlus, FiSearch } from 'react-icons/fi'
import {AccountRoles} from '../../../constants/Account'

const ListTopComponent: React.FC<{ onClickNew: () => void; onSearchUpdate; searchTerm: string; selectedRoles: string[]; onToggleRole: (name: string) => void }> = ({ onClickNew, onSearchUpdate, searchTerm, selectedRoles, onToggleRole }) => {

  return(
    <Flex align='center' h='80px' zIndex={1}>
      <HStack spacing={2}>
        <Button as={Tag} size='small' variant={selectedRoles.length === 4 ? 'solid' : 'outline'} borderRadius='full' onClick={() => onToggleRole('ALL')}><TagLabel>All Roles</TagLabel></Button>
        <Button as={Tag} size='small' variant={selectedRoles.length !== 4 && selectedRoles.includes(AccountRoles.OWNER) ? 'solid' : 'outline'} borderRadius='full' onClick={() => onToggleRole(AccountRoles.OWNER)}><TagLabel>Owners</TagLabel></Button>
        <Button as={Tag} size='small' variant={selectedRoles.length !== 4 && selectedRoles.includes(AccountRoles.ADMIN) ? 'solid' : 'outline'} borderRadius='full' onClick={() => onToggleRole(AccountRoles.ADMIN)}><TagLabel>Admins</TagLabel></Button>
        <Button as={Tag} size='small' variant={selectedRoles.length !== 4 && selectedRoles.includes(AccountRoles.ADVOCATE) ? 'solid' : 'outline'} borderRadius='full' onClick={() => onToggleRole(AccountRoles.ADVOCATE)}><TagLabel>Editors</TagLabel></Button>
        <Button as={Tag} size='small' variant={selectedRoles.length !== 4 && selectedRoles.includes(AccountRoles.OBSERVER) ? 'solid' : 'outline'} borderRadius='full' onClick={() => onToggleRole(AccountRoles.OBSERVER)}><TagLabel>Observers</TagLabel></Button>
      </HStack>
      <Spacer />
      <Button mr={4} borderRadius='3px' fontSize='16px' lineHeight='24px' leftIcon={<FiPlus />} onClick={onClickNew}>New Teammate</Button>
      <Stack spacing={4}>
        <InputGroup>
          <InputLeftElement
            pointerEvents='none'>
            <Icon as={FiSearch} />
          </InputLeftElement>
          <Input
            id='searchTermInput'
            type='text'
            borderRadius='3px'
            backgroundColor='white'
            placeholder={'Search Teammates'}
            value={searchTerm}
            onChange={({currentTarget}) => onSearchUpdate(currentTarget.value)}
          />
        </InputGroup>
      </Stack>
    </Flex>
  )
}

export default ListTopComponent
