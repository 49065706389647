import { Text } from '@chakra-ui/react'
import { Statuses as DirectiveStatuses, StatusesType } from '../../../constants/Directive'

const DirectiveStatusText: React.FC<{status: StatusesType[keyof StatusesType]}> = ({status}) => {
  const backgroundColor = (() => {
    switch (status) {
      case DirectiveStatuses.COMPLETED: return '#BCF5E7'
      case DirectiveStatuses.IN_PROGRESS: return '#FFBDAD'
      case DirectiveStatuses.NOT_STARTED: // fall-through
      case DirectiveStatuses.SENT: return '#F4C5FC'
      default: return '#FFF0B3'
    }
  })()
  const text = (() => {
    switch (status) {
      case DirectiveStatuses.COMPLETED: return 'Complete'
      case DirectiveStatuses.IN_PROGRESS: return 'In Progress'
      case DirectiveStatuses.NOT_STARTED: return 'Not Started'
      case DirectiveStatuses.SENT: // fall-through
      default: return 'Awaiting Signatures'
    }
  })()
  const width = status === DirectiveStatuses.SENT ? '145px' : 'initial'
  return (
    <Text
      backgroundColor={backgroundColor}
      fontSize='12px'
      fontWeight='500'
      padding='2px 6px'
      width={width}
      marginRight='auto'>
      {text}
    </Text>
  )
}

export default DirectiveStatusText
