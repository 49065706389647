import React, { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { css } from '@emotion/react'
import { Box } from '@chakra-ui/react'

const HIDDEN_PRINT_CLASS = 'printable-provider-hidden-for-print'
const SHOWN_PRINT_CLASS = 'printable-provider-show-for-print'

const printCss = css`
  @media print {
    .${HIDDEN_PRINT_CLASS} {
      display: none !important;
    }

    .${SHOWN_PRINT_CLASS} {
      -webkit-print-color-adjust: exact !important;
      display: block !important;
    }
  }
`

interface IContext {
  isPrinting: boolean
  print: (node: React.ReactNode, timeout?: number) => void
}

const useHooks = () => {
  const mainPortalRef = useRef<HTMLDivElement>(null)
  const printPortalRef = useRef<HTMLDivElement>(null)
  const [printableElement, setPrintableElement] = useState<React.ReactNode>()
  const [isPrinting, setIsPrinting] = useState(false)
  const [printModalTimeout, setPrintModalTimeout] = useState<number>()

  const print = (component: React.ReactNode, timeout = 0) => {
    setIsPrinting(true)
    setPrintableElement(component)
    setPrintModalTimeout(timeout)
  }

  useEffect(() => {
    if (printModalTimeout != null) {
      setPrintModalTimeout(undefined)
      setTimeout(() => {
        mainPortalRef.current?.classList.add(HIDDEN_PRINT_CLASS)
        printPortalRef.current?.classList.add(SHOWN_PRINT_CLASS)

        window.print()

        mainPortalRef.current?.classList.remove(HIDDEN_PRINT_CLASS)
        printPortalRef.current?.classList.remove(SHOWN_PRINT_CLASS)
      }, printModalTimeout)
    }
  }, [printModalTimeout])

  const afterPrintHandler = () => {
    setPrintableElement(undefined)
    setIsPrinting(false)
  }

  useEffect(() => {
    window.addEventListener('afterprint', afterPrintHandler)

    return () => {
      window.removeEventListener('afterprint', afterPrintHandler)
    }
  }, [])

  return {
    currentContext: { isPrinting, print },
    mainPortalRef,
    printPortalRef,
    printableElement,
  }
}

export const Context = React.createContext<IContext>({
  isPrinting: false,
  print: () => null,
})

const PrintableProvider: React.FC<React.PropsWithChildren<unknown>> = ({children}) => {
  const {
    currentContext,
    mainPortalRef,
    printPortalRef,
    printableElement,
  } = useHooks()

  return <Context.Provider value={currentContext}>
    <Box css={printCss}>
      <div ref={mainPortalRef}>{children}</div>
      <div ref={printPortalRef} hidden>{printableElement}</div>
    </Box>
  </Context.Provider>
}

export default PrintableProvider

type PrintableHandle = {
  print: (timeout?: number) => void
}
export const wrapper = <T,>(Element: (props: T) => JSX.Element) => {
  const PrintableElement: React.ForwardRefRenderFunction<PrintableHandle, T> = (props, ref) => {
    const { print } = useContext(Context)

    useImperativeHandle(ref, () => ({
      print(timeout) {
        // @ts-ignore
        print(() => <Element {...props}/>, timeout)
      },
    }))

    return <div hidden><Element {...props} hidden/></div>
  }

  return forwardRef(PrintableElement)
}
