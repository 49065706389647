const Roles = {
  ADMIN: 'ADMIN',
  ADVOCATE: 'ADVOCATE',
  ALTERNATE_SURROGATE: 'ALTERNATE_SURROGATE',
  MEMBER: 'MEMBER',
  PRIMARY_CARE_PROVIDER: 'PRIMARY_CARE_PROVIDER',
  SURROGATE: 'SURROGATE',
} as const

export default Roles
