interface OwnerModel {
  email: string
  name: string
}
export interface Model {
  createdAt: string
  avatar: string
  subdomain: string
  id: string
  logo: string
  name: string
  owners: OwnerModel[]
  primaryColor: string
  secondaryColor: string
  memberLeadName: string
  currentUserRole?: string
  flexTemplateId: string
  stripeSubscriptionStatus: string | null
}

export const AccountRoles = {
  OWNER: 'OWNER',
  ADMIN: 'ADMIN',
  ADVOCATE: 'ADVOCATE',
  OBSERVER: 'OBSERVER',
  MEMBER: 'MEMBER',
}


