import React, { useContext, useEffect, useState } from 'react'
import { Alert,
  AlertIcon,
  Box,
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  SlideFade,
  Stack,
  Text,
} from '@chakra-ui/react'
import SentPlane from '../../../../components/SentPlane'
import ShareDirectivesForm from './ShareDirectivesForm'
import { Context as DirtyFormAlertContext } from '../../../../components/DirtyFormAlert'
import { textPalette } from '../../../../constants/Colors'
import { useToast } from '../../../../hooks/Toast'

enum Steps {
  SHARE_DIRECTIVES = 0,
  SENT = 1,
}
type ShareDirectivesModalProps = Omit<ModalProps, 'children'>

const useHooks = ({isOpen}: ShareDirectivesModalProps) => {
  const toast = useToast()
  const { setDirtyFormAlert } = useContext(DirtyFormAlertContext)
  const [errorMessage, setErrorMessage] = useState<string>()
  const [step, setStep] = useState<Steps>(Steps.SHARE_DIRECTIVES)
  const [nextStep, setNextStep] = useState<Steps>(Steps.SHARE_DIRECTIVES)

  useEffect(() => {
    setNextStep(Steps.SHARE_DIRECTIVES)

    if (isOpen) {
      setErrorMessage(undefined)
    } else {
      setDirtyFormAlert(false)
    }
  }, [isOpen])

  useEffect(() => {
    setTimeout(() => setStep(nextStep), 300)
  }, [nextStep])

  useEffect(() => {
    setErrorMessage(undefined)
  }, [step])

  const onError = () => {
    setErrorMessage('Something went wrong. Please Try again.')
    toast({
      description: 'Issue with sending Directives',
      status: 'error',
    })
  }

  return {
    errorMessage,
    nextStep,
    onError,
    setNextStep,
    step,
  }
}

const ShareDirectivesModal: React.FC<ShareDirectivesModalProps> = (props) => {
  const { errorMessage, nextStep, onError, step, setNextStep } = useHooks(props)
  const { onClose } = props

  return (
    <Modal size='xl' closeOnOverlayClick={false} {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{step === Steps.SHARE_DIRECTIVES && <Center fontSize='26px' fontWeight='normal'>Share Directives</Center>}</ModalHeader>
        <ModalCloseButton />
        <ModalBody w='100%'>
          <Stack align='center' spacing='20px' margin='0 24px 24px 24px' >
            { step === Steps.SHARE_DIRECTIVES &&
              <Text fontSize='16px' textAlign='center' color={textPalette.light}>Enter email to receive Directives</Text>
            }
            <Box height='0' width='100%'>{errorMessage && (
              <Alert status='error'>
                <AlertIcon />
                <Center flex='1'>{errorMessage}</Center>
              </Alert>
            )}</Box>
            <SlideFade in={step === nextStep}>
              <Box w='100%'>
                <Center height='100%' w='100%'>
                  {(() => {
                    switch(step) {
                      case Steps.SENT:
                        return <SentPlane onFinish={onClose} text='Directives Sent!'/>
                      case Steps.SHARE_DIRECTIVES:
                        return <ShareDirectivesForm
                          onError={onError}
                          onFinish={() => setNextStep(Steps.SENT)}
                        />
                      default: return null
                    }
                  })()}
                </Center>
              </Box>
            </SlideFade>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default ShareDirectivesModal
