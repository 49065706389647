import React, { useContext, useEffect } from 'react'
import * as ReactRedux from 'react-redux'
import { ChildFormProps as ContainerProps } from '.'
import { update as updateDirective } from '../../../../redux/directives/actions'
import { Context as DirtyFormAlertContext } from '../../../../components/DirtyFormAlert'
import { Field, FieldInputProps, Form, Formik, FormikContextType } from 'formik'
import { isError } from '../../../../redux/AsyncState'
import { StoreState } from '../../../../redux'
import {
  Button,
  Center,
  Checkbox,
  FormControl,
  Icon,
  Stack,
  Text,
  Textarea,
} from '@chakra-ui/react'
import { textPalette } from '../../../../constants/Colors'
import { FiSave } from 'react-icons/fi'
import isEmpty from 'lodash.isempty'

enum FormFields {
  surrogatePowers2Text1 = 'surrogatePowers2Text1',
  surrogatePowers2Checkbox1 = 'surrogatePowers2Checkbox1',
  surrogatePowers2Checkbox2 = 'surrogatePowers2Checkbox2',
  surrogatePowers2Checkbox3 = 'surrogatePowers2Checkbox3',
  surrogatePowers2Checkbox4 = 'surrogatePowers2Checkbox4',
}

enum PlaceHolders {
  typeHere = 'Type here...',
}

const initialValues = {
  [FormFields.surrogatePowers2Text1]: '',
  [FormFields.surrogatePowers2Checkbox1]: false as any,
  [FormFields.surrogatePowers2Checkbox2]: false as any,
  [FormFields.surrogatePowers2Checkbox3]: false as any,
  [FormFields.surrogatePowers2Checkbox4]: false as any,
}

type Values = typeof initialValues;
type ValueTypes = Values[keyof Values];
type FormType = FormikContextType<Values>
type FieldPropType = { field: FieldInputProps<ValueTypes>; form: FormType }
type SurrogatePowers2FormProps = ContainerProps & { form: FormType }

export const useHooks = ({ form, directive }: SurrogatePowers2FormProps) => {
  const { setDirtyFormAlert } = useContext(DirtyFormAlertContext)
  const { shallowEqual, useSelector } = ReactRedux

  const {
    updatingState,
  } = useSelector(({ directives }: StoreState) => ({
    updatingState: directives.updatingState,
  }), shallowEqual)

  useEffect(() => {
    form.resetForm({
      values: {
        ...form.values,
        [FormFields.surrogatePowers2Text1]: directive.questions[FormFields.surrogatePowers2Text1] ?? '',
        [FormFields.surrogatePowers2Checkbox1]: directive.questions[FormFields.surrogatePowers2Checkbox1] ?? false,
        [FormFields.surrogatePowers2Checkbox2]: directive.questions[FormFields.surrogatePowers2Checkbox2] ?? false,
        [FormFields.surrogatePowers2Checkbox3]: directive.questions[FormFields.surrogatePowers2Checkbox3] ?? false,
        [FormFields.surrogatePowers2Checkbox4]: directive.questions[FormFields.surrogatePowers2Checkbox4] ?? false,
      },
    })
  }, [directive])

  const isUpdateError = isError(updatingState)

  useEffect(() => {
    if (isUpdateError) {
      form.setSubmitting(false)
    }
  }, [isUpdateError])

  useEffect(() => {
    setDirtyFormAlert({ blockSearchChanges: true, isDirty: form.dirty })
  }, [form.dirty])
}

const SurrogatePowers2Form: React.FC<SurrogatePowers2FormProps> = (props) => {
  useHooks(props)
  const { continueLater, form, goBack, currentStep, totalSteps } = props

  return (
    <Form>
      <Stack align='center' spacing='20px' maxWidth='540px'>
        <Text fontSize='16px' color={textPalette.light}>Step {currentStep} of {totalSteps}</Text>
        <Text fontSize='25px'>Health Care Agent Powers and Limitations</Text>
        <Text color={textPalette.light}>
          My Healthcare Agent is NOT automatically given the powers listed below. If I WANT my Agent to have any of these powers, I must SELECT the checkbox in front of the power; then my Agent WILL HAVE that power.
        </Text>

        <Field name={FormFields.surrogatePowers2Checkbox2}>
          {({ field }: FieldPropType) => (
            <FormControl>
              <Checkbox
                {...field}
                alignItems='baseline'
                colorScheme='brand'
                id={field.name}
                isChecked={field.value === true}
                value={undefined}
              >
                <Text ml='16px' color={textPalette.light} fontSize='16px' lineHeight='24px'>
                  To decide whether to donate my body or body part(s), including organs, tissues, and eyes, when I die; if I have any specific thoughts or preferences about donation, I include them here:
                </Text>
              </Checkbox>
            </FormControl>
          )}
        </Field>
        <Field name={FormFields.surrogatePowers2Text1}>
          {({ field }: FieldPropType) => (
            <FormControl isInvalid={!isEmpty(form.errors.surrogatePowers2Text1) && form.touched.surrogatePowers2Text1} display='flex'>
              <Textarea {...field} id={field.name} minH='96px' borderRadius='3px' placeholder={PlaceHolders.typeHere} ml='35px' />
            </FormControl>
          )}
        </Field>

        <Field name={FormFields.surrogatePowers2Checkbox1}>
          {({ field }: FieldPropType) => (
            <FormControl>
              <Checkbox
                {...field}
                alignItems='baseline'
                colorScheme='brand'
                id={field.name}
                isChecked={field.value === true}
                value={undefined}
              >
                <Text ml='16px' color={textPalette.light} fontSize='16px' lineHeight='24px'>
                  To decide what will happen with my body when I die (burial, cremation; note that whole-body donation to a medical school or scientific research center must be pre-arranged while the donor has capacity to complete required documentation).
                </Text>
              </Checkbox>
            </FormControl>
          )}
        </Field>

        <Field name={FormFields.surrogatePowers2Checkbox3}>
          {({ field }: FieldPropType) => (
            <FormControl>
              <Checkbox
                {...field}
                alignItems='baseline'
                colorScheme='brand'
                id={field.name}
                isChecked={field.value === true}
                value={undefined}
              >
                <Text ml='16px' color={textPalette.light} fontSize='16px' lineHeight='24px'>
                  In the event that I am pregnant, my Agent can make decisions about the pregnancy based on my Agent’s understanding of my values, preferences, and/or instructions.
                </Text>
              </Checkbox>
            </FormControl>
          )}
        </Field>

        <Field name={FormFields.surrogatePowers2Checkbox4}>
          {({ field }: FieldPropType) => (
            <FormControl>
              <Checkbox
                {...field}
                alignItems='baseline'
                colorScheme='brand'
                id={field.name}
                isChecked={field.value === true}
                value={undefined}
              >
                <Text ml='16px' color={textPalette.light} fontSize='16px' lineHeight='24px'>
                  To continue on as my Agent even if our legal relationship (marriage or domestic partnership) is ending or has ended.
                </Text>
              </Checkbox>
            </FormControl>
          )}
        </Field>

        <Stack direction='row' width='100%'>
          <Button width='100%' variant='outline' onClick={goBack}>
            Back
          </Button>
          <Button width='100%' isLoading={form.isSubmitting} type='submit' onClick={() => form.submitForm()}>
            Next
          </Button>
        </Stack>
        <Center width='100%'>
          <Button
            isLoading={form.isSubmitting}
            leftIcon={<Icon as={FiSave} height='20px' width='20px' />}
            onClick={() => continueLater(form, false)}
            variant='link'>
            Save &amp; Continue Later
          </Button>
        </Center>
      </Stack>
    </Form>
  )
}

const SurrogatePowers2FormContainer: React.FC<ContainerProps> = (props) => {
  const dispatch = ReactRedux.useDispatch()
  const { currentStep, directive } = props

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => { dispatch(updateDirective({ ...values, currentStep }, directive.id)) }}
    >{(formik) => <SurrogatePowers2Form {...props} form={formik} />}</Formik>
  )
}

export default SurrogatePowers2FormContainer