import React, { useContext, useEffect, useState } from 'react'
import * as ReactRedux from 'react-redux'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Field, FieldInputProps, Form, Formik, FormikContextType } from 'formik'
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
  useTheme,
} from '@chakra-ui/react'
import * as Yup from 'yup'
import { FiCheckCircle, FiEyeOff, FiEye } from 'react-icons/fi'
import isEmpty from 'lodash.isempty'
import { Context as DirtyFormAlertContext } from '../../components/DirtyFormAlert'
import { StoreState } from '../../redux'
import {isDone, isError} from '../../redux/AsyncState'
import {updateProfile as updateUser} from '../../redux/users/actions'
import * as Colors from '../../constants/Colors'
import { fullValidatorForSchema }from '../../utils/Forms'
import { useToast } from '../../hooks/Toast'

enum FormFields {
  password = 'password',
  passwordConfirm = 'passwordConfirm',
}

enum PasswordErrors {
  CASING = 'CASING',
  LENGTH = 'LENGTH',
  MATCHING = 'MATCHING',
  SPECIAL_CHARS = 'SPECIAL_CHARS',
}

const initialValues = {
  [FormFields.password]: '',
  [FormFields.passwordConfirm]: '',
} as const

type Values = typeof initialValues;
type ValueTypes = Values[keyof Values];
type FormType = FormikContextType<typeof initialValues>
type FieldPropType = {field: FieldInputProps<ValueTypes>; form: FormType}
type PasswordFormContainerProps = RouteComponentProps<any> & {
  passwordConfirmLabel?: string
  passwordLabel?: string
  submitText?: string
  title?: string
}

type PasswordFormProps = PasswordFormContainerProps & { form: FormType }

const ValidationSchema = Yup.object().shape({
  [FormFields.password]: Yup.string().required(PasswordErrors.LENGTH).min(8, PasswordErrors.LENGTH) // minimum length
    .matches(/[a-z]/, PasswordErrors.CASING) // lowercase letters
    .matches(/[A-Z]/, PasswordErrors.CASING) // uppercase letters
    .matches(/[^a-zA-Z]/, PasswordErrors.SPECIAL_CHARS), // special characters
  [FormFields.passwordConfirm]: Yup.string().required(PasswordErrors.MATCHING).oneOf([Yup.ref(FormFields.password)], PasswordErrors.MATCHING), // matches password
})

const FieldRequirement: React.FC<{error: boolean; children: React.ReactNode}> = ({children, error}) => {
  const { colors: { brand } } = useTheme()

  return (
    <Flex align='center' paddingTop='4px' color={error ? Colors.textPalette.light : 'inherit'}>
      <Icon as={FiCheckCircle} color={error ? 'inherit' : brand[300]} />
      <Text paddingLeft='10px'>{children}</Text>
    </Flex>
  )
}

// visibleForTesting
export const useHooks = ({form}: PasswordFormProps) => {
  const { shallowEqual, useSelector } = ReactRedux
  const { setDirtyFormAlert } = useContext(DirtyFormAlertContext)
  const [isPasswordVisible, setPasswordVisible] = useState(false)
  const toast = useToast()

  const {
    updatingState,
  } = useSelector(({users}: StoreState) => ({
    updatingState: users.updatingState,
  }), shallowEqual)

  const isUpdateError = isError(updatingState)
  const isUpdateDone = isDone(updatingState)

  useEffect(() => {
    if (!form.dirty) return undefined

    if (isUpdateDone) {
      setDirtyFormAlert(false)
      toast({
        description: 'Password updated successfully.',
        status: 'success',
      })
    }
  }, [isUpdateDone])

  useEffect(() => {
    if (!form.dirty) return undefined

    if (isUpdateError) {
      form.setSubmitting(false)
      toast({
        description: 'Password was not updated; try again.',
        status: 'error',
      })
    }
  }, [isUpdateError])

  useEffect(() => {
    setDirtyFormAlert(form.dirty)
  }, [form.dirty])

  return {
    isPasswordVisible,
    isUpdateError,
    setPasswordVisible,
  }
}

const PasswordForm: React.FC<PasswordFormProps> = (props) => {
  const {isPasswordVisible, setPasswordVisible} = useHooks(props)
  const {
    form,
    passwordConfirmLabel = 'Confirm Password',
    passwordLabel = 'Password',
    submitText = 'Next',
    title = 'Create Your Password',
  } = props
  const hasSubmitted = form.submitCount > 0
  const isDirty = form.dirty
  const passwordErrors = form.errors.password
  const passwordConfirmErrors = form.errors.passwordConfirm

  const isLengthError = !isDirty || passwordErrors?.includes(PasswordErrors.LENGTH) === true
  const isCasingError = !isDirty || passwordErrors?.includes(PasswordErrors.CASING) === true
  const isSpecialCharsError = !isDirty || passwordErrors?.includes(PasswordErrors.SPECIAL_CHARS) === true
  const isMatchingError = !isDirty || passwordConfirmErrors?.includes(PasswordErrors.MATCHING) === true

  return (
    <Form>
      <Stack align='center' spacing='20px'>
        <Text fontSize='26px'>{title}</Text>
        <Field name={FormFields.password}>
          {({ field }: FieldPropType) => (
            <FormControl width='350px' isInvalid={hasSubmitted && !isEmpty(passwordErrors)}>
              <FormLabel htmlFor={field.name}>{passwordLabel}</FormLabel>
              <InputGroup>
                <Input {...field} id={field.name} placeholder='Password' type={isPasswordVisible ? 'text' : 'password'}/>
                <InputRightElement
                  _hover={{cursor: 'pointer'}}
                  onClick={() => setPasswordVisible(!isPasswordVisible)}>
                  <Icon as={isPasswordVisible ? FiEyeOff : FiEye} />
                </InputRightElement>
              </InputGroup>
              <FieldRequirement error={isLengthError}>At least 8 characters</FieldRequirement>
              <FieldRequirement error={isCasingError}>Upper and lowercase letters</FieldRequirement>
              <FieldRequirement error={isSpecialCharsError}>At least 1 number or special character</FieldRequirement>
            </FormControl>
          )}
        </Field>
        <Field name={FormFields.passwordConfirm}>
          {({ field }: FieldPropType) => (
            <FormControl width='350px' isInvalid={hasSubmitted && !isEmpty(passwordConfirmErrors)}>
              <FormLabel htmlFor={field.name}>{passwordConfirmLabel}</FormLabel>
              <InputGroup>
                <Input {...field} id={field.name} placeholder='Password' type={isPasswordVisible ? 'text' : 'password'}/>
                <InputRightElement
                  _hover={{cursor: 'pointer'}}
                  onClick={() => setPasswordVisible(!isPasswordVisible)}>
                  <Icon as={isPasswordVisible ? FiEyeOff : FiEye} />
                </InputRightElement>
              </InputGroup>
              <FieldRequirement error={isMatchingError}>Matches password</FieldRequirement>
            </FormControl>
          )}
        </Field>
        <Button width='full' isLoading={form.isSubmitting} type='submit'>
          {submitText}
        </Button>
      </Stack>
    </Form>
  )
}

const PasswordFormContainer: React.FC<PasswordFormContainerProps> = (props) => {
  const dispatch = ReactRedux.useDispatch()

  return (
    <Formik
      initialValues={initialValues}
      validate={fullValidatorForSchema(ValidationSchema)}
      onSubmit={(values) => { dispatch(updateUser(values)) }}
    >{(formik) => <PasswordForm {...props} form={formik} />}</Formik>
  )
}

export default withRouter(PasswordFormContainer)
