import React from 'react'
import {Box, Button, Center, Icon, Spacer, Stack, Text, UnorderedList} from '@chakra-ui/react'
import {
  FlexFormSection,
  FlexFormQuestion,
  getErrors,
  getEmptyInitialValues,
} from './data/FlexFormModel'
import {textPalette} from '../../../../constants/Colors'
import MissingFieldsBox from '../MissingFieldsBox'
import QuestionRadio from './QuestionRadio'
import QuestionCheckbox from './QuestionCheckbox'
import QuestionTextArea from './QuestionTextArea'
import {Form, Formik} from 'formik'
import ReactMarkdown from 'react-markdown'
import ChakraUIRenderer from 'chakra-ui-markdown-renderer'
import {FiSave} from 'react-icons/fi'
import markdownTheme from './data/markdownTheme'
import {update as updateDirective} from '../../../../redux/directives/actions'
import * as ReactRedux from 'react-redux'
import {ChildFormProps} from './index'
import isEmpty from 'lodash.isempty'

type FormStepProps = ChildFormProps & {
  section: FlexFormSection
  savedValues: {[id:string]: string}
  stepsCompleted: number
}
const FormStep: React.FC<FormStepProps> = ({ currentStep, totalSteps, section, savedValues, directive, stepsCompleted, goBack, continueLater }) => {
  const dispatch = ReactRedux.useDispatch()
  if (section === undefined) {
    return null
  }

  // inital values will be the values from the database, if they exist, an empty object if the step hasn't been completed, or a full object with blank answers if the step has been completed/passed
  const initialValues = isEmpty(savedValues || {}) ? (stepsCompleted >= currentStep ? getEmptyInitialValues(section?.questions || []) : {}) : savedValues
  const initialTouched = Object.keys(initialValues).reduce((p, k) => { p[k] = true; return p}, {} as {[id: string]: boolean})
  const initialErrors = Object.keys(savedValues).length > 0 ? getErrors(section, initialValues) : {}

  return (
    <Formik
      initialValues={initialValues}
      initialErrors={initialErrors}
      onSubmit={(values) => { dispatch(updateDirective({ payload: {...values}, currentStep }, directive.id)) }}
      initialTouched={Object.keys(savedValues).length > 0 ? initialTouched : {}}
    >
      {(form) => {
        const { errors, touched, values, handleChange, setFieldValue, isSubmitting, submitForm } = form

        return (
          <Form>
            <Stack align='center' spacing='20px' width='540px'>
              <Text fontSize='16px' color={textPalette.light}>Step {currentStep} of {totalSteps}</Text>
              <Text fontSize='26px'>{section.title}</Text>
              {Object.keys(errors).length > 0 && <MissingFieldsBox errors={Object.keys(errors).map((k) => `${k}: ${errors[k]}`)}/>}
              {section.subtitle &&
                  <ReactMarkdown components={ChakraUIRenderer(markdownTheme, true)} children={section.subtitle} skipHtml />
                // <Text color={textPalette.light} width='100%'>
                //   {section.subtitle}
                // </Text>
              }
              <Spacer />
              {section.questions.map((q) => (
                <FormStepQuestion key={q.id} question={q} onChange={handleChange} error={errors[q.id]} touched={touched[q.id]} value={values[q.id]} setFieldValue={setFieldValue}/>
              ))}
              <Stack direction='row' spacing={4} width='100%'>
                {currentStep !== 1 &&
                    <Button width='100%' variant='outline' onClick={goBack}>
                        Back
                    </Button>
                }
                <Button width='100%' isLoading={isSubmitting} type='submit' onClick={submitForm}>
                  Next
                </Button>
              </Stack>
              <Center width='100%'>
                <Button
                  isLoading={isSubmitting}
                  leftIcon={<Icon as={FiSave} height='20px' width='20px' />}
                  variant='link'
                  onClick={() => continueLater(form, false)}
                >
                  Save &amp; Continue Later
                </Button>
              </Center>
            </Stack>
          </Form>
        )
      }}
    </Formik>

  )
}

type FormStepQuestionProps = {
  question: FlexFormQuestion
  error?: string
  touched?: boolean
  value: any
  onChange: (val) => void
  setFieldValue: (field: string, value: any) => void
}

const FormStepQuestion: React.FC<FormStepQuestionProps> = ({question, error, touched, value, setFieldValue}) => {
  switch (question.formControlType) {
    case 'radio':
      return <QuestionRadio key={question.id} name={question.id} label={question.label} description={question.description} error={error} touched={touched} value={value}
        setFieldValue={setFieldValue} options={question.options || []} compact={question.compact || false}/>
    case 'checkbox':
      return <QuestionCheckbox key={question.id} name={question.id} label={question.label} description={question.description} error={error} touched={touched} value={value}
        setFieldValue={setFieldValue} options={question.options || []} compact={question.compact || false}/>
    case 'textarea':
      return <QuestionTextArea key={question.id} name={question.id} label={question.label} description={question.description} error={error} touched={touched} value={value || question.defaultValue} hidden={question.hidden} placeholder={'Enter answer here...'} setFieldValue={setFieldValue}/>
    case 'sectionheader':
      return <Box pt={question.pt || 10} w='100%'>
        <Text fontWeight='semibold' fontSize='lg'>{question.label}</Text>
        <Text>{question.description}</Text>
        { question.options &&
          <>
            <UnorderedList pt={5}>
              { question.options.map((option) => (
                <li>{option.label}</li>
              ))}
            </UnorderedList>
          </>
        }
      </Box>
    default:
      return <Text>Element Not Supported</Text>
  }

}

export default FormStep