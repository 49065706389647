import React from 'react'
import * as ReactRedux from 'react-redux'
import { Link } from 'react-router-dom'
import {
  Box,
  Button,
  Flex,
  Icon,
  Spacer,
  Text,
  Image,
} from '@chakra-ui/react'
import { FiEdit } from 'react-icons/fi'
import { differenceInMinutes, format as formatDate, parseISO } from 'date-fns'
import { StoreState } from '../../../redux'
import * as RoutePaths from '../../../constants/RoutePaths'
import { Model as UserModel } from '../../../constants/User'
import { yellows, black } from '../../../constants/Colors'
import LoadingPage from '../../../components/LoadingPage'
import { getCodeMapForTime } from '../../../utils/BusinessLogic'
import { DATE_FORMAT, TIME_12HR_FORMAT } from '../../../utils/Forms'
import { privateFileUrl } from '../../../utils/Routing'
import { downloadFile } from '../../../utils/Forms'
import { AiOutlineDownload } from 'react-icons/ai'
import ReactPlayer from 'react-player'

interface AccordionTableCellProps {
  teamMeetingId: string
}

const useHooks = ({teamMeetingId}: AccordionTableCellProps) => {
  const { shallowEqual, useSelector } = ReactRedux

  const {
    allLoadedUsers,
    currentUser,
    teamMeeting,
  } = useSelector(({
    teamMeetings: { records },
    users,
  }: StoreState) => ({
    allLoadedUsers: users.records,
    currentUser: users.currentUser,
    teamMeeting: records[teamMeetingId],
  }), shallowEqual)
  const member = allLoadedUsers[teamMeeting.memberId]
  const {
    advocateIds = [],
    primaryCareProviderIds = [],
  } = member
  const surrogateIds = (member?.alternateSurrogateIds || []).concat(member?.surrogateIds || [])

  const advocateIdMap = advocateIds.reduce((memo, id) => ({...memo, [id]: true}), {})
  const pcpIdMap = primaryCareProviderIds.reduce((memo, id) => ({...memo, [id]: true}), {})
  const surrogateIdMap = surrogateIds.reduce((memo, id) => ({...memo, [id]: true}), {})

  const attendeeInfo = teamMeeting.attendeeIds.reduce((memo, id) => {
    const didMemberAttend = memo.didMemberAttend || id === teamMeeting.memberId
    const isAdvocate = !!advocateIdMap[id]
    const isPCP = !isAdvocate && !!pcpIdMap[id]
    const isSurrogate = !isPCP && !!surrogateIdMap[id]

    return {
      advocateAttendees: isAdvocate ? memo.advocateAttendees.concat(allLoadedUsers[id]) : memo.advocateAttendees,
      didMemberAttend,
      pcpAttendees: isPCP ? memo.pcpAttendees.concat(allLoadedUsers[id]) : memo.pcpAttendees,
      surrogateAttendees: isSurrogate ? memo.surrogateAttendees.concat(allLoadedUsers[id]) : memo.surrogateAttendees,
    }
  }, {
    advocateAttendees: [] as UserModel[],
    didMemberAttend: false,
    pcpAttendees: [] as UserModel[],
    surrogateAttendees: [] as UserModel[],
  })

  return {
    ...attendeeInfo,
    allLoadedUsers,
    codeMap: getCodeMapForTime(differenceInMinutes(parseISO(teamMeeting.endedAt), parseISO(teamMeeting.startedAt))),
    creator: allLoadedUsers[teamMeeting.creatorId],
    currentUser,
    member: allLoadedUsers[teamMeeting.memberId],
    teamMeeting,
  }
}

const AccordionTableCell: React.FC<AccordionTableCellProps> = (props) => {
  const {
    advocateAttendees,
    codeMap,
    creator,
    didMemberAttend,
    member,
    pcpAttendees,
    surrogateAttendees,
    teamMeeting,
  } = useHooks(props)

  if (teamMeeting == null) return <LoadingPage/>

  return (
    <Box w='100%' h='100%' padding='48px 95px'>
      <Flex align='center'>
        <Text fontSize='26px' fontWeight='500' pr='32px'>
          {teamMeeting.meetingPurposeNames.join(', ')}
        </Text>
        <Spacer />
        <Button as={Link} variant='outline' to={RoutePaths.MemberNoteDetail(teamMeeting.memberId, teamMeeting.id)}>
          <Icon as={FiEdit} display='inline' marginRight='6px'/>
          Edit
        </Button>
      </Flex>
      <Flex align='top' marginTop='35px'>
        <Box bgColor={yellows.highlight} flexBasis='270px' flexShrink={0} mr='30px' fontSize='14px' p='16px'>
          <Text fontWeight='500' pb='4px'>Date &amp; Time</Text>
          <Text pt='6px'>
            {formatDate(parseISO(teamMeeting.startedAt), DATE_FORMAT)}
            &nbsp;-&nbsp;
            {formatDate(parseISO(teamMeeting.startedAt), TIME_12HR_FORMAT)}
            &nbsp;-&nbsp;
            {formatDate(parseISO(teamMeeting.endedAt), TIME_12HR_FORMAT)}
          </Text>
          <Text fontWeight='500' pt='36px' pb='4px'>CPT Code(s)</Text>
          {Object.keys(codeMap).map((k) => (
            <Text key={k} pt='6px'>{codeMap[k] === 1 ? k : `${k} x ${codeMap[k]}`}</Text>
          ))}
          <Text fontWeight='500' pt='36px' pb='4px'>Present</Text>
          {didMemberAttend && (
            <Text pt='6px'>{member.firstName} {member.lastName} (Member)</Text>
          )}
          {advocateAttendees.filter(s => s !== undefined).map((advocate) => (
            <Text key={advocate.id} pt='6px'>{advocate.firstName} {advocate.lastName} (Advocate)</Text>
          ))}
          {surrogateAttendees.filter(s => s !== undefined).map((surrogate) => (
            <Text key={surrogate.id} pt='6px'>{surrogate.firstName} {surrogate.lastName} (Agent)</Text>
          ))}
          {pcpAttendees.filter(s => s !== undefined).map((pcp) => (
            <Text key={pcp.id} pt='6px'>{pcp.firstName} {pcp.lastName} (Primary Care Provider)</Text>
          ))}
          {teamMeeting.treatingPhysicianName != null && (
            <Text pt='6px'>{teamMeeting.treatingPhysicianName} (Treating Physician)</Text>
          )}
          <Text fontWeight='500' pt='36px' pb='4px'>Completed by</Text>
          <Text pt='6px'>{creator?.firstName} {creator?.lastName}</Text>
          <Text pt='6px'>{creator?.email}</Text>
        </Box>
        <Box fontSize='16px' width='100%'>
          <Text fontSize='14px' fontWeight='500' pb='16px'>Summary</Text>
          <Text>{teamMeeting.note}</Text>
          { teamMeeting.noteFile &&
            <>
              <Box marginTop='20px'>
                {  teamMeeting.noteFileType?.includes('video') &&
                  <>
                    <ReactPlayer
                      controls
                      url={privateFileUrl(teamMeeting.noteFile)}
                      style={{backgroundColor: black}}
                      height='300px'
                      width='100%'
                    />
                  </>
                }

                {  teamMeeting.noteFileType?.includes('image') &&
                  <>
                    <Image
                      src={privateFileUrl(teamMeeting.noteFile)}
                      style={{backgroundColor: black}}
                      height='300px'
                      width='100%'
                      objectFit='cover'
                    />
                  </>
                }
              </Box>
              <Text marginTop='20px'>{teamMeeting.noteFileFilename}
                <Button leftIcon={<AiOutlineDownload />} marginLeft='10px' onClick={() => {
                  downloadFile(privateFileUrl(teamMeeting.noteFile) || '', teamMeeting.noteFileFilename)
                }}>Download</Button>
              </Text>
            </>
          }
        </Box>
      </Flex>
    </Box>
  )
}

export default AccordionTableCell
