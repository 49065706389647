import React from 'react'
import { useTheme } from '@chakra-ui/react'

const GeneralInfo = (props: React.SVGProps<SVGSVGElement>) => {
  const { colors: { brand, brandSecondary } } = useTheme()

  return (
    <svg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g>
        <path d='M34.2857 9.14289H17.8834C17.5951 9.14288 17.3314 8.9801 17.2023 8.72232L15.7592 5.82708C15.3714 5.0541 14.5791 4.56761 13.7143 4.57146H2.28571C1.02335 4.57146 0 5.59481 0 6.85718V34.2857C1.90612e-07 35.5481 1.02335 36.5714 2.28571 36.5714H34.2857C35.548 36.5714 36.5714 35.5481 36.5714 34.2857V11.4286C36.5714 10.1662 35.548 9.14289 34.2857 9.14289Z' fill={brandSecondary[500]}/>
      </g>
      <g>
        <path d='M47.9999 26.187C47.9999 25.2129 47.2102 24.4233 46.2362 24.4233H40.948C40.1906 24.4233 39.5765 23.8093 39.5765 23.0519V17.7637C39.5765 16.7896 38.7869 16 37.8129 16H30.7583C29.7857 16 28.9973 16.7884 28.9973 17.7609C28.9973 17.7618 28.9973 17.7627 28.9973 17.7637V23.0519C28.9973 23.8093 28.3833 24.4233 27.6259 24.4233H22.3349C21.3609 24.4233 20.5713 25.2129 20.5713 26.187C20.5713 26.187 20.5713 26.187 20.5713 26.187V33.2416V33.2416C20.5713 34.2156 21.3609 35.0053 22.3349 35.0053H27.6259C28.3833 35.0053 28.9973 35.6193 28.9973 36.3767V41.6649V41.6649C28.9958 42.6375 29.783 43.4271 30.7555 43.4286C30.7565 43.4286 30.7574 43.4286 30.7583 43.4286H37.8129C38.787 43.4286 39.5766 42.639 39.5766 41.6649V36.3767C39.5766 35.6193 40.1906 35.0053 40.948 35.0053H46.2362C47.2103 35.0052 47.9999 34.2156 47.9999 33.2416V26.187Z' fill={brand[500]}/>
      </g>
    </svg>
  )
}

export default GeneralInfo
