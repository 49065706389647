import React from 'react'
import * as Yup from 'yup'
import { Input, InputProps } from '@chakra-ui/react'

export const FileValidationGenerator = (maxFileSize: number, supportedFormats: string[]) => Yup.mixed().test(
  'fileSize',
  'File too large',
  value => value == null || value.size <= maxFileSize,
).test(
  'fileFormat',
  'Unsupported Format',
  value => value == null || supportedFormats.includes(value.type),
)

interface FileInputProps extends Omit<InputProps, 'onChange'> {
  onChange: ({file, fileUrl}: {file: File; fileUrl: string }) => void
}

const FileInput = React.forwardRef(({onChange, ...otherProps}: FileInputProps, ref) => {
  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    const files = e.target.files
    if (files == null) return undefined

    const newFile = files[0]
    if (newFile == null) return undefined

    const reader = new FileReader()
    reader.onloadend = () => {
      onChange({file: newFile, fileUrl: reader.result?.toString() || ''})
    }
    reader.readAsDataURL(newFile)
  }

  return (
    <Input
      {...otherProps}
      hidden={true}
      onChange={onFileChange}
      ref={ref as any}
      type='file'
      value={undefined}
    />
  )
})

export default FileInput
