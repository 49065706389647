import React from 'react'
import {
  Flex,
  Text,
  Spacer,
  Stack,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  Icon,
} from '@chakra-ui/react'
import { FiPlus, FiSearch } from 'react-icons/fi'

const ListTopComponent: React.FC<{ onClickNew: () => void; modelName: string; onSearchUpdate; searchTerm: string }> = ({ onClickNew, modelName, onSearchUpdate, searchTerm }) => {

  return(
    <Flex align='center' h='80px' zIndex={1}>
      <Text fontSize='42px' fontWeight='500'>{modelName}s</Text>
      <Spacer />
      <Button mr={4} borderRadius='3px' fontSize='16px' lineHeight='24px' leftIcon={<FiPlus />} onClick={onClickNew}>New {modelName}</Button>
      <Stack spacing={4}>
        <InputGroup>
          <InputLeftElement
            pointerEvents='none'>
            <Icon as={FiSearch} />
          </InputLeftElement>
          <Input
            id='searchTermInput'
            type='text'
            borderRadius='3px'
            backgroundColor='white'
            placeholder={`Search ${modelName}s`}
            value={searchTerm}
            onChange={({currentTarget}) => onSearchUpdate(currentTarget.value)}
          />
        </InputGroup>
      </Stack>
    </Flex>
  )
}

export default ListTopComponent
