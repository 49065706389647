import { call, put, race, take, takeEvery, takeLatest } from 'redux-saga/effects'
import ActionIds from '../ActionIds'
import { BaseAction } from '../types'
import {
  create as createApi,
  getMany as getManyApi,
  getOne as getOneApi,
  update as updateApi,
} from './api'
import { Model as TeamMeetingModel } from '../../constants/TeamMeeting'
import { getOne as getUser } from '../users/actions'

export default function* watch() {
  yield takeLatest(ActionIds.TEAM_MEETINGS_GET_MANY_START, requestMany)
  yield takeLatest(ActionIds.TEAM_MEETINGS_GET_ONE_START, requestOne)
  yield takeEvery(ActionIds.TEAM_MEETINGS_CREATE_START, requestCreate)
  yield takeEvery(ActionIds.TEAM_MEETINGS_UPDATE_START, requestUpdate)
}

function* requestMany({payload: params}: BaseAction) {
  const { apiResponse: {response, error}, isCanceled } = yield race({
    apiResponse: call(getManyApi, params),
    isCanceled: take(ActionIds.TEAM_MEETINGS_GET_MANY_CANCEL),
  })
  if (isCanceled) return

  if (error) {
    yield put({type: ActionIds.TEAM_MEETINGS_GET_MANY_FAIL})
    return
  }

  yield put({type: ActionIds.TEAM_MEETINGS_GET_MANY_SUCCESS, payload: response.data})
}

function* requestOne({payload: id}: BaseAction) {
  const { apiResponse: {response, error}, isCanceled } = yield race({
    apiResponse: call(getOneApi, id),
    isCanceled: take(ActionIds.TEAM_MEETINGS_GET_ONE_CANCEL),
  })
  if (isCanceled) return

  if (error) {
    yield put({type: ActionIds.TEAM_MEETINGS_GET_ONE_FAIL})
    return
  }

  const payload = response.data
  const { creatorId } = payload.data.attributes as TeamMeetingModel
  yield put(getUser(creatorId)) // tell users store to fetch creator (who may not be a team member)

  yield put({type: ActionIds.TEAM_MEETINGS_GET_ONE_SUCCESS, payload})
}

function* requestCreate({payload: params}: BaseAction) {
  const{response, error} = yield call(createApi, params)

  if (error) {
    yield put({type: ActionIds.TEAM_MEETINGS_CREATE_FAIL})
    return
  }

  yield put({type: ActionIds.TEAM_MEETINGS_CREATE_SUCCESS, payload: response.data})
}

function* requestUpdate({payload: {id, params}}: BaseAction) {
  const{response, error} = yield call(updateApi, id, params)

  if (error) {
    yield put({type: ActionIds.TEAM_MEETINGS_UPDATE_FAIL})
    return
  }

  yield put({type: ActionIds.TEAM_MEETINGS_UPDATE_SUCCESS, payload: response.data})
}
