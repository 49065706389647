import ActionIds from '../ActionIds'
import AsyncState from '../AsyncState'
import { BaseAction } from '../types'

const initialState = {
  deletingState: AsyncState.NOT_STARTED,
}

export type StateType = typeof initialState;

export default function reducer(state = initialState, action: BaseAction): StateType {
  switch (action.type) {
    case ActionIds.FILES_DELETE_START:
      return { ...state, deletingState: AsyncState.LOADING }
    case ActionIds.FILES_DELETE_SUCCESS:
      return { ...state, deletingState: AsyncState.DONE }
    case ActionIds.FILES_DELETE_FAIL:
      return { ...state, deletingState: AsyncState.ERROR }
    default:
      return state
  }
}
