import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import {
  Avatar,
  Box,
  Center,
  Flex,
  Icon,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Spacer,
  Stack, Text,
  useTheme,
} from '@chakra-ui/react'
import {Link as RouterLink, useLocation} from 'react-router-dom'
import isEmpty from 'lodash.isempty'
import { FiChevronDown } from 'react-icons/fi'
import { StoreState } from '../../redux'
import { signOut } from '../../redux/auth/actions'
import logo from '../../assets/Images/logo.svg'
import * as RoutePaths from '../../constants/RoutePaths'
import {getCurrentSubdomain, getSubdomainRoute, isCurrentPath, privateFileUrl} from '../../utils/Routing'
import { purples, textPalette, white, grays } from '../../constants/Colors'
import {userCanAdmin} from '../../constants/User'
import {useEffect, useState} from 'react'
import AsyncState from '../../redux/AsyncState'

const TOPBAR_PADDING_LR = '165px'

interface Props {
  children: React.ReactNode
}
const Chrome: React.FC<Props> = ({children}) => {
  const dispatch = useDispatch()
  const { logoUrl } = useTheme()
  const {
    currentUser,
    currentAccount,
    currentAccountLoading,
  } = useSelector(({users, accounts}: StoreState) => ({
    currentUser: users.currentUser,
    currentAccount: accounts.current,
    currentAccountLoading: accounts.loadingState,
  }), shallowEqual)

  const location = useLocation()
  const [showAccountInfo, setShowAccountInfo] = useState(false)
  useEffect(() => {
    if (isCurrentPath(RoutePaths.AccountListPage())) {
      setShowAccountInfo(false)
    } else {
      setShowAccountInfo(true)
    }
  }, [location])

  const hasLoadedUser = !isEmpty(currentUser)
  const hasLoadedAccount = !isEmpty(currentAccount)
  const hasFirstName = currentUser.firstName != null

  return (
    <Flex direction='column' height='100%'>
      <Flex align='center' h='80px' boxShadow='base' zIndex={1} backgroundColor={white}>
        <Box pl={TOPBAR_PADDING_LR}>
          <Link as={RouterLink} to='/'>
            <Image src={logoUrl} fallbackSrc={logo} alt='logo' width='160px' />
          </Link>
        </Box>
        <Spacer />
        {hasLoadedAccount && getCurrentSubdomain() === 'app' && showAccountInfo && currentAccountLoading !== AsyncState.LOADING &&
            <Stack isInline height='100%' mr='15px'>
              <Menu>
                <MenuButton>
                  <Link as={RouterLink} to='#'>
                    <Flex align='right' width='300px' justifyContent='flex-end' alignItems='center'>
                      <Box textAlign='right'>
                        <Text display='block' color={grays[600]} fontWeight='bold'>{currentAccount.name}</Text>
                        <Text display='block' color={grays[500]}>Owner: {currentAccount.owners[0].name || currentAccount.owners[0].email}</Text>
                      </Box>
                      <Icon as={FiChevronDown} color={grays[500]} ml='10px'/>
                    </Flex>

                  </Link>
                </MenuButton>
                <MenuList>
                  { userCanAdmin(currentUser) && getCurrentSubdomain() === 'app' &&
                      <>
                        <MenuItem as={RouterLink} to={RoutePaths.AccountAdminPage()}>
                          Manage Account
                        </MenuItem>
                        <MenuItem as={RouterLink} to={RoutePaths.AccountAdminUsersPage()}>
                          Manage Team
                        </MenuItem>
                        <MenuItem as={RouterLink} to={RoutePaths.AccountAdminPaymentPage()}>
                          Manage Payment
                        </MenuItem>
                        <MenuDivider />
                      </>
                  }
                  <MenuItem as={RouterLink} to={RoutePaths.AccountListPage()}>
                    View All Accounts
                  </MenuItem>
                </MenuList>
              </Menu>
            </Stack>
        }
        { hasLoadedUser && (
          <Stack isInline height='100%' pr={hasLoadedUser ? '32px' : TOPBAR_PADDING_LR}>
            <Menu>
              <MenuButton>
                <Link as={RouterLink} to='#'>
                  <Flex align='center' width='300px'>
                    <Avatar
                      bg={purples.avatar}
                      color={white}
                      name={currentUser?.firstName + ` ${currentUser?.lastName}`}
                      src={privateFileUrl(currentUser?.avatar)}
                    />
                    <Center pl='8px' fontWeight='bold'>
                      {hasFirstName ? currentUser.firstName : currentUser.email}
                        &nbsp;
                      {hasFirstName && currentUser.lastName}
                    </Center>
                    <Spacer />
                    <Icon as={FiChevronDown}/>
                  </Flex>
                </Link>
              </MenuButton>
              <MenuList>
                { userCanAdmin(currentUser) && getCurrentSubdomain() !== 'app' &&
                    <>
                      <MenuItem as={RouterLink} to={RoutePaths.AccountAdminPage()}>
                        Manage Account
                      </MenuItem>
                      <MenuItem as={RouterLink} to={RoutePaths.AccountAdminUsersPage()}>
                        Manage Team
                      </MenuItem>
                      <MenuItem as={RouterLink} to={RoutePaths.AccountAdminPaymentPage()}>
                        Manage Payment
                      </MenuItem>
                    </>
                }
                {currentUser.hasAcceptedTerms && (
                  <MenuItem
                    _hover={{color: textPalette.normal}}
                    as={RouterLink}
                    to={RoutePaths.ProfilePage(currentUser.id)}>
                      My Profile
                  </MenuItem>
                )}
                {currentUser.isSystemAdmin && (
                  <MenuItem
                    _hover={{color: textPalette.normal}}
                    as={RouterLink}
                    to={getSubdomainRoute(process.env.REACT_APP_PARTNER_MANAGEMENT_DOMAIN!)}
                    target='_blank'>
                      Manage Partners
                  </MenuItem>
                )}
                <MenuDivider />
                <MenuItem onClick={() => dispatch(signOut())}>Sign Out</MenuItem>
              </MenuList>
            </Menu>
          </Stack>
        )}
      </Flex>
      <Box flexGrow={1} overflow='auto'>{children}</Box>
    </Flex>
  )
}

export default Chrome