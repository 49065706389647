import React from 'react'
import { useTheme } from '@chakra-ui/react'

const Surrogate = (props: React.SVGProps<SVGSVGElement>) => {
  const { colors: { brand, brandSecondary } } = useTheme()

  return (
    <svg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g>
        <path d='M43.475 10.0266C40.9195 4.90379 34.6949 2.82253 29.5721 5.37806C28.5691 5.87841 27.6538 6.53813 26.862 7.33154L24.8142 9.37936C24.3645 9.82899 23.6354 9.82899 23.1856 9.37936L21.1355 7.33154C17.0848 3.28087 10.5174 3.28085 6.46672 7.33153C2.41605 11.3822 2.41603 17.9497 6.46672 22.0003L21.8772 38.0766C23.0008 39.2483 24.8614 39.2873 26.0331 38.1638C26.0628 38.1353 26.0919 38.1063 26.1203 38.0766L41.5308 22.0003C44.6904 18.8445 45.4737 14.02 43.475 10.0266Z' fill={brandSecondary[500]}/>
      </g>
      <g>
        <path d='M11.9124 26.4054V26.4054C10.6811 27.2275 10.2737 28.8492 10.9702 30.1556L13.7144 34.7163C14.0075 35.2031 13.8505 35.8354 13.3637 36.1285C12.8769 36.4216 12.2446 36.2646 11.9515 35.7778L9.1702 31.1492H9.1702C8.3296 29.5231 8.4278 27.5707 9.42734 26.0372C9.53947 25.869 9.59958 25.6716 9.60014 25.4694V19.5428C9.60014 17.8386 8.21862 16.4571 6.51443 16.4571C4.81023 16.4571 3.42871 17.8386 3.42871 19.5428V30.1803L3.42871 30.1823C3.42871 32.2849 4.14451 34.3249 5.45832 35.9665L10.9373 42.8132C11.1321 43.0572 11.4273 43.1995 11.7396 43.1999H19.8858C20.4539 43.1999 20.9144 42.7394 20.9144 42.1714V35.9794L20.9144 35.9793C20.9162 34.1486 20.3742 32.3586 19.3571 30.8364C15.4753 26.7942 14.2616 24.8379 11.9124 26.4054L11.9124 26.4054Z' fill={brand[500]}/>
        <path d='M41.4859 16.4571C39.7817 16.4571 38.4002 17.8386 38.4002 19.5428V25.4694C38.4008 25.6716 38.4609 25.869 38.573 26.0372C39.5726 27.5707 39.6708 29.5231 38.8302 31.1492L36.0489 35.7778C35.7558 36.2646 35.1235 36.4216 34.6367 36.1285C34.1499 35.8354 33.9928 35.2031 34.2859 34.7163L37.0302 30.1556C37.7267 28.8492 37.3193 27.2275 36.088 26.4054C33.7387 24.8379 32.525 26.7942 28.6432 30.8427C27.6261 32.3649 27.0841 34.1549 27.0859 35.9855V42.1714C27.0859 42.7394 27.5464 43.2 28.1145 43.2H36.2608C36.5731 43.1995 36.8682 43.0572 37.0631 42.8132L42.5433 35.965C43.8568 34.3231 44.5721 32.2829 44.5717 30.1803V19.5428C44.5717 17.8386 43.1901 16.4571 41.4859 16.4571Z' fill={brand[500]}/>
      </g>
    </svg>
  )
}

export default Surrogate
