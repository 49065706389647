import React, { useContext, useEffect, useState } from 'react'
import * as ReactRedux from 'react-redux'
import { Alert,
  AlertIcon,
  Box,
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Stack,
  Text,
} from '@chakra-ui/react'
import NewPartnerForm from './NewPartnerForm'
import { Context as DirtyFormAlertContext } from '../../../../components/DirtyFormAlert'
import { StoreState } from '../../../../redux'
import { textPalette } from '../../../../constants/Colors'
import { useToast } from '../../../../hooks/Toast'

type NewPartnerModalProps = Omit<ModalProps, 'children'>

const useHooks = ({isOpen, onClose}: NewPartnerModalProps) => {
  const { shallowEqual, useSelector } = ReactRedux
  const toast = useToast()
  const { setDirtyFormAlert } = useContext(DirtyFormAlertContext)
  const [errorMessage, setErrorMessage] = useState<string>()

  const {
    lastCreatedPartner,
  } = useSelector(({accounts: {lastCreatedId, records}}: StoreState) => ({
    lastCreatedPartner: lastCreatedId == null ? null : records[lastCreatedId],
  }), shallowEqual)

  useEffect(() => {
    if (isOpen) {
      setErrorMessage(undefined)
    } else {
      setDirtyFormAlert(false)
    }
  }, [isOpen])

  const onError = () => {
    setErrorMessage('Something went wrong. Please Try again.')
    toast({
      description: 'Partner was not created; try again.',
      status: 'error',
    })
  }

  const onFinish = () => {
    toast({
      description: `Partner "${lastCreatedPartner?.name}" was successfully created.`,
      status: 'success',
      width: '650px',
    })
    onClose()
  }

  return {
    errorMessage,
    onError,
    onFinish,
  }
}

const NewPartnerModal: React.FC<NewPartnerModalProps> = (props) => {
  const { errorMessage, onError, onFinish } = useHooks(props)
  const { onClose } = props

  return (
    <Modal size='xl' closeOnOverlayClick={false} {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader><Center fontSize='26px' fontWeight='normal'>Create New Account</Center></ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack align='center' spacing='20px' margin='0 24px 24px 24px'>
            <Text fontSize='16px' textAlign='center' color={textPalette.light}>Please fill out the section below.</Text>
            <Box height='0' width='100%'>{errorMessage && (
              <Alert status='error' zIndex={1000}>
                <AlertIcon />
                <Center flex='1'>{errorMessage}</Center>
              </Alert>
            )}</Box>
            <Box width='100%'>
              <NewPartnerForm
                onCancel={onClose}
                onError={onError}
                onFinish={onFinish}
              />
            </Box>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default NewPartnerModal
