import { BaseAction } from '../types'
import ActionIds from '../ActionIds'

export const cancelGetMany = (): BaseAction => ({type: ActionIds.TEAM_MEETINGS_GET_MANY_CANCEL})

export const getMany = (params: any = {}): BaseAction => ({
  type: ActionIds.TEAM_MEETINGS_GET_MANY_START,
  payload: params,
})
export const getOne = (id: string): BaseAction => ({
  type: ActionIds.TEAM_MEETINGS_GET_ONE_START,
  payload: id,
})

export const update = (params, id: string): BaseAction => ({
  type: ActionIds.TEAM_MEETINGS_UPDATE_START,
  payload: {id, params},
})

export const create = (params): BaseAction => ({
  type: ActionIds.TEAM_MEETINGS_CREATE_START,
  payload: params,
})
