import React, { useContext, useEffect } from 'react'
import * as ReactRedux from 'react-redux'
import {Field, FieldInputProps, Form, Formik, FormikContextType} from 'formik'
import {
  Button,
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
  FormLabel,
  Input, Select,
  Stack,
} from '@chakra-ui/react'
import * as Yup from 'yup'
import isEmpty from 'lodash.isempty'
import { AiOutlineWarning } from 'react-icons/ai'
import { Context as DirtyFormAlertContext } from '../../../../components/DirtyFormAlert'
import { StoreState } from '../../../../redux'
import { isDone, isError } from '../../../../redux/AsyncState'
import { invite as inviteUser, update as updateUser } from '../../../../redux/users/actions'
import {displayFullName, parsePhoneNumber, userNameAttrsFromFullName} from '../../../../utils/Forms'
import { Model as UserModel } from '../../../../constants/User'
import {AccountRoles} from '../../../../constants/Account'
import {white} from '../../../../constants/Colors'
import capitalize from 'lodash.capitalize'

enum FormFields {
  email = 'email',
  fullName = 'fullName',
  phoneNumber = 'phoneNumber',
  specialty = 'specialty',
  accountRole = 'accountRole',
}

const ValidationSchema = Yup.object().shape({
  [FormFields.email]: Yup.string().required('Must not be empty').email('Must be a valid email'),
  [FormFields.phoneNumber]: Yup.string().matches(/^\([0-9]{3}\) [0-9]{3}-[0-9]{4}?$/, 'Must be 10 digits'),
  [FormFields.specialty]: Yup.string().max(50, 'Must not be more than 50 characters'),
  [FormFields.accountRole]: Yup.string().required('Must not be empty'),
})

const initialValues = {
  [FormFields.email]: '',
  [FormFields.fullName]: '',
  [FormFields.phoneNumber]: '',
  [FormFields.specialty]: '',
  [FormFields.accountRole]: AccountRoles.ADVOCATE,
}

type Values = typeof initialValues;
type ValueTypes = Values[keyof Values];
type FormType = FormikContextType<Values>
type FieldPropType = {field: FieldInputProps<ValueTypes>; form: FormType}
type ContainerProps = { onError: (string) => any; onFinish: () => any; userToEdit: UserModel | undefined }
type AdvocateInfoFormProps = ContainerProps & { form: FormType; userToEdit: UserModel | undefined }

// visibleForTesting
export const useHooks = ({ form, onError, onFinish }: AdvocateInfoFormProps) => {
  const { shallowEqual, useSelector } = ReactRedux
  const { setDirtyFormAlert } = useContext(DirtyFormAlertContext)

  const {
    errorData,
    invitingState,
    updatingState,
  } = useSelector(({ users }: StoreState) => ({
    errorData: users.errorData,
    invitingState: users.creatingState,
    updatingState: users.updatingState,
  }), shallowEqual)

  const isInviteDone = isDone(invitingState)
  const isInviteError = isError(invitingState)

  const isUpdateDone = isDone(updatingState)
  const isUpdateError = isError(updatingState)

  useEffect(() => {
    form.resetForm()
  }, [])


  useEffect(() => {
    if (!form.dirty) return
    if (!isUpdateError && !isInviteError) return

    if (typeof errorData === 'object' && errorData?.email != null) {
      form.setFieldError(FormFields.email, `Email ${errorData?.email.join(', ')}`)
    }

    onError(errorData)
    form.setSubmitting(false)
  }, [errorData, isInviteError, isUpdateError])

  useEffect(() => {
    if (form.dirty && (isInviteDone || isUpdateDone)) {
      form.setSubmitting(false)
    }
  }, [isInviteDone, isUpdateDone])

  useEffect(() => {
    if (!form.dirty && form.isSubmitting) {
      onFinish()
    }
    if (form.dirty && (isInviteDone || isUpdateDone) && !form.isSubmitting) {
      onFinish()
    }
  }, [form.isSubmitting])

  useEffect(() => {
    setDirtyFormAlert(form.dirty)
  }, [form.dirty])
}

const TeammateInfoForm: React.FC<AdvocateInfoFormProps> = (props) => {
  useHooks(props)
  const { form } = props

  return (
    <Form>
      <Stack align='center' spacing='20px'>
        <Field name={FormFields.fullName}>
          {({ field }: FieldPropType) => (
            <FormControl isInvalid={!isEmpty(form.errors.fullName) && form.touched.fullName}>
              <FormLabel htmlFor={field.name}>Full Name</FormLabel>
              <Input {...field} id={field.name} type='text' maxLength={32}/>
              <FormErrorMessage><FormErrorIcon as={AiOutlineWarning} />{form.errors.fullName}</FormErrorMessage>
            </FormControl>
          )}
        </Field>
        <Stack direction='row' spacing={4} width='100%'>
          <Field name={FormFields.email}>
            {({ field }: FieldPropType) => (
              <FormControl isInvalid={!isEmpty(form.errors.email) && form.touched.email}>
                <FormLabel htmlFor={field.name}>Email*</FormLabel>
                <Input {...field} id={field.name} type='email'/>
                <FormErrorMessage><FormErrorIcon as={AiOutlineWarning} />{form.errors.email}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name={FormFields.phoneNumber}>
            {({ field }: FieldPropType) => (
              <FormControl isInvalid={!isEmpty(form.errors.phoneNumber) && form.touched.phoneNumber}>
                <FormLabel htmlFor={field.name}>Phone</FormLabel>
                <Input
                  {...field}
                  id={field.name}
                  onChange={({currentTarget}) => {form.setFieldValue(field.name, parsePhoneNumber(currentTarget.value))}}
                  type='text'
                />
                <FormErrorMessage><FormErrorIcon as={AiOutlineWarning} />{form.errors.phoneNumber}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
        </Stack>
        <Stack direction='row' spacing={4} width='100%'>
          <Field name={FormFields.specialty}>
            {({ field }: FieldPropType) => (
              <FormControl isInvalid={!isEmpty(form.errors.specialty) && form.touched.specialty}>
                <FormLabel htmlFor={field.name}>Specialty</FormLabel>
                <Input {...field} id={field.name}/>
                <FormErrorMessage><FormErrorIcon as={AiOutlineWarning} />{form.errors.specialty}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name={FormFields.accountRole}>
            {({ field }: FieldPropType) => (
              <FormControl isInvalid={!isEmpty(form.errors.accountRole) && form.touched.accountRole}>
                <FormLabel htmlFor={field.name}>Account Role</FormLabel>
                <Select size='md' {...field} value={form.values.accountRole} disabled={form.values.accountRole === AccountRoles.OWNER} id={field.name} backgroundColor={white} >
                  {form.values.accountRole === AccountRoles.OWNER && <option value={AccountRoles.OWNER}>{capitalize(AccountRoles.OWNER)}</option>}
                  {Object.keys(AccountRoles).reverse().filter(r => ![AccountRoles.OWNER, AccountRoles.MEMBER].includes(r)).map((r) => (
                    <option value={r}>{r === AccountRoles.ADVOCATE ? 'Editor' : capitalize(r)}</option>
                  ))}
                </Select>
                <FormErrorMessage>{form.errors.accountRole}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
        </Stack>
        <Button width='full' isLoading={form.isSubmitting} type='submit'>
          {form.initialValues.email !== '' ? 'Update' : 'Invite'}
        </Button>
      </Stack>
    </Form>
  )
}

const AdvocateInfoFormContainer: React.FC<ContainerProps> = (props) => {
  const dispatch = ReactRedux.useDispatch()
  const user = props.userToEdit
  const onSubmit = ({fullName, phoneNumber, accountRole, specialty, ...values}) => {
    const data = {
      ...values,
      ...userNameAttrsFromFullName(fullName),
      specialty,
      phoneNumber: phoneNumber.match(/\d/g)?.join(''),
      redirectUrl: window.location.origin,
      accountRole,
    }
    if (user === undefined) {
      dispatch(inviteUser(data))
    } else {
      dispatch(updateUser(data, user.id))
    }
  }


  const initVals = user === undefined ? initialValues : {
    email: user.email,
    fullName: displayFullName(user),
    phoneNumber: user.phoneNumber ? parsePhoneNumber(user.phoneNumber) : '',
    specialty: user.specialty || '',
    accountRole: user.currentAccountRole || AccountRoles.ADVOCATE,
  }

  return (
    <Formik
      initialValues={initVals}
      validationSchema={ValidationSchema}
      onSubmit={onSubmit}
    >{(formik) => <TeammateInfoForm {...props} form={formik} />}</Formik>
  )
}

export default AdvocateInfoFormContainer
