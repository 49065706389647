import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { useHistory, useLocation, withRouter } from 'react-router-dom'
import { StoreState } from '../../../redux'
import { getData as getMemberListData, resetData as resetMemberListData } from '../../../redux/views/memberList/actions'
import {
  Avatar,
  Flex,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
  useTheme,
} from '@chakra-ui/react'
import { purples, grays, textPalette, white } from '../../../constants/Colors'
import Roles from '../../../constants/Roles'
import { Model as UserModel } from '../../../constants/User'
import {
  Box,
  Container,
  Divider,
  Text,
  Center,
} from '@chakra-ui/react'
import ListTopComponent from '../../../components/ListTopComponent'
import Pagination from '../../../components/Pagination'
import Table from '../../../components/Table'
import { isLoading } from '../../../redux/AsyncState'
import NewMemberModal from './NewMemberModal'
import * as RoutePaths from '../../../constants/RoutePaths'
import isEmpty from 'lodash.isempty'
import debounce from 'lodash.debounce'
import {
  QUERY_PARAM_KEYS,
  getNewSearchString,
  getPageParam,
  getSearchTermParam,
  getTabParam,
  privateFileUrl,
} from '../../../utils/Routing'
import { parsePhoneNumber } from '../../../utils/Forms'
import { TeamStatuses, teamStatus } from '../../../utils/BusinessLogic'
import { getTeamRole, getFullName } from '../../../redux/directives/selector'

type MemberListProps = RouteComponentProps<any>

const STATUS_VALUE_MAP = {
  [TeamStatuses.COMPLETED]: 'Complete',
  [TeamStatuses.IN_PROGRESS]: 'In Progress',
  [TeamStatuses.READY_TO_RESEND]: 'Ready to Resend',
  [TeamStatuses.READY_TO_SEND]: 'Ready to Send',
  [TeamStatuses.SENT]: 'Awaiting Signatures',
}
const memberTableDataForUser = (member: UserModel, advocate: UserModel | null, surrogate: UserModel | null, surrogateTeamRole: any | null) => {
  return {
    id: member.id,
    linkTo: RoutePaths.MemberDetail,
    memberData: (
      <Flex>
        <Center w='120px' h='100px'>
          <Avatar
            size='lg'
            name={member.firstName + ' ' + member.lastName}
            src={privateFileUrl(member.avatar)}
            color={white}
            bg={purples.avatar}
          />
        </Center>
        <Stack spacing={1} mt={4} mb={4}>
          <Text fontSize='16px' color={textPalette.light}>{member.firstName + ' ' + member.lastName || '-'}</Text>
          <Text fontSize='10px' color={textPalette.light}>{member.phoneNumber ? parsePhoneNumber(member.phoneNumber) : 'NA'}</Text>
          <Text fontSize='10px' color={textPalette.light} width={'135px'} noOfLines={1}>{member.email || '-'}</Text>
        </Stack>
      </Flex>
    ),
    surrogateData: (surrogate == null ?
      <Text mt={4} fontSize='16px' color={textPalette.lighter}>NA</Text> :
      <Stack spacing={1} mt={4}>
        <Text fontSize='16px' color={textPalette.light}>{getFullName(surrogateTeamRole) || '-'}</Text>
        <Text fontSize='10px' color={textPalette.light}>{surrogateTeamRole?.phoneNumber ? parsePhoneNumber(surrogateTeamRole.phoneNumber) : 'NA'}</Text>
        <Text fontSize='10px' color={textPalette.light} width={'135px'} noOfLines={1}>{surrogateTeamRole?.email || '-'}</Text>
      </Stack>
    ),
    advocateData: (advocate == null ?
      <Text mt={4} fontSize='16px' color={textPalette.lighter}>NA</Text> :
      <Stack spacing={1} mr={0} mt={4} mb={4}>
        <Text fontSize='16px' color={textPalette.light}>{advocate?.firstName + ' ' + advocate?.lastName || '-'}</Text>
        <Text fontSize='10px' color={textPalette.light} width={'135px'} noOfLines={1}>{advocate?.email || '-'}</Text>
      </Stack>
    ),
    directivesData: (<Text mt={4} fontSize='16px' color={textPalette.lighter}>{STATUS_VALUE_MAP[teamStatus(member).status]}</Text>),
  }
}

function MemberList(_props: MemberListProps) {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const { colors: { brand } } = useTheme()
  const {
    isOpen: isNewMemberModalOpen,
    onClose: closeNewMemberModal,
    onOpen: openNewMemberModal,
  } = useDisclosure()
  const {
    allLoadedUsers,
    currentUser,
    isLoadingStore,
    orderedMembers,
    totalPages,
    memberLeadName,
  } = useSelector(({ memberList, users, accounts }: StoreState) => ({
    allLoadedUsers: users.records,
    currentUser: users.currentUser,
    isLoadingStore: isLoading(memberList.loadingState, users.loadingState),
    orderedMembers: memberList.orderedMemberIds.map(id => users.records[id]),
    totalPages: memberList.totalPages,
    memberLeadName: accounts.current?.memberLeadName,
  }), shallowEqual)
  const [isMounted, setIsMounted] = useState(false)
  const [oldTabIndex, setOldTabIndex] = useState(0)
  const debouncedDispatch = useCallback(debounce(dispatch, 300), [])
  const debouncedUpdateUrlParams = useCallback(debounce((newSearch: string, newPage: number, newTab: number) => history.replace({
    pathname: RoutePaths.Members(),
    search: getNewSearchString({
      [QUERY_PARAM_KEYS.PAGE]: String(newPage),
      [QUERY_PARAM_KEYS.SEARCH_TERM]: String(newSearch),
      [QUERY_PARAM_KEYS.TAB]: String(newTab),
    }),
  }), 500, {leading: true}), [])

  const tabIndexParam = getTabParam(location) || String(0)
  const currentPageParam = getPageParam(location) || String(1)
  const [currentPage, setCurrentPage] = useState(parseInt(currentPageParam, 10))
  const [searchTerm, setSearchTerm] = useState(getSearchTermParam(location) || '')
  const [tabIndex, setTabIndex] = useState(parseInt(tabIndexParam, 10))
  const isMembersTab = tabIndex === 0
  const isAssignedTab = tabIndex === 1

  const currentPageData = oldTabIndex === tabIndex ? orderedMembers.map((user) => user) : []
  const onChangeTab = (newTab: number) => setTabIndex(newTab)
  const onChangePage = (newPage: number) => setCurrentPage(newPage)
  const onChangeSearch = (newSearch: string) => setSearchTerm(newSearch)

  const tabParams = (() => {
    switch (tabIndex) {
      case 1: return { advocateId: currentUser.id }
      default: return { role_names: [Roles.MEMBER] }
    }
  })()

  useEffect(() => {
    setIsMounted(true)
  }, [])

  useEffect(() => {
    debouncedUpdateUrlParams(searchTerm, currentPage, tabIndex)
    return () => debouncedUpdateUrlParams.cancel()
  }, [currentPage, searchTerm, tabIndex])

  useEffect(() => {
    if (!isLoadingStore) setOldTabIndex(tabIndex)
  }, [isLoadingStore])

  useEffect(() => {
    if (isMounted) {
      onChangePage(1)
    }

    const isSearchTermEmpty = isEmpty(searchTerm)
    if (!isSearchTermEmpty && searchTerm.length < 3) return

    debouncedDispatch(getMemberListData({
      ...tabParams,
      name: isSearchTermEmpty ? null : searchTerm,
      page: currentPage,
    }))
    return () => debouncedDispatch.cancel()
  }, [searchTerm])

  useEffect(() => {
    if (isMounted) {
      onChangeSearch('')
      onChangePage(1)
    }

    if (isMembersTab) dispatch(getMemberListData({ role_names: [Roles.MEMBER], page: currentPage }))
    if (isAssignedTab) dispatch(getMemberListData({ advocate_id: currentUser.id, page: currentPage }))
  }, [tabIndex])

  useEffect(() => {
    if (isMembersTab) dispatch(getMemberListData({ role_names: [Roles.MEMBER], page: currentPage }))
    if (isAssignedTab) dispatch(getMemberListData({ advocate_id: currentUser.id, page: currentPage }))

    return () => {
      dispatch(resetMemberListData()) // ensure stale data not used on next mount
    }
  }, [currentPage])

  const goToMemberPage = (id: string) => history.push(RoutePaths.MemberDetail(id))
  const availableTabs = currentUser.isAdmin ? 3 : 2
  const tableData = currentPageData.map((user) => memberTableDataForUser(
    user,
    user.advocateIds !== null ? allLoadedUsers[user.advocateIds![0]] : null,
    user.advocateIds !== null ? allLoadedUsers[user.surrogateIds![0]] : null,
    user.advocateIds !== null ? getTeamRole(allLoadedUsers[user.surrogateIds![0]], user.teamId) : null,
  ))

  const memberLeadMonikor = useMemo(() => memberLeadName, [memberLeadName])

  const MemberTableColumnData = [
    { dataKey: 'memberData', text: 'Member', width: '35%' },
    { dataKey: 'surrogateData', text: 'Agent', width: '17%' },
    { dataKey: 'advocateData', text: memberLeadMonikor, width: '17%' },
    { dataKey: 'directivesData', text: 'Directives', width: '31%' },
  ]

  return (
    <Container maxW='1100px' mt={20} mb='10vh'>
      <NewMemberModal goToMember={goToMemberPage} isOpen={isNewMemberModalOpen} onClose={closeNewMemberModal}/>
      <ListTopComponent onClickNew={openNewMemberModal} modelName='Member' onSearchUpdate={onChangeSearch} searchTerm={searchTerm}/>
      <Box shadow='lg' backgroundColor='white' border='1px solid' borderColor={grays.light}>
        <Tabs index={tabIndex} onChange={onChangeTab}>
          <TabList>
            <Tab _selected={{ color: brand[500], fontWeight: 500 }}
              _hover={{ color: brand[300] }}>
              <Text fontSize='20px'>All Members</Text>
            </Tab>
            <Center height='72px'>
              <Divider orientation='vertical' h='24px' color={grays[400]} />
            </Center>
            <Tab _selected={{ color: brand[500], fontWeight: 500 }}
              _hover={{ color: brand[300] }}>
              <Text fontSize='20px'>Assigned to Me</Text>
            </Tab>
          </TabList>
          <TabPanels mb='0px'>
            {Array(availableTabs).fill(null).map((_, i)=> (
              <TabPanel key={i} background={grays.background} pb='0px'>
                <Table
                  columns={MemberTableColumnData}
                  data={tableData}
                  emptyProps={{text: 'No results found, refine search and try again'}}
                  isLoading={isLoadingStore}
                  rowHeight='100px'
                />
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </Box>
      <Pagination currentPage={currentPage} onPageChange={onChangePage} totalPages={totalPages}/>
    </Container>
  )
}

export default withRouter(MemberList)
