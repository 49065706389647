import React, { useContext, useEffect, useState } from 'react'
import * as ReactRedux from 'react-redux'

import * as Yup from 'yup'
import { Link } from 'react-router-dom'
import { FiLock, FiSave, FiXCircle, FiChevronLeft } from 'react-icons/fi'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { RouteComponentProps, useParams } from 'react-router'
import { useHistory } from 'react-router-dom'
import { StoreState } from '../../redux'
import {getOne as getUser} from '../../redux/users/actions'
import { white } from '../../constants/Colors'
import LoadingPage from '../../components/LoadingPage'

import {
  Container,
  Icon,
  Text,
  Spacer,
  Flex,
  Button,
  Box,
  Stack,
  Input,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select,
  useDisclosure,
  useTheme,
} from '@chakra-ui/react'
import { Field, FieldInputProps, Form, Formik, FormikContextType } from 'formik'
import { Context as DirtyFormAlertContext } from '../../components/DirtyFormAlert'
import { isDone, isError } from '../../redux/AsyncState'
import {update as updateUser} from '../../redux/users/actions'
import { uploadFile } from '../../redux/axios'
import {deleteOne as deleteUser} from '../../redux/users/actions'
import isEmpty from 'lodash.isempty'
import { parsePhoneNumber, userNameAttrsFromFullName } from '../../utils/Forms'
import * as RoutePaths from '../../constants/RoutePaths'
import { useToast } from '../../hooks/Toast'
import ImageUpload, { FileValidation } from '../../components/ImageUpload'
import WarningModal from '../../components/WarningModal'
import { privateFileUrl } from '../../utils/Routing'

enum PlaceHolders {
  phone = '(888) 888-8888',
  fullName = 'John Doe',
  specialty = 'Select specialty',
}

const initialValues = {
  avatar: undefined,
  fullName: '',
  email: '',
  phone: '',
  specialty: '',
}

enum FormFields {
  avatar = 'avatar',
  fullName = 'fullName',
  email = 'email',
  phone = 'phone',
  specialty = 'specialty',
}

const ValidationSchema = Yup.object().shape({
  [FormFields.avatar]: FileValidation,
  [FormFields.email]: Yup.string().nullable().required('Must not be empty').email('Must be a valid email'),
  [FormFields.fullName]: Yup.string().required('First name is required'),
  [FormFields.specialty]: Yup.string().required('Please select a specialty'),
  [FormFields.phone]: Yup.string().required('Phone number is required').matches(/^\([0-9]{3}\) [0-9]{3}-[0-9]{4}?$/, 'Phone number is not valid'),
})

type Values = typeof initialValues;
type ValueTypes = Values[keyof Values];
type FormType = FormikContextType<Values>
type FieldPropType = {field: FieldInputProps<ValueTypes>; form: FormType}
type ContainerProps = RouteComponentProps<any> & { goBack: () => any }
type AccountPageFormProps = ContainerProps & { form: FormType }

export const useHooks = ({form}: AccountPageFormProps) => {
  const dispatch = useDispatch()
  const toast = useToast()
  const { setDirtyFormAlert } = useContext(DirtyFormAlertContext)
  const { id } = useParams<{id: string}>()
  const history = useHistory()
  const {
    currentUser,
    errorData,
    navigatedUser,
    updatingState,
    deletingState,
    memberLeadName,
  } = useSelector(({ users, accounts }: StoreState) => ({
    currentUser: users.currentUser,
    errorData: users.errorData,
    navigatedUser: users.records[id],
    updatingState: users.updatingState,
    deletingState: users.deletingState,
    memberLeadName: accounts.current?.memberLeadName,
  }), shallowEqual)

  const [hasClickedDelete, sethasClickedDelete] = useState(false)

  const isUpdatingError = isError(updatingState)
  const isUpdatingDone = isDone(updatingState)
  const isDeletingError = isError(deletingState)
  const isDeletingDone = isDone(deletingState)

  const removeAdvocate = (advocateId: string) => {
    sethasClickedDelete(true)
    dispatch(deleteUser(advocateId))
  }

  useEffect(() => {
    form.resetForm({ values: {
      ...form.values,
      [FormFields.fullName]: `${navigatedUser?.firstName} ${navigatedUser?.lastName}`,
      [FormFields.email]: navigatedUser?.email,
      [FormFields.phone]: parsePhoneNumber(navigatedUser?.phoneNumber),
      [FormFields.specialty]: navigatedUser?.specialty || '',
    }})
  }, [navigatedUser])

  useEffect(() => {
    dispatch(getUser(id))
  }, [id])

  useEffect(() => {
    if (isDeletingError && hasClickedDelete) {
      form.setSubmitting(false)
      toast({
        description: `${navigatedUser.firstName} ${navigatedUser.lastName} could not be deleted; try again`,
        status: 'error',
        width: '550px',
      })
    } else if (isDeletingDone && hasClickedDelete) {
      toast({
        description: `${navigatedUser.firstName} ${navigatedUser.lastName} was successfully deleted.`,
        status: 'success',
        width: '500px',
      })
      history.push(RoutePaths.Members())
    }
  }, [isDeletingDone, isDeletingError])

  useEffect(() => {
    if (isUpdatingDone && form.isValid && form.submitCount > 0) {
      toast({
        description: 'Changes have been saved.',
        status: 'success',
      })
    }
  }, [isUpdatingDone])

  useEffect(() => {
    if (!isUpdatingError) return

    if (typeof errorData === 'object' && errorData?.email != null) {
      form.setFieldError(FormFields.email, `Email ${errorData?.email.join(', ')}`)
    }

    form.setSubmitting(false)
    toast({
      description: 'Changes were not saved; try again.',
      status: 'error',
    })
  }, [errorData, isUpdatingError])

  useEffect(() => {
    setDirtyFormAlert(form.dirty)
  }, [form.dirty])

  return {
    history,
    currentUser,
    navigatedUser,
    removeAdvocate,
    memberLeadName,
  }
}

const AccountPageForm: React.FC<AccountPageFormProps> = (props) => {
  const { currentUser, history, navigatedUser, removeAdvocate, memberLeadName } = useHooks(props)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { colors: { brand } } = useTheme()
  const { form } = props

  if (navigatedUser == null) return <LoadingPage />

  return (
    <Container maxW='1100px' mt='132px'>
      <Box as={Link} to={RoutePaths.Members()} fontWeight={500} color={brand[500]}>
        <Icon as={FiChevronLeft} display='inline' marginRight='6px'/>
          Back
      </Box>
      <Flex align='center' h='80px' mt='28px'>
        {currentUser.isAdmin && currentUser.id !== navigatedUser.id ? <>
          <Text fontSize='42px' fontWeight='500'>Edit {memberLeadName}</Text>
          <Spacer />
          <Button
            mr={4} borderRadius='3px'
            fontSize='16px'
            lineHeight='24px'
            variant='transparentRedOutline'
            leftIcon={<FiXCircle />}
            onClick={onOpen}
          >Remove {memberLeadName}</Button>
        </>
          : <>
            <Text fontSize='42px' fontWeight='500'>My Account</Text>
            <Spacer />
            <Button
              mr={4}
              borderRadius='3px'
              fontSize='16px'
              lineHeight='24px'
              variant='transparentPurpleOutline'
              leftIcon={<FiLock />}
              onClick={() => history.push(RoutePaths.PasswordReset())}
            >Change password</Button>
          </>}
        <Button
          type='submit'
          borderRadius='3px'
          fontSize='16px'
          lineHeight='24px'
          leftIcon={<FiSave />}
          onClick={() => form.submitForm()}
        >Save</Button>
      </Flex>
      <WarningModal
        headerText={`Are you sure you want to delete ${navigatedUser.firstName} ${navigatedUser.lastName}?`}
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={() => removeAdvocate(navigatedUser.id)}
      >
        Deleting an Advocate cannot be undone.
      </WarningModal>
      <Box w='375px' mt='48px'>
        <Stack spacing={1}>
          <Button
            type='submit'
            borderRadius='3px'
            fontSize='16px'
            lineHeight='24px'
            marginBottom='24px'
            onClick={() => history.push(RoutePaths.PricingOptions())}
          >Subscription</Button>
        </Stack>
        <Stack direction='row' spacing={2} alignSelf='flex-start' alignItems='center'>
          <Field name={FormFields.avatar}>
            {({ field }: FieldPropType) => (
              <FormControl isInvalid={!isEmpty(form.errors.avatar)} width='inherit'>
                <ImageUpload
                  {...field}
                  avatarProps={{ size: 'xl' }}
                  id={field.name}
                  initialValue={privateFileUrl(navigatedUser.avatar)}
                  onChange={file => {
                    form.setFieldValue(FormFields.avatar, file)
                  }}
                />
                <FormErrorMessage>{form.errors.avatar}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Stack spacing={1}>
            <Text fontSize='16px'>Profile Picture</Text>
            <Text fontSize='12px'>Click to upload profile picture</Text>
          </Stack>
        </Stack>
      </Box>

      <Box width='540px' mt={4}>
        <Form>
          <Field name={FormFields.fullName}>
            {({ field }: FieldPropType) => (
              <FormControl isInvalid={!isEmpty(form.errors.fullName) && form.touched.fullName}>
                <FormLabel htmlFor={field.name}>Full Name</FormLabel>
                <Input {...field} id={field.name} placeholder={PlaceHolders.fullName} size='lg'  />
                <FormErrorMessage>{form.errors.fullName}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Flex direction='row' w='100%' mt='17px'>
            <Field name={FormFields.email}>
              {({ field }: FieldPropType) => (
                <FormControl mr='16px' isInvalid={!isEmpty(form.errors.email) && form.touched.email}>
                  <FormLabel htmlFor={field.name}>Email</FormLabel>
                  <Input {...field} id={field.name} size='lg'/>
                  <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name={FormFields.phone}>
              {({ field }: FieldPropType) => (
                <FormControl isInvalid={!isEmpty(form.errors.phone) && form.touched.phone} >
                  <FormLabel htmlFor={field.name}>Phone</FormLabel>
                  <Input
                    {...field}
                    id={field.name}
                    placeholder={PlaceHolders.phone}
                    onChange={({currentTarget}) => {form.setFieldValue(field.name, parsePhoneNumber(currentTarget.value))}}
                    size='lg'
                  />
                  <FormErrorMessage>{form.errors.phone}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </Flex>
          <Field name={FormFields.specialty}>
            {({ field }: FieldPropType) => (
              <FormControl isInvalid={!isEmpty(form.errors.specialty) && form.touched.specialty} mr='16px' maxW='262px' mt='30px'>
                <FormLabel htmlFor={field.name}>Specialty</FormLabel>
                <Select {...field} id={field.name} placeholder={PlaceHolders.specialty} size='lg' backgroundColor={white} >
                  <option>Advocate</option>
                  <option>Cardiology</option>
                  <option>Hospice</option>
                  <option>Infectious Disease</option>
                  <option>Nephrology</option>
                  <option>Neuroscience</option>
                  <option>Oncology</option>
                  <option>Palliative</option>
                  <option>Pulmonology</option>
                </Select>
                <FormErrorMessage>{form.errors.specialty}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
        </Form>

      </Box>

    </Container>
  )
}

const AccountPageFormContainer: React.FC<ContainerProps> = (props) => {
  const dispatch = ReactRedux.useDispatch()
  const { id } = useParams<{id: string}>()

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ValidationSchema}
      onSubmit={({avatar, phone, ...values}) => {
        uploadFile(avatar).then((avatarId) => {

          dispatch(updateUser({
            ...values,
            ...userNameAttrsFromFullName(values.fullName),
            avatar: avatarId,
            phoneNumber: phone.match(/\d/g)?.join(''),
            specialty: values.specialty,
          }, id))
        })
      }}
    >{(formik) => <AccountPageForm {...props} form={formik} />}</Formik>
  )
}

export default AccountPageFormContainer