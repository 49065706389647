import React, { useEffect, useState } from 'react'
import { axiosAuthed } from '../redux/axios'
import axios from 'axios'
import humps from 'humps'
import { Model as Subdomain } from '../constants/Subdomain'
import {BrandedTheme, createTheme} from '../constants/Theme'
import { getCurrentSubdomain } from '../utils/Routing'

interface IContext {
  currentSubdomain: string
  isLoading: boolean
  isPartnerManagement: boolean
  reload: () => void
}

const useHooks = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [subdomain, setSubdomain] = useState<Subdomain>()
  const currentSubdomain = getCurrentSubdomain()
  const isPartnerManagement = currentSubdomain === process.env.REACT_APP_PARTNER_MANAGEMENT_DOMAIN?.toLowerCase()

  const reload = async () => {
    if (isPartnerManagement) {
      setIsLoading(false)
      return
    }

    setIsLoading(true)

    if(currentSubdomain === 'app'){
      try{
        const {data: {data: {attributes}}} = await axiosAuthed({method: 'get'}, `/subdomains/${currentSubdomain}/app_show`)
        setSubdomain(humps.camelizeKeys(attributes) as any)
      } catch {
      }
    }
    else {
      const {data: {data: {attributes}}} = await axios.get(`/api/subdomains/${currentSubdomain}`)
      setSubdomain(humps.camelizeKeys(attributes) as any)
    }

    setIsLoading(false)
  }

  useEffect(() => {
    reload()
  }, [])

  return {
    currentContext: { currentSubdomain, isLoading, isPartnerManagement, reload },
    isLoading,
    subdomain,
  }
}

export const Context = React.createContext<IContext>({
  currentSubdomain: '',
  isLoading: true,
  isPartnerManagement: true,
  reload: () => null,
})

type SubdomainProviderProps = {
  children: (props: {isLoading: boolean; theme: BrandedTheme}) => any
}

const SubdomainProvider: React.FC<SubdomainProviderProps> = ({children}) => {
  const {
    currentContext,
    isLoading,
    subdomain,
  } = useHooks()

  return <Context.Provider value={currentContext}>
    {children({isLoading, theme: createTheme(subdomain)})}
  </Context.Provider>
}

export default SubdomainProvider
