import ActionIds from '../ActionIds'
import AsyncState from '../AsyncState'
import { Model } from '../../constants/TeamMeeting'
import { BaseAction, Records } from '../types'

const initialState = {
  creatingState: AsyncState.NOT_STARTED,
  lastCreatedId: undefined as unknown as string,
  loadingState: AsyncState.NOT_STARTED,
  records: {} as Records<Model>,
  updatingState: AsyncState.NOT_STARTED,
}

export type StateType = typeof initialState;

export default function reducer(state = initialState, action: BaseAction): StateType {
  switch (action.type) {
    case ActionIds.TEAM_MEETINGS_UPDATE_START:
      return { ...state, updatingState: AsyncState.LOADING }
    case ActionIds.TEAM_MEETINGS_GET_ONE_START: // fall-through
    case ActionIds.TEAM_MEETINGS_GET_MANY_START:
      return { ...state, loadingState: AsyncState.LOADING }
    case ActionIds.TEAM_MEETINGS_GET_MANY_SUCCESS: {
      const users = action.payload.data.map(({attributes}) => attributes) as Model[]
      const { records } = state
      return {
        ...state,
        loadingState: AsyncState.DONE,
        records: users.reduce((memo: Records<Model>, u: Model) => ({...memo, [u.id]: u}), records),
      }
    }
    case ActionIds.TEAM_MEETINGS_UPDATE_SUCCESS: {
      const newTeamMeeting = action.payload.data.attributes
      const { records } = state
      return { ...state, records: {...records, [newTeamMeeting.id]: newTeamMeeting}, updatingState: AsyncState.DONE }
    }
    case ActionIds.TEAM_MEETINGS_CREATE_START:
      return { ...state, creatingState: AsyncState.LOADING }
    case ActionIds.TEAM_MEETINGS_CREATE_SUCCESS: {
      const newTeamMeeting = action.payload.data.attributes
      const { records } = state
      return {
        ...state,
        creatingState: AsyncState.DONE,
        lastCreatedId: newTeamMeeting.id,
        records: { ...records, [newTeamMeeting.id]: newTeamMeeting },
      }
    }
    case ActionIds.TEAM_MEETINGS_GET_ONE_SUCCESS: {
      const newTeamMeeting = action.payload.data.attributes
      const { records } = state
      return { ...state, records: {...records, [newTeamMeeting.id]: newTeamMeeting}, loadingState: AsyncState.DONE }
    }
    case ActionIds.TEAM_MEETINGS_CREATE_FAIL:
      return { ...state, creatingState: AsyncState.ERROR }
    case ActionIds.TEAM_MEETINGS_UPDATE_FAIL:
      return { ...state, updatingState: AsyncState.ERROR }
    case ActionIds.TEAM_MEETINGS_GET_ONE_FAIL: // fall-through
    case ActionIds.TEAM_MEETINGS_GET_MANY_FAIL:
      return { ...state, loadingState: AsyncState.ERROR }
    default:
      return state
  }
}
