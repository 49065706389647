import React, { useEffect } from 'react'
import * as ReactRedux from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { withRouter } from 'react-router-dom'
import { Field, FieldInputProps, Form, Formik, FormikContextType } from 'formik'
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Center,
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
  Text,
} from '@chakra-ui/react'
import * as Yup from 'yup'
import isEmpty from 'lodash.isempty'
import { FiAlertTriangle } from 'react-icons/fi'
import { StoreState } from '../../redux'
import {isError} from '../../redux/AsyncState'
import { resetPassword } from '../../redux/auth/actions'

enum FormFields {
  email = 'email',
}

const ValidationSchema = Yup.object().shape({
  [FormFields.email]: Yup.string().required('Must not be empty').email('Must be a valid email'),
})

const initialValues = {
  [FormFields.email]: '',
} as const

type Values = typeof initialValues[keyof typeof initialValues];
type FormType = FormikContextType<typeof initialValues>
type FieldPropType = {field: FieldInputProps<Values>; form: FormType}
type ForgotPasswordFormContainerProps = RouteComponentProps<any> & { onClickCancel: () => void }
type ForgotPasswordFormProps = ForgotPasswordFormContainerProps & { form: FormType }

// visibleForTesting
export const useHooks = ({form}: ForgotPasswordFormProps) => {
  const { shallowEqual, useSelector } = ReactRedux

  const {
    passwordResetState,
  } = useSelector(({auth}: StoreState) => ({
    passwordResetState: auth.passwordResetState,
  }), shallowEqual)

  const isPasswordResetError = isError(passwordResetState)

  useEffect(() => {
    if (isPasswordResetError) {
      form.setSubmitting(false)
    }
  }, [isPasswordResetError])

  return {
    isPasswordResetError,
  }
}

const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = (props) => {
  const { isPasswordResetError } = useHooks(props)
  const { form, onClickCancel } = props

  return (
    <>
      <Box height='0'>{isPasswordResetError && (
        <Alert status='error'>
          <AlertIcon />
          <Center flex='1'>Something went wrong. Please Try again.</Center>
        </Alert>
      )}</Box>
      <Center height='100%'>
        <Form>
          <Stack align='center' spacing='20px' width='350px'>
            <Text fontSize='26px'>Reset Your Password</Text>
            <Text textAlign='center'>Enter your email address below and we’ll send you instructions to reset your password.</Text>
            <Field name={FormFields.email}>
              {({ field }: FieldPropType) => (
                <FormControl isInvalid={!isEmpty(form.errors.email) && form.touched.email}>
                  <FormLabel htmlFor={field.name}>Email</FormLabel>
                  <Input {...field} id={field.name} placeholder='Email' type='email'/>
                  <FormErrorMessage><FormErrorIcon as={FiAlertTriangle} />{form.errors.email}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Stack direction='row' spacing={4} width='100%'>
              <Button width='100%' isDisabled={form.isSubmitting} variant='outline' onClick={onClickCancel}>
                Cancel
              </Button>
              <Button width='100%' isLoading={form.isSubmitting} type='submit' onClick={() => form.submitForm()}>
                Reset Password
              </Button>
            </Stack>
          </Stack>
        </Form>
      </Center>
    </>
  )
}

const ForgotPasswordFormContainer: React.FC<ForgotPasswordFormContainerProps> = (props) => {
  const dispatch = ReactRedux.useDispatch()

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ValidationSchema}
      onSubmit={(values) => { dispatch(resetPassword(values)) }}
    >{(formik) => <ForgotPasswordForm {...props} form={formik} />}</Formik>
  )
}

export default withRouter(ForgotPasswordFormContainer)
