import queryString, { StringifiableRecord } from 'query-string'
import { AuthTokens } from '../redux/auth/reducer'
import { StoreState, getStore } from '../redux/'

export enum QUERY_PARAM_KEYS {
  ACCESS_TOKEN = 'at',
  CONFIRMATION_SUCCESS = 'cs',
  PAGE = 'p',
  PASSWORD_RESET = 'pr',
  REDIRECT_URL = 'ru',
  REFRESH_TOKEN = 'rt',
  SEARCH_TERM = 'q',
  STEP = 's',
  STORAGE_TOKEN = 'st',
  TAB = 't',
  THANKSGIVING = 'th',
}

export type SearchParams = Partial<{[key in QUERY_PARAM_KEYS]?: string | string[] | boolean}>

const trimSlashes = (slashedString: string) => slashedString.replace(/^\/+|\/+$/g, '')
export const isCurrentPath = (testPath, location: any = window.location): boolean => trimSlashes(testPath) === trimSlashes(location.pathname)

export const pathStartsWith = (testPath, location: any = window.location): boolean => trimSlashes(location.pathname).startsWith(trimSlashes(testPath))
export const getSearchParams = (location: any = window.location): SearchParams => queryString.parse(location.search)
export const getNewSearchString = (newParams: SearchParams = {}) => `?${queryString.stringify(newParams, {skipNull: true, skipEmptyString: true})}`

export const getAuthTokenParams = (location: any = window.location): AuthTokens => ({
  accessToken: getSearchParams(location)[QUERY_PARAM_KEYS.ACCESS_TOKEN] as string,
  refreshToken: getSearchParams(location)[QUERY_PARAM_KEYS.REFRESH_TOKEN] as string,
  storageToken: getSearchParams(location)[QUERY_PARAM_KEYS.STORAGE_TOKEN] as string,
})
export const getConfirmationParam = (location: any = window.location): string | undefined => getSearchParams(location)[QUERY_PARAM_KEYS.CONFIRMATION_SUCCESS] as string
export const getPasswordResetParam = (location: any = window.location): string | undefined => getSearchParams(location)[QUERY_PARAM_KEYS.PASSWORD_RESET] as string
export const getPageParam = (location: any = window.location): string | undefined => getSearchParams(location)[QUERY_PARAM_KEYS.PAGE] as string
export const getSearchTermParam = (location: any = window.location): string | undefined => getSearchParams(location)[QUERY_PARAM_KEYS.SEARCH_TERM] as string
export const getStepParam = (location: any = window.location): string | undefined => getSearchParams(location)[QUERY_PARAM_KEYS.STEP] as string
export const getRedirectParam = (location: any = window.location): string | undefined => getSearchParams(location)[QUERY_PARAM_KEYS.REDIRECT_URL] as string
export const getTabParam = (location: any = window.location): string | undefined => getSearchParams(location)[QUERY_PARAM_KEYS.TAB] as string
export const getThanksgivingParam = (location: any = window.location): string | undefined => getSearchParams(location)[QUERY_PARAM_KEYS.THANKSGIVING] as string
export const generateRedirectSearchParam = (location: any = window.location) => {
  const { pathname, search } = location
  const uriComponent = pathname + search

  return `${QUERY_PARAM_KEYS.REDIRECT_URL}=${encodeURIComponent(uriComponent)}`
}
export const privateFileUrl = (baseUrl?: string, params: StringifiableRecord = {}) => {
  if (baseUrl == null) return undefined

  const { auth: { storageToken } } = getStore().getState() as StoreState
  return baseUrl + `?${queryString.stringify({storage_token: storageToken, ...params})}`
}
export const getSubdomainRoute = (subdomain: string, route: string = '') => {
  const baseUrl = window.location.origin.replace(/:\/\/.+?\./, `://${subdomain}.`)
  return baseUrl + route
}
export const getCurrentSubdomain = () => window.location.host.split('.')[0].toLowerCase()
