import React from 'react'
import {
  Button,
  Image,
  Stack,
  Text,
} from '@chakra-ui/react'
import paperPlane from '../assets/Images/paper-plane.svg'

type SentProps = { onFinish: () => any; text: string }
const Sent: React.FC<SentProps> = ({onFinish, text}) => (
  <Stack align='center' spacing='20px' width='540px'>
    <Image src={paperPlane} />
    <Text fontSize='42px'>{text}</Text>
    <Button width='full' onClick={onFinish}>
      Done
    </Button>
  </Stack>
)

export default Sent
