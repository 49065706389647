import {TableData} from '../../../../components/TableOfContents'
import {FlexForm, FlexFormSection, questionAnswersValid} from './data/FlexFormModel'
import {MemberDirective} from '../../../../constants/RoutePaths'
import {Model as DirectiveModel, Types} from '../../../../constants/Directive'
import {Model as UserModel} from '../../../../constants/User'
import transform from 'lodash.transform'
import snakeCase from 'lodash.snakecase'

const allinaTemplate = require('./template/allina/directives_v1.json')
const idlmTemplate = require('./template/idlm/directives_v1.json')
const defaultTemplate = require('./template/default/directives_v1.json')
const default2Template = require('./template/default2/directives_v1.json')
const moriendi = require('./template/moriendi/directives_v1.json')
const empoweredendings = require('./template/empoweredendings/directives_v1.json')

export const getFlexDirective = (templateId?: string): FlexForm => {
  switch (templateId) {
    case 'allina':
      return mergeQuestionTemplates(allinaTemplate)
    case 'idlm':
      return mergeQuestionTemplates(idlmTemplate)
    case 'moriendi':
      return mergeQuestionTemplates(moriendi)
    case 'default2':
      return mergeQuestionTemplates(default2Template)
    case 'empoweredendings':
      return mergeQuestionTemplates(empoweredendings)
    default:
      return mergeQuestionTemplates(defaultTemplate)
  }
}

const mergeQuestionTemplates = (form: FlexForm): FlexForm => {
  const newSections: FlexFormSection[] = []
  form.sections.forEach((section) => {
    const questions = section.questions.map((q) => {
      if (q.questionTemplate != null) {
        return { ...q, ...form.questionTemplates[q.questionTemplate]}
      }
      return q
    })
    section.questions = questions
    newSections.push(section)
  })
  form.sections = newSections
  return form
}

export const getFlexDirectiveTableData = (currentStep: number, member: UserModel, directive: DirectiveModel | undefined): {[index: string]: TableData} => {
  const flexDirective = getFlexDirective(directive?.templateId)
  const sections: {[index: string]: TableData} = {}
  const savedValuesSnaked = transform(directive?.questions || {}, (result, val, key) => {
    result[snakeCase(key)] = val
  }, {})
  flexDirective.sections.forEach((sec, i) => {
    let status = ''
    if (i < (member?.flexDirectiveStepsCompleted || 0)) {
      status = 'in_progress'
      if (directive !== undefined && questionAnswersValid(sec.questions, savedValuesSnaked)) {
        status = 'complete'
      }
    }
    sections[sec.navTitle] = {
      isActive: currentStep === i + 1,
      status,
      to: MemberDirective(member?.id || '', Types.HEALTHCARE_FLEX_DIRECTIVE, i + 1),
    }
  })
  const reviewSectionStatus = Object.values(sections).reduce((fin, curr) => {
    switch (fin) {
      case '':
        return ''
      case 'in_progress':
        return curr.status === '' ? '' : 'in_progress'
      default:
        return curr.status || ''
    }
  }, 'complete')
  sections['Review'] = {
    isActive: currentStep === flexDirective.sections.length + 1,
    status: reviewSectionStatus,
    to: MemberDirective(member?.id || '', Types.HEALTHCARE_FLEX_DIRECTIVE, flexDirective.sections.length + 1),
  }
  return sections
}
