import ActionIds from '../ActionIds'
import AsyncState from '../AsyncState'
import { BaseAction } from '../types'

export interface AuthTokens {
  accessToken: string
  refreshToken: string
  storageToken: string
}

const initialState = {
  accessToken: null as unknown as string,
  loadingState: AsyncState.NOT_STARTED,
  passwordResetState: AsyncState.NOT_STARTED,
  refreshToken: null as unknown as string,
  storageToken: null as unknown as string,
  accountId: null as unknown as string,
}

export type StateType = typeof initialState;

export default function reducer(
  state = initialState,
  action: BaseAction,
): StateType {
  switch (action.type) {
    case ActionIds.AUTH_PASSWORD_RESET_START:
      return { ...initialState, passwordResetState: AsyncState.LOADING }
    case ActionIds.AUTH_POST_START:
      return { ...initialState, loadingState: AsyncState.LOADING }
    case ActionIds.AUTH_PASSWORD_RESET_SUCCESS:
      return { ...initialState, passwordResetState: AsyncState.DONE }
    case ActionIds.AUTH_POST_SUCCESS: {
      const {accessToken, refreshToken, storageToken} = action.payload.data.attributes as AuthTokens
      return { ...state, accessToken, refreshToken, storageToken, loadingState: AsyncState.DONE }
    }
    case ActionIds.AUTH_REFRESH_SUCCESS: {
      const {accessToken, storageToken} = action.payload as AuthTokens
      return { ...state, accessToken, storageToken }
    }
    case ActionIds.AUTH_PASSWORD_RESET_FAIL:
      return { ...initialState, passwordResetState: AsyncState.ERROR }
    case ActionIds.AUTH_POST_FAIL:
      return { ...initialState, loadingState: AsyncState.ERROR }
    case ActionIds.AUTH_REFRESH_FAIL: // fall-through
    case ActionIds.AUTH_DESTROY_SUCCESS:
      return initialState
    case ActionIds.AUTH_SET_ACCOUNT_ID:
      return { ...state, accountId: action.payload.id}
    case ActionIds.AUTH_UNSET_ACCOUNT_ID:
      return { ...state, accountId: '' }
    default:
      return state
  }
}
