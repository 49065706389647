import React from 'react'
import {Field, FieldInputProps, FormikContextType} from 'formik'
import {FormControl, Text, FormErrorMessage, FormErrorIcon, Checkbox, CheckboxGroup, FormLabel, Stack} from '@chakra-ui/react'
import {textPalette} from '../../../../constants/Colors'
import isEmpty from 'lodash.isempty'
import {FiAlertTriangle} from 'react-icons/fi'
import {FlexFormQuestionOption} from './data/FlexFormModel'
import ExplanationAnswer from './ExplanationAnswer'

type FieldPropType = { field: FieldInputProps<string>; form: FormikContextType<any> }


export type QuestionCheckboxProps = {
  name: string
  label?: string
  description?: string
  error?: string
  touched?: boolean
  value: any
  compact: boolean
  setFieldValue: (field: string, value: any) => void
  options: FlexFormQuestionOption[]
}
const QuestionCheckbox: React.FC<QuestionCheckboxProps> = ({name, label, description, value, compact, error, touched, options, setFieldValue}) => {
  // const selectedOption: FlexFormQuestionOption = find(options, (o: FlexFormQuestionOption) => o.value === value)
  const initialValue = (typeof value === 'string' && value !== '' ? JSON.parse(value) : value)
  return (
    <>
      <Field name={name}>
        {({ field }: FieldPropType) => (
          <FormControl
            id={name}
            isInvalid={!isEmpty(error) && touched}
          >
            <CheckboxGroup value={initialValue} onChange={val => {
              setFieldValue(field.name, val)
            }}>
              {(label || description) &&
                  <FormLabel htmlFor={field.name} fontSize='md' color={textPalette.normal}>
                    {label && description &&
                      <Text width='100%' fontWeight='bold'>{label}</Text>
                    }
                    {/*just show a simple label if no description*/}
                    {!description && label}
                    {description &&
                        <Text width='100%' color={textPalette.light} marginTop='0 !important'>
                          {description}
                        </Text>
                    }
                  </FormLabel>
              }

              <Stack direction={compact ? 'row': 'column'} >
                {options.map((option) => (
                  <>
                    <Checkbox key={option.value} size='md' value={option.value} alignItems='center'>
                      {option.label && option.description &&
                            <Text fontWeight='500'>{option.label}</Text>
                      }
                      {(option.description || option.label) &&
                            <Text color={textPalette.light}>
                              {option.description || option.label}
                            </Text>
                      }
                    </Checkbox>

                    <>
                      { option.includeExplanation &&
                          <ExplanationAnswer name={`${field.name}_${option.value}_explanation`} hidden={!option.includeExplanation || !(value?.indexOf(option.value) > -1)} required={false} defaultValue=''/>
                      }
                    </>
                  </>
                ))}
              </Stack>
            </CheckboxGroup>
            <FormErrorMessage><FormErrorIcon as={FiAlertTriangle} />{error}</FormErrorMessage>
          </FormControl>
        )}
      </Field>
    </>
  )
}

export default QuestionCheckbox