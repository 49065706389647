import { axiosAuthed } from '../axios'

const BASE_ROUTE = '/directives'

export const getOne = async (id: string) => {
  try {
    const response = await axiosAuthed({method: 'get'}, `${BASE_ROUTE}/${id}`)
    return { response }
  } catch(error) {
    return { error }
  }
}

export const update = async (id: string, data: any) => {
  try {
    const response = await axiosAuthed({method: 'put'}, `${BASE_ROUTE}/${id}`, data)
    return { response }
  } catch(error) {
    return { error }
  }
}

export const create = async (data: any) => {
  try {
    const response = await axiosAuthed({method: 'post'}, BASE_ROUTE, data)
    return { response }
  } catch(error) {
    return { error }
  }
}

export const sendToDocusign = async (data: any) => {
  try {
    const response = await axiosAuthed({method: 'post'}, `${BASE_ROUTE}/send_to_docusign`, data)
    return { response }
  } catch(error) {
    return { error }
  }
}

export const sendPolstToDocusign = async (data: any) => {
  try {
    const response = await axiosAuthed({method: 'post'}, `${BASE_ROUTE}/send_to_docusign`, data)
    return { response }
  } catch (error) {
    return { error }
  }
}

export const createPdf = async (memberId: string, signatureLevel: number, redo: boolean) => {
  try {
    const response = await axiosAuthed({method: 'get'}, `/pdfs/${memberId}`, {params: {signatureLevel, redo}})
    return { response }
  } catch (error) {
    return { error }
  }
}

export const share = async (data: any) => {
  try {
    const response = await axiosAuthed({method: 'post'}, `${BASE_ROUTE}/share`, data)
    return { response }
  } catch(error) {
    return { error }
  }
}
