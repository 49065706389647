import { axiosAuthed } from '../axios'

const BASE_ROUTE = '/users'
const PROFILE_BASE_ROUTE = '/profile'

export const getOne = async (id: string) => {
  try {
    const response = await axiosAuthed({method: 'get'}, `${BASE_ROUTE}/${id}`)
    return { response }
  } catch(error) {
    return { error }
  }
}

export const getProfile = async () => {
  try {
    const response = await axiosAuthed({method: 'get'}, PROFILE_BASE_ROUTE)
    return { response }
  } catch(error) {
    return { error }
  }
}

export const updateProfile = async (params: any) => {
  try {
    const response = await axiosAuthed({method: 'put'}, PROFILE_BASE_ROUTE, params)
    return { response }
  } catch(error) {
    return { error }
  }
}

export const getMany = async (params: any) => {
  try {
    const response = await axiosAuthed({method: 'get'}, BASE_ROUTE, {params})
    return { response }
  } catch(error) {
    return { error }
  }
}

export const update = async (id: string, data: any) => {
  try {
    const response = await axiosAuthed({method: 'put'}, `${BASE_ROUTE}/${id}`, data)
    return { response }
  } catch(error) {
    return { error }
  }
}

export const create = async (data: any) => {
  try {
    const response = await axiosAuthed({method: 'post'}, BASE_ROUTE, data)
    return { response }
  } catch(error) {
    return { error }
  }
}

export const signup = async (data: any) => {
  try {
    const response = await axiosAuthed({method: 'post', skipTokenRefresh: true}, `${BASE_ROUTE}/signup`, data)
    return { response }
  } catch(error) {
    return { error }
  }
}

export const invite = async (data: any) => {
  try {
    const response = await axiosAuthed({method: 'post'}, `${BASE_ROUTE}/invite`, data)
    return { response }
  } catch(error) {
    return { error }
  }
}

export const deleteOne = async (id: string, teamMemberId: string | null) => {
  try {
    const response = await axiosAuthed({method: 'delete'}, `${BASE_ROUTE}/${id}`, {params: {teamMemberId}})
    return { response }
  } catch(error) {
    return { error }
  }
}

export const assignTeamMember = async (id: string, data: any) => {
  try {
    const response = await axiosAuthed({method: 'post'}, `${BASE_ROUTE}/${id}/assign_team_member`, data)
    return { response }
  } catch(error) {
    return { error }
  }
}
