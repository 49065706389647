enum ActionIds {
  API_DESTROY = '@@apiMiddleware/API_DESTROY',
  API_GET = '@@apiMiddleware/API_GET',
  API_POST = '@@apiMiddleware/API_POST',
  API_PUT = '@@apiMiddleware/API_PUT',

  AUTH_DESTROY_START = 'thanacare/AUTH_DESTROY_START',
  AUTH_DESTROY_SUCCESS = 'thanacare/AUTH_DESTROY_SUCCESS',
  AUTH_PASSWORD_RESET_FAIL = 'thanacare/AUTH_PASSWORD_RESET_FAIL',
  AUTH_PASSWORD_RESET_START = 'thanacare/AUTH_PASSWORD_RESET_START',
  AUTH_PASSWORD_RESET_SUCCESS = 'thanacare/AUTH_PASSWORD_RESET_SUCCESS',
  AUTH_POST_CANCEL = 'thanacare/AUTH_POST_CANCEL',
  AUTH_POST_FAIL = 'thanacare/AUTH_POST_FAIL',
  AUTH_POST_START = 'thanacare/AUTH_POST_START',
  AUTH_POST_SUCCESS = 'thanacare/AUTH_POST_SUCCESS',
  AUTH_REFRESH_FAIL = 'thanacare/AUTH_REFRESH_FAIL',
  AUTH_REFRESH_START = 'thanacare/AUTH_REFRESH_START',
  AUTH_REFRESH_SUCCESS = 'thanacare/AUTH_REFRESH_SUCCESS',
  AUTH_SET_TOKENS = 'thanacare/AUTH_SET_TOKENS',
  AUTH_SET_ACCOUNT_ID = 'thanacare/AUTH_SET_ACCOUNT_ID',
  AUTH_UNSET_ACCOUNT_ID = 'thanacare/AUTH_UNSET_ACCOUNT_ID',
  DIRECTIVES_CREATE_FAIL = 'thanacare/DIRECTIVES_CREATE_FAIL',
  DIRECTIVES_CREATE_START = 'thanacare/DIRECTIVES_CREATE_START',
  DIRECTIVES_CREATE_SUCCESS = 'thanacare/DIRECTIVES_CREATE_SUCCESS',
  DIRECTIVES_GET_ONE_CANCEL = 'thanacare/DIRECTIVES_GET_ONE_CANCEL',
  DIRECTIVES_GET_ONE_FAIL = 'thanacare/DIRECTIVES_GET_ONE_FAIL',
  DIRECTIVES_GET_ONE_START = 'thanacare/DIRECTIVES_GET_ONE_START',
  DIRECTIVES_GET_ONE_SUCCESS = 'thanacare/DIRECTIVES_GET_ONE_SUCCESS',
  DIRECTIVES_GET_MANY_SUCCESS = 'thanacare/DIRECTIVES_GET_MANY_SUCCESS',
  DIRECTIVES_GET_MANY_FAIL = 'thanacare/DIRECTIVES_GET_MANY_FAIL',
  DIRECTIVES_GET_MANY_START = 'thanacare/DIRECTIVES_GET_MANY_START',
  DIRECTIVES_GET_MANY_CANCEL = 'thanacare/DIRECTIVES_GET_MANY_CANCEL',
  DIRECTIVES_SEND_FAIL = 'thanacare/DIRECTIVES_SEND_FAIL',
  DIRECTIVES_SEND_START = 'thanacare/DIRECTIVES_SEND_START',
  DIRECTIVES_POLST_SEND_START = 'thanacare/DIRECTIVES_POLST_SEND_START',
  DIRECTIVES_POLST_SEND_FAIL = 'thanacare/DIRECTIVES_POLST_SEND_FAIL',
  DIRECTIVES_POLST_SEND_SUCCESS = 'thanacare/DIRECTIVES_POLST_SEND_SUCCESS',
  DIRECTIVES_SEND_SUCCESS = 'thanacare/DIRECTIVES_SEND_SUCCESS',
  DIRECTIVES_SHARE_FAIL = 'thanacare/DIRECTIVES_SHARE_FAIL',
  DIRECTIVES_SHARE_START = 'thanacare/DIRECTIVES_SHARE_START',
  DIRECTIVES_SHARE_SUCCESS = 'thanacare/DIRECTIVES_SHARE_SUCCESS',
  DIRECTIVES_UPDATE_FAIL = 'thanacare/DIRECTIVES_UPDATE_FAIL',
  DIRECTIVES_UPDATE_START = 'thanacare/DIRECTIVES_UPDATE_START',
  DIRECTIVES_UPDATE_SUCCESS = 'thanacare/DIRECTIVES_UPDATE_SUCCESS',
  DIRECTIVES_CREATE_PDF_START = 'thanacare/DIRECTIVES_CREATE_PDF_START',
  DIRECTIVES_CREATE_PDF_FAIL = 'thanacare/DIRECTIVES_CREATE_PDF_FAIL',
  DIRECTIVES_CREATE_PDF_SUCCESS = 'thanacare/DIRECTIVES_CREATE_PDF_SUCCESS',
  FILES_DELETE_FAIL = 'thanacare/FILES_DELETE_FAIL',
  FILES_DELETE_START = 'thanacare/FILES_DELETE_START',
  FILES_DELETE_SUCCESS = 'thanacare/FILES_DELETE_SUCCESS',
  FILES_DELETE_CANCEL = 'thanacare/FILES_DELETE_CANCEL',
  HEALTH_CONCERNS_GET_MANY_CANCEL = 'thanacare/HEALTH_CONCERNS_GET_MANY_CANCEL',
  HEALTH_CONCERNS_GET_MANY_FAIL = 'thanacare/HEALTH_CONCERNS_GET_MANY_FAIL',
  HEALTH_CONCERNS_GET_MANY_START = 'thanacare/HEALTH_CONCERNS_GET_MANY_START',
  HEALTH_CONCERNS_GET_MANY_SUCCESS = 'thanacare/HEALTH_CONCERNS_GET_MANY_SUCCESS',
  HEALTH_PLANS_GET_MANY_CANCEL = 'thanacare/HEALTH_PLANS_GET_MANY_CANCEL',
  HEALTH_PLANS_GET_MANY_FAIL = 'thanacare/HEALTH_PLANS_GET_MANY_FAIL',
  HEALTH_PLANS_GET_MANY_START = 'thanacare/HEALTH_PLANS_GET_MANY_START',
  HEALTH_PLANS_GET_MANY_SUCCESS = 'thanacare/HEALTH_PLANS_GET_MANY_SUCCESS',
  MEETING_PURPOSES_GET_MANY_CANCEL = 'thanacare/MEETING_PURPOSES_GET_MANY_CANCEL',
  MEETING_PURPOSES_GET_MANY_FAIL = 'thanacare/MEETING_PURPOSES_GET_MANY_FAIL',
  MEETING_PURPOSES_GET_MANY_START = 'thanacare/MEETING_PURPOSES_GET_MANY_START',
  MEETING_PURPOSES_GET_MANY_SUCCESS = 'thanacare/MEETING_PURPOSES_GET_MANY_SUCCESS',
  MEMBER_LIST_GET_DATA_CANCEL = 'thanacare/MEMBER_LIST_GET_DATA_CANCEL',
  MEMBER_LIST_GET_DATA_FAIL = 'thanacare/MEMBER_LIST_GET_DATA_FAIL',
  MEMBER_LIST_GET_DATA_START = 'thanacare/MEMBER_LIST_GET_DATA_START',
  MEMBER_LIST_GET_DATA_SUCCESS = 'thanacare/MEMBER_LIST_GET_DATA_SUCCESS',
  MEMBER_LIST_RESET_DATA = 'thanacare/MEMBER_LIST_RESET_DATA',
  MEMBER_NOTES_GET_DATA_CANCEL = 'thanacare/MEMBER_NOTES_GET_DATA_CANCEL',
  MEMBER_NOTES_GET_DATA_FAIL = 'thanacare/MEMBER_NOTES_GET_DATA_FAIL',
  MEMBER_NOTES_GET_DATA_START = 'thanacare/MEMBER_NOTES_GET_DATA_START',
  MEMBER_NOTES_GET_DATA_SUCCESS = 'thanacare/MEMBER_NOTES_GET_DATA_SUCCESS',
  MEMBER_NOTES_RESET_DATA = 'thanacare/MEMBER_NOTES_RESET_DATA',
  ACCOUNTS_CREATE_FAIL = 'thanacare/ACCOUNTS_CREATE_FAIL',
  ACCOUNTS_CREATE_START = 'thanacare/ACCOUNTS_CREATE_START',
  ACCOUNTS_CREATE_SUCCESS = 'thanacare/ACCOUNTS_CREATE_SUCCESS',
  ACCOUNTS_GET_MANY_CANCEL = 'thanacare/ACCOUNTS_GET_MANY_CANCEL',
  ACCOUNTS_GET_MANY_FAIL = 'thanacare/ACCOUNTS_GET_MANY_FAIL',
  ACCOUNTS_GET_MANY_START = 'thanacare/ACCOUNTS_GET_MANY_START',
  ACCOUNTS_GET_MANY_SUCCESS = 'thanacare/ACCOUNTS_GET_MANY_SUCCESS',
  ACCOUNTS_GET_ONE_CANCEL = 'thanacare/ACCOUNTS_GET_ONE_CANCEL',
  ACCOUNTS_GET_ONE_FAIL = 'thanacare/ACCOUNTS_GET_ONE_FAIL',
  ACCOUNTS_GET_ONE_START = 'thanacare/ACCOUNTS_GET_ONE_START',
  ACCOUNTS_GET_ONE_SUCCESS = 'thanacare/ACCOUNTS_GET_ONE_SUCCESS',
  ACCOUNTS_GET_CURRENT_START = 'thanacare/ACCOUNTS_GET_CURRENT_START',
  ACCOUNTS_GET_CURRENT_FAIL = 'thanacare/ACCOUNTS_GET_CURRENT_FAIL',
  ACCOUNTS_GET_CURRENT_SUCCESS = 'thanacare/ACCOUNTS_GET_CURRENT_SUCCESS',
  ACCOUNTS_GET_CURRENT_CANCEL = 'thanacare/ACCOUNTS_GET_CURRENT_CANCEL',
  ACCOUNTS_RESET = 'thanacare/ACCOUNTS_RESET',
  ACCOUNTS_UPDATE_FAIL = 'thanacare/ACCOUNTS_UPDATE_FAIL',
  ACCOUNTS_UPDATE_START = 'thanacare/ACCOUNTS_UPDATE_START',
  ACCOUNTS_UPDATE_SUCCESS = 'thanacare/ACCOUNTS_UPDATE_SUCCESS',
  TEAM_MEETINGS_CREATE_FAIL = 'thanacare/TEAM_MEETINGS_CREATE_FAIL',
  TEAM_MEETINGS_CREATE_START = 'thanacare/TEAM_MEETINGS_CREATE_START',
  TEAM_MEETINGS_CREATE_SUCCESS = 'thanacare/TEAM_MEETINGS_CREATE_SUCCESS',
  TEAM_MEETINGS_GET_MANY_CANCEL = 'thanacare/TEAM_MEETINGS_GET_MANY_CANCEL',
  TEAM_MEETINGS_GET_MANY_FAIL = 'thanacare/TEAM_MEETINGS_GET_MANY_FAIL',
  TEAM_MEETINGS_GET_MANY_START = 'thanacare/TEAM_MEETINGS_GET_MANY_START',
  TEAM_MEETINGS_GET_MANY_SUCCESS = 'thanacare/TEAM_MEETINGS_GET_MANY_SUCCESS',
  TEAM_MEETINGS_GET_ONE_CANCEL = 'thanacare/TEAM_MEETINGS_GET_ONE_CANCEL',
  TEAM_MEETINGS_GET_ONE_FAIL = 'thanacare/TEAM_MEETINGS_GET_ONE_FAIL',
  TEAM_MEETINGS_GET_ONE_START = 'thanacare/TEAM_MEETINGS_GET_ONE_START',
  TEAM_MEETINGS_GET_ONE_SUCCESS = 'thanacare/TEAM_MEETINGS_GET_ONE_SUCCESS',
  TEAM_MEETINGS_UPDATE_FAIL = 'thanacare/TEAM_MEETINGS_UPDATE_FAIL',
  TEAM_MEETINGS_UPDATE_START = 'thanacare/TEAM_MEETINGS_UPDATE_START',
  TEAM_MEETINGS_UPDATE_SUCCESS = 'thanacare/TEAM_MEETINGS_UPDATE_SUCCESS',
  USERS_ASSIGN_TEAM_MEMBER_START = 'thanacare/USERS_ASSIGN_TEAM_MEMBER_START',
  USERS_CREATE_FAIL = 'thanacare/USERS_CREATE_FAIL',
  USERS_CREATE_START = 'thanacare/USERS_CREATE_START',
  USERS_CREATE_SUCCESS = 'thanacare/USERS_CREATE_SUCCESS',
  USERS_SIGNUP_FAIL = 'thanacare/USERS_SIGNUP_FAIL',
  USERS_SIGNUP_START = 'thanacare/USERS_SIGNUP_START',
  USERS_SIGNUP_SUCCESS = 'thanacare/USERS_SIGNUP_SUCCESS',
  USERS_GET_MANY_CANCEL = 'thanacare/USERS_GET_MANY_CANCEL',
  USERS_GET_MANY_FAIL = 'thanacare/USERS_GET_MANY_FAIL',
  USERS_GET_MANY_START = 'thanacare/USERS_GET_MANY_START',
  USERS_GET_MANY_SUCCESS = 'thanacare/USERS_GET_MANY_SUCCESS',
  USERS_GET_ONE_CANCEL = 'thanacare/USERS_GET_ONE_CANCEL',
  USERS_GET_ONE_FAIL = 'thanacare/USERS_GET_ONE_FAIL',
  USERS_GET_ONE_START = 'thanacare/USERS_GET_ONE_START',
  USERS_GET_ONE_SUCCESS = 'thanacare/USERS_GET_ONE_SUCCESS',
  USERS_INVITE_FAIL = 'thanacare/USERS_INVITE_FAIL',
  USERS_INVITE_START = 'thanacare/USERS_INVITE_START',
  USERS_INVITE_SUCCESS = 'thanacare/USERS_INVITE_SUCCESS',
  USERS_SET_CURRENT = 'thanacare/USERS_SET_CURRENT',
  USERS_UPDATE_FAIL = 'thanacare/USERS_UPDATE_FAIL',
  USERS_UPDATE_START = 'thanacare/USERS_UPDATE_START',
  USERS_UPDATE_SUCCESS = 'thanacare/USERS_UPDATE_SUCCESS',
  USERS_DELETE_FAIL = 'thanacare/USERS_DELETE_FAIL',
  USERS_DELETE_START = 'thanacare/USERS_DELETE_START',
  USERS_DELETE_SUCCESS = 'thanacare/USERS_DELETE_SUCCESS',
  USERS_DELETE_CANCEL = 'thanacare/USERS_DELETE_CANCEL',
  USERS_SET_DIRECTIVE_MEMBERID = 'thanacare/USERS_DIRECTIVE_MEMBERID',
  USERS_CLEAR_ERRORS = 'thanacare/USERS_CLEAR_ERRORS',
  USERS_GET_PROFILE_START = 'thanacare/USERS_GET_PROFILE_START',
  USERS_GET_PROFILE_SUCCESS = 'thanacare/USERS_GET_PROFILE_SUCCESS',
  USERS_GET_PROFILE_FAIL = 'thanacare/USERS_GET_PROFILE_FAIL',
  USERS_GET_PROFILE_CANCEL = 'thanacare/USERS_GET_PROFILE_CANCEL',
  USERS_UPDATE_PROFILE_START = 'thanacare/USERS_UPDATE_PROFILE_START',
  USERS_UPDATE_PROFILE_SUCCESS = 'thanacare/USERS_UPDATE_PROFILE_SUCCESS',
  USERS_UPDATE_PROFILE_FAIL = 'thanacare/USERS_UPDATE_PROFILE_FAIL',
  USERS_UPDATE_PROFILE_CANCEL = 'thanacare/USERS_UPDATE_PROFILE_CANCEL',
  USERS_RESET = 'thanacare/USERS_RESET',
}

export default ActionIds
