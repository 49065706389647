import { call, put, race, take, takeLatest } from 'redux-saga/effects'
import ActionIds from '../ActionIds'
import { BaseAction } from '../types'
import { getMany as getManyApi } from './api'

export default function* watch() {
  yield takeLatest(ActionIds.MEETING_PURPOSES_GET_MANY_START, requestMany)
}

function* requestMany({payload: params}: BaseAction) {
  const { apiResponse: {response, error}, isCanceled } = yield race({
    apiResponse: call(getManyApi, params),
    isCanceled: take(ActionIds.MEETING_PURPOSES_GET_MANY_CANCEL),
  })
  if (isCanceled) return

  if (error) {
    yield put({type: ActionIds.MEETING_PURPOSES_GET_MANY_FAIL})
    return
  }

  yield put({type: ActionIds.MEETING_PURPOSES_GET_MANY_SUCCESS, payload: response.data})
}
