import React from 'react'
import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Spacer,
  Text,
} from '@chakra-ui/react'

interface WarningModalProps extends ModalProps {
  headerText: string
  isLoading?: boolean
  onCancel?: () => void
  onConfirm: () => void
}

const WarningModal: React.FC<WarningModalProps> = (props) => {
  const {
    children,
    headerText,
    isLoading = false,
    isOpen,
    onCancel,
    onClose,
    onConfirm,
    ...otherProps
  } = props

  const parsedOnCancel = onCancel || onClose

  return (
    <Modal isOpen={isOpen || isLoading} onClose={onClose} isCentered size='xl' {...otherProps} >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{headerText}</ModalHeader>
        <ModalBody><Text>{children}</Text></ModalBody>
        <Flex direction='row' padding='24px'>
          <Button
            width='50%'
            mr='8px'
            minHeight='48px'
            variant='transparentRedOutline'
            onClick={parsedOnCancel}
          >
            Cancel
          </Button>
          <Spacer width='10%'/>
          <Button
            isLoading={isLoading}
            width='50%'
            ml='8px'
            minHeight='48px'
            colorScheme='red'
            onClick={onConfirm}
          >
            Yes
          </Button>
        </Flex>
      </ModalContent>
    </Modal>
  )
}

export default WarningModal
