import React, { useEffect, useState } from 'react'
import * as ReactRedux from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { withRouter } from 'react-router-dom'
import { Field, FieldInputProps, Form, Formik, FormikContextType } from 'formik'
import { Alert,
  AlertIcon,
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Stack,
  Text,
} from '@chakra-ui/react'
import * as Yup from 'yup'
import isEmpty from 'lodash.isempty'
import { FiEye, FiEyeOff, FiAlertTriangle } from 'react-icons/fi'
import { StoreState } from '../../redux'
import {isError} from '../../redux/AsyncState'
import { signIn } from '../../redux/auth/actions'
import {getCurrentSubdomain} from '../../utils/Routing'

enum FormFields {
  email = 'email',
  password = 'password',
}

const ValidationSchema = Yup.object().shape({
  [FormFields.email]: Yup.string().required('Must not be empty').email('Must be a valid email'),
  [FormFields.password]: Yup.string().required('Must not be empty'),
})

const initialValues = {
  [FormFields.email]: '',
  [FormFields.password]: '',
} as const

type Values = typeof initialValues[keyof typeof initialValues];
type FormType = FormikContextType<typeof initialValues>
type FieldPropType = {field: FieldInputProps<Values>; form: FormType}
type LoginFormContainerProps = RouteComponentProps<any> & { onClickForgotPassword: () => void }
type LoginFormProps = LoginFormContainerProps & { form: FormType }

// visibleForTesting
export const useHooks = ({form}: LoginFormProps) => {
  const { shallowEqual, useSelector } = ReactRedux
  const [isPasswordVisible, setPasswordVisible] = useState(false)

  const {
    isAuthenticated,
    loadingState,
  } = useSelector(({auth}: StoreState) => ({
    isAuthenticated: auth.accessToken != null,
    loadingState: auth.loadingState,
  }), shallowEqual)

  const isSignInError = isError(loadingState)

  useEffect(() => {
    if (isSignInError) {
      form.setSubmitting(false)
    }
  }, [isSignInError])

  useEffect(() => {
    if (!isAuthenticated) {
      form.setSubmitting(false)
    }
  }, [isAuthenticated])

  return {
    isPasswordVisible,
    isSignInError,
    setPasswordVisible,
  }
}

const LoginForm: React.FC<LoginFormProps> = (props) => {

  const { isPasswordVisible, isSignInError, setPasswordVisible } = useHooks(props)
  const { form, onClickForgotPassword } = props
  return (
    <>
      <Box height='0'>{isSignInError && (
        <Alert status='error'>
          <AlertIcon />
          <Center flex='1'>Incorrect email or password. Please Try again.</Center>
        </Alert>
      )}</Box>
      <Center height='100%'>
        <Form>
          <Stack align='center' spacing='20px' width='350px'>
            <Field name={FormFields.email}>
              {({ field }: FieldPropType) => (
                <FormControl isInvalid={!isEmpty(form.errors.email) && form.touched.email}>
                  <FormLabel htmlFor={field.name}>Email</FormLabel>
                  <Input {...field} id={field.name} placeholder='Email' type='email'/>
                  <FormErrorMessage><FormErrorIcon as={FiAlertTriangle} />{form.errors.email}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name={FormFields.password}>
              {({ field }: FieldPropType) => (
                <FormControl isInvalid={!isEmpty(form.errors.password) && form.touched.password}>
                  <FormLabel htmlFor={field.name}>
                    <Flex justify='space-between'>
                      <Text>Password</Text>
                      <Link onClick={onClickForgotPassword} fontWeight='bold'>Forgot Password?</Link>
                    </Flex>
                  </FormLabel>
                  <InputGroup>
                    <Input {...field} id={field.name} placeholder='Password' type={isPasswordVisible ? 'text' : 'password'}/>
                    <InputRightElement
                      _hover={{cursor: 'pointer'}}
                      onClick={() => setPasswordVisible(!isPasswordVisible)}>
                      <Icon as={isPasswordVisible ? FiEyeOff : FiEye} />
                    </InputRightElement>
                  </InputGroup>
                  <FormErrorMessage><FormErrorIcon as={FiAlertTriangle} />{form.errors.password}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Button isLoading={form.isSubmitting} type='submit' width='100%'>
              Log In
            </Button>
            { getCurrentSubdomain() === 'app' &&
                <Text>Don't have an account? <Link href='/signup'>Signup</Link></Text>
            }

          </Stack>
        </Form>
      </Center>
    </>
  )
}

const LoginFormContainer: React.FC<LoginFormContainerProps> = (props) => {
  const dispatch = ReactRedux.useDispatch()

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ValidationSchema}
      onSubmit={(values) => { dispatch(signIn(values)) }}
    >{(formik) => <LoginForm {...props} form={formik} />}</Formik>
  )
}

export default withRouter(LoginFormContainer)
