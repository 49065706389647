import ActionIds from '../../ActionIds'
import AsyncState from '../../AsyncState'
import { BaseAction } from '../../types'

const initialState = {
  loadingState: AsyncState.NOT_STARTED,
  orderedMemberIds: [] as any[], // ordered ID's of last fetch
  totalPages: undefined as unknown as number,
}

export type StateType = typeof initialState;

export default function reducer(state = initialState, action: BaseAction): StateType {
  switch (action.type) {
    case ActionIds.MEMBER_LIST_GET_DATA_START:
      return { ...state, loadingState: AsyncState.LOADING }
    case ActionIds.MEMBER_LIST_GET_DATA_SUCCESS: {
      return {
        ...state,
        loadingState: AsyncState.DONE,
        orderedMemberIds: action.payload.data.map(({id}) => id),
        totalPages: action.payload.meta.totalPages,
      }
    }
    case ActionIds.MEMBER_LIST_GET_DATA_FAIL:
      return { ...state, loadingState: AsyncState.ERROR }
    case ActionIds.MEMBER_LIST_RESET_DATA:
      return initialState
    default:
      return state
  }
}
