import React, {useCallback, useContext, useEffect, useState} from 'react'

import transform from 'lodash.transform'
import snakeCase from 'lodash.snakecase'
import {
  Alert,
  AlertIcon,
  Box,
  Center,
  SlideFade,
} from '@chakra-ui/react'
import ProgressSegments from '../../../../components/ProgressSegments'
import {white} from '../../../../constants/Colors'
import ThanksStep from '../ThanksStep'
import FormStep from './FormStep'
import {FlexForm} from './data/FlexFormModel'
import {getFlexDirective} from './getFlexDirective'
import {MemberDirectiveChildProps} from '../index'
import * as ReactRedux from 'react-redux'
import {Context as DirtyFormAlertContext} from '../../../../components/DirtyFormAlert'
import {useHistory, useParams} from 'react-router-dom'
import {
  Model as DirectiveModel,
  Types as DirectiveTypes,
} from '../../../../constants/Directive'
import {StoreState} from '../../../../redux'
import {isDone, isError} from '../../../../redux/AsyncState'
import * as RoutePaths from '../../../../constants/RoutePaths'
import {FormikContextType} from 'formik'
import {update as updateDirective} from '../../../../redux/directives/actions'
import ReviewStep from '../ReviewStep'

type FlexDirectiveStepsProps = MemberDirectiveChildProps

const useHooks = ({ goToStep, isGivingThanks, isTransitioning, onClickThanks, step }: FlexDirectiveStepsProps) => {
  const { shallowEqual, useDispatch, useSelector } = ReactRedux
  const { setDirtyFormAlert } = useContext(DirtyFormAlertContext)
  const { id } = useParams<{ id: string; type: DirectiveTypes }>()
  const dispatch = useDispatch()
  const history = useHistory()
  const [shouldQuitOnSave, setShouldQuitOnSave] = useState(false)
  const [isMounted, setIsMounted] = useState(false)

  const {
    allDirectives,
    member,
    updatingState,
  } = useSelector(({ directives, users }: StoreState) => ({
    allDirectives: directives.records,
    member: users.records[id]!,
    updatingState: directives.updatingState,
  }), shallowEqual)

  const directive = allDirectives[member.flexDirectiveId!]
  const formData: FlexForm = getFlexDirective(directive.templateId)
  const isReviewStep = step === formData.sections.length + 1
  const isUpdateDone = isDone(updatingState)
  const isUpdateError = isError(updatingState)
  const goBack = () => goToStep(step - 1)
  const goToMemberDetail = useCallback(() => history.push(RoutePaths.MemberDetail(id)), [history, id])
  // const gotToNextType = useCallback(() => goToType(DirectiveTypes.POLST, true), [goToType])
  const continueLater = (form?: FormikContextType<any>) => {
    if (form == null) return goToMemberDetail()

    setShouldQuitOnSave(true)
    form.setSubmitting(true)
    dispatch(updateDirective({payload: { ...form.values}}, directive!.id!))
  }

  useEffect(() => {
    setIsMounted(true)
  }, [])

  useEffect(() => {
    if (isUpdateError) {
      setShouldQuitOnSave(false)
    }
  }, [isUpdateError])

  useEffect(() => {
    if (!isMounted || !isUpdateDone) return

    if (shouldQuitOnSave) {
      return setDirtyFormAlert(false, () => goToMemberDetail())
    }

    if (isReviewStep) return goToMemberDetail()

    setDirtyFormAlert(false, () => goToStep(step + 1))
  }, [isUpdateDone])

  return {
    continueLater,
    directive,
    goBack,
    isGivingThanks,
    isTransitioning,
    isUpdateError,
    onClickThanks,
    step,
    stepsCompleted: member.flexDirectiveStepsCompleted || 0,
    formData,
  }
}

export interface ChildFormProps extends FlexDirectiveStepsProps {
  continueLater: (form?: FormikContextType<any>, isAppointSurrogate?: boolean) => void
  currentStep: number
  directive: DirectiveModel
  goBack: () => void
  totalSteps: number
}

const MemberFlexDirective: React.FC<MemberDirectiveChildProps> = (props) => {
  const {
    continueLater,
    directive,
    goBack,
    isGivingThanks,
    isTransitioning,
    isUpdateError,
    onClickThanks,
    step,
    stepsCompleted,
    formData,
  } = useHooks(props)

  const isReviewStep = props.step === formData.sections.length + 1

  const sharedFormProps: ChildFormProps = {
    ...props,
    continueLater,
    currentStep: step,
    directive,
    goBack,
    totalSteps: formData.sections.length + 1,
  }
  const section = formData.sections[props.step - 1]
  const savedValuesSnaked = transform(directive.questions, (result, val, key) => {
    const snaked = snakeCase(key)
    if (section?.questions?.find(q => q.id === snaked || snaked.includes(q.id)) !== undefined ||  snaked.includes('explanation') ) {
      result[snakeCase(key)] = val
    }
  }, {})
  return (
    <Box>
      {!isGivingThanks && <ProgressSegments currentStep={props.step} totalSteps={formData.sections.length + 1}/>}
      <SlideFade in={!isTransitioning}>
        <Box shadow='md' backgroundColor={white} w='100%' mb='160px'>
          <Box height='0'>{isUpdateError && (
            <Alert status='error'>
              <AlertIcon/>
              <Center flex='1'>Something went wrong. Please Try again.</Center>
            </Alert>
          )}</Box>
          <Center height='100%' padding='48px 95px'>
            {props.isGivingThanks &&
                <ThanksStep
                  buttonText='Continue to Part 2'
                  onClickContinue={onClickThanks}
                  title='Healthcare Directive Part 1 complete!'
                />
            }
            { !props.isGivingThanks && !isReviewStep &&
                <FormStep key={props.step} section={section} savedValues={savedValuesSnaked} {...sharedFormProps} stepsCompleted={stepsCompleted} />
            }
            {isReviewStep && !props.isGivingThanks &&
                <ReviewStep
                  {...sharedFormProps}
                  text='Review Healthcare Directive Part 2 to make sure it reflects patient instructions and wishes accurately before sending for signatures.'
                />
            }

          </Center>
        </Box>
      </SlideFade>
    </Box>
  )
}



export default MemberFlexDirective