import React, { useContext, useEffect, useState } from 'react'
import { Alert,
  AlertIcon,
  Box,
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Stack,
} from '@chakra-ui/react'
import ProviderInfoForm from './ProviderInfoForm'
import { Model as User} from '../../../constants/User'
import { Context as DirtyFormAlertContext } from '../../../components/DirtyFormAlert'
import { useToast } from '../../../hooks/Toast'

type BaseProps = ModalProps & { teamUserId: string; currentProvider: User }
type InviteProviderModalProps = Omit<BaseProps, 'children'>

const useHooks = ({isOpen}: InviteProviderModalProps) => {
  const toast = useToast()
  const { setDirtyFormAlert } = useContext(DirtyFormAlertContext)
  const [errorMessage, setErrorMessage] = useState<string>()

  useEffect(() => {
    if (isOpen) {
      setErrorMessage(undefined)
    } else {
      setDirtyFormAlert(false)
    }
  }, [isOpen])


  const onError = () => {
    setErrorMessage('Something went wrong. Please Try again.')
    toast({
      description: 'Advocate profile was not created; try again.',
      status: 'error',
    })
  }

  return {
    errorMessage,
    onError,
  }
}

const InviteAdvocateModal: React.FC<InviteProviderModalProps> = (props) => {
  const { errorMessage, onError } = useHooks(props)
  const { onClose, teamUserId, currentProvider } = props

  return (
    <Modal size='xl' closeOnOverlayClick={false} {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader><Center fontSize='26px' fontWeight='normal'>Add New Provider</Center></ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack align='center' spacing='20px' margin='0 24px 24px 24px'>
            <Box height='0' width='100%'>{errorMessage && (
              <Alert status='error'>
                <AlertIcon />
                <Center flex='1'>{errorMessage}</Center>
              </Alert>
            )}</Box>
            <Box>
              <Center height='100%' minH='240px'>
                <ProviderInfoForm onError={onError} onFinish={onClose} teamUserId={teamUserId} currentProvider={currentProvider} />
              </Center>
            </Box>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default InviteAdvocateModal
