import React, { useEffect } from 'react'
import * as ReactRedux from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { Link, useParams, withRouter } from 'react-router-dom'
import {
  Box,
  Button,
  Center,
  Container,
  Flex,
  Icon,
  Spacer,
  Text,
  useTheme,
} from '@chakra-ui/react'
import { FiChevronLeft, FiPlus } from 'react-icons/fi'
import { format as formatDate, parseISO } from 'date-fns'
import AccordionTableCell from './AccordionTableCell'
import PurposeTableCell from './PurposeTableCell'
import { StoreState } from '../../../redux'
import * as RoutePaths from '../../../constants/RoutePaths'
import { textPalette } from '../../../constants/Colors'
import LoadingPage from '../../../components/LoadingPage'
import Table from '../../../components/Table'
import { getData as getMemberNotesData, resetData as resetMemberNotesData } from '../../../redux/views/memberNotes/actions'
import { isDone } from '../../../redux/AsyncState'
import { DATE_FORMAT } from '../../../utils/Forms'

type MemberNotesProps = RouteComponentProps<any>

const TABLE_COLUMN_DATA = [
  { dataKey: 'lastUpdatedData', text: 'Last Updated', width: '27%' },
  { dataKey: 'advocateData', text: 'Advocate', width: '27%' },
  { dataKey: 'purposeData', text: 'Purpose', width: '46%' },
]

const useHooks = () => {
  const { shallowEqual, useDispatch, useSelector } = ReactRedux
  const dispatch = useDispatch()
  const { id: memberId } = useParams<{id: string}>()

  const {
    allLoadedUsers,
    currentUser,
    isLoadingStore,
    orderedTeamMeetings,
  } = useSelector(({
    memberNotes: { loadingState, orderedTeamMeetingIds },
    teamMeetings,
    users,
  }: StoreState) => ({
    allLoadedUsers: users.records,
    currentUser: users.currentUser,
    isLoadingStore: !isDone(loadingState, teamMeetings.loadingState, users.loadingState),
    orderedTeamMeetings: orderedTeamMeetingIds.map(id => teamMeetings.records[id]),
  }), shallowEqual)

  useEffect(() => {
    dispatch(getMemberNotesData({ memberId }))

    return () => {
      dispatch(resetMemberNotesData())
    }
  }, [])

  return {
    allLoadedUsers,
    currentUser,
    isLoadingStore,
    member: allLoadedUsers[memberId],
    teamMeetings: orderedTeamMeetings,
  }
}

const MemberNotes: React.FC<MemberNotesProps> = () => {
  const { allLoadedUsers, isLoadingStore, member, teamMeetings } = useHooks()
  const { colors: { brand } } = useTheme()

  if (member == null) return <LoadingPage/>

  const tableData = teamMeetings.map((tm) => {
    const advocate = allLoadedUsers[tm.creatorId]

    return {
      id: tm.id,
      lastUpdatedData: <Center h='100%' w='100%'><Text fontSize='16px' color={textPalette.light}>{formatDate(parseISO(tm.updatedAt), DATE_FORMAT)}</Text></Center>,
      advocateData: <Center h='100%'><Text fontSize='16px' color={textPalette.light}>{`${advocate?.firstName || ''} ${advocate?.lastName || ''}`}</Text></Center>,
      purposeData: <PurposeTableCell purposeNames={tm.meetingPurposeNames}/>,
      accordion: <AccordionTableCell teamMeetingId={tm.id}/>,
    }
  })

  return (
    <Container maxW='1100px' padding='105px 0'>
      <Box as={Link} to={RoutePaths.MemberDetail(member.id)} fontWeight={500} color={brand[500]}>
        <Icon as={FiChevronLeft} display='inline' marginRight='6px'/>
        Back
      </Box>
      <Flex align='center' h='80px' mt='48px'>
        <Text fontSize='42px' fontWeight='500' lineHeight='44px'>{member.firstName}'s Progress Notes</Text>
        <Spacer />
        <Button as={Link} to={RoutePaths.MemberNoteCreate(member.id)}>
          <Icon as={FiPlus} display='inline' marginRight='6px'/>
          New Note
        </Button>
      </Flex>
      <Box marginTop='48px'>
        <Table
          accordions
          columns={TABLE_COLUMN_DATA}
          data={tableData}
          emptyProps={{text: 'No notes created yet'}}
          isLoading={isLoadingStore}
          rowHeight='56px'
        />
      </Box>
    </Container>
  )
}

export default withRouter(MemberNotes)
