import { AppointSurrogateFormFields as FormFields } from '../../constants/Directive'
import { StoreState } from '../index'
import { ValidationSchema } from '../../routes/Authenticated/MemberDirective/HealthcarePt1DirectiveSteps/AppointSurrogateForm'

export const getAllLoadedUsers = (state: StoreState) => state.users.records

export const getMemberId = (state: StoreState) => state.users.currentUser.id

export const getDirectiveMemberId = (state: StoreState ) => state.users.directiveMemberId

export const getMember  = (state: StoreState) => {
  const memberId = getDirectiveMemberId(state)
  const allLoadedUsers = getAllLoadedUsers(state)
  if (!memberId) return null
  return allLoadedUsers[memberId]
}

export const getAppointSurrogateFormFields = (state) => {
  const allLoadedUsers = getAllLoadedUsers(state)
  const member = getMember(state)

  if (!member) return

  const sId = member.surrogateIds![0]
  const aId = member.alternateSurrogateIds![0]
  const bId = member.alternateSurrogateIds![1]

  const surrogate = allLoadedUsers[sId]
  const surrogateTeamRole = getTeamRole(surrogate, member.teamId)
  const alternateSurrogate = allLoadedUsers[aId]
  const alternateTeamRole = getTeamRole(alternateSurrogate, member.teamId)
  const secondAlternateSurrogate = allLoadedUsers[bId]
  const secondAlternateTeamRole = getTeamRole(secondAlternateSurrogate, member.teamId)
  const completePhoneNumber = '+' + (surrogateTeamRole?.countryCodePhoneNo || '1') + surrogateTeamRole?.phoneNumber
  const completeAlternatePhoneNumber = '+' + (alternateTeamRole?.countryCodePhoneNo || '1')+ alternateTeamRole?.phoneNumber
  const completeSecondAlternatePhoneNumber = '+' + (secondAlternateTeamRole?.countryCodePhoneNo || '1')+ secondAlternateTeamRole?.phoneNumber

  return {
    [FormFields.fullName]: surrogate == null ? '' : `${surrogateTeamRole?.firstName} ${surrogateTeamRole?.middleName} ${surrogateTeamRole?.lastName}`,
    [FormFields.pronouns]: surrogateTeamRole?.pronouns || '',
    [FormFields.memberRelationship]: surrogateTeamRole?.memberRelationship || '',
    [FormFields.email]: surrogateTeamRole?.email || '',
    [FormFields.phone]: completePhoneNumber || '',
    [FormFields.streetAddress]: surrogateTeamRole?.streetAddress || '',
    [FormFields.city]: surrogateTeamRole?.city || '',
    [FormFields.state]: surrogateTeamRole?.state || '',
    [FormFields.country]: surrogateTeamRole?.country || '',
    [FormFields.zipCode]: surrogateTeamRole?.zipCode || '',
    [FormFields.hasAlternate]: alternateSurrogate != null,
    [FormFields.alternateFullName]: alternateTeamRole == null ? '' : `${alternateTeamRole?.firstName} ${alternateTeamRole?.middleName} ${alternateTeamRole?.lastName}`,
    [FormFields.alternatePronouns]: alternateTeamRole?.pronouns || '' ,
    [FormFields.alternateMemberRelationship]: alternateTeamRole?.memberRelationship || '',
    [FormFields.alternateEmail]: alternateTeamRole?.email || '' ,
    [FormFields.alternatePhone]: completeAlternatePhoneNumber || '',
    [FormFields.alternateStreetAddress]: alternateTeamRole?.streetAddress || '',
    [FormFields.alternateCity]: alternateTeamRole?.city || '',
    [FormFields.alternateState]: alternateTeamRole?.state || '',
    [FormFields.alternateCountry]: alternateTeamRole?.country || '',
    [FormFields.alternateZipCode]: alternateTeamRole?.zipCode || '',
    [FormFields.hasSecondAlternate]: secondAlternateSurrogate != null,
    [FormFields.secondAlternateFullName]: secondAlternateTeamRole == null ? '' : `${secondAlternateTeamRole?.firstName} ${secondAlternateTeamRole?.middleName} ${secondAlternateTeamRole?.lastName}`,
    [FormFields.secondAlternatePronouns]: secondAlternateTeamRole?.pronouns || '' ,
    [FormFields.secondAlternateMemberRelationship]: secondAlternateTeamRole?.memberRelationship || '',
    [FormFields.secondAlternateEmail]: secondAlternateTeamRole?.email || '' ,
    [FormFields.secondAlternatePhone]: completeSecondAlternatePhoneNumber || '',
    [FormFields.secondAlternateStreetAddress]: secondAlternateTeamRole?.streetAddress || '',
    [FormFields.secondAlternateCity]: secondAlternateTeamRole?.city || '',
    [FormFields.secondAlternateState]: secondAlternateTeamRole?.state || '',
    [FormFields.secondAlternateCountry]: secondAlternateTeamRole?.country || '',
    [FormFields.secondAlternateZipCode]: secondAlternateTeamRole?.zipCode || '',
  }
}

export const getTeamRole = (user, teamId) => {
  return user?.teamRoles?.find(r => r.teamId === teamId)
}

export const getFullName = (user) => {
  if (!user) return ''
  return user.firstName + ` ${user.middleName} ${user.lastName}`
}

export const getAppointSurrogateValid = (state) => {
  const currentForm = getAppointSurrogateFormFields(state)

  try {
    ValidationSchema.validateSync(currentForm, {abortEarly: false})
  } catch (error) {
    return false
  }
  return true
}


