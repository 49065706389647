import React, {useEffect, useState, useRef, useMemo} from 'react'
import * as ReactRedux from 'react-redux'
import {
  Box,
  Button,
  FormControl,
  FormErrorIcon,
  FormErrorMessage, Icon, VStack,
} from '@chakra-ui/react'
import { update as updateUser } from '../../../../redux/users/actions'
import { uploadFile } from '../../../../redux/axios'
import {FiAlertTriangle, FiUploadCloud} from 'react-icons/fi'
import FileInput from '../../../../components/FileInput'
import {black} from '../../../../constants/Colors'
import ReactPlayer from 'react-player'

enum FormFields {
  wishesVideo = 'wishesVideo',
}

const maxFileSize = Math.pow(1024, 10)

const acceptedFileTypes = [
  'video/3gpp',
  'video/3gpp2',
  'video/mp2t',
  'video/mp4',
  'video/mpeg',
  'video/ogg',
  'video/webm',
  'video/x-msvideo',
]

type ContainerProps = { userId: string; onComplete: () => void }
type MemberWishesFormProps = ContainerProps & { onSubmit: (file: File) => void; uploadProgress: number }

const MemberWishesForm: React.FC<MemberWishesFormProps> = (props) => {
  const {
    uploadProgress,
    onSubmit,
  } = props
  const fileInputRef = useRef<HTMLInputElement>(null)
  const showFileSelect = () => {
    const currentInput = fileInputRef?.current
    if (currentInput == null) return undefined

    currentInput.click()
  }

  const [selectedFile, setSelectedFile] = useState<File>()
  const [error, setError] = useState('')

  const fileUrl = useMemo(() => {
    if (selectedFile !== undefined) {
      return URL.createObjectURL(selectedFile)
    }

  }, [selectedFile])

  useEffect(() => {
    if (selectedFile === undefined) {
      setError('')
      return
    }
    if (selectedFile.size > maxFileSize) {
      setError('file is too large')
    } else {
      setError('')
    }
  }, [selectedFile])
  return (
    <VStack h='100%' justifyContent='center' spacing={4}>
      {!selectedFile &&
          <>
            <Icon as={FiUploadCloud} w={6} h={6} />
            <Box as='span' fontSize='20px' fontWeight='500' lineHeight='24px'>
              Upload a Video
            </Box>
          </>
      }
      { selectedFile && error === '' &&
          <>
            <Box as='span' fontSize='20px' fontWeight='500' lineHeight='24px'>
              Your Video Preview
            </Box>
            <ReactPlayer
              controls
              url={fileUrl}
              style={{backgroundColor: black}}
              height='200px'
              width='66%'
            />
          </>
      }
      { selectedFile &&  error !== '' &&
          <Button onClick={() => setSelectedFile(undefined)}>Restart</Button>
      }
      <FormControl isInvalid={error !== ''} width='inherit'>
        {
          !selectedFile &&
              <Button isLoading={uploadProgress !== 0} onClick={showFileSelect} loadingText={`${uploadProgress}%`}>
                <FileInput
                  id={FormFields.wishesVideo}
                  accept={acceptedFileTypes.join(', ')}
                  onChange={ async ({file}) => {
                    setSelectedFile(file)
                  }}
                  ref={fileInputRef}
                />
                Open Finder
              </Button>
        }
        {selectedFile && error === '' &&
              <>
                <Button onClick={() => setSelectedFile(undefined)}>Cancel</Button>{' '}
                <Button isLoading={uploadProgress !== 0} onClick={() => onSubmit(selectedFile)} loadingText={`${uploadProgress}%`}>Upload</Button>
              </>
        }
        <FormErrorMessage><FormErrorIcon as={FiAlertTriangle} />{error}</FormErrorMessage>
      </FormControl>
    </VStack>
  )
}

const MemberWishesFormContainer: React.FC<ContainerProps> = (props) => {
  const dispatch = ReactRedux.useDispatch()
  const [uploadProgress, setUploadProgress] = useState(0)
  const { userId, onComplete } = props
  const onUploadProgress = ({progress}: {progress: number}) => setUploadProgress(progress)

  const onSubmit = (file) => {
    setUploadProgress(0)
    uploadFile(file, onUploadProgress).then(wishesVideoId => {
      onComplete()
      const data = {
        wishesVideo: wishesVideoId,
      }
      dispatch(updateUser(data, userId))
    })
  }

  return <MemberWishesForm {...props} onSubmit={onSubmit} uploadProgress={uploadProgress}/>
}

export default MemberWishesFormContainer
