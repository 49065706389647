import React from 'react'
import AccountSettings from '../../../components/AccountSettings'
import GeneralAdminTab from './GeneralAdminTab'
import TeamAdmin from './TeamAdminTab'
import PaymentTab from './PaymentTab'

const AccountAdminContainer: React.FC = () => {
  return (
    <AccountSettings>
      <GeneralAdminTab />
      <TeamAdmin />
      <PaymentTab />
    </AccountSettings>
  )
}

export default AccountAdminContainer
