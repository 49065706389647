import React, {useCallback, useEffect, useState} from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { useHistory, useLocation, withRouter } from 'react-router-dom'
import { StoreState } from '../../redux'
import { getMany as getAccounts } from '../../redux/accounts/actions'
import {
  Avatar,
  Box,
  // Button,
  Center,
  Container,
  Flex,
  // HStack,
  Text,
  useDisclosure,
  useTheme,
} from '@chakra-ui/react'
import NewAccountModal from './PartnerList/NewPartnerModal'
import { purples, grays, textPalette, white } from '../../constants/Colors'
import { Model as AccountModel } from '../../constants/Account'
import ListTopComponent from '../../components/ListTopComponent'
import Pagination from '../../components/Pagination'
import Table from '../../components/Table'
import {isDone, isLoading, isNotStarted} from '../../redux/AsyncState'
import * as RoutePaths from '../../constants/RoutePaths'
import isEmpty from 'lodash.isempty'
import debounce from 'lodash.debounce'
import {
  QUERY_PARAM_KEYS,
  getNewSearchString,
  getPageParam,
  getSearchTermParam,
  privateFileUrl, getSearchParams,
} from '../../utils/Routing'
import { accountNameInitialsGenerator } from '../../utils/BusinessLogic'
import {setAccountId, unsetAccountId} from '../../redux/auth/actions'
import LoadingPage from '../../components/LoadingPage'

type AccountListProps = RouteComponentProps<any>

const TABLE_COLUMN_DATA = [
  { dataKey: 'nameData', text: 'Account', width: '35%' },
  { dataKey: 'ownerData', text: 'Owner', width: '65%' },
]

interface AccountTableDataInterface {
  onClickSelect: (id: string) => void
  account: AccountModel
  themeLinkHoverBg: string
}
const tableDataForAccount = ({account, onClickSelect}: AccountTableDataInterface) => ({
  id: account.id,
  nameData: (
    <Flex>
      <Center h='64px'>
        <Avatar
          size='md'
          getInitials={accountNameInitialsGenerator}
          name={account.name}
          src={privateFileUrl(account.avatar)}
          margin='32px'
          color={white}
          bg={purples.avatar}
        />
      </Center>
      <Center h='64px'>
        <Text fontSize='16px' fontWeight='400' color={textPalette.light}>{account.name}</Text>
      </Center>
    </Flex>
  ),
  ownerData: (
    <Center h='64px' flexDirection='column' pr='20px'>
      <Box>
        <Text fontSize='16px' fontWeight='500' color={textPalette.light}>{account.owners[0]?.name}</Text>
        <Text fontSize='14px' fontWeight='400' color={textPalette.lighter}>{account.owners[0]?.email}</Text>
      </Box>
    </Center>

  ),
  onClick: () => onClickSelect(account.id),
})

function AccountList(_props: AccountListProps) {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const { colors: { brand } } = useTheme()
  const {
    isOpen: isNewAccountModalOpen,
    onClose: closeNewAccountModal,
    onOpen: openNewAccountModal,
  } = useDisclosure()
  const {
    isOpen: isInviteAdminModalOpen,
    onOpen: openInviteAdminModal,
  } = useDisclosure()
  const {
    isDoneCreating,
    isLoadingStore,
    notLoadedStore,
    lastCreatedAccount,
    orderedAccounts,
    totalPages,
    currentUser,
    currentAccount,
    currentlySetAccountId,
  } = useSelector(({ accounts, users, auth }: StoreState) => ({
    isDoneCreating: isDone(accounts.creatingState),
    isLoadingStore: isLoading(accounts.loadingState, accounts.loadingState),
    notLoadedStore: isNotStarted(accounts.loadingState),
    lastCreatedAccount: accounts.lastCreatedId == null ? null : accounts.records[accounts.lastCreatedId],
    orderedAccounts: accounts.recentOrderedPartnerIds.map(id => accounts.records[id]),
    totalPages: accounts.totalPages,
    currentUser: users.currentUser,
    currentAccount: accounts.current,
    currentlySetAccountId: auth.accountId,
  }), shallowEqual)
  const [isMounted, setIsMounted] = useState(false)
  const [selectedSubdomainName, setSelectedSubdomainName] = useState<string>()
  const debouncedDispatch = useCallback(debounce(dispatch, 300), [])
  const debouncedUpdateUrlParams = useCallback(debounce((newSearch: string, newPage: number) => history.replace({
    pathname: RoutePaths.AccountListPage(),
    search: getNewSearchString({
      [QUERY_PARAM_KEYS.PAGE]: String(newPage),
      [QUERY_PARAM_KEYS.SEARCH_TERM]: String(newSearch),
    }),
  }), 500, {leading: true}), [])

  const currentPageParam = getPageParam(location) || String(1)
  const [currentPage, setCurrentPage] = useState(parseInt(currentPageParam, 10))
  const [searchTerm, setSearchTerm] = useState(getSearchTermParam(location) || '')

  const onChangePage = (newPage: number) => setCurrentPage(newPage)
  const onChangeSearch = (newSearch: string) => setSearchTerm(newSearch)

  useEffect(() => {
    if (!isMounted) {
      setIsMounted(true)
      return
    }

    dispatch(getAccounts({
      name: isEmpty(searchTerm) ? null : searchTerm,
      page: currentPage,
    }))
  }, [currentPage, isMounted])

  useEffect(() => {
    if (isMounted && isDoneCreating) {
      onChangeSearch(lastCreatedAccount!.name)
    }
  }, [isDoneCreating, isMounted])

  useEffect(() => {
    debouncedUpdateUrlParams(searchTerm, currentPage)
    return () => debouncedUpdateUrlParams.cancel()
  }, [currentPage, searchTerm])

  useEffect(() => {
    if (selectedSubdomainName != null) {
      openInviteAdminModal()
    }
  }, [selectedSubdomainName])

  useEffect(() => {
    if (!isInviteAdminModalOpen) {
      setSelectedSubdomainName(undefined)
    }
  }, [isInviteAdminModalOpen])

  useEffect(() => {
    if (!isMounted) return

    const isSearchTermEmpty = isEmpty(searchTerm)
    if (!isSearchTermEmpty && searchTerm.length < 3) return

    onChangePage(1)
    debouncedDispatch(getAccounts({
      name: isSearchTermEmpty ? null : searchTerm,
      page: currentPage,
    }))
    return () => debouncedDispatch.cancel()
  }, [searchTerm])

  const [readyToNavigate, setReadyToNavigate] = useState(false)

  const [fromParam] = useState(getSearchParams(location)['from'])

  //user is coming from signup or login, and they only have one account... so let's send them straight member page
  useEffect(() => {
    if (fromParam === 'login_or_signup' && orderedAccounts.length === 1) {
      dispatch(setAccountId({id: orderedAccounts[0].id}))
      setReadyToNavigate(true)
    }
  }, [fromParam, orderedAccounts])

  const onClickSelect = (id: string) => {
    //need to clear it first so effects know the selection has changed (i.e. if they choose the same one)
    dispatch(unsetAccountId())
    dispatch(setAccountId({id}))
    setReadyToNavigate(true)
  }

  useEffect(() => {
    if (readyToNavigate && currentlySetAccountId !== null && !isEmpty(currentAccount) && !isEmpty(currentUser)) {
      fromParam === 'login_or_signup' ? history.replace(RoutePaths.Members()) : history.push(RoutePaths.Members())
    }
  }, [currentlySetAccountId, currentUser, currentAccount, readyToNavigate])

  const tableData = orderedAccounts.map(p => tableDataForAccount({
    onClickSelect,
    account: p,
    themeLinkHoverBg: brand[600],
  }))

  if (notLoadedStore) {
    return <LoadingPage />
  }

  return (
    <Container maxW='1100px' mt={20} mb='10vh'>
      <NewAccountModal isOpen={isNewAccountModalOpen} onClose={closeNewAccountModal}/>
      <ListTopComponent onClickNew={openNewAccountModal} modelName='Account' onSearchUpdate={onChangeSearch} searchTerm={searchTerm}/>
      <Box shadow='lg' backgroundColor='white' border='1px solid' borderColor={grays.light}>
        <Table
          actionIcons={true}
          columns={TABLE_COLUMN_DATA}
          data={tableData}
          emptyProps={{margin: '0', text: 'No results found, refine search and try again'}}
          headerProps={{background: grays.input, margin: '0'}}
          isLoading={isLoadingStore}
          rowHeight={'64px'}
        />
      </Box>
      <Pagination currentPage={currentPage} onPageChange={onChangePage} totalPages={totalPages}/>
    </Container>
  )
}

export default withRouter(AccountList)
