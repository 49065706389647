import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Beforeunload } from 'react-beforeunload'

const ALERT_TEXT = 'You have unsaved work. Are you sure you want to leave?'

type ContextValue = { isDirty: boolean; blockSearchChanges?: boolean }
interface IContext {
  setDirtyFormAlert: (newValue: boolean | ContextValue, callback?: () => void) => void
}

const useHooks = () => {
  const location = useLocation()
  const currentPathname = location.pathname
  const [lastPathname, setlastPathname] = useState(currentPathname)
  const [isDirty, setIsDirty] = useState(false)
  const history = useHistory()
  const [unregisterBlocker, setUnregisterBlocker] = useState<ReturnType<typeof history.block>>()

  const setDirtyFormAlert = (newValue: boolean | ContextValue, callback?) => {
    const isBooleanValue = typeof newValue === 'boolean'
    const isNewlyDirty = isBooleanValue ? newValue as boolean : (newValue as ContextValue).isDirty
    const blockSearchChanges = isBooleanValue ? false : !!(newValue as ContextValue).blockSearchChanges

    setIsDirty(isNewlyDirty)
    if (isNewlyDirty) {
      // set up history blocker to handle path changing within our app
      const newBlocker = history.block(({ pathname }) => {
        if (lastPathname !== pathname || blockSearchChanges) return ALERT_TEXT
      })
      setUnregisterBlocker(() => newBlocker)
    } else {
      reset()
    }

    callback && callback()
  }

  const currentContext = { setDirtyFormAlert }

  const reset = () => {
    unregisterBlocker && unregisterBlocker()
    setUnregisterBlocker(undefined)
  }

  useEffect(() => {
    setlastPathname(currentPathname)
    setDirtyFormAlert(false)
  }, [currentPathname])

  const onBeforeunload = () => {
    // handle browser navigation outside of our app
    if (isDirty) {
      // most browsers display their own message, but we supply one in case it's supported
      return ALERT_TEXT
    }
  }

  return {
    currentContext,
    onBeforeunload,
  }
}

export const Context = React.createContext<IContext>({
  setDirtyFormAlert: () => null,
})

const DirtyFormAlertProvider: React.FC<React.PropsWithChildren<unknown>> = ({children}) => {
  const {
    currentContext,
    onBeforeunload,
  } = useHooks()
  return (
    <Context.Provider value={currentContext}>
      <Beforeunload onBeforeunload={onBeforeunload}>
        {children}
      </Beforeunload>
    </Context.Provider>
  )
}

export default DirtyFormAlertProvider
