import React, {useEffect, useState} from 'react'
import * as ReactRedux from 'react-redux'
import {RouteComponentProps} from 'react-router'

import {grays, white} from '../../constants/Colors'
import {
  Box,
  Center,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormErrorIcon,
  Stack,
  Text,
  Input,
  InputGroup,
  InputRightElement,
  Icon,
  Button, Link,
} from '@chakra-ui/react'
import * as Yup from 'yup'
import {Field, FieldInputProps, Form, Formik, FormikContextType} from 'formik'
import { FiAlertTriangle, FiEye, FiEyeOff } from 'react-icons/fi'
import isEmpty from 'lodash.isempty'
import {signup} from '../../redux/users/actions'
import {userNameAttrsFromFullName} from '../../utils/Forms'
import {useSelector} from 'react-redux'
import {StoreState} from '../../redux'
import {useToast} from '../../hooks/Toast'
import * as RoutePaths from '../../constants/RoutePaths'
import {getCurrentSubdomain} from '../../utils/Routing'
import {useHistory} from 'react-router-dom'

enum FormFields {
  name = 'name',
  email = 'email',
  password = 'password',
}

const ValidationSchema = Yup.object().shape({
  [FormFields.name]: Yup.string().required('Must not be empty'),
  [FormFields.email]: Yup.string().required('Must not be empty').email('Must be a valid email'),
  [FormFields.password]: Yup.string().required('Must not be empty'),
})

const initialValues = {
  [FormFields.name]: '',
  [FormFields.email]: '',
  [FormFields.password]: '',
} as const

type Values = typeof initialValues[keyof typeof initialValues];
type FormType = FormikContextType<typeof initialValues>
type FieldPropType = {field: FieldInputProps<Values>; form: FormType}
type LoginFormContainerProps = RouteComponentProps<any>
type LoginFormProps = LoginFormContainerProps & { form: FormType; submitError: string }
const SignupForm: React.FC<LoginFormProps> = (props) => {
  const { form, submitError } = props
  const toast = useToast()
  useEffect(() => {
    if (submitError !== null) {
      form.setSubmitting(false)
      toast({
        description: submitError,
        status: 'error',
      })
    }
  }, [submitError])
  const [isPasswordVisible, setPasswordVisible] = useState(false)
  // @ts-ignore
  return (
    <Center height='100%' bg={grays.background}>
      <Stack align='center' spacing='20px'>
        <Text fontSize='42px' fontWeight='500'>Sign Up</Text>
        <Box shadow='md' backgroundColor={white} h='500px' w='730px'>

          <Center height='100%'>
            <Form>
              <Stack align='center' spacing='20px' width='350px'>
                <Field name={FormFields.name}>
                  {({ field }: FieldPropType) => (
                    <FormControl isInvalid={!isEmpty(form.errors.name) && form.touched.name}>
                      <FormLabel htmlFor={field.name}>Name</FormLabel>
                      <Input {...field} id={field.name} placeholder='Name' type='name'/>
                      <FormErrorMessage><FormErrorIcon as={FiAlertTriangle} />{form.errors.email}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name={FormFields.email}>
                  {({ field }: FieldPropType) => (
                    <FormControl isInvalid={!isEmpty(form.errors.email) && form.touched.email}>
                      <FormLabel htmlFor={field.name}>Email</FormLabel>
                      <Input {...field} id={field.name} placeholder='Email' type='email'/>
                      <FormErrorMessage><FormErrorIcon as={FiAlertTriangle} />{form.errors.email}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name={FormFields.password}>
                  {({ field }: FieldPropType) => (
                    <FormControl isInvalid={!isEmpty(form.errors.password) && form.touched.password}>
                      <FormLabel htmlFor={field.name}>
                        <Text>Password</Text>
                      </FormLabel>
                      <InputGroup>
                        <Input {...field} id={field.name} placeholder='Password' type={isPasswordVisible ? 'text' : 'password'}/>
                        <InputRightElement
                          _hover={{cursor: 'pointer'}}
                          onClick={() => setPasswordVisible(!isPasswordVisible)}>
                          <Icon as={isPasswordVisible ? FiEyeOff : FiEye} />
                        </InputRightElement>
                      </InputGroup>
                      <FormErrorMessage><FormErrorIcon as={FiAlertTriangle} />{form.errors.password}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Text>By creating an account with Thanacare LLC, you are agreeing to our <Link target='_blank' href='/p/privacy_and_terms'>Privacy Policy and Terms of Service</Link></Text>
                <Button isLoading={form.isSubmitting} type='submit' width='100%'>
                  Create Account
                </Button>
                <Text>Already have an account? <Link href='/login'>Login</Link></Text>
              </Stack>
            </Form>

          </Center>

        </Box>
      </Stack>
    </Center>
  )
}

const SignupContainer: React.FC<LoginFormContainerProps> = (props) => {
  const history = useHistory()
  if (getCurrentSubdomain() !== 'app') {
    history.replace(RoutePaths.Login())
  }
  const { errorData } = useSelector(({users}: StoreState) => ({
    errorData: users.errorData,
  }))
  const dispatch = ReactRedux.useDispatch()

  const doSignup = values => {
    const { email, password, name } = values
    const valsToSend = {email, password, ...userNameAttrsFromFullName(name)}
    dispatch(signup(valsToSend))
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ValidationSchema}
      onSubmit={doSignup}
    >{(formik) => <SignupForm {...props} form={formik} submitError={errorData as string} />}</Formik>
  )
}


export default SignupContainer