import React  from 'react'
import { RouteComponentProps } from 'react-router'
import { withRouter } from 'react-router-dom'
import {
  Box,
  Button,
  Center,
  Stack,
  Text,
} from '@chakra-ui/react'
import SecurityLock from '../../components/Images/SecurityLock'

type ThanksProps = RouteComponentProps<any> & { onClickResend: () => void }

const Thanks: React.FC<ThanksProps> = (props) => {
  const { onClickResend } = props

  return (
    <Center height='100%'>
      <Stack align='center' spacing='20px' width='350px'>
        <Box as={SecurityLock} />
        <Text fontSize='42px'>Thanks!</Text>
        <Text textAlign='center'>Check your email for a password reset link.</Text>
        <Button width='full' onClick={onClickResend}>
          Resend Email
        </Button>
      </Stack>
    </Center>
  )
}

export default withRouter(Thanks)
