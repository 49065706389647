import React, { useCallback, useState } from 'react'
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { Button, useToast } from '@chakra-ui/react'
import { axiosAuthed } from '../../../../redux/axios'
import { PaymentIntentResult, SetupIntentResult } from '@stripe/stripe-js'


const PaymentForm: React.FC<{onSuccess: () => void}> = ({onSuccess}) => {
  const stripe = useStripe()
  const elements = useElements()
  const toast = useToast()

  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(async (e) => {
    e.preventDefault()

    if (isSubmitting || !stripe || !elements) return

    setIsSubmitting(true)

    try {
      const elementsRes = await elements.submit()

      if (elementsRes.error) {
        throw elementsRes.error
      }

      const subscriptionResponse = await axiosAuthed({ method: 'post' }, '/stripe_subscriptions')

      if (subscriptionResponse.status !== 200) {
        throw new Error(subscriptionResponse.data)
      }

      const { type, stripeClientSecret } = subscriptionResponse.data

      let confirmResult: SetupIntentResult | PaymentIntentResult

      if (type === 'setup') {
        confirmResult = await stripe.confirmSetup({
          elements,
          redirect: 'if_required',
          clientSecret: stripeClientSecret,
        })
      } else if (type === 'payment') {
        confirmResult = await stripe.confirmPayment({
          elements,
          redirect: 'if_required',
          clientSecret: stripeClientSecret,
        })
      } else {
        throw new Error('Unexpected subscription type from server')
      }

      if (confirmResult.error) {
        throw confirmResult.error
      } else {
        onSuccess()
      }

    } catch (err) {
      console.error(err)
      toast({
        title: 'Error',
        description: (err as Error).message || 'Something went wrong. Please try again or contact support if the problem persists.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }

    setIsSubmitting(false)
  }, [onSuccess, stripe, elements, isSubmitting])

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <Button
        type='submit'
        borderRadius='3px'
        fontSize='16px'
        lineHeight='24px'
        marginTop='24px'
        marginBottom='24px'
        disabled={isSubmitting}
      >
        Submit
      </Button>
    </form>
  )
}

export default PaymentForm
