import React from 'react'
import {
  Code,
  Heading,
  Link,
  Text,
} from '@chakra-ui/layout'
import { Image } from '@chakra-ui/image'
import { Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/table'
import { chakra } from '@chakra-ui/system'

type GetCoreProps = {
  children?: React.ReactNode
  'data-sourcepos'?: any
};

function getCoreProps(props: GetCoreProps): any {
  return props['data-sourcepos']
    ? { 'data-sourcepos': props['data-sourcepos'] }
    : {}
}

const theme = {
  p: props => {
    const { children } = props
    return <Text mb={2} width='100%'>{children}</Text>
  },
  em: props => {
    const { children } = props
    return <Text as='em'>{children}</Text>
  },
  blockquote: props => {
    const { children } = props
    return (
      <Code as='blockquote' p={2}>
        {children}
      </Code>
    )
  },
  code: props => {
    const { inline, children, className } = props

    if (inline) {
      return <Code p={2} children={children} />
    }

    return (
      <Code
        className={className}
        whiteSpace='break-spaces'
        display='block'
        w='full'
        p={2}
        children={children}
      />
    )
  },
  del: props => {
    const { children } = props
    return <Text as='del'>{children}</Text>
  },
  a: Link,
  img: Image,
  text: props => {
    const { children } = props
    return <Text as='span'>{children}</Text>
  },
  h1: props => {
    const { children } = props
    return (
      <Heading
        width='100%'
        as='h1'
        size='2xl'
      >
        {children}
      </Heading>
    )
  },
  h2: props => {
    const { children } = props
    return (
      <Heading
        width='100%'
        as='h2'
        size='xl'
      >
        {children}
      </Heading>
    )
  },
  h3: props => {
    const { children } = props
    return (
      <Heading
        width='100%'
        as='h3'
        size='lg'
      >
        {children}
      </Heading>
    )
  },
  h4: props => {
    const { children } = props
    return (
      <Heading
        width='100%'
        as='h4'
        size='md'
      >
        {children}
      </Heading>
    )
  },
  h5: props => {
    const { children } = props
    return (
      <Heading
        width='100%'
        as='h5'
        size='sm'
      >
        {children}
      </Heading>
    )
  },
  pre: props => {
    const { children } = props
    return <chakra.pre {...getCoreProps(props)}>{children}</chakra.pre>
  },
  table: Table,
  thead: Thead,
  tbody: Tbody,
  tr: props => <Tr>{props.children}</Tr>,
  td: props => <Td>{props.children}</Td>,
  th: props => <Th>{props.children}</Th>,
}

export default theme