import React, {useEffect, useState} from 'react'
import {Tab, Tabs, TabPanel, TabPanels, Text, TabList, Container} from '@chakra-ui/react'
import {Link} from 'react-router-dom'
import LoadingPage from './LoadingPage'
import {isCurrentPath} from '../utils/Routing'
import {AccountAdminPage, AccountAdminUsersPage, AccountAdminPaymentPage} from '../constants/RoutePaths'
import {useLocation} from 'react-router-dom'

interface AccountSettingProps {
  children: JSX.Element[]
}
const AccountSettings: React.FC<AccountSettingProps> = ({children}) => {
  const location = useLocation()
  const [currentTabSelection, setCurrentTabSelection] = useState<number>()
  useEffect(() => {
    if (isCurrentPath(AccountAdminPage(), location)) {
      setCurrentTabSelection(0)
    }
    if (isCurrentPath(AccountAdminUsersPage(), location)) {
      setCurrentTabSelection(1)
    }
    if (isCurrentPath(AccountAdminPaymentPage(), location)) {
      setCurrentTabSelection(2)
    }
  }, [location.pathname])
  if (currentTabSelection === null) {
    return <LoadingPage />
  }
  return (
    <Container maxW='1100px' mt={20} mb='10vh'>
      <Text fontSize='42px' fontWeight='500'>
          Account Management
      </Text>
      <Tabs isLazy index={currentTabSelection}>
        <TabList>
          <Tab as={Link} to={AccountAdminPage()}>General</Tab>
          <Tab as={Link} to={AccountAdminUsersPage()}>Team</Tab>
          <Tab as={Link} to={AccountAdminPaymentPage()}>Payment</Tab>
        </TabList>
        <TabPanels>
          {children.map(c => <TabPanel>{c}</TabPanel>)}
        </TabPanels>

      </Tabs>
    </Container>

  )
}

export default AccountSettings