import React, { useCallback, useEffect, useState } from 'react'
import { Link, useParams, useHistory } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { StoreState } from '../../../redux'
import {getMany as getUsers} from '../../../redux/users/actions'
import {deleteOne as deleteOneUser} from '../../../redux/users/actions'
import {Types as DirectiveTypes, Statuses as DirectiveStatuses, Statuses, TemplateNames} from '../../../constants/Directive'
import * as RoutePaths from '../../../constants/RoutePaths'
import isEmpty from 'lodash.isempty'
import { grays, white, purples, textPalette } from '../../../constants/Colors'
import {
  Avatar,
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Icon,
  Spacer,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react'
import { FiXCircle, FiChevronLeft, FiChevronRight, FiUpload } from 'react-icons/fi'
import { AiOutlineQrcode } from 'react-icons/ai'
import { format as formatDate, parseISO } from 'date-fns'
import SendButton from './SendButton'
import ShareDirectivesModal from './ShareDirectivesModal'
import DirectiveStatusText from './DirectiveStatusText'
import PrintModal from './PrintModal'
import GeneralInfo from '../../../components/Images/GeneralInfo'
import Polst from '../../../components/Images/Polst'
import Surrogate from '../../../components/Images/Surrogate'
import Will from '../../../components/Images/Will'
import { DATE_FORMAT, parsePhoneNumber } from '../../../utils/Forms'
import { isDone, isError, isLoading } from '../../../redux/AsyncState'
import { useToast } from '../../../hooks/Toast'
import { privateFileUrl } from '../../../utils/Routing'
import LoadingPage from '../../../components/LoadingPage'
import ProgressSegments from '../../../components/ProgressSegments'
import WarningModal from '../../../components/WarningModal'
import { TeamStatuses, directiveStatus, teamStatus } from '../../../utils/BusinessLogic'
import Printer from '../../../components/Images/Printer'
import { sendPolstToDocusign } from '../../../redux/directives/actions'
import Roles from '../../../constants/Roles'
import { getFullName, getTeamRole } from '../../../redux/directives/selector'

const BOX_BORDER = `1px solid ${grays.light}`
const PROGRESS_BARS_MAP = {
  [TeamStatuses.IN_PROGRESS]: 0,
  [TeamStatuses.READY_TO_SEND]: 1,
  [TeamStatuses.SENT]: 2,
  [TeamStatuses.READY_TO_RESEND]: 2,
  [TeamStatuses.COMPLETED]: 3,
} as const
const TOTAL_PROGRESS_BARS = Object.values(PROGRESS_BARS_MAP).sort().reverse()[0]
const PROGRESS_TITLE_MAP = {
  [TeamStatuses.SENT]: 'Awaiting signatures',
  [TeamStatuses.COMPLETED]: 'Healthcare Directive complete & signed 🎉',
  [TeamStatuses.IN_PROGRESS]: 'Healthcare Directive in progress',
  [TeamStatuses.READY_TO_RESEND]: 'Healthcare Directive updated',
  [TeamStatuses.READY_TO_SEND]: 'Healthcare Directive complete',
}

type TeamStatusesType = typeof TeamStatuses
const progressSubtext = (status: TeamStatusesType[keyof TeamStatusesType]) => {
  switch(status) {
    case TeamStatuses.SENT: return (
      <HStack>
        <Text>Member signs with Two Witnesses </Text>
      </HStack>
    )
    case TeamStatuses.COMPLETED: return (
      <HStack>
        <Text>Directive signed by all parties </Text>
      </HStack>
    )
    case TeamStatuses.IN_PROGRESS: return 'Select a section below to continue'
    case TeamStatuses.READY_TO_RESEND: return 'Resend to Docusign to collect signatures'
    case TeamStatuses.READY_TO_SEND: return 'Send to Docusign to collect signatures'
    default: return null
  }
}

function MemberDetail() {
  const dispatch = useDispatch()
  const toast = useToast()
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure()
  const {
    isOpen: isShareModalOpen,
    onOpen: onShareOpen,
    onClose: onShareModalClose,
  } = useDisclosure()
  const {
    isOpen: isPrintModalOpen,
    onOpen: onPrintOpen,
    onClose: onPrintModalClose,
  } = useDisclosure()
  const history = useHistory()
  const { id } = useParams<{id: string}>()
  const {
    currentUser,
    allusers,
    deletingState,
    sendingState,
    polstState,
    memberLeadName,
  } = useSelector(({ directives, users, accounts }: StoreState) => ({
    currentUser: users.currentUser,
    allusers: users.records,
    deletingState: users.deletingState,
    sendingState: directives.sendingState,
    polstState: directives.polstState,
    memberLeadName: accounts.current?.memberLeadName,
  }), shallowEqual)

  const [hasClickedDelete, sethasClickedDelete] = useState(false)
  const [hasClickedSendPolst, setHasClickedSendPolst] = useState(false)

  const isDeleting = isLoading(deletingState)
  const isDeletingError = isError(deletingState)
  const isDeletingDone = isDone(deletingState)
  const isSending = isLoading(sendingState)
  const isPolstError = isError(polstState)
  const isPolstDone = isDone(polstState)

  const deleteUser = (userId: string) => {
    sethasClickedDelete(true)
    dispatch(deleteOneUser(userId))
  }

  const member = allusers[id]
  const advocates = member == null ? [] : member.advocateIds!.map((aId) => allusers[aId])
  const primaryCareProviders = member == null ? [] : member.primaryCareProviderIds!.map((pcpId) => allusers[pcpId])
  const surrogates = member == null ? [] : member.surrogateIds!.concat(member.alternateSurrogateIds!).map((sId) => allusers[sId])
  const teamRoles = member == null ? [] : surrogates.map((surrogate) => getTeamRole(surrogate, member.teamId))
  const { incompleteDirectiveTypes, status } = teamStatus(member)
  const nextDirectiveTypeToComplete = incompleteDirectiveTypes[0]
  const send = useCallback(() => {
    dispatch(sendPolstToDocusign(member.id, Roles.MEMBER, TemplateNames.POLST))
  }, [dispatch, member])


  const sendPolstDirectiveEmail = (e: React.MouseEvent<SVGSVGElement>) => {
    e.stopPropagation()
    send()
    setHasClickedSendPolst(true)
  }

  useEffect(() => {
    if (id != null) {
      dispatch(getUsers({teamUserId: id}))
    }
  }, [id])

  useEffect(() => {
    if (isPolstError && hasClickedSendPolst) {
      toast({
        description: 'There has been an error submitting your Polst form',
        status: 'error',
        width: '550px',
      })
    } else if (isPolstDone && hasClickedSendPolst) {
      toast({
        description: 'Polst Successfully Submitted ',
        status: 'success',
        width: '500px',
      })
    }
  }, [isPolstDone, isPolstError])


  useEffect(() => {
    if (isDeletingError && hasClickedDelete) {
      toast({
        description: `${member?.firstName} ${member?.lastName} could not be deleted; try again`,
        status: 'error',
        width: '550px',
      })
    } else if (isDeletingDone && hasClickedDelete) {
      toast({
        description: `${member?.firstName} ${member?.lastName} was successfully deleted.`,
        status: 'success',
        width: '500px',
      })
      history.push(RoutePaths.Members())
    }
  }, [isDeletingDone, isDeletingError])

  if (member == null || isDeleting) return <LoadingPage/>

  return (
    <>
      <Box width='100%' backgroundColor={grays.background}>
        <Box minHeight='296px' minWidth='1440px' maxWidth='1600px' p='80px 105px 49px 105px' textAlign='left' marginLeft='auto' marginRight='auto'>
          <Box>
            <Link to={RoutePaths.Members}>
              <Icon as={FiChevronLeft} display='inline' marginRight='6px'/>
              Back
            </Link>
          </Box>
          <Flex marginTop='24px' alignItems='top' width='100%'>
            <Avatar
              bg={purples.avatar}
              color={white}
              height='120px'
              marginRight='24px'
              marginTop='10px'
              name={member.firstName + ` ${member.lastName}`}
              size='xl'
              src={privateFileUrl(member.avatar)}
              width='120px'
            />
            <Stack display='flex'>
              <Text fontSize='42px' fontWeight='500' lineHeight='44px'>{member.firstName} {member.lastName}</Text>
              <Text fontSize='16px' fontWeight='400'>{member.phoneNumber ? parsePhoneNumber(member.phoneNumber) : 'Not Provided'}</Text>
              <Text fontSize='16px' fontWeight='400' mb='20px'>{member.email}</Text>
              {(currentUser.isAdmin) ?
                <Button
                  mr={4}
                  borderRadius='3px'
                  fontSize='16px'
                  lineHeight='24px'
                  variant='transparentRedOutline'
                  leftIcon={<FiXCircle />}
                  onClick={onDeleteOpen}
                >
                  Remove Member
                </Button> : null }
            </Stack>
            <Spacer/>
            {!(member.finalPdfSecretGeneratedAt == null || member.finalPdfQrCodeUrl == null) &&
            <Box pt='20px'>
              <Tooltip label='Please complete & sign remaining directive(s)' isDisabled={member.finalPdfQrCodeUrl != null}>
                <span>{/* span needed to allow tooltip when button is disabled  */}

                  <Button
                    mr={4}
                    borderRadius='3px'
                    disabled={member.finalPdfQrCodeUrl == null}
                    fontSize='16px'
                    lineHeight='24px'
                    variant='purpleOutline'
                    leftIcon={<AiOutlineQrcode />}
                    onClick={onPrintOpen}>
                        Print ID Card
                  </Button>
                </span>
              </Tooltip>
              <Tooltip label='Please complete & sign remaining directive(s)' isDisabled={member.finalPdfQrCodeUrl != null}>
                <span>{/* span needed to allow tooltip when button is disabled  */}
                  <Button fontSize='16px' onClick={onShareOpen} disabled={member.finalPdfQrCodeUrl == null}>
                    <Icon as={FiUpload} marginRight='8px'/>
                    Share
                  </Button>
                </span>
              </Tooltip>
            </Box>
            }
          </Flex>
        </Box>

        <WarningModal
          headerText={`Are you sure you want to delete ${member.firstName} ${member.lastName}?`}
          isOpen={isDeleteOpen}
          onClose={onDeleteClose}
          onConfirm={() => deleteUser(member.id)}
        >
          Deleting a User cannot be undone.
        </WarningModal>
        <ShareDirectivesModal isOpen={isShareModalOpen} onClose={onShareModalClose} />
        <PrintModal isOpen={isPrintModalOpen} memberId={member.id} onClose={onPrintModalClose} />
      </Box>
      <Divider color={grays[900]} />
      <Box width='100%' backgroundColor={grays[200]}>
        <Box display='flex' flexDirection='row' minWidth='1440px' maxWidth='1600px' padding='72px 165px 92px 165px' marginLeft='auto' marginRight='auto'>
          <Stack>
            <Box width='730px' height='116px' bg={grays.background} border='1px solid #C1C7D0' p='2px'>
              <ProgressSegments currentStep={PROGRESS_BARS_MAP[status]} totalSteps={TOTAL_PROGRESS_BARS}/>
              <Flex padding='20px 48px 0 32px' align='left' alignItems='center'>
                <Box marginRight='auto'>
                  <Text fontSize='20px' fontWeight='500' lineHeight='24px'>
                    {PROGRESS_TITLE_MAP[status]}
                  </Text>
                  <Text>
                    {progressSubtext(status)}
                  </Text>
                </Box>
                {(() => {
                  switch(status) {
                    case TeamStatuses.SENT: return (
                      <SendButton
                        isSending={isSending}
                        member={member}
                        isNew={false}
                      >
                        Continue Authorization
                      </SendButton>
                    )
                    case TeamStatuses.READY_TO_SEND: return (
                      <SendButton
                        isSending={isSending}
                        member={member}
                        isNew
                      >
                        Begin Authorization
                      </SendButton>
                    )
                    case TeamStatuses.IN_PROGRESS: return (
                      <Button as={Link} to={RoutePaths.MemberDirective(member.id, nextDirectiveTypeToComplete)}>
                        Continue
                      </Button>
                    )
                    default: return null
                  }
                })()}
              </Flex>
            </Box>
            <Box as='button' width='730px' height='116px' display='flex' flexDirection='row' border={BOX_BORDER} marginTop='16px' alignItems='center' padding='24px 32px'
              onClick={() => history.push(RoutePaths.GeneralInformation(member.id))} >
              <Stack height='100%' display='flex' alignItems='center' marginRight='24px'>
                <Box as={GeneralInfo} marginTop='auto' marginBottom='auto'/>
              </Stack>
              <Stack width='480px' textAlign='left'>
                <Text fontSize='20px' fontWeight='500' lineHeight='24px'>
                General Information
                </Text>
                <Text>View and edit general information</Text>
              </Stack>
              <DirectiveStatusText status={DirectiveStatuses.COMPLETED}/>
              <Icon as={FiChevronRight} />
            </Box>
            <Box as='button' width='730px' height='116px' display='flex' flexDirection='row' border={BOX_BORDER} marginTop='16px' alignItems='center' padding='24px 32px'
              onClick={() => history.push(RoutePaths.MemberDirective(member.id, DirectiveTypes.HEALTHCARE_PT1_DIRECTIVE))}
            >
              <Stack height='100%' display='flex' alignItems='center' marginRight='24px'>
                <Box as={Surrogate} marginTop='auto' marginBottom='auto'/>
              </Stack>
              <Stack width='480px' textAlign='left'>
                <Text fontSize='20px' fontWeight='500' lineHeight='24px'>
                  Healthcare Directive Part 1
                </Text>
                <Text>Naming my Healthcare Agent</Text>
              </Stack>
              <DirectiveStatusText status={directiveStatus({stepsCompleted: member.healthcarePt1DirectiveStepsCompleted!, type: DirectiveTypes.HEALTHCARE_PT1_DIRECTIVE})}/>
              <Icon as={FiChevronRight} />
            </Box>
            <Box as='button' width='730px' height='116px' display='flex' flexDirection='row' border={BOX_BORDER} marginTop='16px' alignItems='center' padding='24px 32px'
              onClick={() => history.push(RoutePaths.MemberDirective(member.id, DirectiveTypes.HEALTHCARE_FLEX_DIRECTIVE))}
            >
              <Stack height='100%' display='flex' alignItems='center' marginRight='24px'>
                <Box as={Will} marginTop='auto' marginBottom='auto'/>
              </Stack>
              <Stack width='480px' textAlign='left'>
                <Text fontSize='20px' fontWeight='500' lineHeight='24px'>
                  Healthcare Directive Part 2
                </Text>
                <Text>Healthcare instructions and wishes</Text>
              </Stack>
              <DirectiveStatusText status={directiveStatus({stepsCompleted: member.flexDirectiveStepsCompleted!, type: DirectiveTypes.HEALTHCARE_FLEX_DIRECTIVE})}/>
              <Icon as={FiChevronRight} />
            </Box>
            <Box
              borderTop={`1px solid ${grays.image}`}
              height='40px'
              paddingBottom='30px'
              paddingTop='20px'
              width='100%'>
              <Text
                color={textPalette.light}
                fontSize='16px'
                fontWeight='500'>
                Optional
              </Text>
            </Box>
            { process.env.NODE_ENV !== 'production' &&
                <Box as='button' width='730px' height='116px' display='flex' flexDirection='row' border={BOX_BORDER} marginTop='16px' alignItems='center' padding='24px 32px'
                  onClick={() => history.push(RoutePaths.MemberDirective(member.id, DirectiveTypes.POLST))}
                >
                  <Stack height='100%' display='flex' alignItems='center' marginRight='24px'>
                    <Box as={Polst} marginTop='auto' marginBottom='auto'/>
                  </Stack>
                  <Stack width='432px' textAlign='left'>
                    <Text fontSize='20px' fontWeight='500' lineHeight='24px'>
                      POLST
                    </Text>
                    <Text>Recuscitation and intubation orders</Text>
                  </Stack>
                  <Stack>
                    {directiveStatus({stepsCompleted: member.polstDirectiveStepsCompleted!, type: DirectiveTypes.POLST}) === Statuses.COMPLETED ?
                      <Box as={Printer} marginRight='24px' onClick={(e: React.MouseEvent<SVGSVGElement, MouseEvent> & React.MouseEvent<HTMLDivElement, MouseEvent>) => sendPolstDirectiveEmail(e)} /> : <Box width='48px' />}
                  </Stack>
                  <DirectiveStatusText status={directiveStatus({stepsCompleted: member.polstDirectiveStepsCompleted!, type: DirectiveTypes.POLST})}/>
                  <Icon as={FiChevronRight} />
                </Box>
            }
            <Box as='button' width='730px' height='116px' display='flex' flexDirection='row' border={BOX_BORDER} marginTop='16px' alignItems='center' padding='24px 32px'
              onClick={() => history.push(RoutePaths.MemberDirective(member.id, 'VideoWishes'))}
            >
              <Stack height='100%' display='flex' alignItems='center' marginRight='24px'>
                <Box as={Will} marginTop='auto' marginBottom='auto'/>
              </Stack>
              <Stack width='480px' textAlign='left'>
                <Text fontSize='20px' fontWeight='500' lineHeight='24px'>
                  Video Wishes
                </Text>
                <Text>Video wishes for my care team</Text>
              </Stack>
              <DirectiveStatusText status={member.wishesVideoStatus!}/>
              <Icon as={FiChevronRight} />
            </Box>
          </Stack>
          <Stack marginLeft='auto'>
            <Box padding='24px 32px' width='350px' height='fit-content' border={BOX_BORDER} textAlign='left' backgroundColor='white'>
              <Button
                width='100%' background='none' color={textPalette.normal}
                _hover={{bg: 'none'}}
                _active={{bg: 'none'}}
                onClick={() => history.push(RoutePaths.MemberNotes(member.id))}
                padding='0'
              >
                <Box as='span' fontSize='20px' fontWeight='500' lineHeight='24px'>
                Progress Notes
                </Box>
                <Icon as={FiChevronRight} marginLeft='auto'/>
              </Button>
              <Flex>
                <Text color={textPalette.light}>Last updated</Text>
                &nbsp;
                <Text fontWeight='500'>{
                  member.teamMeetingsLastUpdatedAt == null ? 'N/A' : formatDate(parseISO(member.teamMeetingsLastUpdatedAt), DATE_FORMAT)
                }</Text>
              </Flex>
            </Box>
            <Box padding='24px 32px' width='350px' height='fit-content' border={BOX_BORDER} textAlign='left' backgroundColor='white'>
              <Button
                width='100%' background='none' color={textPalette.normal}
                _hover={{bg: 'none'}}
                _active={{bg: 'none'}}
                onClick={() => history.push(RoutePaths.MemberTeam(member.id))}
                padding='0'
              >
                <Box as='span' fontSize='20px' fontWeight='500' lineHeight='24px'>
                  {member.firstName}'s Team
                </Box>
                <Icon as={FiChevronRight} marginLeft='auto'/>
              </Button>
              {!isEmpty(advocates) &&
                <Box marginBottom='36px'>
                  <Text fontSize='12px' fontWeight='500' color={textPalette.light} marginBottom='20px'>
                    {memberLeadName}s
                  </Text>
                  {advocates.map((advocate, i) =>
                    <Box
                      width='100%'
                      height=' 56px'
                      display=' flex'
                      flex-direction=' row'
                      marginTop={i !== 0 ? '32px' : ''}
                      key={i}
                    >
                      <Avatar
                        bg={purples.avatar}
                        color={white}
                        height='56px'
                        marginRight='16px' name={advocate?.firstName + ` ${advocate?.lastName}`}
                        size='md'
                        src={privateFileUrl(advocate?.avatar)}
                        width='56px'
                      />
                      <Stack>
                        <Text color={textPalette.normal} fontWeight='500' lineHeight='24px'>
                          {advocate?.namePrefix} {advocate?.firstName} {advocate?.lastName} {advocate?.nameSuffix}
                        </Text>
                        <Text fontSize='12px' color={textPalette.light} lineHeight='18px' marginTop='0 !important'>
                          {advocate?.specialty}
                        </Text>
                      </Stack>
                    </Box>,
                  )}
                </Box>
              }
              {!isEmpty(primaryCareProviders) &&
                <Box marginBottom='36px'>
                  <Text fontSize='12px' fontWeight='500' color={textPalette.light} marginBottom='20px'>
                    Primary Care Providers
                  </Text>
                  {primaryCareProviders.map((provider, i) =>
                    <Box
                      width='100%'
                      height=' 56px'
                      display=' flex'
                      flex-direction=' row'
                      marginTop={i !== 0 ? '32px' : ''}
                      key={i}
                    >
                      <Avatar
                        bg={purples.avatar}
                        color={white}
                        height='56px'
                        marginRight='16px'
                        name={provider?.firstName + ` ${provider?.lastName}`}
                        size='md'
                        src={privateFileUrl(provider?.avatar)}
                        width='56px'
                      />
                      <Stack>
                        <Text color={textPalette.normal} fontWeight='500' lineHeight='24px'>
                          {provider?.firstName} {provider?.lastName}
                        </Text>
                        <Text fontSize='12px' color={textPalette.light} lineHeight='18px' marginTop='0 !important'>
                          Detail
                        </Text>
                        <Text fontSize='12px' color={textPalette.light} lineHeight='18px' marginTop='0 !important'>
                          {parsePhoneNumber(provider?.phoneNumber)}
                        </Text>
                      </Stack>
                    </Box>,
                  )}
                </Box>
              }
              {!isEmpty(surrogates) &&
                <Box marginBottom='36px'>
                  <Text fontSize='12px' fontWeight='500' color={textPalette.light} marginBottom='20px'>
                    Agents
                  </Text>
                  {surrogates.map((surrogateMember, i) =>
                    <Box
                      width='100%'
                      height=' 56px'
                      display=' flex'
                      flex-direction=' row'
                      marginTop={i !== 0 ? '32px' : ''}
                      key={i}
                    >
                      <Avatar
                        bg={purples.avatar}
                        color={white}
                        height='56px'
                        marginRight='16px'
                        name={getFullName(teamRoles[i])}
                        size='md'
                        src={privateFileUrl(surrogateMember?.avatar)}
                        width='56px'
                      />
                      <Stack>
                        <Text color={textPalette.normal} fontWeight='500' lineHeight='24px'>
                          {getFullName(teamRoles[i])}
                        </Text>
                        <Text fontSize='12px' color={textPalette.light} lineHeight='18px' marginTop='0 !important'>
                          {teamRoles[i]?.memberRelationship}
                        </Text>
                        <Text fontSize='12px' color={textPalette.light} lineHeight='18px' marginTop='0 !important'>
                          {parsePhoneNumber(teamRoles[i]?.phoneNumber)}
                        </Text>
                      </Stack>
                    </Box>,
                  )}
                </Box>
              }
            </Box>
          </Stack>
        </Box>
      </Box>
    </>
  )
}

export default MemberDetail
