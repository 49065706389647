import React from 'react'
import {grays} from '../../constants/Colors'
import {Center, Stack} from '@chakra-ui/react'
import PrivacyAndTerms from '../../components/PrivacyAndTerms'

const PrivacyAndTos: React.FC = () => {

  return (
    <Center height='100%' bg={grays.background} marginX={45} marginY={30}>
      <Stack spacing='20px'>
        <PrivacyAndTerms />
      </Stack>
    </Center>
  )
}

export default PrivacyAndTos