import React, { useContext, useMemo } from 'react'
import {Redirect, RouteComponentProps} from 'react-router'
import { Route, Switch, withRouter, useLocation } from 'react-router-dom'
import { useSelector, shallowEqual } from 'react-redux'
import * as RoutePaths from '../../constants/RoutePaths'
import { StoreState } from '../../redux'
import AccountPage from './AccountPage'
import MemberDetail from './MemberDetail'
import MemberDirective from './MemberDirective'
import MemberList from './MemberList'
import MemberNoteDetail from './MemberNoteDetail'
import MemberNotes from './MemberNotes'
import MemberTeam from './MemberTeam'
import PartnerList from './PartnerList'
import ThemeSettings from './ThemeSettings'
import { Context as SubdomainContext } from '../../components/Subdomain'
import RootRedirect from '../RedirectToHome'
import AccountAdmin from './AccountAdmin'
import PricingOptions from './PricingOptions'
import Payment from './Payment'

type AuthenticatedProps = RouteComponentProps<any>
const Authenticated: React.FC<AuthenticatedProps> = () => {
  const location = useLocation()
  const {
    currentUser,
    accountCreatedAt,
    stripeSubscriptionStatus,
  } = useSelector(({ users, accounts }: StoreState) => ({
    currentUser: users.currentUser,
    stripeSubscriptionStatus: accounts.current.stripeSubscriptionStatus,
    accountCreatedAt: accounts.current.createdAt,
  }), shallowEqual)

  const { isPartnerManagement } = useContext(SubdomainContext)
  const { isAdmin, isSystemAdmin } = currentUser

  const {pathname} = location

  const shouldRedirectToPricing = useMemo(() => {
    if (isSystemAdmin) { return false }

    if (
      stripeSubscriptionStatus &&
      ['trialing', 'active'].includes(stripeSubscriptionStatus)
    )
    { return false }

    // Routes either for payment or leading to payment, plus account admin pages
    if (
      [
        RoutePaths.PricingOptions(),
        RoutePaths.Payment(),
        RoutePaths.AccountAdminPage(),
        RoutePaths.AccountAdminUsersPage(),
        RoutePaths.AccountAdminPaymentPage(),
      ].includes(pathname)
    )
    { return false }

    // this env var must be ISO string as well for the comparison to work
    const subscriptionRequiredAfter = process.env.REACT_APP_SUBSCRIPTION_REQUIRED_FROM || new Date('2024-02-01').toISOString()

    return accountCreatedAt >= subscriptionRequiredAfter
  }, [pathname, stripeSubscriptionStatus, isSystemAdmin])

  return (
    <Switch>
      {shouldRedirectToPricing && <Redirect to={RoutePaths.PricingOptions()} />}
      {isAdmin && !isPartnerManagement && <Route path={RoutePaths.ThemeSettings()} component={ThemeSettings} exact />}
      <Route path={RoutePaths.MemberDetail(':id')} component={MemberDetail} exact />
      <Route path={RoutePaths.MemberNoteDetail(':memberId', ':id')} component={MemberNoteDetail} exact />
      <Route path={RoutePaths.MemberNotes(':id')} component={MemberNotes} exact />
      <Route path={RoutePaths.MemberTeam(':id')} component={MemberTeam} exact />
      <Route path={RoutePaths.MemberDirective(':id', ':type' as any)} component={MemberDirective} exact />
      <Route path={RoutePaths.ProfilePage(':id')} component={AccountPage} exact />
      <Route path={RoutePaths.AccountAdminPage()} component={AccountAdmin} />
      <Route path={RoutePaths.Members()} component={MemberList} exact />
      <Route path={RoutePaths.Partners()} component={PartnerList} exact />
      <Route path={RoutePaths.PricingOptions()} component={PricingOptions} exact />
      <Route path={RoutePaths.Payment()} component={Payment} exact />
      <Route path={RoutePaths.Root()} component={RootRedirect} />
    </Switch>
  )
}



export default withRouter(Authenticated)
