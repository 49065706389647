import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {getCurrent as getCurrentAccount} from '../../../redux/accounts/actions'
import { StoreState } from '../../../redux'
import { Redirect } from 'react-router'
import { Root } from '../../../constants/RoutePaths'
import { Spinner } from '@chakra-ui/react'

/**
 * Component that redirects to the root path if the user has an active subscription.
 * Polls the current account every second to check if the subscription status has changed.
 */
export const PaymentRedirect: React.FC = () => {
  const dispatch = useDispatch()
  const subscriptionStatus = useSelector((state:StoreState) => state.accounts.current.stripeSubscriptionStatus)

  const requestAccountRefresh = useCallback(() => {
    dispatch(getCurrentAccount())
  }, [dispatch])

  useEffect(() => {
    const interval = setInterval(requestAccountRefresh, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [requestAccountRefresh])

  if (!subscriptionStatus || !['active', 'trialing'].includes(subscriptionStatus)) return <Spinner />

  return <Redirect to={Root()} />
}