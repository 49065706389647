import React, { useContext, useEffect, useState } from 'react'
import {
  Alert,
  AlertIcon,
  Box,
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  SlideFade,
  Stack,
  Text,
} from '@chakra-ui/react'
import SentPlane from '../../../../components/SentPlane'
import AdminInfoForm from './AdminInfoForm'
import { Context as DirtyFormAlertContext } from '../../../../components/DirtyFormAlert'
import { textPalette } from '../../../../constants/Colors'
import { useToast } from '../../../../hooks/Toast'

enum Steps {
  ADVOCATE_INFO = 0,
  SENT = 1,
}
type InviteAdminModalProps = Omit<ModalProps, 'children'> & {
  subdomain?: string
}

const useHooks = ({isOpen}: InviteAdminModalProps) => {
  const toast = useToast()
  const { setDirtyFormAlert } = useContext(DirtyFormAlertContext)
  const [errorMessage, setErrorMessage] = useState<string>()
  const [step, setStep] = useState<Steps>(Steps.ADVOCATE_INFO)
  const [nextStep, setNextStep] = useState<Steps>(Steps.ADVOCATE_INFO)

  useEffect(() => {
    if (isOpen) {
      setErrorMessage(undefined)
    } else {
      setDirtyFormAlert(false)
    }
    setNextStep(Steps.ADVOCATE_INFO)
  }, [isOpen])

  useEffect(() => {
    setTimeout(() => setStep(nextStep), 300)
  }, [nextStep])

  useEffect(() => {
    setErrorMessage(undefined)
  }, [step])

  const onError = () => {
    setErrorMessage('Something went wrong. Please Try again.')
    toast({
      description: 'Admin profile was not created; try again.',
      status: 'error',
    })
  }

  return {
    errorMessage,
    nextStep,
    onError,
    setNextStep,
    step,
  }
}

const InviteAdminModal: React.FC<InviteAdminModalProps> = (props) => {
  const { subdomain, ...otherProps } = props
  const { onClose } = otherProps
  const { errorMessage, nextStep, onError, step, setNextStep } = useHooks(props)

  return (
    <Modal size='xl' closeOnOverlayClick={false} {...otherProps}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{step === Steps.ADVOCATE_INFO && <Center fontSize='26px' fontWeight='normal'>Invite an Admin</Center>}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack align='center' spacing='20px' margin='0 24px 24px 24px'>
            { step === Steps.ADVOCATE_INFO &&
              <Text fontSize='16px' textAlign='center' color={textPalette.light}>Invite people by email to be administrators for this account!</Text>
            }
            <Box height='0' width='100%'>{errorMessage && (
              <Alert status='error'>
                <AlertIcon />
                <Center flex='1'>{errorMessage}</Center>
              </Alert>
            )}</Box>
            <SlideFade in={step === nextStep}>
              <Box>
                <Center height='100%' minH='240px'>
                  {(() => {
                    switch(step) {
                      case Steps.SENT:
                        return <SentPlane onFinish={onClose} text='Invite Sent!'/>
                      case Steps.ADVOCATE_INFO:
                        return <AdminInfoForm onCancel={onClose} onError={onError} onFinish={() => setNextStep(Steps.SENT)} subdomain={subdomain} />
                      default: return null
                    }
                  })()}
                </Center>
              </Box>
            </SlideFade>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default InviteAdminModal
