import React, { useContext, useEffect } from 'react'
import * as ReactRedux from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { withRouter } from 'react-router-dom'
import { Field, FieldInputProps, Form, Formik, FormikContextType } from 'formik'
import {
  Button,
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
  Text,
} from '@chakra-ui/react'
import * as Yup from 'yup'
import isEmpty from 'lodash.isempty'
import { Context as DirtyFormAlertContext } from '../../../components/DirtyFormAlert'
import { FiAlertTriangle } from 'react-icons/fi'
import { StoreState } from '../../../redux'
import { isError } from '../../../redux/AsyncState'
import { updateProfile as updateUser } from '../../../redux/users/actions'
import { parsePhoneNumber } from '../../../utils/Forms'
import ImageUpload, { FileValidation } from '../../../components/ImageUpload'
import { privateFileUrl } from '../../../utils/Routing'
import { uploadFile } from '../../../redux/axios'

enum FormFields {
  avatar = 'avatar',
  firstName = 'firstName',
  middleName = 'middleName',
  lastName = 'lastName',
  email = 'email',
  phoneNumber = 'phoneNumber',
}

const ValidationSchema = Yup.object().shape({
  [FormFields.avatar]: FileValidation,
  [FormFields.email]: Yup.string().nullable().required('Must not be empty').email('Must be a valid email'),
  [FormFields.firstName]: Yup.string().nullable().required('First name is required'),
  [FormFields.lastName]: Yup.string().nullable().required('Last name is required'),
  [FormFields.phoneNumber]: Yup.string().nullable().required('Phone number is required').matches(/^\([0-9]{3}\) [0-9]{3}-[0-9]{4}?$/, 'Phone number is not valid'),
})

const initialValues = {
  avatar: undefined,
  firstName: '',
  middleName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
}

type Values = typeof initialValues;
type ValueTypes = Values[keyof Values];
type FormType = FormikContextType<Values>
type FieldPropType = {field: FieldInputProps<ValueTypes>; form: FormType}
type ContainerProps = RouteComponentProps<any> & { goBack: () => any }
type AccountInfoFormProps = ContainerProps & { form: FormType }

// visibleForTesting
export const useHooks = ({form}: AccountInfoFormProps) => {
  const { shallowEqual, useSelector } = ReactRedux
  const { setDirtyFormAlert } = useContext(DirtyFormAlertContext)

  const {
    currentProfile,
    errorData,
    updatingState,
  } = useSelector(({users}: StoreState) => ({
    currentProfile: users.currentProfile,
    errorData: users.errorData,
    updatingState: users.updatingState,
  }), shallowEqual)

  const isUpdateError = isError(updatingState)

  useEffect(() => {
    form.resetForm({ values: {
      ...form.values,
      [FormFields.firstName]: currentProfile.firstName || '',
      [FormFields.middleName]: currentProfile.middleName || '',
      [FormFields.lastName]: currentProfile.lastName || '',
      [FormFields.email]: currentProfile.email || '',
      [FormFields.phoneNumber]: parsePhoneNumber(currentProfile.phoneNumber),
    }})
  }, [currentProfile])

  useEffect(() => {
    if (!isUpdateError) return

    if (typeof errorData === 'object' && errorData?.email != null) {
      form.setFieldError(FormFields.email, `Email ${errorData?.email.join(', ')}`)
    }

    form.setSubmitting(false)
  }, [errorData, isUpdateError])

  useEffect(() => {
    setDirtyFormAlert({blockSearchChanges: true, isDirty: form.dirty})
  }, [form.dirty])

  return { currentProfile }
}

const AccountInfoForm: React.FC<AccountInfoFormProps> = (props) => {
  const { currentProfile } = useHooks(props)
  const { form, goBack } = props

  return (
    <Form>
      <Stack align='center' spacing='20px' width='540px'>
        <Text fontSize='26px'>Account Information</Text>
        <Text textAlign='center'>Please fill out the section below, this can be updated later.</Text>
        <Stack direction='row' spacing={2} alignSelf='flex-start' alignItems='center'>
          <Field name={FormFields.avatar}>
            {({ field }: FieldPropType) => (
              <FormControl isInvalid={!isEmpty(form.errors.avatar)} width='inherit'>
                <ImageUpload
                  {...field}
                  avatarProps={{ size: 'xl' }}
                  id={field.name}
                  initialValue={privateFileUrl(currentProfile.avatar)}
                  onChange={file => {
                    form.setFieldValue(FormFields.avatar, file)
                  }}
                />
                <FormErrorMessage><FormErrorIcon as={FiAlertTriangle} />{form.errors.avatar}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Stack spacing={1}>
            <Text fontSize='16px'>Profile Picture</Text>
            <Text fontSize='12px'>Click to upload profile picture</Text>
          </Stack>
        </Stack>
        <Stack direction='row' spacing={1} width='100%'>
          <Field name={FormFields.firstName}>
            {({ field }: FieldPropType) => (
              <FormControl isInvalid={!isEmpty(form.errors.firstName) && form.touched.firstName}>
                <FormLabel htmlFor={field.name}>Full Name</FormLabel>
                <Input {...field} placeholder='first' id={field.name} type='text' maxLength={32}/>
                <FormErrorMessage><FormErrorIcon as={FiAlertTriangle} />{form.errors.firstName}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name={FormFields.middleName}>
            {({ field }: FieldPropType) => (
              <FormControl isInvalid={!isEmpty(form.errors.middleName) && form.touched.middleName}>
                <FormLabel htmlFor={field.name}>&nbsp;</FormLabel>
                <Input {...field} placeholder='middle' id={field.name} type='text' maxLength={32}/>
                <FormErrorMessage><FormErrorIcon as={FiAlertTriangle} />{form.errors.middleName}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name={FormFields.lastName}>
            {({ field }: FieldPropType) => (
              <FormControl isInvalid={!isEmpty(form.errors.lastName) && form.touched.lastName}>
                <FormLabel htmlFor={field.name}>&nbsp;</FormLabel>
                <Input {...field} placeholder='last' id={field.name} type='text' maxLength={32}/>
                <FormErrorMessage><FormErrorIcon as={FiAlertTriangle} />{form.errors.lastName}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
        </Stack>
        <Stack direction='row' spacing={4} width='100%'>
          <Field name={FormFields.email}>
            {({ field }: FieldPropType) => (
              <FormControl isInvalid={!isEmpty(form.errors.email) && form.touched.email}>
                <FormLabel htmlFor={field.name}>Email</FormLabel>
                <Input {...field} id={field.name} type='text'/>
                <FormErrorMessage><FormErrorIcon as={FiAlertTriangle} />{form.errors.email}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name={FormFields.phoneNumber}>
            {({ field }: FieldPropType) => (
              <FormControl isInvalid={!isEmpty(form.errors.phoneNumber) && form.touched.phoneNumber}>
                <FormLabel htmlFor={field.name}>Phone</FormLabel>
                <Input
                  {...field}
                  id={field.name}
                  onChange={({currentTarget}) => {form.setFieldValue(field.name, parsePhoneNumber(currentTarget.value))}}
                  type='text'
                />
                <FormErrorMessage><FormErrorIcon as={FiAlertTriangle} />{form.errors.phoneNumber}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
        </Stack>
        <Stack direction='row' spacing={4} width='100%'>
          <Button width='100%' isDisabled={form.isSubmitting} variant='outline' onClick={goBack}>
            Back
          </Button>
          <Button
            width='100%'
            isLoading={form.isSubmitting}
            type='submit'
            onClick={() => form.submitForm()}>
            Next
          </Button>
        </Stack>
      </Stack>
    </Form>
  )
}

const AccountInfoFormContainer: React.FC<ContainerProps> = (props) => {
  const dispatch = ReactRedux.useDispatch()

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ValidationSchema}
      onSubmit={({avatar, email: _email, phoneNumber, ...values}) => {
        uploadFile(avatar).then((avatarId) => {
          dispatch(updateUser({
            ...values,
            avatar: avatarId,
            phoneNumber: phoneNumber.match(/\d/g)?.join(''),
          }))
        })
      }}
    >{(formik) => <AccountInfoForm {...props} form={formik} />}</Formik>
  )
}

export default withRouter(AccountInfoFormContainer)
