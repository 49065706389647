import React, { useCallback, useContext, useEffect } from 'react'
import * as ReactRedux from 'react-redux'
import * as Yup from 'yup'
import { useParams } from 'react-router-dom'
import { Context as DirtyFormAlertContext } from '../../../../components/DirtyFormAlert'
import { Field, FieldInputProps, Form, Formik, FormikContextType } from 'formik'
import { StoreState } from '../../../../redux'
import { isDone, isError } from '../../../../redux/AsyncState'
import { share } from '../../../../redux/directives/actions'
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
} from '@chakra-ui/react'
import isEmpty from 'lodash.isempty'

enum FormFields {
  email = 'email',
}

const ValidationSchema = Yup.object().shape({
  [FormFields.email]: Yup.string().required('Email is required').email('Must be a valid email'),
})

const initialValues = {
  [FormFields.email]: '',
}

type Values = typeof initialValues;
type ValueTypes = Values[keyof Values];
type FormType = FormikContextType<Values>
type FieldPropType = { field: FieldInputProps<ValueTypes>; form: FormType }
type ContainerProps = { onError: () => any; onFinish: () => any }
type ShareDirectivesFormProps = ContainerProps & { form: FormType }

export const useHooks = ({ form, onError, onFinish }: ShareDirectivesFormProps) => {
  const { setDirtyFormAlert } = useContext(DirtyFormAlertContext)
  const { shallowEqual, useSelector } = ReactRedux

  const {
    sharingState,
  } = useSelector(({ directives }: StoreState) => ({
    sharingState: directives.sharingState,
  }), shallowEqual)

  const isSharingDone = isDone(sharingState)
  const isSharingError = isError(sharingState)

  useEffect(() => {
    form.resetForm()
  }, [])

  useEffect(() => {
    if (form.dirty && isSharingError) {
      onError()
      form.setSubmitting(false)
    }
  }, [isSharingError])

  useEffect(() => {
    if (form.dirty && isSharingDone) {
      form.setSubmitting(false)
    }
  }, [isSharingDone])

  useEffect(() => {
    if (form.dirty && isSharingDone && !form.isSubmitting) {
      onFinish()
    }
  }, [form.isSubmitting])

  useEffect(() => {
    setDirtyFormAlert({ blockSearchChanges: true, isDirty: form.dirty })
  }, [form.dirty])
}

const ShareDirectivesForm: React.FC<ShareDirectivesFormProps> = (props) => {
  useHooks(props)
  const { form } = props

  return (
    <Box width='100%'>
      <Form>
        <Stack align='center' width='100%'>
          <Field name={FormFields.email} w='100%'>
            {({ field }: FieldPropType) => (
              <FormControl isInvalid={!isEmpty(form.errors.email) && form.touched.email}>
                <FormLabel htmlFor={field.name}>Email</FormLabel>
                <Input {...field} id={field.name} size='lg' />
                <FormErrorMessage>{form.errors.email}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Stack direction='row' width='100%'>
            <Button width='100%' isLoading={form.isSubmitting} type='submit' onClick={() => form.submitForm()}>
              Submit
            </Button>
          </Stack>
        </Stack>
      </Form>
    </Box>
  )
}

const ShareDirectivesFormContainer: React.FC<ContainerProps> = (props) => {
  const { id } = useParams<{id: string}>()
  const dispatch = ReactRedux.useDispatch()

  const submitAction = useCallback(({ email }) => {
    dispatch(share(id, email.trim()))
  }, [dispatch, id])

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ValidationSchema}
      onSubmit={(values) => submitAction(values)}
    >{(formik) => <ShareDirectivesForm {...props} form={formik} />}</Formik>
  )
}

export default ShareDirectivesFormContainer