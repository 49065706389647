import React from 'react'
import { Link } from 'react-router-dom'
import {
  Button,
  Stack,
  useTheme,
} from '@chakra-ui/react'
import { TableOfContentsProps } from '.'
import LinkIcon from './LinkIcon'

type SubLinksProps = Omit<TableOfContentsProps, 'isLoading'>

const SubLinks: React.FC<SubLinksProps> = ({links}) => {
  const { colors: { brand } } = useTheme()

  if (Object.keys(links).length === 0) return null

  return (
    <Stack paddingLeft='16px' width='100%' paddingRight='16px'>
      {Object.keys(links).map((title, i) => {
        const linkData = links[title]

        return (
          <Button
            _hover={{ color: brand[300] }}
            as={Link}
            justifyContent='space-between'
            colorScheme={linkData.isActive ? undefined : 'black'}
            key={`${title}${i}`}
            to={linkData.to}
            variant='link'>
            {title}
            <LinkIcon status={linkData.status} />
          </Button>
        )
      })}
    </Stack>
  )
}

export default SubLinks
