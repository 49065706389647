import React, { useEffect, useState } from 'react'
import * as ReactRedux from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { useHistory, withRouter } from 'react-router-dom'
import {
  Alert,
  AlertIcon,
  Box,
  Center,
  Stack,
  Text,
} from '@chakra-ui/react'
import PasswordForm from '../../components/PasswordForm'
import { StoreState } from '../../redux'
import { isDone, isError } from '../../redux/AsyncState'
import * as RoutePaths from '../../constants/RoutePaths'
import { grays, white } from '../../constants/Colors'

type PasswordResetProps = RouteComponentProps<any>

const useHooks = () => {
  const { shallowEqual, useSelector } = ReactRedux
  const history = useHistory()
  const [hasMounted, setHasMounted] = useState(false)

  const {
    currentUser,
    updatingState,
  } = useSelector(({users}: StoreState) => ({
    currentUser: users.currentUser,
    updatingState: users.updatingState,
  }), shallowEqual)

  const isUpdateDone = isDone(updatingState)
  const isUpdateError = isError(updatingState)

  useEffect(() => {
    setHasMounted(true)
  }, [])

  useEffect(() => {
    if (!hasMounted) return undefined

    if (isUpdateDone) {
      history.push(RoutePaths.Root())
    }
  }, [isUpdateDone])

  return {
    currentUser,
    isUpdateError,
  }
}

const PasswordReset: React.FC<PasswordResetProps> = (props) => {
  const { isUpdateError } = useHooks()

  return (
    <Center height='100%' bg={grays.background}>
      <Stack align='center' spacing='20px'>
        <Text fontSize='42px' fontWeight='500'>Password Reset</Text>
        <Box shadow='md' backgroundColor={white} w='730px'>
          <Box height='0'>{isUpdateError && (
            <Alert status='error'>
              <AlertIcon />
              <Center flex='1'>Something went wrong. Please Try again.</Center>
            </Alert>
          )}</Box>
          <Center height='100%' padding='48px 95px'>
            <PasswordForm
              {...props}
              passwordConfirmLabel='Confirm New Password'
              passwordLabel='New Password'
              submitText='Reset Password'
              title='Create a new password'
            />
          </Center>
        </Box>
      </Stack>
    </Center>
  )
}

export default withRouter(PasswordReset)
