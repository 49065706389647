import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  Box,
  Button,
  Stack,
  Text,
} from '@chakra-ui/react'
import Doctors from '../../../components/Images/Doctors'
import * as RoutePaths from '../../../constants/RoutePaths'

interface ThanksStepProps {
  buttonText: string
  onClickContinue: () => void
  text?: string
  title: string
}

const ThanksStep: React.FC<ThanksStepProps> = ({buttonText, onClickContinue, text, title}) => {
  const history = useHistory()
  const { id } = useParams<{id: string}>()

  return (
    <Stack align='center' spacing='20px' width='540px'>
      <Box as={Doctors} />
      <Text fontSize='26px'>{title}</Text>
      <Text textAlign='center'>{text}</Text>
      <Stack direction='row' spacing={4} width='100%'>
        <Button width='full' variant='purpleOutline' onClick={() => history.push(RoutePaths.MemberDetail(id))}>
          Exit to Dashboard
        </Button>
        <Button width='full' onClick={() => onClickContinue()}>
          {buttonText}
        </Button>
      </Stack>
    </Stack>
  )
}

export default ThanksStep
