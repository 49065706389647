import React, { useEffect } from 'react'
import { Container, Button, Text } from '@chakra-ui/react'
import { RouteComponentProps } from 'react-router'
import { useHistory } from 'react-router-dom'
import * as RoutePaths from '../../../constants/RoutePaths'
import { useDispatch, useSelector } from 'react-redux'
import {getCurrent as getCurrentAccount} from '../../../redux/accounts/actions'
import { StoreState } from '../../../redux'

type PricingOptionsProps = RouteComponentProps

const PricingOptions: React.FC<PricingOptionsProps> = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const handleSubmit = () => {
    history.push(RoutePaths.Payment())
  }

  useEffect(() => {
    // refresh the current account to get the latest subscription status
    dispatch(getCurrentAccount())
  },[])

  const subscriptionStatus = useSelector((state:StoreState) => state.accounts.current.stripeSubscriptionStatus)

  useEffect(() => {
    if (subscriptionStatus !== 'active') return

    history.push(RoutePaths.Root())
  },[subscriptionStatus])

  return (
    <Container width='400px'>
      <Container backgroundColor='white' rounded='lg' margin='36px'>
        <Container
          centerContent
          padding='16px'
          borderBottom='1px solid #e2e8f0'
        >
          <Text align='center' fontWeight='600'>
            Pro Plan
          </Text>
        </Container>
        <Container
          centerContent
          padding='16px'
          borderBottom='1px solid #e2e8f0'
        >
          <Text align='center'>1 Seat License</Text>
        </Container>
        <Container
          centerContent
          padding='16px'
          borderBottom='1px solid #e2e8f0'
        >
          <Text align='center'>Unlimited Clients</Text>
        </Container>
        <Container
          centerContent
          padding='16px'
          borderBottom='1px solid #e2e8f0'
        >
          <Text align='center'>Print/Sign/Scan Certification</Text>
        </Container>
        <Container
          centerContent
          padding='16px'
          borderBottom='1px solid #e2e8f0'
        >
          <Text align='center'>Standard Support</Text>
        </Container>
        <Container
          centerContent
          padding='16px'
          borderBottom='1px solid #e2e8f0'
        >
          <Text align='center'>HIPAA Security & Compliance</Text>
        </Container>
        <Container centerContent padding='16px'>
          <Text align='center'>Users, Permissions, & Teams Management</Text>
        </Container>
      </Container>
      <Container centerContent margin='36px'>
        <Button
          type='submit'
          borderRadius='3px'
          fontSize='16px'
          lineHeight='24px'
          marginBottom='24px'
          onClick={handleSubmit}
        >
          Start Now
        </Button>
      </Container>
    </Container>
  )
}

export default PricingOptions
