import React from 'react'
import {
  Center,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import { textPalette } from '../../../constants/Colors'

interface PurposeTableCellProps {
  purposeNames: string[]
}

const PurposeTableCell: React.FC<PurposeTableCellProps> = ({purposeNames}) => {
  const joinedNames = purposeNames.join(', ')
  const isSinglePurpose = purposeNames.length === 1
  const displayedText = isSinglePurpose ? purposeNames[0] : `${purposeNames.length} purposes`

  return (
    <Center h='100%'>
      <Tooltip label={joinedNames} isDisabled={isSinglePurpose}>
        <Text
          color={textPalette.light}
          fontSize='16px'
          fontWeight={isSinglePurpose ? undefined : '500'}>
          {displayedText}
        </Text>
      </Tooltip>
    </Center>
  )
}

export default PurposeTableCell
