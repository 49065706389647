import React, { useRef, useState, useEffect } from 'react'
import { HStack, Box, InputProps, Button, Icon } from '@chakra-ui/react'
import FileInput from './FileInput'
import { FiUploadCloud } from 'react-icons/fi'
import { downloadFile } from '../utils/Forms'
import { AiOutlineDownload, AiOutlineCloseCircle } from 'react-icons/ai'
import ReactPlayer from 'react-player'
import {black} from '../constants/Colors'

const acceptedFileTypes = [
  'video/3gpp',
  'video/3gpp2',
  'video/mp2t',
  'video/mp4',
  'video/mpeg',
  'video/ogg',
  'video/webm',
  'video/x-msvideo',
  'application/pdf',
  'image/apng',
  'image/bmp',
  'image/gif',
  'image/jpeg',
  'image/pjpeg',
  'image/png',
  'image/svg+xml',
  'image/tiff',
  'image/webp',
  'image/x-icon',
]

interface FileUploadProps extends Omit<InputProps, 'onChange'> {
  initialValue?: string
  filename?: string
  filetype?: string
  onChange: (file: File) => void
}

const FileUpload: React.FC<FileUploadProps> = ({initialValue, filetype, filename, onChange, ...otherProps}) => {
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [filePreviewUrl, setFilePreviewUrl] = useState<string|undefined>(initialValue)
  const [name, setName] = useState<string|undefined>(filename)
  const [type, setType] = useState<string|undefined>(filetype)
  const [dirty, setDirty] = useState<boolean>(false)

  const showFileSelect = () => {
    const currentInput = fileInputRef?.current
    if (currentInput == null) return undefined

    currentInput.click()
  }

  useEffect(() => {
    setFilePreviewUrl(initialValue)
    setName(filename)
    setType(filetype)
    setDirty(false)
  }, [initialValue, filename, filetype])

  return (
    <Box>
      <Box>
        {  filePreviewUrl && name && !dirty && type?.includes('video') &&
          <>
            <ReactPlayer
              controls
              url={filePreviewUrl}
              style={{backgroundColor: black}}
              height='200px'
              width='66%'
            />
          </>
        }
      </Box>
      <HStack w='100%' marginTop='20px' justifyContent='center' spacing={4}>
        {!filePreviewUrl &&
            <>
              <Icon as={FiUploadCloud} w={6} h={6} />
              <Box as='span' fontSize='20px' fontWeight='500' lineHeight='24px'>
                Upload a Video/Image/Pdf
              </Box>
            </>
        }
        { filePreviewUrl && name && filePreviewUrl !== '' &&
            <>
              <Box as='span' fontSize='20px' fontWeight='500' lineHeight='24px'>
                {name}
              </Box>
              {
                !dirty &&
                <>
                  <Button leftIcon={<AiOutlineDownload />} onClick={() => {
                    downloadFile(filePreviewUrl, name)
                  }}>Download</Button>
                </>
              }
              <Button leftIcon={<AiOutlineCloseCircle />} variant='transparentRedOutline' onClick={() => {
                setFilePreviewUrl(undefined)
              }}>Remove</Button>

            </>
        }
        {
          !filePreviewUrl &&
          <Button onClick={showFileSelect} >
            <FileInput
              accept={acceptedFileTypes.join(', ')}
              {...otherProps}
              onChange={({file, fileUrl}) => {
                setFilePreviewUrl(fileUrl)
                setName(file.name)
                setDirty(true)
                onChange(file)
              }}
              ref={fileInputRef}
            />
            Upload
          </Button>
        }
      </HStack>
    </Box>
  )
}

export default FileUpload
