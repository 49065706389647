import React from 'react'
import {Field, FieldInputProps} from 'formik'
import {FormControl, FormLabel, FormErrorMessage, FormErrorIcon, Textarea} from '@chakra-ui/react'
import {textPalette} from '../../../../constants/Colors'
import isEmpty from 'lodash.isempty'
import {FiAlertTriangle} from 'react-icons/fi'

type FieldPropType = { field: FieldInputProps<string> }

export type QuestionTextAreaProps = {
  name: string
  label?: string
  description?: string
  value: string
  error?: string
  touched?: boolean
  placeholder: string
  hidden: boolean
  setFieldValue: (field: string, value: any) => void
}
const QuestionTextArea: React.FC<QuestionTextAreaProps> = ({name, setFieldValue, label, value, error, touched, placeholder, hidden}) => {
  if (value === 'default' && hidden)
    setFieldValue(name, value + '1')

  return (
    <Field name={name} key={name}>
      {({field}: FieldPropType) => (
        <FormControl isInvalid={!isEmpty(error) && touched as any}>
          {label &&
              <FormLabel htmlFor={field.name} fontSize='md' color={textPalette.normal}>
                {label}
              </FormLabel>
          }
          <Textarea {...field} id={field.name} value={value} placeholder={placeholder} hidden={hidden || false}/>
          <FormErrorMessage><FormErrorIcon as={FiAlertTriangle}/>{error}</FormErrorMessage>
        </FormControl>
      )}
    </Field>
  )
}

export default QuestionTextArea