import React, { useCallback, useEffect, useMemo, useState } from 'react'
import * as ReactRedux from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import GeneralInformation from './GeneralInformation'
import PolstDirectiveSteps, { StepTitles as PolstStepTitles } from './PolstDirectiveSteps'
import MemberVideoWishes from './MemberVideoWishes'
import HealthcareHealthcarePt1DirectiveSteps, { StepTitles as HealthcarePt1StepTitles } from './HealthcarePt1DirectiveSteps'
import {Types as DirectiveTypes, DirectiveIdKeys, Types} from '../../../constants/Directive'
import { Model as UserModel } from '../../../constants/User'
import LoadingPage from '../../../components/LoadingPage'
import TableOfContents from '../../../components/TableOfContents'
import { StoreState } from '../../../redux'
import { isDone } from '../../../redux/AsyncState'
import { getMany as getUsers, getOne as getUser, updateDirectiveMemberId } from '../../../redux/users/actions'
import { create as createDirective, getMany as getDirectives, getOne as getDirective } from '../../../redux/directives/actions'
import * as RoutePaths from '../../../constants/RoutePaths'
import { QUERY_PARAM_KEYS, getStepParam, getNewSearchString, getThanksgivingParam } from '../../../utils/Routing'
import { isDirectiveComplete } from '../../../utils/BusinessLogic'
import {
  Box,
  HStack,
} from '@chakra-ui/react'
import { getAppointSurrogateValid } from '../../../redux/directives/selector'
import {getFlexDirectiveTableData} from './MemberFlexDirective/getFlexDirective'
import MemberFlexDirective from './MemberFlexDirective'
const TOC_DIVIDERS = { 3: 'Optional' }

type MixedDirectiveTypes = DirectiveTypes | RoutePaths.NonDirectiveViewTypes

const directiveIdKey = (type: MixedDirectiveTypes): keyof UserModel | undefined => {
  switch (type) {
    case DirectiveTypes.HEALTHCARE_PT1_DIRECTIVE: return DirectiveIdKeys.HEALTHCARE_PT1_DIRECTIVE
    case DirectiveTypes.HEALTHCARE_FLEX_DIRECTIVE: return DirectiveIdKeys.HEALTHCARE_FLEX_DIRECTIVE
    case DirectiveTypes.POLST: return DirectiveIdKeys.POLST
    default: return
  }
}

export interface MemberDirectiveChildProps {
  goToStep: (step: number) => void
  goToType: (type: MixedDirectiveTypes, giveThanks?: boolean) => void
  isGivingThanks: boolean
  isTransitioning: boolean
  onClickThanks: () => void
  step: number
}

const useHooks = () => {
  const { shallowEqual, useDispatch, useSelector } = ReactRedux
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const { id, type } = useParams<{id: string; type: MixedDirectiveTypes}>()
  const {
    allDirectives,
    member,
    updatingState,
  } = useSelector(({directives, users}: StoreState) => ({
    allDirectives: directives.records,
    member: users.records[id],
    updatingState: directives.updatingState,
  }), shallowEqual)

  const appointValidSurrogateForm = useSelector((state:StoreState) => getAppointSurrogateValid(state))
  const stepParam = useMemo(() => parseInt(getStepParam(location) as any, 10), [location])
  const pathname = useMemo(() => location.pathname, [location])
  const isGivingThanks = useMemo(() => getThanksgivingParam(location) === 'true', [location])
  const [step, setStep] = useState(stepParam)
  const isTransitioning = useMemo(() => step !== stepParam, [step, stepParam])
  const isUpdateDone = useMemo(() => isDone(updatingState), [updatingState])
  const isMemberLoaded = useMemo(() => member != null, [member])
  const idKey = useMemo(() => directiveIdKey(type), [type])
  const isDirectiveType = useMemo(() => idKey != null, [idKey])
  const directiveId = useMemo(() => isMemberLoaded && isDirectiveType ? member[idKey!] as string : null, [isMemberLoaded, member, idKey, isDirectiveType])
  const directive = useMemo(() => (
    directiveId == null ? undefined : allDirectives[directiveId]
  ), [allDirectives, directiveId])
  const isDirectiveLoaded = useMemo(() => directive != null, [directive])
  const isPageLoaded = useMemo(() => (
    isMemberLoaded && (
      isDirectiveLoaded ||
      !isDirectiveType
    )
  ), [isDirectiveLoaded, isDirectiveType, isMemberLoaded])

  const directiveIdKeys = Object.values(DirectiveIdKeys)
  const memberDirectiveKeys = (member && directiveIdKeys ? directiveIdKeys : []).reduce((acc, dId) => member[dId] ? [...acc, member[dId]] : acc, [] as any [])

  useEffect(()=> {dispatch(getDirectives(memberDirectiveKeys))}, [member])

  const goToStep = useCallback((newStep: number, giveThanks = false, replace = false) => {

    const destination = {
      pathname: RoutePaths.MemberDirective(id, type),
      search: getNewSearchString({
        [QUERY_PARAM_KEYS.THANKSGIVING]: giveThanks,
        [QUERY_PARAM_KEYS.STEP]: String(newStep),
      }),
    }

    replace ? history.replace(destination) : history.push(destination)
  }, [history, isGivingThanks, type])




  const goToType = useCallback((newType: MixedDirectiveTypes, giveThanks = false) => history.push({
    pathname: RoutePaths.MemberDirective(id, newType),
    search: getNewSearchString({
      [QUERY_PARAM_KEYS.THANKSGIVING]: giveThanks,
    }),
  }), [history])

  const onClickContinue = useCallback(() => goToStep(step + 1), [goToStep, step])
  const onClickThanks = useCallback(() => goToStep(step), [goToStep, step])

  const sharedChildProps: MemberDirectiveChildProps = useMemo(() => ({
    goToStep,
    goToType,
    isGivingThanks,
    isTransitioning,
    onClickThanks,
    step,
  }), [goToStep, goToType, isGivingThanks, isTransitioning, onClickThanks, step])

  useEffect(() => {
    if (id != null) {
      dispatch(getUsers({teamUserId: id}))
      dispatch(updateDirectiveMemberId(id, {memberId: id}))
    }
  }, [id])

  useEffect(() => {
    if (isUpdateDone && id) dispatch(getUser(id))
  }, [isUpdateDone, id])

  useEffect(() => {
    if (isMemberLoaded) {
      setStep(NaN)
    }
  }, [pathname])

  useEffect(() => {
    if (!isMemberLoaded) return
    if (!isDirectiveType) return

    if (directiveId == null) {
      dispatch(createDirective({memberId: id, type}))
    } else {
      dispatch(getDirective(directiveId))
    }
  }, [directiveId, isDirectiveType, isMemberLoaded])

  useEffect(() => {
    if (!isNaN(stepParam)) {
      setTimeout(() => setStep(stepParam), 300)
    }
  }, [stepParam])

  useEffect(() => {
    if (isDirectiveLoaded && isNaN(stepParam)) {
      if (directive?.type === Types.HEALTHCARE_FLEX_DIRECTIVE) {
        goToStep(member.flexDirectiveComplete ? directive!.stepsCompleted : directive!.stepsCompleted + 1,
          isGivingThanks, true,
        )
      }
      goToStep(
        isDirectiveComplete(directive!) ? directive!.stepsCompleted : directive!.stepsCompleted + 1,
        isGivingThanks, true,
      )
    }
  }, [directive, isDirectiveLoaded, stepParam])

  const flexDirectiveTableData = getFlexDirectiveTableData(stepParam, member, allDirectives[member?.flexDirectiveId || ''])
  const flexDirectiveComplete = Object.values(flexDirectiveTableData).reduce<boolean>((valid, curr) => valid && curr.status === 'complete', true)
  const tocLinks = {
    'General Information': {
      isActive: type === RoutePaths.NonDirectiveViews.GENERAL_INFO,
      status: 'complete',
      to: RoutePaths.MemberDirective(member?.id, RoutePaths.NonDirectiveViews.GENERAL_INFO),
    },
    'Healthcare Directive Part 1': {
      isActive: type === DirectiveTypes.HEALTHCARE_PT1_DIRECTIVE,
      status: isDirectiveComplete({stepsCompleted: member?.healthcarePt1DirectiveStepsCompleted ?? 0, type: DirectiveTypes.HEALTHCARE_PT1_DIRECTIVE}) && appointValidSurrogateForm ? 'complete' : 'in_progress',
      links: Object.values(HealthcarePt1StepTitles).reduce((memo, title, i) => ({
        ...memo,
        [title]: {
          isActive: stepParam === i + 1,
          status: (member?.healthcarePt1DirectiveStepsCompleted! >= i + 1 && !appointValidSurrogateForm && i === 0) ? 'in_progress'
            : member?.healthcarePt1DirectiveStepsCompleted! >= i + 1 && i !== 0 ? 'complete'
              :  member?.healthcarePt1DirectiveStepsCompleted! >= i + 1 ? 'complete' : '',
          to: RoutePaths.MemberDirective(member?.id, DirectiveTypes.HEALTHCARE_PT1_DIRECTIVE, i + 1),
        },
      }), {}),
      to: RoutePaths.MemberDirective(member?.id, DirectiveTypes.HEALTHCARE_PT1_DIRECTIVE),
    },
    'Healthcare Directive Part 2': {
      isActive: type === DirectiveTypes.HEALTHCARE_FLEX_DIRECTIVE,
      status: flexDirectiveComplete ? 'complete' : 'in_progress',
      links: flexDirectiveTableData,
      to: RoutePaths.MemberDirective(member?.id, DirectiveTypes.HEALTHCARE_FLEX_DIRECTIVE),
    },
    'Video Wishes': {
      isActive: type === RoutePaths.NonDirectiveViews.VIDEO_WISHES,
      status: member?.wishesVideo != null ? '' : '',
      to: RoutePaths.MemberDirective(member?.id, RoutePaths.NonDirectiveViews.VIDEO_WISHES),
    },
  }

  if (process.env.NODE_ENV !== 'production') {
    tocLinks['POLST'] = {
      isActive: type === DirectiveTypes.POLST,
      status: isDirectiveComplete({stepsCompleted: member?.polstDirectiveStepsCompleted ?? 0, type: DirectiveTypes.POLST}) ? '' : '',
      links: Object.values(PolstStepTitles).reduce((memo, title, i) => ({
        ...memo,
        [title]: {
          isActive: stepParam === i + 1,
          to: RoutePaths.MemberDirective(member?.id, DirectiveTypes.POLST, i + 1),
        },
      }), {}),
      to: RoutePaths.MemberDirective(member?.id, DirectiveTypes.POLST),
    }
  }

  return {
    isMemberLoaded,
    isPageLoaded,
    onClickContinue,
    sharedChildProps,
    tocLinks,
    type,
  }
}

const MemberDirective: React.FC<{}> = () => {
  const {
    isMemberLoaded,
    isPageLoaded,
    sharedChildProps,
    tocLinks,
    type,
  } = useHooks()
  return(
    <HStack spacing='30px' alignItems='flex-start' paddingTop='100px' paddingLeft='30px'>
      <TableOfContents isLoading={!isMemberLoaded} links={tocLinks} dividers={TOC_DIVIDERS}/>
      <Box w='730px'>
        {(() => {
          if (!isPageLoaded) return <LoadingPage />

          switch(type) {
            case DirectiveTypes.HEALTHCARE_PT1_DIRECTIVE:
              return <HealthcareHealthcarePt1DirectiveSteps {...sharedChildProps}/>
            case DirectiveTypes.POLST:
              return <PolstDirectiveSteps {...sharedChildProps}/>
            case DirectiveTypes.HEALTHCARE_FLEX_DIRECTIVE:
              return <MemberFlexDirective {...sharedChildProps} />
            case RoutePaths.NonDirectiveViews.VIDEO_WISHES:
              return <MemberVideoWishes {...sharedChildProps}/>
            case RoutePaths.NonDirectiveViews.GENERAL_INFO: // fall-through
            default: return <GeneralInformation {...sharedChildProps}/>
          }
        })()}
      </Box>
    </HStack>
  )
}

export default MemberDirective
