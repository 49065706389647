import { axiosAuthed } from '../axios'

const BASE_ROUTE = '/auth'

export const resetPassword = async (data = {}) => {
  try {
    const response = await axiosAuthed({method: 'post', skipTokenRefresh: true}, `${BASE_ROUTE}/password_reset`, {...data, redirectUrl: window.location.origin})
    return { response }
  } catch(error) {
    return { error }
  }
}

export const signIn = async (data: any) => {
  try {
    const response = await axiosAuthed({method: 'post', skipTokenRefresh: true}, `${BASE_ROUTE}/sign_in`, data)
    return { response }
  } catch(error) {
    return { error }
  }
}

export const signOut = async () => {
  try {
    const response = await axiosAuthed({method: 'post', skipTokenRefresh: true}, `${BASE_ROUTE}/sign_out`)
    return { response }
  } catch(error) {
    return { error }
  }
}
