import React from 'react'
import {Field, useField, FieldInputProps, FormikContextType} from 'formik'
import {FormControl, FormErrorMessage, FormErrorIcon, FormLabel, Textarea} from '@chakra-ui/react'
import isEmpty from 'lodash.isempty'
import {FiAlertTriangle} from 'react-icons/fi'

type ExplanationProps = {
  name: string
  hidden: boolean
  required: boolean
  defaultValue: string
}
type FieldPropType = { field: FieldInputProps<string>; form: FormikContextType<any> }

const ExplanationAnswer: React.FC<ExplanationProps> = ({name, hidden, required, defaultValue}) => {
  const [, meta] = useField(`${name}-explanation`)
  const { error, touched } = meta
  return (
    <Field name={name}>
      {({ field }: FieldPropType) => (
        <FormControl hidden={hidden} paddingLeft='27px' isInvalid={!isEmpty(error) && touched}>
          <FormLabel htmlFor={field.name} fontSize='12px'>Please explain{!required && ' (optional)'}:</FormLabel>
          <Textarea {...field} id={field.name} placeholder='Type here...' defaultValue={defaultValue} />
          <FormErrorMessage><FormErrorIcon as={FiAlertTriangle} />{error}</FormErrorMessage>
        </FormControl>
      )}
    </Field>
  )
}

export default ExplanationAnswer