import { call, put, race, select, take, takeEvery, takeLatest } from 'redux-saga/effects'
import ActionIds from '../ActionIds'
import { BaseAction } from '../types'
import { MY_ID } from '../../constants/User'
import {
  assignTeamMember as assignTeamMemberApi,
  create as createApi,
  signup as signupApi,
  getMany as getManyApi,
  getOne as getOneApi,
  invite as inviteApi,
  update as updateApi,
  deleteOne as deleteApi, getProfile as getProfileApi, updateProfile as updateProfileApi,
} from './api'
import {
  getMany as getManyAction,
} from './actions'
import { StoreState } from '..'

export default function* watch() {
  yield takeEvery(ActionIds.USERS_GET_ONE_START, requestOne)
  yield takeEvery(ActionIds.USERS_GET_PROFILE_START, requestProfile)
  yield takeEvery(ActionIds.USERS_UPDATE_PROFILE_START, requestUpdateProfile)
  yield takeLatest(ActionIds.USERS_GET_MANY_START, requestMany)
  yield takeEvery(ActionIds.USERS_CREATE_START, requestCreate)
  yield takeEvery(ActionIds.USERS_SIGNUP_START, requestSignup)
  yield takeEvery(ActionIds.USERS_INVITE_START, requestInvite)
  yield takeEvery(ActionIds.USERS_UPDATE_START, requestUpdate)
  yield takeEvery(ActionIds.USERS_SET_DIRECTIVE_MEMBERID, requestUpdate)
  yield takeEvery(ActionIds.USERS_DELETE_START, requestDelete)
  yield takeEvery(ActionIds.USERS_ASSIGN_TEAM_MEMBER_START, assignTeamMember)
}

function* requestOne({payload: id}: BaseAction) {
  const { apiResponse: {response, error}, isCanceled } = yield race({
    apiResponse: call(getOneApi, id),
    isCanceled: take(ActionIds.USERS_GET_ONE_CANCEL),
  })
  if (isCanceled) return

  if (error) {
    yield put({type: ActionIds.USERS_GET_ONE_FAIL})
    return
  }

  const currentUserId = yield select(({ users: {currentUser} }: StoreState) => currentUser.id)

  if ([MY_ID, currentUserId].includes(id)) {
    yield put({type: ActionIds.USERS_SET_CURRENT, payload: response.data})
  }

  yield put({type: ActionIds.USERS_GET_ONE_SUCCESS, payload: response.data})
}

function* requestProfile() {
  const { apiResponse: {response, error}, isCanceled } = yield race({
    apiResponse: call(getProfileApi),
    isCanceled: take(ActionIds.USERS_GET_PROFILE_CANCEL),
  })
  if (isCanceled) return

  if (error) {
    yield put({type: ActionIds.USERS_GET_PROFILE_FAIL})
    return
  }
  yield put({type: ActionIds.USERS_GET_PROFILE_SUCCESS, payload: response.data})
}

function* requestUpdateProfile({payload: params}: BaseAction) {
  const { apiResponse: {response, error}, isCanceled } = yield race({
    apiResponse: call(updateProfileApi, params),
    isCanceled: take(ActionIds.USERS_UPDATE_PROFILE_CANCEL),
  })
  if (isCanceled) return

  if (error) {
    yield put({type: ActionIds.USERS_UPDATE_PROFILE_FAIL})
    return
  }
  yield put({type: ActionIds.USERS_UPDATE_PROFILE_SUCCESS, payload: response.data})
}
function* requestMany({payload: params}: BaseAction) {
  const { apiResponse: {response, error}, isCanceled } = yield race({
    apiResponse: call(getManyApi, params),
    isCanceled: take(ActionIds.USERS_GET_MANY_CANCEL),
  })
  if (isCanceled) return

  if (error) {
    yield put({type: ActionIds.USERS_GET_MANY_FAIL})
    return
  }

  yield put({type: ActionIds.USERS_GET_MANY_SUCCESS, payload: response.data})
}

function* requestUpdate({payload: {id, params}}: BaseAction) {
  const{response, error} = yield call(updateApi, id, params)

  if (error) {
    yield put({payload: error.response, type: ActionIds.USERS_UPDATE_FAIL})
    return
  }

  const currentUserId = yield select(({ users: {currentUser} }: StoreState) => currentUser.id)

  if ([MY_ID, currentUserId].includes(id)) {
    yield put({type: ActionIds.USERS_SET_CURRENT, payload: response.data})
  }

  yield put({type: ActionIds.USERS_UPDATE_SUCCESS, payload: response.data})
}

function* requestCreate({payload: params}: BaseAction) {
  const{response, error} = yield call(createApi, params)

  if (error) {
    yield put({payload: error.response, type: ActionIds.USERS_CREATE_FAIL})
    return
  }

  yield put({type: ActionIds.USERS_CREATE_SUCCESS, payload: response.data})
}

function* requestSignup({payload: params}: BaseAction) {
  const{response, error} = yield call(signupApi, params)
  if (error) {
    yield put({payload: error.response, type: ActionIds.USERS_SIGNUP_FAIL})
    return
  }

  yield put({type: ActionIds.USERS_SIGNUP_SUCCESS, payload: response.data})
  yield put({type: ActionIds.AUTH_POST_START, payload: {username: params['email'], password: params['password']}})
}

function* requestInvite({payload: params}: BaseAction) {
  const{response, error} = yield call(inviteApi, params)

  if (error) {
    yield put({payload: error.response, type: ActionIds.USERS_INVITE_FAIL})
    return
  }

  yield put({type: ActionIds.USERS_INVITE_SUCCESS, payload: response.data})
}

function* requestDelete({payload: {id}}: BaseAction) {
  const { apiResponse: {response, error}, isCanceled } = yield race({
    apiResponse: call(deleteApi, id, null),
    isCanceled: take(ActionIds.USERS_DELETE_CANCEL),
  })
  if (isCanceled) return

  if (error) {
    yield put({type: ActionIds.USERS_DELETE_FAIL})
    return
  }

  yield put({type: ActionIds.USERS_DELETE_SUCCESS, payload: response.data})
}

function* assignTeamMember({payload: {id, params}}: BaseAction) {
  const{response, error} = yield call(assignTeamMemberApi, id, params)

  if (error) {
    yield put({payload: error.response, type: ActionIds.USERS_UPDATE_FAIL})
    return
  }

  yield put(getManyAction({teamUserId: id}))

  yield put({type: ActionIds.USERS_UPDATE_SUCCESS, payload: response.data})
}
