enum AsyncState {
  DONE = 'DONE',
  ERROR = 'ERROR',
  LOADING = 'LOADING',
  NOT_STARTED = 'NOT_STARTED',
}

export const isDone = (...states: AsyncState[]) =>
  combine(...states) === AsyncState.DONE
export const isError = (...states: AsyncState[]) =>
  combine(...states) === AsyncState.ERROR
export const isLoading = (...states: AsyncState[]) =>
  combine(...states) === AsyncState.LOADING
export const isNotStarted = (...states: AsyncState[]) =>
  combine(...states) === AsyncState.NOT_STARTED

export const combine = (...states: AsyncState[]): AsyncState => {
  const statesObj = states.reduce(
    (memo, currentState) => {
      memo[currentState] = true
      return memo
    },
    {} as { [S in AsyncState]: boolean },
  )

  if (statesObj[AsyncState.LOADING]) {
    return AsyncState.LOADING
  }

  const done = statesObj[AsyncState.DONE]
  const error = statesObj[AsyncState.ERROR]
  const notStarted = statesObj[AsyncState.NOT_STARTED]

  if (notStarted && error) {
    return AsyncState.ERROR
  }

  if (notStarted && done) {
    return AsyncState.DONE
  }

  if (error) {
    return AsyncState.ERROR
  }

  if (done) {
    return AsyncState.DONE
  }

  return AsyncState.NOT_STARTED
}

export default AsyncState
