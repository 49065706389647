import React from 'react'
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Center, Divider,
  Flex,
  Skeleton,
  Spacer,
  Text,
  useTheme,
} from '@chakra-ui/react'
import { grays, textPalette, white } from '../constants/Colors'
import { Link } from 'react-router-dom'
import { FiChevronRight } from 'react-icons/fi'

const SKELETON_DATA: RowData[] = Array(5).fill(null).map((_item, i) => ({
  id: String(i),
}))

type BoxProps = React.ComponentProps<typeof Box>
type FlexProps = React.ComponentProps<typeof Flex>
type EmptyDataProps = BoxProps & {
  text?: string
}
const EmptyData: React.FC<EmptyDataProps> = ({height, text, ...boxProps}) => (
  <Box h={height} my={1} mr={-4} ml= {-4} background={white} {...boxProps}>
    <Center h={height}>
      <Text fontWeight='500' color={textPalette.light} fontSize='18px'>
        {text || 'No data'}
      </Text>
    </Center>
  </Box>
)

interface ColumnData {
  dataKey: string
  text?: string
  width: string
}
type TableHeadersProps = FlexProps & {
  data: ColumnData[]
}

const TableHeaders: React.FC<TableHeadersProps> = ({data, height = '48px', ...otherProps}) => (
  <Flex
    direction='column'
    height={height}
    background={grays.background}
    mr={-4}
    mt='-17px'
    ml={-4}
    pr='20px'
    {...otherProps}>
    <Flex h={height}>
      {data.map(({text, width}, i) => {
        const isLastColumn = i === data.length - 1
        return <React.Fragment key={`${text}-${i}`}>
          <Box w={width}>
            {i === 0 ? (
              <Center h='100%'>
                <Text mt={2} fontWeight='500' fontSize='16px' color={textPalette.lighter}>{text}</Text>
              </Center>
            ) : (
              <Text mt={4} fontWeight='500' fontSize='16px' color={textPalette.lighter}>{text}</Text>
            )}
          </Box>
          {!isLastColumn && <Spacer />}
        </React.Fragment>
      })}
    </Flex>
  </Flex>
)

type RowData = {
  [index: string]: any | JSX.Element
  id: string
  linkTo?: (id: string) => string
  onClick?: (id: string) => void
}

type TableDataProps = FlexProps & {
  columns: ColumnData[]
  data: RowData
}

const TableData: React.FC<TableDataProps> = ({ columns, data, height, ...flexProps }) => {
  const { id, linkTo, onClick } = data
  const lastColumnIndex = columns.length - 1

  const renderedColumns = (
    <Flex
      direction='column'
      height={height}
      ml={-4}
      mr={-4}
      my={1}
      onClick={() => onClick && onClick(id)}
      {...flexProps}>
      <Flex h={height}>
        {columns.map(({dataKey, width}, i) => {
          const isLastColumn = lastColumnIndex === i
          return <React.Fragment key={`${dataKey}-${i}`}>
            <Box w={width} h='100%'>
              <Flex mr='32px' h='100%' textAlign='initial'>
                {data[dataKey]}
              </Flex>
            </Box>
            {!isLastColumn && <Spacer />}
          </React.Fragment>
        })}
      </Flex>
    </Flex>
  )

  if (linkTo == null) return renderedColumns
  return <Link to={linkTo(id)}>{renderedColumns}</Link>
}

interface TableRowsProps {
  accordions: boolean
  actionIcons: boolean
  columns: ColumnData[]
  data: TableDataProps['data'][]
  height: TableDataProps['height']
  isLoading: boolean
}

const TableRows: React.FC<TableRowsProps> = ({ accordions, actionIcons, columns, data, height, isLoading }) => {
  const { colors: { brand } } = useTheme()
  const dataToRender = isLoading ? SKELETON_DATA : data
  const isAccordionEnabled = accordions

  if (accordions || actionIcons) {
    return (
      <Accordion allowToggle>
        {dataToRender.map((item) => (
          <AccordionItem key={item.id}>
            <h2>
              <Skeleton key={item.id} height={height} startColor={brand[50]} endColor={brand[200]} isLoaded={!isLoading}>
                <AccordionButton background={white} _expanded={{ bg: brand[50] }} _hover={{ bg: brand[50] }} height={height} padding='0 20px 0 0'>
                  <Box flex='1' mr='-20px'>
                    <TableData columns={columns} data={item} height={height}/>
                  </Box>
                  {(() => {
                    if (!actionIcons) return null
                    return isAccordionEnabled ? <AccordionIcon /> : <FiChevronRight />
                  })()}
                </AccordionButton>
              </Skeleton>
            </h2>
            {isAccordionEnabled && <AccordionPanel background={white}>{item.accordion}</AccordionPanel>}
          </AccordionItem>
        ))}
      </Accordion>
    )
  }

  return (
    <>
      <Divider />
      {dataToRender.map((item) => (
        <>
          <TableData columns={columns} data={item} height={height}/>
          <Divider />
        </>

      ))}
      <Divider />
    </>
  )

}

interface TableProps {
  accordions?: TableRowsProps['accordions']
  actionIcons?: TableRowsProps['actionIcons']
  columns: TableHeadersProps['data']
  data: TableDataProps['data'][]
  emptyProps?: EmptyDataProps
  isLoading?: TableRowsProps['isLoading']
  headerProps?: Omit<TableHeadersProps, 'data'>
  rowHeight?: TableDataProps['height']
}

const Table: React.FC<TableProps> = ({accordions = false, actionIcons = true, columns, data, emptyProps = {}, headerProps = {}, isLoading = false, rowHeight = '100px'}) => {
  return <>
    <TableHeaders {...headerProps} data={columns}/>
    {!isLoading && data.length === 0 && <EmptyData height={rowHeight} {...emptyProps}/>}
    <TableRows accordions={accordions} actionIcons={actionIcons} columns={columns} data={data} height={rowHeight} isLoading={isLoading}/>
  </>
}

export default Table
