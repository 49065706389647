import { call, put, race, take, takeLatest } from 'redux-saga/effects'
import ActionIds from '../../ActionIds'
import { BaseAction } from '../../types'
import { getMany as getManyTeamMeetingsApi } from '../../teamMeetings/api'
import { getMany as getManyUsers } from '../../users/actions'
import {Model as TeamMeetingModel} from '../../../constants/TeamMeeting'

export default function* watch() {
  yield takeLatest(ActionIds.MEMBER_NOTES_GET_DATA_START, requestData)
}

function* requestData({payload: params}: BaseAction) {
  const { apiResponse: {response, error}, isCanceled } = yield race({
    apiResponse: call(getManyTeamMeetingsApi, params),
    isCanceled: take(ActionIds.MEMBER_NOTES_GET_DATA_CANCEL),
  })
  if (isCanceled) return

  if (error) {
    yield put({type: ActionIds.MEMBER_NOTES_GET_DATA_FAIL})
    return
  }

  const payload = response.data
  // dispatch new TeamMeeting records to TeamMeetings store
  yield put({type: ActionIds.TEAM_MEETINGS_GET_MANY_SUCCESS, payload})

  const userIds = (payload.data as Array<{attributes: TeamMeetingModel}>).reduce((memo, {
    attributes: { attendeeIds, creatorId, memberId },
  }) => ([...new Set([
    ...memo,
    ...(attendeeIds || []),
    creatorId,
    memberId,
  ])]), [] as string[])

  if (userIds.length > 0) {
    yield put(getManyUsers({ids: userIds, perPage: 1000})) // tell users store to fetch member's team of users by id
  }

  // dispatch member records to MemberList view store
  yield put({type: ActionIds.MEMBER_NOTES_GET_DATA_SUCCESS, payload})
}
