import { useEffect, useState } from 'react'
import { useFormikContext as useActualFormikContext } from 'formik'

/**
 * Add helpers to Formik context
 *
 */
const useFormikContext = <Values>() => {
  const [otherResetParams, shouldResetForm] = useState(false)
  const form = useActualFormikContext<Values>()

  useEffect(() => {
    if (otherResetParams != null) {
      shouldResetForm(false)
      form.resetForm({values: form.values})
    }
  }, [otherResetParams])

  /**
   * This is a more sane replacement for "resetForm" that allows for only some fields to be changed while also clearing the "dirty" flag
   *
   */
  const reinitialize = (values: Partial<Values>) => {
    Object.keys(values).forEach((k) => {
      form.setFieldValue(k, values[k], false)
    })
    shouldResetForm(true)
  }

  return {
    ...form,
    reinitialize,
  }
}

export default useFormikContext