import {Types as DirectiveTypes} from './Directive'
import { QUERY_PARAM_KEYS } from '../utils/Routing'

export const NEW_RECORD_ID = 'new'
export const NonDirectiveViews = {
  GENERAL_INFO: 'GeneralInfo',
  VIDEO_WISHES: 'VideoWishes',
} as const

export type NonDirectiveViewTypes = typeof NonDirectiveViews[keyof typeof NonDirectiveViews]

export const GeneralInformation = (id: string) => MemberDirective(id, NonDirectiveViews.GENERAL_INFO)
export const ProfileCreate = () => ProfilePage(NEW_RECORD_ID)
export const ProfilePage = (id: string) => `${Root()}profile/${id}`
export const AccountListPage = () => `${Root()}accounts`

export const AccountAdminPage = () => `${Root()}account/admin`
export const AccountAdminUsersPage = () => `${AccountAdminPage()}/users`
export const AccountAdminPaymentPage = () => `${AccountAdminPage()}/payment`
export const Login = () => `${Root()}login`
export const Signup = () => `${Root()}signup`
export const MemberDetail = (id: string) => `${Members()}/${id}`
export const MemberDirective = (id: string, type: DirectiveTypes | NonDirectiveViewTypes, step?: number) => (
  `${Members()}/${id}/directives/${type}${step == null ? '' : `?${QUERY_PARAM_KEYS.STEP}=${step}`}`
)
export const MemberNoteCreate = (id: string) => MemberNoteDetail(id, NEW_RECORD_ID)
export const MemberNoteDetail = (memberId: string, id: string) => `${Members()}/${memberId}/notes/${id}`
export const MemberNotes = (id: string) => `${Members()}/${id}/notes`
export const MemberTeam = (id: string) => `${Members()}/${id}/team`
export const Members = () => `${Root()}members`
export const Partners = () => `${Root()}partners`
export const PasswordReset = () => `${Root()}password-reset`
export const Root = () => '/'
export const ThemeSettings = () => `${Root()}theme-settings`

export const PricingOptions = () => `${Root()}pricing`
export const Payment = () => `${Root()}payment`

// Public must not have the route of /public (that is the root of the assets path)
export const Public = () => `${Root()}p`

export const PrivacyAndTos = () => `${Public()}/privacy_and_terms`
