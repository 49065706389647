// CAUTION: THIS PAGE IS REALLLLLLLY FRAGILE. BE CAREFUL OR THE APP WILL BREAK BADLY

import React, {useContext, useEffect} from 'react'
import {Route, Switch, useHistory, useLocation, withRouter} from 'react-router-dom'
import {RouteComponentProps} from 'react-router'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import isEmpty from 'lodash.isempty'
import {Context as DirtyFormAlertContext} from '../components/DirtyFormAlert'
import {useToast} from '../hooks/Toast'
import * as RoutePaths from '../constants/RoutePaths'
import {
  generateRedirectSearchParam,
  getAuthTokenParams,
  getConfirmationParam, getCurrentSubdomain,
  getPasswordResetParam,
  getRedirectParam,
  isCurrentPath,
  pathStartsWith,
} from '../utils/Routing'
import Authenticated from './Authenticated'
import Login from './Login'
import Public from './Public'
import LoadingPage from '../components/LoadingPage'
import {StoreState} from '../redux'
import {setTokens as setAuthTokens, signOut, unsetAccountId} from '../redux/auth/actions'
import {getOne as getCurrentUser, getProfile} from '../redux/users/actions'
import {getCurrent as getCurrentAccount} from '../redux/accounts/actions'
import Signup from './Signup'
import AccountList from './Authenticated/AccountList'
import NewAccount from './Authenticated/NewAccount'
import PasswordReset from './Authenticated/PasswordReset'
import RedirectToHome from './RedirectToHome'

export const useHooks = () => {

  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const toast = useToast()
  const {setDirtyFormAlert} = useContext(DirtyFormAlertContext)
  const isConfirmationSuccess = getConfirmationParam(location) === 'true'
  const isConfirmationFail = getConfirmationParam(location) === 'false'
  const isNotConfirmation = !isConfirmationSuccess && !isConfirmationFail
  const isPasswordResetSuccess = getPasswordResetParam(location) === 'true'
  const isPasswordResetFail = getPasswordResetParam(location) === 'false'
  const isNotPasswordReset = !isPasswordResetSuccess && !isPasswordResetFail
  const {
    currentUser,
    currentProfile,
    isAuthenticated,
  } = useSelector(({auth, users}: StoreState) => ({
    currentUser: users.currentUser,
    currentProfile: users.currentProfile,
    isAuthenticated: auth.accessToken != null,
  }), shallowEqual)

  const hasLoadedUser = !isEmpty(currentUser)
  const hasLoadedProfile = !isEmpty(currentProfile)

  const {currentAccount, currentlySetAccountId} = useSelector(({accounts, auth}: StoreState) => ({
    currentAccount: accounts.current,
    currentlySetAccountId: auth.accountId,
  }), shallowEqual)

  const hasLoadedCurrentAccount = !isEmpty(currentAccount)

  useEffect(() => {
    if (isConfirmationSuccess || isPasswordResetSuccess) {
      // initial page load after confirmation or password reset. Set tokens and load user
      dispatch(setAuthTokens(getAuthTokenParams(location)))
    }

    if (isConfirmationFail) {
      // initial page load after confirmation. Show error.
      toast({
        description: 'Invitation token invalid or expired.',
        status: 'error',
      })
      history.push(RoutePaths.Login())
      return
    }

    if (isPasswordResetFail) {
      // initial page load after password reset. Show error.
      toast({
        description: 'Password Reset token invalid or expired.',
        status: 'error',
      })
      history.push(RoutePaths.Login())
      return
    }
    if (isAuthenticated && !hasLoadedProfile) {
      dispatch(getProfile())
    }

    if (isAuthenticated && hasLoadedProfile) {
      if (isPasswordResetSuccess) {
        history.push(RoutePaths.PasswordReset())
        return
      }
      if (!isCurrentPath(RoutePaths.ProfileCreate(), location) && (!currentProfile.hasSetOwnPassword || !currentProfile.hasAcceptedTerms)) {
        history.push(RoutePaths.ProfileCreate())
        return
      }
      if (getCurrentSubdomain() !== 'app' || ![undefined, '', null].includes(currentlySetAccountId)) {
        dispatch(getCurrentAccount())
        dispatch(getCurrentUser())
        return
      }
      if (isCurrentPath(RoutePaths.AccountListPage(), location) ||
          isCurrentPath(RoutePaths.ProfileCreate(), location)
      ) {
        return
      }
      history.push({pathname: RoutePaths.AccountListPage(), search: 'from=login_or_signup'})
    }
  }, [isAuthenticated, currentProfile])

  useEffect(() => {
    if (isNotConfirmation && isNotPasswordReset && !isAuthenticated && !isCurrentPath(RoutePaths.Login(), location) && !isCurrentPath(RoutePaths.Signup(), location) && !pathStartsWith(RoutePaths.Public(), location)) {
      // initial page load OR logged out on non-login page. Go to login
      setDirtyFormAlert(false)
      history.push({
        pathname: RoutePaths.Login(),
        search: generateRedirectSearchParam(location),
      })
    }
  }, [isAuthenticated, isConfirmationFail, isConfirmationSuccess])

  useEffect(() => {
    if (pathStartsWith(RoutePaths.Public(), location) || isCurrentPath(RoutePaths.AccountListPage(), location)) {
      return
    }
    if (hasLoadedUser && !currentUser.isAdmin && !currentUser.isAdvocate) {
      toast({
        description: 'Access not authorized.',
        status: 'error',
      })
      if (getCurrentSubdomain() === 'app') {
        history.push(RoutePaths.AccountListPage())
        return
      }
      dispatch(unsetAccountId())
      dispatch(signOut())
      return
    }

    if (hasLoadedUser && (isCurrentPath(RoutePaths.Login(), location) || isCurrentPath(RoutePaths.Signup(), location) || isCurrentPath(RoutePaths.Root(), location))) {
      // currentUser loaded after login. Go to next page
      const redirectParam = getRedirectParam(location)
      const nextLocation = (redirectParam == null || isCurrentPath(RoutePaths.Signup(), location)) ? RoutePaths.Members() : decodeURIComponent(redirectParam)
      history.push(nextLocation)
    }
  }, [isAuthenticated, hasLoadedProfile, hasLoadedUser, isConfirmationSuccess])

  return {
    hasLoadedProfile,
    hasLoadedUser,
    hasLoadedCurrentAccount,
    isAuthenticated,
  }
}

type RoutesProps = RouteComponentProps<any>
const Routes: React.FC<RoutesProps> = () => {
  const {hasLoadedProfile, hasLoadedCurrentAccount, hasLoadedUser, isAuthenticated} = useHooks()
  return (
    <Switch>
      <Route path={RoutePaths.Signup()} component={Signup} exact={true}/>
      <Route path={RoutePaths.Login()} component={Login} exact={true}/>`
      <Route path={RoutePaths.Public()} component={Public}/>
      <Route path={RoutePaths.AccountListPage()}
        component={getCurrentSubdomain() !== 'app' ? RedirectToHome : AccountList} exact/>
      <Route path={RoutePaths.ProfileCreate()} component={hasLoadedProfile ? NewAccount : LoadingPage} exact/>
      <Route path={RoutePaths.PasswordReset()} component={hasLoadedProfile ? PasswordReset : LoadingPage} exact/>
      <Route path={RoutePaths.Root()}
        component={isAuthenticated && hasLoadedCurrentAccount && hasLoadedUser ? Authenticated : LoadingPage}/>
    </Switch>
  )
}

export default withRouter(Routes)