import React, { useCallback, useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { useHistory, useLocation, withRouter } from 'react-router-dom'
import { StoreState } from '../../../redux'
import { getMany as getPartners } from '../../../redux/accounts/actions'
import {
  Avatar,
  Box,
  Button,
  Center,
  Container,
  Flex,
  HStack,
  Link,
  Text,
  useDisclosure,
  useTheme,
} from '@chakra-ui/react'
import InviteAdminModal from './InviteAdminModal'
import NewPartnerModal from './NewPartnerModal'
import { purples, grays, textPalette, white } from '../../../constants/Colors'
import { Model as AccountModel } from '../../../constants/Account'
import ListTopComponent from '../../../components/ListTopComponent'
import Pagination from '../../../components/Pagination'
import Table from '../../../components/Table'
import { isDone, isLoading } from '../../../redux/AsyncState'
import * as RoutePaths from '../../../constants/RoutePaths'
import isEmpty from 'lodash.isempty'
import debounce from 'lodash.debounce'
import {
  QUERY_PARAM_KEYS,
  getNewSearchString,
  getPageParam,
  getSearchTermParam,
  getSubdomainRoute,
  privateFileUrl,
} from '../../../utils/Routing'
import { accountNameInitialsGenerator } from '../../../utils/BusinessLogic'

type PartnerListProps = RouteComponentProps<any>

const TABLE_COLUMN_DATA = [
  { dataKey: 'nameData', text: 'Partner', width: '35%' },
  { dataKey: 'subdomainData', text: 'Subdomain', width: '35%' },
  { dataKey: 'actionData', text: '', width: '30%' },
]

interface PartnerTableDataInterface {
  onClickInvite: (id: string) => void
  account: AccountModel
  themeLinkHoverBg: string
}
const tableDataForPartner = ({account, onClickInvite, themeLinkHoverBg}: PartnerTableDataInterface) => ({
  id: account.id,
  nameData: (
    <Flex>
      <Center h='64px'>
        <Avatar
          size='md'
          getInitials={accountNameInitialsGenerator}
          name={account.name}
          src={privateFileUrl(account.avatar)}
          margin='32px'
          color={white}
          bg={purples.avatar}
        />
      </Center>
      <Center h='64px'>
        <Text fontSize='16px' fontWeight='400' color={textPalette.light}>{account.name}</Text>
      </Center>
    </Flex>
  ),
  subdomainData: (
    <Center>
      <Link href={getSubdomainRoute(account.subdomain)} target='_blank'>
        {getSubdomainRoute(account.subdomain).split('//')[1]}
      </Link>
    </Center>
  ),
  actionData: (
    <Center width='100%'>
      <HStack spacing={2} width='100%' justifyContent='flex-end'>
        <Button
          _hover={{backgroundColor: themeLinkHoverBg, color: white}}
          as={Link}
          href={getSubdomainRoute(account.subdomain, RoutePaths.ThemeSettings())}
          target='_blank'>
          Theme Settings
        </Button>
        <Button
          onClick={() => onClickInvite(account.subdomain)}
          variant='transparentPurpleOutline'
        >
        Invite Admin
        </Button>
      </HStack>
    </Center>
  ),
})

function PartnerList(_props: PartnerListProps) {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const { colors: { brand } } = useTheme()
  const {
    isOpen: isNewPartnerModalOpen,
    onClose: closeNewPartnerModal,
    onOpen: openNewPartnerModal,
  } = useDisclosure()
  const {
    isOpen: isInviteAdminModalOpen,
    onClose: closeInviteAdminModal,
    onOpen: openInviteAdminModal,
  } = useDisclosure()
  const {
    isDoneCreating,
    isLoadingStore,
    lastCreatedPartner,
    orderedPartners,
    totalPages,
  } = useSelector(({ accounts }: StoreState) => ({
    isDoneCreating: isDone(accounts.creatingState),
    isLoadingStore: isLoading(accounts.loadingState, accounts.loadingState),
    lastCreatedPartner: accounts.lastCreatedId == null ? null : accounts.records[accounts.lastCreatedId],
    orderedPartners: accounts.recentOrderedPartnerIds.map(id => accounts.records[id]),
    totalPages: accounts.totalPages,
  }), shallowEqual)
  const [isMounted, setIsMounted] = useState(false)
  const [selectedSubdomainName, setSelectedSubdomainName] = useState<string>()
  const debouncedDispatch = useCallback(debounce(dispatch, 300), [])
  const debouncedUpdateUrlParams = useCallback(debounce((newSearch: string, newPage: number) => history.replace({
    pathname: RoutePaths.Partners(),
    search: getNewSearchString({
      [QUERY_PARAM_KEYS.PAGE]: String(newPage),
      [QUERY_PARAM_KEYS.SEARCH_TERM]: String(newSearch),
    }),
  }), 500, {leading: true}), [])

  const currentPageParam = getPageParam(location) || String(1)
  const [currentPage, setCurrentPage] = useState(parseInt(currentPageParam, 10))
  const [searchTerm, setSearchTerm] = useState(getSearchTermParam(location) || '')

  const onChangePage = (newPage: number) => setCurrentPage(newPage)
  const onChangeSearch = (newSearch: string) => setSearchTerm(newSearch)

  useEffect(() => {
    if (!isMounted) {
      setIsMounted(true)
      return
    }

    dispatch(getPartners({
      name: isEmpty(searchTerm) ? null : searchTerm,
      page: currentPage,
    }))
  }, [currentPage, isMounted])

  useEffect(() => {
    if (isMounted && isDoneCreating) {
      onChangeSearch(lastCreatedPartner!.name)
    }
  }, [isDoneCreating, isMounted])

  useEffect(() => {
    debouncedUpdateUrlParams(searchTerm, currentPage)
  }, [currentPage, searchTerm])

  useEffect(() => {
    if (selectedSubdomainName != null) {
      openInviteAdminModal()
    }
  }, [selectedSubdomainName])

  useEffect(() => {
    if (!isInviteAdminModalOpen) {
      setSelectedSubdomainName(undefined)
    }
  }, [isInviteAdminModalOpen])

  useEffect(() => {
    if (!isMounted) return

    const isSearchTermEmpty = isEmpty(searchTerm)
    if (!isSearchTermEmpty && searchTerm.length < 3) return

    onChangePage(1)
    debouncedDispatch(getPartners({
      name: isSearchTermEmpty ? null : searchTerm,
      page: currentPage,
    }))
  }, [searchTerm])

  const tableData = orderedPartners.map(p => tableDataForPartner({
    onClickInvite: setSelectedSubdomainName,
    account: p,
    themeLinkHoverBg: brand[600],
  }))

  return (
    <Container maxW='1100px' mt={20} mb='10vh'>
      <InviteAdminModal isOpen={isInviteAdminModalOpen} onClose={closeInviteAdminModal} subdomain={selectedSubdomainName}/>
      <NewPartnerModal isOpen={isNewPartnerModalOpen} onClose={closeNewPartnerModal}/>
      <ListTopComponent onClickNew={openNewPartnerModal} modelName='Partner' onSearchUpdate={onChangeSearch} searchTerm={searchTerm}/>
      <Box shadow='lg' backgroundColor='white' border='1px solid' borderColor={grays.light}>
        <Table
          actionIcons={false}
          columns={TABLE_COLUMN_DATA}
          data={tableData}
          emptyProps={{margin: '0', text: 'No results found, refine search and try again'}}
          headerProps={{background: grays.input, margin: '0'}}
          isLoading={isLoadingStore}
          rowHeight={'64px'}
        />
      </Box>
      <Pagination currentPage={currentPage} onPageChange={onChangePage} totalPages={totalPages}/>
    </Container>
  )
}

export default withRouter(PartnerList)
