import * as Yup from 'yup'
import { formatISO, isValid as isValidDate, parse as parseDateString, addHours } from 'date-fns'
import isEmpty from 'lodash.isempty'
import { Model as UserModel } from '../constants/User'
import { PhoneNumberUtil } from 'google-libphonenumber'
import axios from 'axios'
import fileDownload from 'js-file-download'

export const DATE_FORMAT = 'MM/dd/yyyy'
export const TIME_FORMAT = 'HH:mm'
export const TIME_12HR_FORMAT = 'h:mm a'
export const HEX_COLOR_REGEX = /^#(?:[0-9a-fA-F]{3}){1,2}$/

/**
 * Generates a Formik validator for a Yup Schema which will return _all_ validation errors instead of just the first.
 *
 * @param schema - The Yup schema object
 * @returns A Formik validator for returning an array of errors instead of the first
 *
 */
export const fullValidatorForSchema = (schema: Yup.ObjectSchema<any>) => (values: any) => schema.validate(values, {
  abortEarly: false,
  strict: false,
}).then(() => ({})).catch(({inner}) => inner.reduce((memo, {path, message}) => ({
  ...memo,
  [path]: (memo[path] || []).concat(message),
}), {}))

/**
 * Automatically formats numeric input from standard text field to match a template
 *
 * @param value - The current text field value
 * @param template - The template for the final value
 * @returns The formatted text
 *
 */
export const parseNumericTemplate = (value: string, template: string) => {
  const valueChars = value.match(/\d/g) || []
  return Array.from(template).reduce((memo, templateChar) => {
    const isNumberSlot = templateChar === '*'
    if (valueChars[0] == null) return memo
    if (isNumberSlot) return memo + valueChars.shift() as string

    return memo + templateChar
  }, '')
}

const PHONE_TEMPLATE = '(***) ***-****'
export const parsePhoneNumber = (value?: string) => parseNumericTemplate(value || '', PHONE_TEMPLATE)

const DATE_TEMPLATE = '**/**/****'
export const parseDate = (value?: string) => parseNumericTemplate(value || '', DATE_TEMPLATE)

interface GetIsoDateFromStringOptions {
  format?: string
  nextAfter?: Date
}
export const getIsoDateFromString = (
  value?: string,
  {nextAfter, format = DATE_FORMAT}: GetIsoDateFromStringOptions = {},
) => {
  if (isEmpty(value) ) return

  const date = parseDateString(value!, format, new Date())
  if (!isValidDate(date)) return
  if (nextAfter == null || date > nextAfter) return formatISO(date)

  return formatISO(addHours(date, 24))
}

/**
 * Pass to Yup validation to transform Dates
 *
 * @param format - date-fns format to parse
 * @param value - value transformed by yup (unused)
 * @param originalValue - raw string value to transform
 * @returns parsed date or undefined
 *
 */
export const yupTransformDate = (_value: any, originalValue: string | Date, format = DATE_FORMAT) => {
  if (typeof originalValue !== 'string') return originalValue

  if (originalValue.length !== format.length) return false

  const parsedDate = parseDateString(originalValue, format, new Date())

  return isValidDate(parsedDate) ? parsedDate : false
}

export const userNameAttrsFromFullName = (fullName?: string) => {
  if (fullName == null) return {}

  const parts = fullName.trim().split(' ')
  const lastPartIndex = parts.length - 1

  return {
    firstName: parts[0] || '',
    lastName: lastPartIndex > 0 ? parts[lastPartIndex] : '',
    middleName: parts.slice(1, lastPartIndex).join(' '),
  }
}

export const displayFullName = (user: UserModel): string => {
  let final = user.firstName
  if (user.middleName !== '' && user.middleName !== null) {
    final += ` ${user.middleName}`
  }
  if (user.lastName !== '' && user.lastName !== null) {
    final += ` ${user.lastName}`
  }
  return final || ''
}

const phoneUtil = PhoneNumberUtil.getInstance()

export const getNationalNo = (str: string): string => {
  try {
    return phoneUtil.parseAndKeepRawInput(str).getNationalNumber()
  } catch (error) {
    return ''
  }
}

export const getCountryCode = (str: string): string => {
  try {
    return phoneUtil.parseAndKeepRawInput(str).getCountryCode()
  } catch (error) {
    return ''
  }
}


export const isPhoneValid = (phone: string) => {
  try {
    const no = phoneUtil.parseAndKeepRawInput(phone)
    return phoneUtil.isValidNumber(no)
  } catch (error) {
    return true
  }
}

export const downloadFile = (url: string, name: string) => {
  axios.get(url, {
    responseType: 'blob',
  }).then((res) => {
    fileDownload(res.data, name || '')
  })
}