import { Model as Directive, StatusesType } from './Directive'
import { Model as HealthConcern } from './HealthConcern'
import { Model as TeamRole } from './TeamRole'
import {AccountRoles} from './Account'

export interface Model {
  avatar: string
  advocateIds?: string[]
  alternateSurrogateIds?: string[]
  birthday?: string
  city?: string
  country?: string
  email: string
  pronouns: string
  generatedPdfComplete: boolean
  finalPdfComplete: boolean
  finalPdfQrCodeUrl?: string
  finalPdfSecretGeneratedAt?: string
  firstName?: string
  hasAcceptedTerms: boolean
  hasSetOwnPassword: boolean
  healthcareDirectivesLastUpdatedAt?: string
  healthPlanAbbrv?: string
  healthPlanId?: string
  healthPlanName?: string
  id: string
  isAdmin: boolean
  isAdvocate: boolean
  isMember: boolean
  isSystemAdmin: boolean
  lastName?: string
  medicareRecordNumber?: string
  memberRelationship?: string
  teamRoles: TeamRole[]
  middleName?: string
  namePrefix?: string
  nameSuffix?: string
  phoneNumber?: string
  countryCodePhoneNo?: string
  polstDirectiveId?: Directive['id']
  polstDirectiveStepsCompleted?: Directive['stepsCompleted']
  primaryCareProviderIds?: string[]
  primaryHealthConcernNames?: Array<HealthConcern['name']>
  specialty?: string
  state?: string
  streetAddress?: string
  healthcarePt1DirectiveId?: Directive['id']
  healthcarePt1DirectiveStepsCompleted?: Directive['stepsCompleted']
  flexDirectiveId?: Directive['id']
  flexDirectiveStepsCompleted?: Directive['stepsCompleted']
  flexDirectiveTotalSteps: number
  flexDirectiveComplete: boolean
  surrogateIds?: string[]
  teamEnvelopeStatus?: StatusesType[keyof StatusesType]
  teamId?: string
  teamLastSentEnvelopeAt?: string
  teamMeetingsLastUpdatedAt?: string
  teamMostRecentEnvelopeLogCreatedAt?: string
  wishesVideo: string
  wishesVideoId: string
  wishesVideoStatus?: StatusesType[keyof StatusesType]
  zipCode?: string
  witnessEmail1?: string
  witnessEmail2?: string
  currentAccountRole?: string
}

export const userCanOwn: (user: Model) => boolean = (user) => user.isSystemAdmin || user.currentAccountRole === AccountRoles.OWNER
export const userCanAdmin: (user: Model) => boolean = (user: Model) => userCanOwn(user) || user.currentAccountRole === AccountRoles.ADMIN
export const userCanAdvocate: (user: Model) => boolean = (user: Model) => userCanAdmin(user) || user.currentAccountRole === AccountRoles.ADVOCATE
export const userCanObserve: (user: Model) => boolean = (user: Model) => userCanAdvocate(user) || user.currentAccountRole === AccountRoles.OBSERVER

export const MY_ID = 'my'

