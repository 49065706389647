import ActionIds from '../ActionIds'
import AsyncState from '../AsyncState'
import {Model} from '../../constants/User'
import {Model as Profile} from '../../constants/Profile'
import {BaseAction, Records} from '../types'

const initialState = {
  creatingState: AsyncState.NOT_STARTED,
  currentUser: {} as Model,
  currentProfile: {} as Profile,
  errorData: null as null | string | Records<string[]>,
  lastCreatedId: undefined as unknown as string,
  loadingState: AsyncState.NOT_STARTED,
  records: {} as Records<Model>,
  recentOrderedUserIds: [] as any[],
  updatingState: AsyncState.NOT_STARTED,
  deletingState: AsyncState.NOT_STARTED,
  directiveMemberId: null as null | string,
}

export type StateType = typeof initialState;

export default function reducer(state = initialState, action: BaseAction): StateType {
  switch (action.type) {
    case ActionIds.USERS_GET_ONE_START: // fall-through
    case ActionIds.USERS_GET_MANY_START:
      return { ...state, loadingState: AsyncState.LOADING }
    case ActionIds.USERS_UPDATE_START:
    case ActionIds.USERS_ASSIGN_TEAM_MEMBER_START:
    case ActionIds.USERS_UPDATE_PROFILE_START:
      return { ...state, updatingState: AsyncState.LOADING }
    case ActionIds.USERS_UPDATE_SUCCESS: {
      const newUser = action.payload.data.attributes
      const { records } = state
      return { ...state, records: {...records, [newUser.id]: newUser}, updatingState: AsyncState.DONE }
    }
    case ActionIds.USERS_INVITE_START: // fall-through
    case ActionIds.USERS_SIGNUP_START: // fall-through
    case ActionIds.USERS_CREATE_START:
      return { ...state, creatingState: AsyncState.LOADING }
    case ActionIds.USERS_INVITE_SUCCESS: // fall-through
    case ActionIds.USERS_SIGNUP_SUCCESS: // fall-through
    case ActionIds.USERS_CREATE_SUCCESS: {
      const newUser = action.payload.data.attributes
      const { records } = state
      return {
        ...state,
        creatingState: AsyncState.DONE,
        lastCreatedId: newUser.id,
        records: { ...records, [newUser.id]: newUser },
      }
    }
    case ActionIds.USERS_GET_ONE_SUCCESS: {
      const newUser = action.payload.data.attributes
      const { records } = state
      return { ...state, records: {...records, [newUser.id]: newUser}, loadingState: AsyncState.DONE }
    }
    case ActionIds.USERS_GET_MANY_SUCCESS: {
      const users = action.payload.data.map(({attributes}) => attributes) as Model[]
      const orderedUserIds = action.payload.data.map(({id}) => id)
      const { records } = state
      return {
        ...state,
        loadingState: AsyncState.DONE,
        records: users.reduce((memo: Records<Model>, u: Model) => ({...memo, [u.id]: u}), records),
        recentOrderedUserIds: orderedUserIds,
      }
    }
    case ActionIds.USERS_SET_CURRENT:
      return { ...state, currentUser: action.payload.data.attributes }
    case ActionIds.USERS_GET_MANY_FAIL: // fall-through
    case ActionIds.USERS_GET_ONE_FAIL:
    case ActionIds.USERS_GET_PROFILE_FAIL:
      return { ...state, loadingState: AsyncState.ERROR }
    case ActionIds.USERS_INVITE_FAIL: // fall-through
    case ActionIds.USERS_CREATE_FAIL:
    case ActionIds.USERS_SIGNUP_FAIL:
      const genErr = parseError(action.payload)
      return { ...state, errorData: genErr, creatingState: AsyncState.ERROR }
    case ActionIds.USERS_UPDATE_FAIL:
      const updateErr = parseError(action.payload)
      return { ...state, errorData: updateErr, updatingState: AsyncState.ERROR }
    case ActionIds.AUTH_DESTROY_SUCCESS:
      return { ...state, currentUser: {} as Model }
    case ActionIds.USERS_DELETE_START:
      return { ...state, deletingState: AsyncState.LOADING }
    case ActionIds.USERS_DELETE_FAIL:
      return { ...state, deletingState: AsyncState.ERROR }
    case ActionIds.USERS_DELETE_SUCCESS:
      return { ...state, deletingState: AsyncState.DONE }
    case ActionIds.USERS_SET_DIRECTIVE_MEMBERID:
      return { ...state, directiveMemberId: action.payload.params.memberId}
    case ActionIds.USERS_CLEAR_ERRORS:
      return { ...state, errorData: null }
    case ActionIds.USERS_GET_PROFILE_SUCCESS:
      return { ...state, currentProfile: action.payload.data.attributes }
    case ActionIds.USERS_UPDATE_PROFILE_SUCCESS:
      return { ...state, currentProfile: action.payload.data.attributes, updatingState: AsyncState.DONE }
    case ActionIds.USERS_RESET:
      return { ...initialState }
    default:
      return state
  }
}

const parseError = payload => {
  let errData = payload.data
  if (typeof payload.data === 'object' && !Array.isArray(payload.data)) {
    errData = payload.data.error || payload.data.errors
  }
  return errData
}
