import React, { useEffect, useState } from 'react'
import * as ReactRedux from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { withRouter } from 'react-router-dom'
import {
  Box,
  Center,
  SlideFade,
  Stack,
  Text,
} from '@chakra-ui/react'
import {signOut} from '../../redux/auth/actions'
import { grays, white } from '../../constants/Colors'
import { StoreState } from '../../redux'
import { isDone, isNotStarted } from '../../redux/AsyncState'
import ForgotPasswordForm from './ForgotPasswordForm'
import LoginForm from './LoginForm'
import Thanks from './Thanks'

enum SlideViews {
  FORGOT_PASSWORD,
  LOGIN,
  THANKS
}

// visibleForTesting
export const useHooks = () => {
  const { shallowEqual, useDispatch, useSelector } = ReactRedux
  const dispatch = useDispatch()
  const [slideView, setSlideView] = useState(SlideViews.LOGIN)
  const [nextSlideView, setNextSlideView] = useState(SlideViews.LOGIN)

  const {
    passwordResetState,
  } = useSelector(({auth}: StoreState) => ({
    passwordResetState: auth.passwordResetState,
  }), shallowEqual)

  useEffect(() => {
    dispatch(signOut())
  }, [])

  useEffect(() => {
    if (isDone(passwordResetState)) return setNextSlideView(SlideViews.THANKS)
    if (isNotStarted(passwordResetState)) return setNextSlideView(SlideViews.LOGIN)
  }, [passwordResetState])

  useEffect(() => {
    setTimeout(() => setSlideView(nextSlideView), 300)
  }, [nextSlideView])

  return {
    nextSlideView,
    setNextSlideView,
    slideView,
  }
}

const Login: React.FC<RouteComponentProps<any>> = (props) => {
  const {nextSlideView, setNextSlideView, slideView} = useHooks()

  return (
    <Center height='100%' bg={grays.background}>
      <Stack align='center' spacing='20px'>
        <Text fontSize='42px' fontWeight='500'>Log In</Text>
        <SlideFade in={nextSlideView === slideView}>
          <Box shadow='md' backgroundColor={white} h='400px' w='730px'>
            {(() => {
              switch(slideView) {
                case SlideViews.FORGOT_PASSWORD: return <ForgotPasswordForm {...props} onClickCancel={() => setNextSlideView(SlideViews.LOGIN)}/>
                case SlideViews.THANKS: return <Thanks {...props} onClickResend={() => setNextSlideView(SlideViews.FORGOT_PASSWORD)}/>
                case SlideViews.LOGIN: // fall-through
                default: return <LoginForm {...props} onClickForgotPassword={() => setNextSlideView(SlideViews.FORGOT_PASSWORD)}/>
              }
            })()}
          </Box>
        </SlideFade>
      </Stack>
    </Center>
  )
}

export default withRouter(Login)
