import { BaseAction } from '../../types'
import ActionIds from '../../ActionIds'

export const cancelGetData = (): BaseAction => ({type: ActionIds.MEMBER_LIST_GET_DATA_CANCEL})
export const getData = (params: any = {}): BaseAction => ({
  type: ActionIds.MEMBER_LIST_GET_DATA_START,
  payload: params,
})
export const resetData = (): BaseAction => ({
  type: ActionIds.MEMBER_LIST_RESET_DATA,
})
