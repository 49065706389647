import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import * as ReactRedux from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { FormikContextType } from 'formik'
import { Model as DirectiveModel, Types as DirectiveTypes } from '../../../../constants/Directive'
import * as RoutePaths from '../../../../constants/RoutePaths'
import { Context as DirtyFormAlertContext } from '../../../../components/DirtyFormAlert'
import { update as updateDirective } from '../../../../redux/directives/actions'
import { StoreState } from '../../../../redux'
import { isDone, isError } from '../../../../redux/AsyncState'
import {
  Alert,
  AlertIcon,
  Box,
  Center,
  SlideFade,
} from '@chakra-ui/react'
import { MemberDirectiveChildProps as BaseProps } from '..'
import { totalStepsForDirectiveType } from '../../../../utils/BusinessLogic'
import { white } from '../../../../constants/Colors'
import { HealthcarePt1Steps as Steps } from '../../../../constants/Directive'
import ProgressSegments from '../../../../components/ProgressSegments'
import AppointSurrogateForm from './AppointSurrogateForm'
import SurrogatePowers1 from './SurrogatePowers1'
import SurrogatePowers2 from './SurrogatePowers2'
import SurrogatePowers3 from './SurrogatePowers3'
import ReviewStep from '../ReviewStep'
import ThanksStep from '../ThanksStep'
import Roles from '../../../../constants/Roles'
import { userNameAttrsFromFullName, getNationalNo, getCountryCode } from '../../../../utils/Forms'

export const StepTitles = {
  [Steps.APPOINT_SURROGATE]: 'Appoint Agent',
  [Steps.SURROGATE_POWERS_1]: 'Agent Powers 1',
  [Steps.SURROGATE_POWERS_2]: 'Agent Powers 2',
  [Steps.SURROGATE_POWERS_3]: 'Agent Powers 3',
  [Steps.REVIEW]: 'Review',
} as const

const TOTAL_STEPS = totalStepsForDirectiveType(DirectiveTypes.HEALTHCARE_PT1_DIRECTIVE)
type HealthcareHealthcarePt1DirectiveStepsProps = BaseProps

export const formValueDTO = (values, member, step, directive) => {
  const alternateSurrogateId = member.alternateSurrogateIds![0]
  const secondAlternateSurrogateId = member.alternateSurrogateIds![1]
  const hasAlternateSurrogate = alternateSurrogateId != null
  const hasSecondAlternateSurrogate = secondAlternateSurrogateId != null
  const alternateSurrogate = values.hasAlternate ? {
    ...userNameAttrsFromFullName(values.alternateFullName),
    city: values.alternateCity,
    email: values.alternateEmail,
    pronouns: values.alternatePronouns,
    memberRelationship: values.alternateMemberRelationship,
    phoneNumber: getNationalNo(values.alternatePhone) ?? '',
    countryCodePhoneNo: getCountryCode(values.alternatePhone) ?? '',
    teamRole: Roles.ALTERNATE_SURROGATE,
    state: values.alternateState,
    streetAddress: values.alternateStreetAddress,
    userId: alternateSurrogateId,
    zipCode: values.alternateZipCode,
    country: values.alternateCountry,
  } : {}

  const secondAlternateSurrogate = values.hasSecondAlternate ? {
    ...userNameAttrsFromFullName(values.secondAlternateFullName),
    city: values.secondAlternateCity,
    email: values.secondAlternateEmail,
    pronouns: values.secondAlternatePronouns,
    memberRelationship: values.secondAlternateMemberRelationship,
    phoneNumber: getNationalNo(values.secondAlternatePhone) ?? '',
    countryCodePhoneNo: getCountryCode(values.secondAlternatePhone) ?? '',
    teamRole: Roles.ALTERNATE_SURROGATE,
    state: values.secondAlternateState,
    streetAddress: values.secondAlternateStreetAddress,
    userId: secondAlternateSurrogateId,
    zipCode: values.secondAlternateZipCode,
    country: values.secondAlternateCountry,
  } : {}

  const directiveParams = {
    alternateSurrogate: hasAlternateSurrogate && !values.hasAlternate ? { delete: alternateSurrogateId } : alternateSurrogate,
    secondAlternateSurrogate: hasSecondAlternateSurrogate && !values.hasSecondAlternate ? { delete: secondAlternateSurrogateId } : secondAlternateSurrogate,
    currentStep: step,
    memberId: directive.memberId,
    surrogate: {
      ...userNameAttrsFromFullName(values.fullName),
      city: values.city,
      email: values.email,
      pronouns: values.pronouns,
      memberRelationship: values.memberRelationship,
      phoneNumber: getNationalNo(values.phone) ?? '',
      countryCodePhoneNo: getCountryCode(values.phone) ?? '',
      teamRole: Roles.SURROGATE,
      state: values.state,
      streetAddress: values.streetAddress,
      userId: member.surrogateIds![0],
      zipCode: values.zipCode,
      country: values.country,
    },
  }

  return directiveParams
}

const useHooks = ({ goToStep, isGivingThanks, isTransitioning, goToType, onClickThanks, step }: HealthcareHealthcarePt1DirectiveStepsProps) => {
  const { shallowEqual, useDispatch, useSelector } = ReactRedux
  const { setDirtyFormAlert } = useContext(DirtyFormAlertContext)
  const { id } = useParams<{ id: string; type: DirectiveTypes }>()
  const dispatch = useDispatch()
  const history = useHistory()
  const [shouldQuitOnSave, setShouldQuitOnSave] = useState(false)
  const [isMounted, setIsMounted] = useState(false)

  const {
    allDirectives,
    member,
    updatingState,
  } = useSelector(({ directives, users }: StoreState) => ({
    allDirectives: directives.records,
    member: users.records[id]!,
    updatingState: directives.updatingState,
  }), shallowEqual)

  const directive = allDirectives[member.healthcarePt1DirectiveId!]
  const isReviewStep = useMemo(() => step === Steps.REVIEW, [step])
  const isUpdateDone = isDone(updatingState)
  const isUpdateError = isError(updatingState)
  const goBack = () => goToStep(step - 1)
  const goToMemberDetail = useCallback(() => history.push(RoutePaths.MemberDetail(id)), [history, id])
  const gotToNextType = useCallback(() => goToType(DirectiveTypes.HEALTHCARE_FLEX_DIRECTIVE, true), [goToType])
  const continueLater = (form?: FormikContextType<any>, isAppointingSurrogate?: boolean) => {
    if (form == null) return goToMemberDetail()

    setShouldQuitOnSave(true)
    form.setSubmitting(true)
    const directiveParams = formValueDTO(form.values, member, step, directive)

    switch (isAppointingSurrogate){
      case true:
        dispatch(updateDirective(directiveParams, directive!.id!))
        break
      case false:
        dispatch(updateDirective(form.values, directive!.id!))
        break
    }
  }

  useEffect(() => {
    setIsMounted(true)
  }, [])

  useEffect(() => {
    if (isUpdateError) {
      setShouldQuitOnSave(false)
    }
  }, [isUpdateError])

  useEffect(() => {
    if (!isMounted || !isUpdateDone) return

    if (shouldQuitOnSave) {
      return setDirtyFormAlert(false, () => goToMemberDetail())
    }

    if (isReviewStep) return gotToNextType()

    setDirtyFormAlert(false, () => goToStep(step + 1))
  }, [isUpdateDone])

  return {
    continueLater,
    directive,
    goBack,
    isGivingThanks,
    isTransitioning,
    isUpdateError,
    onClickThanks,
    step,
  }
}

export interface ChildFormProps extends HealthcareHealthcarePt1DirectiveStepsProps {
  continueLater: (form?: FormikContextType<any>, isAppointSurrogate?: boolean) => void
  currentStep: number
  directive: DirectiveModel
  goBack: () => void
  totalSteps: number
}

const HealthcareHealthcarePt1DirectiveSteps: React.FC<HealthcareHealthcarePt1DirectiveStepsProps> = (props) => {
  const {
    continueLater,
    directive,
    goBack,
    isGivingThanks,
    isTransitioning,
    isUpdateError,
    onClickThanks,
    step,
  } = useHooks(props)
  const sharedFormProps: ChildFormProps = {
    ...props,
    continueLater,
    currentStep: step,
    directive,
    goBack,
    totalSteps: TOTAL_STEPS,
  }

  return (
    <Box>
      {!isGivingThanks && <ProgressSegments currentStep={step} totalSteps={TOTAL_STEPS} />}
      <SlideFade in={!isTransitioning}>
        <Box shadow='md' backgroundColor={white} w='100%' mb='160px'>
          <Box height='0'>{isUpdateError && (
            <Alert status='error'>
              <AlertIcon />
              <Center flex='1'>Something went wrong. Please Try again.</Center>
            </Alert>
          )}</Box>
          <Center height='100%' padding='48px 95px'>
            {(() => {
              if (isGivingThanks) return (
                <ThanksStep
                  buttonText='Continue to Part 1'
                  onClickContinue={onClickThanks}
                  title='General Information complete!' />
              )

              switch (step) {
                case Steps.APPOINT_SURROGATE: return <AppointSurrogateForm {...sharedFormProps} />
                case Steps.SURROGATE_POWERS_1: return <SurrogatePowers1 {...sharedFormProps} />
                case Steps.SURROGATE_POWERS_2: return <SurrogatePowers2 {...sharedFormProps} />
                case Steps.SURROGATE_POWERS_3: return <SurrogatePowers3 {...sharedFormProps} />
                case Steps.REVIEW: // fall-through
                default: return (
                  <ReviewStep
                    {...sharedFormProps}
                    text='Review Healthcare Directive Part 1 to make sure it reflects patient instructions and wishes accurately before sending for signatures.' />
                )
              }
            })()}
          </Center>
        </Box>
      </SlideFade>
    </Box>
  )
}

export default HealthcareHealthcarePt1DirectiveSteps