import { css } from '@emotion/react'
import GothamBlack from './Gotham-Black.otf'
import GothamBlackIta from './Gotham-BlackIta.otf'
import GothamBold from './Gotham-Bold.otf'
import GothamBoldIta from './Gotham-BoldIta.otf'
import GothamBook from './Gotham-Book.otf'
import GothamBookIta from './Gotham-BookIta.otf'
import GothamLight from './Gotham-Light.otf'
import GothamLightIta from './Gotham-LightIta.otf'
import GothamMedium from './Gotham-Medium.otf'
import GothamMediumIta from './Gotham-MediumIta.otf'
import GothamThin from './Gotham-Thin.otf'
import GothamThinIta from './Gotham-ThinIta.otf'
import GothamUltra from './Gotham-Ultra.otf'
import GothamUltraIta from './Gotham-UltraIta.otf'
import GothamXLight from './Gotham-XLight.otf'
import GothamXLightIta from './Gotham-XLightIta.otf'

export default css`
  @font-face {
    font-family: 'Gotham';
    src: local('Gotham Thin'), url(${GothamThin}) format("opentype");
    font-weight: 100;
    font-style: normal;
  }

  @font-face {
    font-family: 'Gotham';
    src: local('Gotham Thin Italic'), url(${GothamThinIta}) format("opentype");
    font-weight: 100;
    font-style: italic;
  }

  @font-face {
    font-family: 'Gotham';
    src: local('Gotham X Light'), url(${GothamXLight}) format("opentype");
    font-weight: 200;
    font-style: normal;
  }

  @font-face {
    font-family: 'Gotham';
    src: local('Gotham X Light Italic'), url(${GothamXLightIta}) format("opentype");
    font-weight: 200;
    font-style: italic;
  }

  @font-face {
    font-family: 'Gotham';
    src: local('Gotham Light'), url(${GothamLight}) format("opentype");
    font-weight: 300;
    font-style: light;
  }

  @font-face {
    font-family: 'Gotham';
    src: local('Gotham Light Italic'), url(${GothamLightIta}) format("opentype");
    font-weight: 300;
    font-style: italic;
  }

  @font-face {
    font-family: 'Gotham';
    src: local('Gotham Book'), url(${GothamBook}) format("opentype");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Gotham';
    src: local('Gotham Book'), url(${GothamBookIta}) format("opentype");
    font-weight: normal;
    font-style: italic;
  }

  @font-face {
    font-family: 'Gotham';
    src: local('Gotham Medium'), url(${GothamMedium}) format("opentype");
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Gotham';
    src: local('Gotham Medium Italic'), url(${GothamMediumIta}) format("opentype");
    font-weight: 500;
    font-style: italic;
  }

  @font-face {
    font-family: 'Gotham';
    src: local('Gotham Bold'), url(${GothamBold}) format("opentype");
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'Gotham';
    src: local('Gotham Bold Italic'), url(${GothamBoldIta}) format("opentype");
    font-weight: 700;
    font-style: italic;
  }

  @font-face {
    font-family: 'Gotham';
    src: local('Gotham'), url(${GothamBlack}) format("opentype");
    font-weight: 900;
    font-style: normal;
  }

  @font-face {
    font-family: 'Gotham';
    src: local('Gotham'), url(${GothamBlackIta}) format("opentype");
    font-weight: 900;
    font-style: italic;
  }

  @font-face {
    font-family: 'Gotham';
    src: local('Gotham Ultra'), url(${GothamUltra}) format("opentype");
    font-weight: 950;
    font-style: normal;
  }

  @font-face {
    font-family: 'Gotham';
    src: local('Gotham Ultra Italic'), url(${GothamUltraIta}) format("opentype");
    font-weight: 950;
    font-style: italic;
  }
`
