import { useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import ReactPlayer from 'react-player'
import { FiXCircle } from 'react-icons/fi'
import { MemberDirectiveChildProps as BaseProps } from '../'
import { StoreState } from '../../../../redux'
import { getOne as getUser } from '../../../../redux/users/actions'
import { deleteOne as deleteFile } from '../../../../redux/files/actions'
import { black, white } from '../../../../constants/Colors'
import LoadingPage from '../../../../components/LoadingPage'
import WarningModal from '../../../../components/WarningModal'
import MemberVideoWishesForm from './MemberVideoWishesForm'
import {
  Box,
  Button,
  Center,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { isDone, isLoading } from '../../../../redux/AsyncState'
import { privateFileUrl } from '../../../../utils/Routing'
import { useToast } from '../../../../hooks/Toast'
import * as RoutePaths from '../../../../constants/RoutePaths'

type MemberVideoWishesProps = BaseProps

const useHooks = (_props: MemberVideoWishesProps) => {
  const dispatch = useDispatch()
  const toast = useToast()
  const history = useHistory()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isMounted, setIsMounted] = useState(false)
  const { id } = useParams<{id: string}>()
  const {
    allLoadedUsers,
    deletingState,
  } = useSelector(({files, users}: StoreState) => ({
    allLoadedUsers: users.records,
    deletingState: files.deletingState,
    updatingState: users.updatingState,
  }), shallowEqual)

  const isDeleting = isLoading(deletingState)
  const member = allLoadedUsers[id]

  const onConfirmDelete = () => {
    dispatch(deleteFile(member.wishesVideoId))
  }

  const onClickExit = useCallback(() => {
    history.push(RoutePaths.MemberDetail(id))
  }, [history, id])

  useEffect(() => {
    setIsMounted(true)
  }, [])

  useEffect(() => {
    if (!isMounted) return

    if (isDone(deletingState)) {
      dispatch(getUser(id))
      onClose()
      toast({
        description: 'Video was successfully deleted.',
        status: 'success',
        width: '500px',
      })
    }
  }, [deletingState])

  return {
    isDeleting,
    isOpen,
    member,
    onClickExit,
    onClose,
    onConfirmDelete,
    onOpen,
  }
}

const MemberVideoWishes: React.FC<MemberVideoWishesProps> = (props) => {
  const {
    isDeleting,
    isOpen,
    member,
    onClickExit,
    onClose,
    onConfirmDelete,
    onOpen,
  } = useHooks(props)

  const toast = useToast()
  const onCompletedUpload = () => {
    toast({
      description: 'Video was successfully uploaded.',
      status: 'success',
      width: '500px',
    })
  }

  if (member == null) return <LoadingPage/>

  const isExistingVideo = member.wishesVideo != null

  return (
    <Box shadow='md' backgroundColor={white} w='730px' mb='160px' padding='48px 95px'>
      <Center>
        <Text fontSize='42px' fontWeight='500' lineHeight='44px' marginTop='48px'>{member?.firstName}'s Video Wishes</Text>
      </Center>
      <Center marginTop='48px'>
        {
          isExistingVideo ? (
            <ReactPlayer
              controls
              url={privateFileUrl(member.wishesVideo)}
              style={{backgroundColor: black}}
              height='300px'
              width='100%'
            />
          ) : (
            <Box shadow='md' backgroundColor={white} h='350px' w='100%'>
              <MemberVideoWishesForm userId={member.id} onComplete={onCompletedUpload}/>
            </Box>
          )
        }
      </Center>
      <Stack direction='row' spacing={4} width='100%' paddingTop='20px'>
        {isExistingVideo ? (
          <Button
            borderRadius='3px'
            fontSize='16px'
            width='full'
            leftIcon={<FiXCircle />}
            lineHeight='24px'
            mr={4}
            onClick={onOpen}
            variant='transparentRedOutline'
          >
              Delete Video
          </Button>
        ) : (
          <Box width='100%'/>
        )}
        <Button width='full' onClick={onClickExit}>
            Exit to Dashboard
        </Button>
      </Stack>
      <WarningModal
        headerText='Are you sure you want to delete this video?'
        isLoading={isDeleting}
        isOpen={isOpen}
        onClose={() => !isDeleting && onClose()}
        onConfirm={() => onConfirmDelete()}
      >
        This action cannot be undone.
      </WarningModal>
    </Box>
  )
}

export default MemberVideoWishes
