import { axiosAuthed } from '../axios'

const BASE_ROUTE = '/accounts'

export const getOne = async (subdomainName: string) => {
  try {
    const response = await axiosAuthed({method: 'get'}, `${BASE_ROUTE}/${subdomainName}`)
    return { response }
  } catch(error) {
    return { error }
  }
}

export const getCurrent = async () => {
  try {
    const response = await axiosAuthed({method: 'get'}, `${BASE_ROUTE}/current`)
    return { response }
  } catch(error) {
    return { error }
  }
}

export const getMany = async (params: any) => {
  try {
    const response = await axiosAuthed({method: 'get'}, BASE_ROUTE, {params})
    return { response }
  } catch(error) {
    return { error }
  }
}

export const update = async (id: string, data: any) => {
  try {
    const response = await axiosAuthed({method: 'put'}, `${BASE_ROUTE}/${id}`, data)
    return { response }
  } catch(error) {
    return { error }
  }
}

export const create = async (data: any) => {
  try {
    const response = await axiosAuthed({method: 'post'}, BASE_ROUTE, data)
    return { response }
  } catch(error) {
    return { error }
  }
}
