import React, { useContext, useEffect } from 'react'
import * as ReactRedux from 'react-redux'
import {
  Field,
  FieldInputProps,
  Form,
  Formik,
  FormikContextType,
} from 'formik'
import {
  Button,
  Center,
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  Stack,
  Text,
} from '@chakra-ui/react'
import * as Yup from 'yup'
import Roles from '../../../../constants/Roles'
import { Context as DirtyFormAlertContext } from '../../../../components/DirtyFormAlert'
import { parsePhoneNumber } from '../../../../utils/Forms'
import { FiAlertTriangle, FiSave } from 'react-icons/fi'
import { ChildFormProps as ContainerProps } from '.'
import { textPalette } from '../../../../constants/Colors'
import { StoreState } from '../../../../redux'
import { isError, isLoading } from '../../../../redux/AsyncState'
import { update as updateDirective } from '../../../../redux/directives/actions'
import isEmpty from 'lodash.isempty'

enum FormFields {
  email = 'email',
  firstName = 'firstName',
  lastName = 'lastName',
  phoneNumber = 'phoneNumber',
}

const ValidationSchema = Yup.object().shape({
  [FormFields.email]: Yup.string().nullable().required('Must not be empty').email('Must be a valid email'),
  [FormFields.firstName]: Yup.string().nullable().required('Must not be empty'),
  [FormFields.lastName]: Yup.string().nullable().required('Must not be empty'),
  [FormFields.phoneNumber]: Yup.string().nullable().required('Must not be empty').matches(/^\([0-9]{3}\) [0-9]{3}-[0-9]{4}?$/, 'Must be 10 digits'),
})

const initialValues = {
  [FormFields.email]: '',
  [FormFields.firstName]: '',
  [FormFields.lastName]: '',
  [FormFields.phoneNumber]: '',
}

type Values = typeof initialValues;
type ValueTypes = Values[keyof Values];
type FormType = FormikContextType<Values>
type FieldPropType = {field: FieldInputProps<ValueTypes>; form: FormType}
type PrimaryCareFormProps = ContainerProps & { form: FormType }

// visibleForTesting
export const useHooks = ({ directive, form }: PrimaryCareFormProps) => {
  const { shallowEqual, useSelector } = ReactRedux
  const { setDirtyFormAlert } = useContext(DirtyFormAlertContext)

  const {
    allLoadedUsers,
    errorData,
    updatingState,
  } = useSelector(({directives, users}: StoreState) => ({
    errorData: directives.errorData,
    updatingState: directives.updatingState,
    allLoadedUsers: users.records,
  }), shallowEqual)

  const member = allLoadedUsers[directive.memberId]
  const primaryCareProvider = member == null ? null : allLoadedUsers[member.primaryCareProviderIds![0]] || null
  const isUpdating = isLoading(updatingState)
  const isUpdatingError = isError(updatingState)

  useEffect(() => {
    form.resetForm({ values: {
      ...form.values,
      [FormFields.email]: primaryCareProvider?.email || '',
      [FormFields.firstName]: primaryCareProvider?.firstName || '',
      [FormFields.lastName]: primaryCareProvider?.lastName || '',
      [FormFields.phoneNumber]: parsePhoneNumber(primaryCareProvider?.phoneNumber || ''),
    }})
  }, [])

  useEffect(() => {
    if (!isUpdatingError) return

    const errorModel = Object.keys(errorData)[0]
    if (errorModel == null) return

    const errorModelData = errorData[errorModel]

    if (typeof errorModelData === 'object' && errorModelData.email != null) {
      form.setFieldError(FormFields.email, `Email ${errorModelData.email.join(', ')}`)
    }

    form.setSubmitting(false)
  }, [errorData, isUpdatingError])

  useEffect(() => {
    setDirtyFormAlert({blockSearchChanges: true, isDirty: form.dirty})
  }, [form.dirty])

  return { isUpdating }
}

const PrimaryCareForm: React.FC<PrimaryCareFormProps> = (props) => {
  const { isUpdating } = useHooks(props)
  const { continueLater, currentStep, goBack, totalSteps } = props
  const { form } = props

  return (
    <Form>
      <Stack align='center' spacing='20px' width='540px'>
        <Text fontSize='16px' color={textPalette.light}>Step {currentStep} of {totalSteps}</Text>
        <Text fontSize='26px'>Healthcare Provider Contact Info:</Text>
        <Stack w='100%' mb='24px' direction='row' justify='space-between'>
          <Field name={FormFields.firstName}>
            {({ field }: FieldPropType) => (
              <FormControl isInvalid={!isEmpty(form.errors.firstName) && form.touched.firstName}>
                <FormLabel htmlFor={field.name}>Provider First Name (required)</FormLabel>
                <Input {...field} id={field.name} type='text' maxLength={32}/>
                <FormErrorMessage><FormErrorIcon as={FiAlertTriangle} />{form.errors.firstName}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name={FormFields.lastName}>
            {({ field }: FieldPropType) => (
              <FormControl isInvalid={!isEmpty(form.errors.lastName) && form.touched.lastName}>
                <FormLabel htmlFor={field.name}>Provider Last Name (required)</FormLabel>
                <Input {...field} id={field.name} type='text' maxLength={32}/>
                <FormErrorMessage><FormErrorIcon as={FiAlertTriangle} />{form.errors.lastName}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
        </Stack>
        <Stack w='100%' mb='24px' direction='row' justify='space-between'>
          <Field name={FormFields.email}>
            {({ field }: FieldPropType) => (
              <FormControl isInvalid={!isEmpty(form.errors.email) && form.touched.email}>
                <FormLabel htmlFor={field.name}>Provider Email (required)</FormLabel>
                <Input {...field} id={field.name} type='email'/>
                <FormErrorMessage><FormErrorIcon as={FiAlertTriangle} />{form.errors.email}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Field name={FormFields.phoneNumber}>
            {({ field }: FieldPropType) => (
              <FormControl isInvalid={!isEmpty(form.errors.phoneNumber) && form.touched.phoneNumber}>
                <FormLabel htmlFor={field.name}>Provider Phone (required)</FormLabel>
                <Input
                  {...field}
                  id={field.name}
                  onChange={({currentTarget}) => {form.setFieldValue(field.name, parsePhoneNumber(currentTarget.value))}}
                  type='text'
                />
                <FormErrorMessage><FormErrorIcon as={FiAlertTriangle} />{form.errors.phoneNumber}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
        </Stack>
        <Stack direction='row' spacing={4} width='100%'>
          <Button width='100%' variant='outline' onClick={goBack} disabled>
            Back
          </Button>
          <Button width='100%' isLoading={isUpdating} type='submit'>
            Next
          </Button>
        </Stack>
        <Center width='100%'>
          <Button
            isLoading={isUpdating}
            leftIcon={<Icon as={FiSave} height='20px' width='20px' />}
            onClick={() => continueLater()}
            variant='link'>
            Save &amp; Continue Later
          </Button>
        </Center>
      </Stack>
    </Form>
  )
}

const PrimaryCareFormContainer: React.FC<ContainerProps> = (props) => {
  const { shallowEqual, useSelector } = ReactRedux
  const dispatch = ReactRedux.useDispatch()
  const { currentStep, directive } = props

  const {
    member,
  } = useSelector(({users}: StoreState) => ({
    member: users.records[directive?.memberId],
  }), shallowEqual)

  const onSubmit = ({phoneNumber, ...values}) => {
    dispatch(updateDirective({
      currentStep,
      memberId: directive.memberId,
      primaryCareProvider: {
        ...values,
        phoneNumber: phoneNumber.match(/\d/g)?.join(''),
        role: Roles.PRIMARY_CARE_PROVIDER,
        userId: member.primaryCareProviderIds![0],
      },
    }, directive.id))
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ValidationSchema}
      onSubmit={(values) => onSubmit(values)}
    >{(formik) => <PrimaryCareForm {...props} form={formik} />}</Formik>
  )
}

export default PrimaryCareFormContainer
