import React from 'react'
import {Field, FieldInputProps, FormikContextType} from 'formik'
import {FormControl, Text, FormErrorMessage, FormErrorIcon, Radio, RadioGroup, FormLabel, Stack} from '@chakra-ui/react'
import {textPalette} from '../../../../constants/Colors'
import isEmpty from 'lodash.isempty'
import find from 'lodash.find'
import {FiAlertTriangle} from 'react-icons/fi'
import {FlexFormQuestionOption} from './data/FlexFormModel'
import ExplanationAnswer from './ExplanationAnswer'

type FieldPropType = { field: FieldInputProps<string>; form: FormikContextType<any> }


export type QuestionRadioProps = {
  name: string
  label?: string
  description?: string
  error?: string
  touched?: boolean
  value: string
  compact: boolean
  setFieldValue: (field: string, value: any) => void
  options: FlexFormQuestionOption[]
}
const QuestionRadio: React.FC<QuestionRadioProps> = ({name, label, description, value, compact, error, touched, options, setFieldValue}) => {
  const selectedOption: FlexFormQuestionOption = find(options, (o: FlexFormQuestionOption) => o.value === value)
  return (
    <>
      <Field name={name}>
        {({ field }: FieldPropType) => (
          <FormControl
            id={name}
            isInvalid={!isEmpty(error) && touched}
          >
            <RadioGroup {...field} id={`${name}-group`} onChange={val => setFieldValue(field.name, val)}>
              {(label || description) &&
                  <FormLabel htmlFor={field.name} fontSize='md' color={textPalette.normal}>
                    {label && description &&
                      <Text width='100%' fontWeight='bold'>{label}</Text>
                    }
                    {/*just show a simple label if no description*/}
                    {!description && label}
                    {description &&
                        <Text width='100%' color={textPalette.light} marginTop='0 !important'>
                          {description}
                        </Text>
                    }
                  </FormLabel>
              }

              <Stack direction={compact ? 'row': 'column'} >
                {options.map((option) => (
                  <>
                    <Radio key={option.value} size='md' value={option.value} alignItems={compact ? 'center': 'flex-start'}>
                      {option.label && option.description &&
                            <Text fontWeight='500'>{option.label}</Text>
                      }
                      {(option.description || option.label) &&
                            <Text color={textPalette.light}>
                              {option.description || option.label}
                            </Text>
                      }
                    </Radio>
                    <>
                      { option.belowRadioExplanation &&
                          <ExplanationAnswer name={`${field.name}_${option.value}_explanation`} hidden={!option.belowRadioExplanation || !(selectedOption?.value === option.value)} required={false} defaultValue={option.defaultExplanation}/>
                      }
                    </>
                  </>
                ))}
              </Stack>
            </RadioGroup>
            <FormErrorMessage><FormErrorIcon as={FiAlertTriangle} />{error}</FormErrorMessage>
          </FormControl>
        )}
      </Field>
      <ExplanationAnswer name={`${name}_explanation`} hidden={!selectedOption || !selectedOption.includeExplanation} required={selectedOption?.explanationRequired || false} defaultValue=''/>
    </>
  )
}

export default QuestionRadio