import React from 'react'
import { Link } from 'react-router-dom'
import {
  Box,
  Button,
  Stack,
} from '@chakra-ui/react'
import Divider from './Divider'
import SubLinks from './SubLinks'
import LinkIcon, { LinkIconProps } from './LinkIcon'

export interface TableData extends LinkIconProps {
  links?: {[index: string]: TableData}
  to: string
}
export interface TableOfContentsProps {
  dividers?: {[index: number]: string}
  isLoading?: boolean
  links: {[index: string]: TableData}
}

const TableOfContents: React.FC<TableOfContentsProps> = ({dividers = {}, isLoading, links}) => (
  <Stack w='340px' alignItems='flex-start' spacing='10px'>
    {Object.keys(links).map((title, i) => {
      const dividerTitle = dividers[i]
      const linkData: TableData = links[title]

      return (
        <Box width={'100%'} key={`toc-${i}`}>
          {dividerTitle != null && <Divider title={dividerTitle}/>}
          <Button
            as={Link}
            colorScheme='gray'
            isActive={linkData.isActive}
            key={`${title}-toc-${i}`}
            justifyContent='space-between'
            rightIcon={<LinkIcon {...linkData} isLoading={isLoading}/>}
            to={linkData.to}
            variant='purpleOutline'
            width='100%'>
            {title}
          </Button>
          {!isLoading && linkData.isActive && linkData.links != null && <SubLinks key={`${title}-sublink-${i}`} links={linkData.links}/>}
        </Box >
      )
    })}
  </Stack>
)

export default TableOfContents
