import ActionIds from '../ActionIds'
import AsyncState from '../AsyncState'
import { Model } from '../../constants/Account'
import { BaseAction, Records } from '../types'

const initialState = {
  creatingState: AsyncState.NOT_STARTED,
  lastCreatedId: undefined as unknown as string,
  loadingState: AsyncState.NOT_STARTED,
  recentOrderedPartnerIds: [] as any[],
  records: {} as Records<Model>,
  current: {} as Model,
  sharingState: AsyncState.NOT_STARTED,
  totalPages: undefined as unknown as number,
  updatingState: AsyncState.NOT_STARTED,
}

export type StateType = typeof initialState;

export default function reducer(state = initialState, action: BaseAction): StateType {
  switch (action.type) {
    case ActionIds.ACCOUNTS_GET_ONE_START: // fall-through
    case ActionIds.ACCOUNTS_GET_CURRENT_START:
    case ActionIds.ACCOUNTS_GET_MANY_START:
      return { ...state, loadingState: AsyncState.LOADING }
    case ActionIds.ACCOUNTS_UPDATE_START:
      return { ...state, updatingState: AsyncState.LOADING }
    case ActionIds.ACCOUNTS_UPDATE_SUCCESS: {
      const newPartner = action.payload.data.attributes as Model
      const { records } = state
      return { ...state, records: { ...records, ...indexRecordByIdAndSubdomain(newPartner) }, updatingState: AsyncState.DONE }
    }
    case ActionIds.ACCOUNTS_CREATE_START:
      return { ...state, creatingState: AsyncState.LOADING }
    case ActionIds.ACCOUNTS_CREATE_SUCCESS: {
      const newPartner = action.payload.data.attributes
      const { records } = state
      return {
        ...state,
        creatingState: AsyncState.DONE,
        lastCreatedId: newPartner.id,
        records: { ...records, ...indexRecordByIdAndSubdomain(newPartner) },
      }
    }
    case ActionIds.ACCOUNTS_GET_CURRENT_SUCCESS: {
      const account = action.payload.data.attributes
      return { ...state, current: account , loadingState: AsyncState.DONE }
    }
    case ActionIds.ACCOUNTS_GET_ONE_SUCCESS: {
      const newPartner = action.payload.data.attributes
      const { records } = state
      return { ...state, records: { ...records, ...indexRecordByIdAndSubdomain(newPartner) }, loadingState: AsyncState.DONE }
    }
    case ActionIds.ACCOUNTS_GET_MANY_SUCCESS: {
      const accounts = action.payload.data.map(({attributes}) => attributes) as Model[]
      const { records } = state
      return {
        ...state,
        loadingState: AsyncState.DONE,
        records: accounts.reduce((memo: Records<Model>, p: Model) => ({...memo, ...indexRecordByIdAndSubdomain(p)}), records),
        recentOrderedPartnerIds: action.payload.data.map(({id}) => id),
        totalPages: action.payload.meta.totalPages,
      }
    }
    case ActionIds.ACCOUNTS_GET_MANY_FAIL: // fall-through
    case ActionIds.ACCOUNTS_GET_CURRENT_FAIL:
    case ActionIds.ACCOUNTS_GET_ONE_FAIL:
      return { ...state, loadingState: AsyncState.ERROR }
    case ActionIds.ACCOUNTS_CREATE_FAIL:
      return { ...state, creatingState: AsyncState.ERROR }
    case ActionIds.ACCOUNTS_UPDATE_FAIL:
      return { ...state, updatingState: AsyncState.ERROR }
    case ActionIds.ACCOUNTS_RESET:
      return { ...initialState }
    default:
      return state
  }
}

const indexRecordByIdAndSubdomain = (newRecord: Model) => ({
  [newRecord.subdomain]: newRecord,
  [newRecord.id]: newRecord,
})