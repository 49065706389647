import { darken as themeColorDarkener } from '@chakra-ui/theme-tools'
// handy site for generating palettes: https://coolors.co/a616bd-dee2ff

const avatarPurple = '#617CAE'
const hoverPurple = '#D14398'
const primaryPurple = '#A616BD'
const lightPurple = '#F8ECFC'

export const flexPallete = {
  green: '#129490',
  blue: '#222E50',
  purple: '#8B85C1',
  red: '#F87575',
  pink: '#FFA9A3',
}

export const purples = {
  50: lightPurple,
  100: '#EEB6F6',
  200: '#E591F2',
  300: hoverPurple,
  400: '#D036E7',
  500: primaryPurple,
  600: '#811192',
  700: '#710F80',
  800: '#510B5B',
  900: '#300637',
  avatar: avatarPurple,
  hover: hoverPurple,
  light: lightPurple,
  primary: primaryPurple,
}

const darken = (color: string, amount: number) => themeColorDarkener(color, amount)({})

export const getBrandPalette = (primaryColor) => (primaryColor == null ? purples : {
  50: darken(primaryColor, -50),
  100: darken(primaryColor, -40),
  200: darken(primaryColor, -30),
  300: darken(primaryColor, -20),
  400: darken(primaryColor, -10),
  500: primaryColor,
  600: darken(primaryColor, 10),
  700: darken(primaryColor, 20),
  800: darken(primaryColor, 30),
  900: darken(primaryColor, 40),
})

const primaryRed = '#FF5630'
const toastErrorRed = '#FF7452'
export const reds = {
  50: '#FFCDC2',
  100: '#FFAC99',
  200: '#FF8A70',
  300: toastErrorRed,
  400: '#FF6947',
  500: primaryRed,
  600: '#FF370A',
  700: '#E56849',
  800: '#B82200',
  900: '#8F1A00',
  primary: primaryRed,
  toastError: toastErrorRed,
}

const backgroundGray = '#F4F5F7'
const inputGray = '#FAFBFC'
const lightGray = '#DFE1E6'
const imageGray = '#A9B0C2'
export const black = '#000000'
export const white = '#FFFFFF'
export const grays = {
  50: white,
  100: backgroundGray,
  200: inputGray,
  300: lightGray,
  400: imageGray,
  500: '#5E6C84',
  600: '#415376',
  700: '#1E2739',
  800: '#172B4D',
  900: black,
  background: backgroundGray,
  image: imageGray,
  input: inputGray,
  light: lightGray,
  neutral: '#C1C7D0',
  buttonHover: '#E5E5E5',
}
export const grayBlue = '#7A869A'

export const textPalette = {
  lightest: grays[400],
  lighter: grays[500],
  light: grays[600],
  normal: grays[700],
  dark: grays[800],
}

export const toastColors = {
  error: toastErrorRed,
  success: '#36B37E',
}

export const yellows = {
  highlight: '#FFFAE6',
  missingInfo: '#FFC400',
}
