import {BaseAction} from '../types'
import ActionIds from '../ActionIds'
import {MY_ID} from '../../constants/User'

export const cancelGetOne = (): BaseAction => ({type: ActionIds.USERS_GET_ONE_CANCEL})
export const getOne = (id: string = MY_ID): BaseAction => ({
  type: ActionIds.USERS_GET_ONE_START,
  payload: id,
})

export const getProfile = (): BaseAction => ({
  type: ActionIds.USERS_GET_PROFILE_START,
})

export const updateProfile = (params): BaseAction => ({
  type: ActionIds.USERS_UPDATE_PROFILE_START,
  payload: params,
})

export const cancelGetMany = (): BaseAction => ({type: ActionIds.USERS_GET_MANY_CANCEL})
export const getMany = (params: any = {}): BaseAction => ({
  type: ActionIds.USERS_GET_MANY_START,
  payload: params,
})

export const updateDirectiveMemberId = (id: string, params: any = {}): BaseAction => ({
  type: ActionIds.USERS_SET_DIRECTIVE_MEMBERID,
  payload: {id, params},
})

export const update = (params, id: string = MY_ID): BaseAction => ({
  type: ActionIds.USERS_UPDATE_START,
  payload: {id, params},
})

export const create = (params): BaseAction => ({
  type: ActionIds.USERS_CREATE_START,
  payload: params,
})

export const signup = (params): BaseAction => ({
  type: ActionIds.USERS_SIGNUP_START,
  payload: params,
})

export const deleteOne = (id: string): BaseAction => ({
  type: ActionIds.USERS_DELETE_START,
  payload: {id},
})

export const invite = (params): BaseAction => ({
  type: ActionIds.USERS_INVITE_START,
  payload: params,
})

export const assignTeamMember = (id: string, params): BaseAction => ({
  type: ActionIds.USERS_ASSIGN_TEAM_MEMBER_START,
  payload: {id, params},
})

export const clearUserErrors = (): BaseAction => ({
  type: ActionIds.USERS_CLEAR_ERRORS,
})
