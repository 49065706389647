import React from 'react'
import {
  Icon,
  Spinner,
} from '@chakra-ui/react'
import { FiCheckCircle, FiChevronRight } from 'react-icons/fi'
import { toastColors } from '../../constants/Colors'
import ProgressCircle from '../../assets/Svgs/ProgressCircle'

export interface LinkIconProps {
  isActive?: boolean
  status?: string
  isLoading?: boolean
}

const ActiveIcon = () => (
  <Icon
    as={FiChevronRight}
    color={undefined}
    height='20px'
    width='20px'
  />
)

const SuccessIcon = () => (
  <Icon
    as={FiCheckCircle}
    color={toastColors.success}
    height='20px'
    width='20px'
  />
)

const LinkIcon: React.FC<LinkIconProps> = ({isActive, status, isLoading}) => {
  if (isLoading) return <Spinner />
  if (isActive) return <ActiveIcon />

  switch (status) {
    case 'in_progress':
      return <ProgressCircle />
    case 'complete':
      return <SuccessIcon />
    default:
      return null
  }
}

export default LinkIcon
