import React, { useContext, useEffect, useRef, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { StoreState } from '../../../redux'
import { Alert,
  AlertIcon,
  Box,
  Button,
  Center,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Stack,
  Text,
} from '@chakra-ui/react'
import { FiRefreshCw } from 'react-icons/fi'
import { format as formatDate, parseISO } from 'date-fns'
import { useToast } from '../../../hooks/Toast'
import CodeCard from '../../../components/CodeCard'
import CodeCardPrintable from '../../../components/CodeCardPrintable'
import { Context as PrintableContext } from '../../../components/Printable'
import { axiosAuthed } from '../../../redux/axios'
import { isLoading } from '../../../redux/AsyncState'
import { getOne as getUser } from '../../../redux/users/actions'
import { privateFileUrl } from '../../../utils/Routing'
import { DATE_FORMAT, parsePhoneNumber } from '../../../utils/Forms'
import { reds } from '../../../constants/Colors'

type PrintModalProps = Omit<ModalProps, 'children'> & {
  memberId: string
}

const useHooks = ({isOpen, memberId}: PrintModalProps) => {
  const toast = useToast()
  const dispatch = useDispatch()
  const codeCardPrintableRef = useRef<React.ElementRef<typeof CodeCardPrintable>>(null)
  const { isPrinting } = useContext(PrintableContext)
  const [errorMessage, setErrorMessage] = useState<string>()
  const [hasClickedGenerate, setHasClickedGenerate] = useState(false)
  const [isGenerating, setIsGenerating] = useState(false)
  const {
    currentUser,
    isLoadingMember,
    member,
  } = useSelector(({ users }: StoreState) => ({
    currentUser: users.currentUser,
    isLoadingMember: isLoading(users.loadingState),
    member: users.records[memberId],
  }), shallowEqual)

  const isLoadingPage = isGenerating || isLoadingMember

  useEffect(() => {
    if (isOpen) {
      setErrorMessage(undefined)
    }
  }, [isOpen])

  useEffect(() => {
    if (isGenerating) {
      setErrorMessage(undefined)
    }
  }, [isGenerating])

  const onConfirmGenerate = async () => {
    setHasClickedGenerate(false)
    setIsGenerating(true)
    try {
      await axiosAuthed({method: 'post'}, 'directives/regenerate_final_pdf_secret', {
        memberId,
      })
      dispatch(getUser(memberId))
    } catch {
      setErrorMessage('Something went wrong. Please Try again.')
      toast({
        description: 'Issue with generating new ID Card',
        status: 'error',
      })
    }
    setIsGenerating(false)
  }

  return {
    codeCardPrintableRef,
    currentUser,
    errorMessage,
    hasClickedGenerate,
    isLoadingPage,
    isPrinting,
    member,
    onConfirmGenerate,
    setHasClickedGenerate,
  }
}

const PrintModal: React.FC<PrintModalProps> = (props) => {
  const {
    codeCardPrintableRef,
    currentUser,
    errorMessage,
    hasClickedGenerate,
    isLoadingPage,
    isPrinting,
    member,
    onConfirmGenerate,
    setHasClickedGenerate,
  } = useHooks(props)
  const { isOpen, onClose, ...otherProps } = props

  // don't attempt to render if missing important values
  if (member.finalPdfSecretGeneratedAt == null || member.finalPdfQrCodeUrl == null) return null

  const codeCardProps: React.ComponentProps<typeof CodeCard> = {
    codeUrl: privateFileUrl(member.finalPdfQrCodeUrl, {cacheBuster: member.finalPdfSecretGeneratedAt}),
    firstName: member.firstName,
    lastName: member.lastName,
    middleName: member.middleName,
    phoneNumber: parsePhoneNumber(member.phoneNumber),
  }

  return (
    <Modal size='xl' closeOnOverlayClick={!isLoadingPage} {...otherProps} onClose={onClose} isOpen={isOpen && !isPrinting}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader><Center fontSize='26px' fontWeight='normal'>Preview &#38; Print ID Card</Center></ModalHeader>
        <ModalCloseButton />
        <ModalBody w='100%'>
          <Stack align='center' spacing='20px' marginBottom='42px' >
            <Box height='0' width='100%'>{errorMessage && (
              <Alert status='error'>
                <AlertIcon />
                <Center flex='1'>{errorMessage}</Center>
              </Alert>
            )}</Box>
            <Box w='100%'>
              <Center height='100%' width='100%'>
                <Stack width='100%' spacing='20px'>
                  <CodeCard
                    {...codeCardProps}
                    size='modal'
                  />
                  <HStack>
                    <Box>QR code generated</Box>
                    <Text fontWeight='500'>{formatDate(parseISO(member.finalPdfSecretGeneratedAt!), DATE_FORMAT)}</Text>
                    {currentUser.isAdmin && (
                      <Button
                        isLoading={isLoadingPage}
                        leftIcon={<Icon as={FiRefreshCw} height='20px' width='20px' />}
                        loadingText='Generating...'
                        onClick={() => setHasClickedGenerate(true)}
                        variant='link'>
                        Generate New
                      </Button>
                    )}
                  </HStack>
                  { hasClickedGenerate && (
                    <HStack border={`1px solid ${reds.toastError}`} padding='5px'>
                      <Box width='65%'>
                        <Text display='inline' color={reds.toastError} fontWeight='500'>Are you sure you want to generate a new QR code?</Text>
                        &nbsp;
                        <Text display='inline'>This will will make already existing ID cards obsolete.</Text>
                      </Box>
                      <Stack direction='row' spacing={4} width='35%'>
                        <Button
                          onClick={() => setHasClickedGenerate(false)}
                          variant='transparentRedOutline'
                          width='100%'>
                          Cancel
                        </Button>
                        <Button
                          onClick={onConfirmGenerate}
                          colorScheme='red'
                          width='100%'>
                          Yes
                        </Button>
                      </Stack>
                    </HStack>
                  )}
                </Stack>
              </Center>
            </Box>
          </Stack>
          <Stack direction='row' spacing={4} width='100%'>
            <Button width='100%' isDisabled={isLoadingPage} variant='outline' onClick={onClose}>
              Cancel
            </Button>
            <Button width='100%' isDisabled={isLoadingPage} type='submit' onClick={() => codeCardPrintableRef.current?.print(100)}>
              Print
            </Button>
          </Stack>
          <CodeCardPrintable
            {...codeCardProps}
            ref={codeCardPrintableRef}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default PrintModal
