import { call, fork, put, race, take, takeLatest } from 'redux-saga/effects'
import ActionIds from '../ActionIds'
import { BaseAction } from '../types'
import {
  resetPassword as resetPasswordApi,
  signIn as signInApi,
  signOut as signOutApi,
} from './api'
import { getOne as getOneUser } from '../users/actions'
import {getCurrent as getCurrentAccount} from '../accounts/actions'

export default function* watch() {
  yield takeLatest(ActionIds.AUTH_PASSWORD_RESET_START, requestPasswordReset)
  yield takeLatest(ActionIds.AUTH_SET_TOKENS, requestSetTokens)
  yield takeLatest(ActionIds.AUTH_POST_START, requestSignIn)
  yield takeLatest(ActionIds.AUTH_DESTROY_START, requestSignOut)
  yield takeLatest(ActionIds.AUTH_SET_ACCOUNT_ID, requestSetAccountId)
}

function* requestSignIn({payload: params}: BaseAction) {
  const { apiResponse: {response, error}, isCanceled } = yield race({
    apiResponse: call(signInApi, params),
    isCanceled: take(ActionIds.AUTH_POST_CANCEL),
  })
  if (isCanceled) return

  if (error) {
    yield put({type: ActionIds.AUTH_POST_FAIL})
    return
  }

  yield put({type: ActionIds.AUTH_POST_SUCCESS, payload: response.data})
}

function* requestSignOut() {
  yield fork(signOutApi) // disregard signout response
  yield put({type: ActionIds.USERS_RESET})
  yield put({type: ActionIds.ACCOUNTS_RESET})
  yield put({type: ActionIds.AUTH_DESTROY_SUCCESS}) // always assume success to clear tokens
}

function* requestSetTokens({payload: attributes}: BaseAction) {
  //need to refresh auth first
  yield put({type: ActionIds.AUTH_DESTROY_SUCCESS})
  yield put({
    payload: {data: {attributes}},
    type: ActionIds.AUTH_POST_SUCCESS,
  })
  //
  // yield put(getOneUser()) // fetch current user
}

function* requestPasswordReset({payload: params}: BaseAction) {
  const { error } = yield call(resetPasswordApi, params)

  if (error) {
    yield put({type: ActionIds.AUTH_PASSWORD_RESET_FAIL})
    return
  }

  yield put({type: ActionIds.AUTH_PASSWORD_RESET_SUCCESS})
}

function* requestSetAccountId() {
  yield put(getOneUser())
  yield put(getCurrentAccount())
}
