import React from 'react'
import {
  Box,
  Center,
  Grid,
  Image,
  useTheme,
  Flex,
  Text,
} from '@chakra-ui/react'
import CodeCard from './CodeCard'
import { white, grays } from '../constants/Colors'
import { wrapper } from './Printable'
import logo from '../assets/Images/logo.svg'
import ThanaCareLogo from './Images/ThanaCareLogo.png'

type CodeCardPrintableProps = React.ComponentProps<typeof CodeCard>
const CodeCardPrintable = (props: CodeCardPrintableProps) => {
  const { logoUrl } = useTheme()

  return (
    <Box>
      <Center style={{pageBreakAfter: 'always'}}>
        <Grid templateColumns='repeat(2, 1fr)'>
          {Array(10).fill(null).map((_, i)=> <CodeCard {...props} key={i} size='print' />)}
        </Grid>
      </Center>
      <Center>
        <Grid templateColumns='repeat(2, 1fr)'>
          {Array(10).fill(null).map((_, i)=> (
            <Flex
              justifyContent='center'
              alignItems='center'
              flexDirection='column'
              key={i}
              backgroundColor={white}
              h='2in'
              w='3.5in'
            >
              <Flex marginTop='12px' flex='1' justifyContent='center' alignItems='center' flexDirection='row'>
                <Text fontSize='10px' color={grays[600]} >
                  Scan QR card for Healthcare Instructions
                </Text>
              </Flex>
              <Flex flex='1' justifyContent='center' alignItems='center'>
                <Image src={logoUrl} fallbackSrc={logo} alt='logo' width='50%'/>
              </Flex>
              <Flex flex='1' justifyContent='center' alignItems='center' flexDirection='row'>
                {logoUrl ?
                  <>
                    <Text fontSize='10px' color={grays[600]} marginRight='8px' >Powered by</Text>
                    <img width='80px' src={ThanaCareLogo} alt='Thanacare Logo' />
                  </> : ''}
              </Flex>
            </Flex>
          ))}
        </Grid>
      </Center>
    </Box>
  )
}

export default wrapper(CodeCardPrintable)
