import React, { useContext, useEffect } from 'react'
import * as ReactRedux from 'react-redux'
import { ChildFormProps as ContainerProps } from '.'
import { update as updateDirective } from '../../../../redux/directives/actions'
import { Context as DirtyFormAlertContext } from '../../../../components/DirtyFormAlert'
import { Field, FieldInputProps, Form, Formik, FormikContextType } from 'formik'
import { isError } from '../../../../redux/AsyncState'
import { StoreState } from '../../../../redux'
import {
  Button,
  Center,
  FormControl,
  Icon,
  Stack,
  Text,
  Textarea,
} from '@chakra-ui/react'
import { textPalette } from '../../../../constants/Colors'
import { FiSave } from 'react-icons/fi'
import isEmpty from 'lodash.isempty'

enum FormFields {
  surrogatePowers3Text1 = 'surrogatePowers3Text1',
}

enum PlaceHolders {
  typeHere = 'Type here...',
}

const initialValues = {
  [FormFields.surrogatePowers3Text1]: '',
}

type Values = typeof initialValues;
type ValueTypes = Values[keyof Values];
type FormType = FormikContextType<Values>
type FieldPropType = { field: FieldInputProps<ValueTypes>; form: FormType }
type SurrogatePowers2FormProps = ContainerProps & { form: FormType }

export const useHooks = ({ form, directive }: SurrogatePowers2FormProps) => {
  const { setDirtyFormAlert } = useContext(DirtyFormAlertContext)
  const { shallowEqual, useSelector } = ReactRedux

  const {
    updatingState,
  } = useSelector(({ directives }: StoreState) => ({
    updatingState: directives.updatingState,
  }), shallowEqual)

  useEffect(() => {
    form.resetForm({
      values: {
        ...form.values,
        [FormFields.surrogatePowers3Text1]: directive.questions[FormFields.surrogatePowers3Text1] ?? '',
      },
    })
  }, [directive])

  const isUpdateError = isError(updatingState)

  useEffect(() => {
    if (isUpdateError) {
      form.setSubmitting(false)
    }
  }, [isUpdateError])

  useEffect(() => {
    setDirtyFormAlert({ blockSearchChanges: true, isDirty: form.dirty })
  }, [form.dirty])
}

const SurrogatePowers2Form: React.FC<SurrogatePowers2FormProps> = (props) => {
  useHooks(props)
  const { continueLater, form, goBack, currentStep, totalSteps } = props

  return (
    <Form>
      <Stack align='center' spacing='20px' maxWidth='540px'>
        <Text fontSize='16px' color={textPalette.light}>Step {currentStep} of {totalSteps}</Text>
        <Text fontSize='25px'>Health Care Agent Powers and Limitations</Text>
        <Text color={textPalette.light}>
          If I want to say anything more about my Healthcare Agent’s powers or limits on the powers, I can say it here:
        </Text>
        <Field name={FormFields.surrogatePowers3Text1}>
          {({ field }: FieldPropType) => (
            <FormControl isInvalid={!isEmpty(form.errors.surrogatePowers3Text1) && form.touched.surrogatePowers3Text1}>
              <Textarea {...field} id={field.name} minH='96px' borderRadius='3px' placeholder={PlaceHolders.typeHere}/>
            </FormControl>
          )}
        </Field>

        <Stack direction='row' width='100%'>
          <Button width='100%' variant='outline' onClick={goBack}>
            Back
          </Button>
          <Button width='100%' isLoading={form.isSubmitting} type='submit' onClick={() => form.submitForm()}>
            Next
          </Button>
        </Stack>
        <Center width='100%'>
          <Button
            isLoading={form.isSubmitting}
            leftIcon={<Icon as={FiSave} height='20px' width='20px' />}
            onClick={() => continueLater(form, false)}
            variant='link'>
            Save &amp; Continue Later
          </Button>
        </Center>
      </Stack>
    </Form>
  )
}

const SurrogatePowers2FormContainer: React.FC<ContainerProps> = (props) => {
  const dispatch = ReactRedux.useDispatch()
  const { currentStep, directive } = props
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => { dispatch(updateDirective({ ...values, currentStep }, directive.id)) }}
    >{(formik) => <SurrogatePowers2Form {...props} form={formik} />}</Formik>
  )
}

export default SurrogatePowers2FormContainer