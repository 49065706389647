import React, { useCallback } from 'react'
import * as ReactRedux from 'react-redux'
import {
  Button,
  Center,
  Icon,
  Stack,
  Text,
} from '@chakra-ui/react'
import { MemberDirectiveChildProps as BaseProps } from './'
import { FiSave } from 'react-icons/fi'
import { update as updateDirective } from '../../../redux/directives/actions'
import { textPalette } from '../../../constants/Colors'
import { StoreState } from '../../../redux'
import { isLoading } from '../../../redux/AsyncState'
import { Types as DirectiveTypes } from '../../../constants/Directive'
import { isDirectiveComplete } from '../../../utils/BusinessLogic'
import { getAppointSurrogateValid } from '../../../redux/directives/selector'
import { useParams } from 'react-router-dom'
import {FormikContextType} from 'formik'
import { Model as DirectiveModel } from '../../../constants/Directive'

export interface ChildFormProps extends BaseProps {
  continueLater: (form?: FormikContextType<any>) => void
  currentStep: number
  directive: DirectiveModel
  goBack: () => void
  totalSteps: number
}


type ReviewStepProps = ChildFormProps & {
  text: string
}

// visibleForTesting
export const useHooks = ({ currentStep, directive }: ReviewStepProps) => {
  const { shallowEqual, useDispatch, useSelector } = ReactRedux
  const dispatch = useDispatch()
  const { id } = useParams<{id: string }>()
  const {
    updatingState,
    member,
  } = useSelector(({directives, users}: StoreState) => ({
    updatingState: directives.updatingState,
    member: users.records[id],
  }), shallowEqual)

  const appointValidSurrogateForm = useSelector((state:StoreState) => getAppointSurrogateValid(state))
  const isHealthCareDirectivePart1Complete = isDirectiveComplete({stepsCompleted: member?.healthcarePt1DirectiveStepsCompleted ?? 0, type: DirectiveTypes.HEALTHCARE_PT1_DIRECTIVE}) && appointValidSurrogateForm
  const isHealthCareDirectivePart2Complete = member.flexDirectiveComplete

  const isUpdating = isLoading(updatingState)

  const onClickComplete = useCallback(() => {
    dispatch(updateDirective({ currentStep }, directive.id))
  }, [currentStep, directive])

  return { isUpdating, onClickComplete, directive, isHealthCareDirectivePart1Complete, isHealthCareDirectivePart2Complete }
}

const ReviewStep: React.FC<ReviewStepProps> = (props) => {
  const { isUpdating, onClickComplete, directive, isHealthCareDirectivePart1Complete, isHealthCareDirectivePart2Complete } = useHooks(props)
  const { continueLater, currentStep, goBack, text, totalSteps } = props
  const isDisabled = directive.type === DirectiveTypes.POLST && !(isHealthCareDirectivePart1Complete && isHealthCareDirectivePart2Complete)

  return (
    <Stack align='center' spacing='20px' width='540px'>
      <Text fontSize='16px' color={textPalette.light}>Step {currentStep} of {totalSteps}</Text>
      <Text fontSize='26px'>Review &amp; Complete</Text>
      <Text textAlign='center'>{text}</Text>
      <Stack direction='row' spacing={4} width='100%'>
        <Button width='full' variant='outline' onClick={goBack}>
          Back
        </Button>
        <Button width='full' onClick={onClickComplete} disabled={isDisabled}>
          Done
        </Button>
      </Stack>
      <Center width='100%'>
        <Button
          isLoading={isUpdating}
          leftIcon={<Icon as={FiSave} height='20px' width='20px' />}
          onClick={() => continueLater()}
          variant='link'>
          Save &amp; Continue Later
        </Button>
      </Center>
    </Stack>
  )
}

export default ReviewStep
