import React, { useContext, useEffect, useState } from 'react'
import * as ReactRedux from 'react-redux'
import * as Yup from 'yup'
import { FiSave } from 'react-icons/fi'
import { useSelector, shallowEqual } from 'react-redux'
import { StoreState } from '../../../redux'
import LoadingPage from '../../../components/LoadingPage'
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightAddon,
  Stack,
  Text,
} from '@chakra-ui/react'
import { css } from '@emotion/react'
import { Field, FieldInputProps, Form, Formik, FormikContextType } from 'formik'
import { Context as DirtyFormAlertContext } from '../../../components/DirtyFormAlert'
import { isDone, isError } from '../../../redux/AsyncState'
import { update as updateAccount } from '../../../redux/accounts/actions'
import { uploadFile } from '../../../redux/axios'
import isEmpty from 'lodash.isempty'
import { useToast } from '../../../hooks/Toast'
import ImageUpload, { FileValidation } from '../../../components/ImageUpload'
import { privateFileUrl } from '../../../utils/Routing'
import { HEX_COLOR_REGEX } from '../../../utils/Forms'

enum PlaceHolders {
  primaryColor = '#A616BD',
  secondaryColor = '#D14398',
  memberLeadName = 'Select'
}

const initialValues = {
  logo: undefined,
  primaryColor: '',
  secondaryColor: '',
  memberLeadName: '',
  name: '',
  flexTemplateId: '',
}

enum FormFields {
  logo = 'logo',
  primaryColor = 'primaryColor',
  secondaryColor = 'secondaryColor',
  memberLeadName = 'memberLeadName',
  name = 'name',
  flexTemplateId = 'flexTemplateId'
}

const ValidationSchema = Yup.object().shape({
  [FormFields.logo]: FileValidation,
  [FormFields.primaryColor]: Yup.string().nullable().matches(HEX_COLOR_REGEX, 'Primary color is not a valid hex color'),
  [FormFields.secondaryColor]: Yup.string().nullable().matches(HEX_COLOR_REGEX, 'Secondary color is not a valid hex color'),
  [FormFields.memberLeadName]: Yup.string(),
  [FormFields.name]: Yup.string().required('field is required'),
  [FormFields.flexTemplateId]: Yup.string(),
})

type Values = typeof initialValues;
type ValueTypes = Values[keyof Values];
type FormType = FormikContextType<Values>
type FieldPropType = {field: FieldInputProps<ValueTypes>; form: FormType}
type ThemeSettingsFormProps = { form: FormType }

export const useHooks = ({form}: ThemeSettingsFormProps) => {
  const toast = useToast()
  const { setDirtyFormAlert } = useContext(DirtyFormAlertContext)
  const [primaryColor, setPrimaryColor] = useState<string>()
  const [secondaryColor, setSecondaryColor] = useState<string>()
  const [reloadPage, setReloadPage] = useState<boolean>()

  const {
    account,
    updatingState,
  } = useSelector(({ accounts }: StoreState) => ({
    account: accounts.current,
    updatingState: accounts.updatingState,
  }), shallowEqual)

  const isUpdatingError = isError(updatingState)
  const isUpdatingDone = isDone(updatingState)

  useEffect(() => {
    form.resetForm({ values: {
      ...form.values,
      [FormFields.primaryColor]: account?.primaryColor,
      [FormFields.secondaryColor]: account?.secondaryColor,
      [FormFields.memberLeadName]: account?.memberLeadName,
      [FormFields.name]: account?.name,
      [FormFields.flexTemplateId]: account?.flexTemplateId,
    }})
    setPrimaryColor(account?.primaryColor)
    setSecondaryColor(account?.secondaryColor)
    setReloadPage(false)
  }, [account])

  useEffect(() => {
    if (isUpdatingError) {
      form.setSubmitting(false)
      toast({
        description: 'Changes were not saved; try again.',
        status: 'error',
      })
    } else if (isUpdatingDone && form.isValid && form.submitCount > 0) {
      toast({
        description: 'Changes have been saved.',
        status: 'success',
      })

      if (reloadPage){
        setTimeout(function() { //Start the timer
          window.location.reload()  //After 1 second, set render to true
        }, 1000)
      }
    }

  }, [isUpdatingError, isUpdatingDone])

  useEffect(() => {
    setDirtyFormAlert(form.dirty)
  }, [form.dirty])

  const onChangePrimaryColor = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()

    const { name, value } = e.currentTarget
    form.setFieldValue(name, value)
    setPrimaryColor(value)
    setReloadPage(true)
  }

  const onChangeSecondaryColor = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()

    const { name, value } = e.currentTarget
    form.setFieldValue(name, value)
    setSecondaryColor(value)
    setReloadPage(true)
  }

  return {
    onChangePrimaryColor,
    onChangeSecondaryColor,
    account,
    primaryColor,
    secondaryColor,
    setReloadPage,
  }
}

const ThemeSettingsForm: React.FC<ThemeSettingsFormProps> = (props) => {
  const {
    onChangePrimaryColor,
    onChangeSecondaryColor,
    account,
    primaryColor,
    secondaryColor,
    setReloadPage,
  } = useHooks(props)
  const { form } = props

  if (account == null) return <LoadingPage />

  return (
    <>
      <Text fontSize='20px'>General Settings</Text>
      <Box w='375px' mt='48px'>
        <Stack spacing={2}>
          <Stack spacing={1}>
            <Text fontSize='16px'>Logo (PNG, JPG, SVG)</Text>
            <Text fontSize='12px'>Click to upload logo. Recommended height of 80px.</Text>
          </Stack>
          <Field name={FormFields.logo}>
            {({ field }: FieldPropType) => (
              <Box css={css`img { object-fit: inherit }`}>
                <FormControl isInvalid={!isEmpty(form.errors.logo)} width='inherit'>
                  <ImageUpload
                    {...field}
                    avatarProps={{ borderRadius: '0', h: '120px', size: 'xl', w: '540px' }}
                    id={field.name}
                    initialValue={privateFileUrl(account.logo)}
                    onChange={file => {
                      form.setFieldValue(FormFields.logo, file)
                      setReloadPage(true)
                    }}
                  />
                  <FormErrorMessage>{form.errors.logo}</FormErrorMessage>
                </FormControl>
              </Box>
            )}
          </Field>
        </Stack>
      </Box>
      <Box width='540px' mt={4}>
        <Form>
          <Stack direction='row' spacing='17px' w='100%' mt='17px'>
            <Field name={FormFields.primaryColor}>
              {({ field }: FieldPropType) => (
                <FormControl isInvalid={!isEmpty(form.errors.primaryColor) && form.touched.primaryColor}>
                  <FormLabel htmlFor={field.name}>Primary Color (HEX)</FormLabel>
                  <InputGroup size='lg'>
                    <Input
                      {...field}
                      id={field.name}
                      onChange={onChangePrimaryColor}
                      placeholder={PlaceHolders.primaryColor}
                    />
                    <InputRightAddon padding='0' w='44px' children={<Box backgroundColor={primaryColor} h='100%' w='100%'/>} />
                  </InputGroup>
                  <FormErrorMessage>{form.errors.primaryColor}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name={FormFields.secondaryColor}>
              {({ field }: FieldPropType) => (
                <FormControl isInvalid={!isEmpty(form.errors.secondaryColor) && form.touched.secondaryColor} >
                  <FormLabel htmlFor={field.name}>Secondary Color (HEX)</FormLabel>
                  <InputGroup size='lg'>
                    <Input
                      {...field}
                      id={field.name}
                      onChange={onChangeSecondaryColor}
                      placeholder={PlaceHolders.secondaryColor}
                    />
                    <InputRightAddon padding='0' w='44px' children={<Box backgroundColor={secondaryColor} h='100%' w='100%'/>} />
                  </InputGroup>
                  <FormErrorMessage>{form.errors.secondaryColor}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </Stack>
        </Form>
      </Box>
      <Form>
        <Box w='540px'>
          <Stack direction='row' spacing='17px' w='100%' mt='17px'>
            <Field name={FormFields.name}>
              {({field}: FieldPropType) => (
                <FormControl isInvalid={!isEmpty(form.errors.name) && form.touched.name}>
                  <FormLabel htmlFor={field.name}>Account Name</FormLabel>
                  <Input {...field} id={field.name} type='text' max={50} />
                </FormControl>
              )}
            </Field>
          </Stack>
          <Stack direction='row' spacing='17px' w='100%' mt='17px'>
            <Field name={FormFields.memberLeadName}>
              {({field}: FieldPropType) => (
                <FormControl isInvalid={!isEmpty(form.errors.memberLeadName) && form.touched.memberLeadName}>
                  <FormLabel htmlFor={field.name}>Member Lead Name</FormLabel>
                  <Input {...field} id={field.name} type='text' max={40} />
                </FormControl>
              )}
            </Field>
            <Field name={FormFields.flexTemplateId}>
              {({field}: FieldPropType) => (
                <FormControl isInvalid={!isEmpty(form.errors.memberLeadName) && form.touched.memberLeadName}>
                  <FormLabel htmlFor={field.name}>Flex Template Id</FormLabel>
                  <Input {...field} id={field.name} type='text' max={40} />
                </FormControl>
              )}
            </Field>
          </Stack>
        </Box>
      </Form>
      <Button
        isDisabled={!form.dirty}
        type='submit'
        borderRadius='3px'
        fontSize='16px'
        lineHeight='24px'
        mt='24px'
        leftIcon={<FiSave />}
        onClick={() => form.submitForm()}
      >
        Save
      </Button>
    </>
  )
}

const GeneralSettingsFormContainer: React.FC = () => {
  const dispatch = ReactRedux.useDispatch()
  const {
    account,
  } = useSelector(({ accounts }: StoreState) => ({
    account: accounts.current,
  }), shallowEqual)
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ValidationSchema}
      onSubmit={({logo, ...values}) => {
        uploadFile(logo).then((logoId) => {
          dispatch(updateAccount({
            ...values,
            logo: logoId,
          }, account.id))
        })
      }}
    >{(formik) => <ThemeSettingsForm form={formik} />}</Formik>
  )
}

export default GeneralSettingsFormContainer