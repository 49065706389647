export enum Types {
  HEALTHCARE_PT1_DIRECTIVE = 'HealthcarePt1',
  HEALTHCARE_FLEX_DIRECTIVE = 'Flex',
  POLST = 'Polst',
}

export enum DirectiveIdKeys {
  HEALTHCARE_PT1_DIRECTIVE = 'healthcarePt1DirectiveId',
  HEALTHCARE_FLEX_DIRECTIVE = 'flexDirectiveId',
  POLST = 'polstDirectiveId',
}

export const DeathLocations = {
  HOME: 'home',
  HOSPICE: 'hospice',
  HOSPITAL: 'hospital',
  OTHER: 'other',
} as const

export const MedicalTreatments = {
  COMFORT_CARE: 'comfort_care',
  FULL_TREATMENT: 'full_treatment',
  SELECTIVE_TREATMENT: 'selective_treatment',
} as const

export const NutritionalPreferences = {
  NO_ARTIFICIAL_MEANS: 'no_artificial_means',
  TRIAL_PERIOD: 'trial_period',
  USE_SURGICAL_TUBES: 'use_surgical_tubes',
  VSED: 'vsed',
} as const

export const Statuses = {
  COMPLETED: 'completed',
  IN_PROGRESS: 'in_progress',
  NOT_STARTED: 'not_started',
  SENT: 'sent',
} as const
export type StatusesType = typeof Statuses

export const TemplateNames = {
  NOTARY: 'notary',
  WITNESS: 'witness',
  POLST: 'polst',
} as const

export const PolstSteps = {
  PRIMARY_CARE: 1,
  REVIEW: 2,
} as const

export const HealthcarePt1Steps = {
  APPOINT_SURROGATE: 1,
  SURROGATE_POWERS_1: 2,
  SURROGATE_POWERS_2: 3,
  SURROGATE_POWERS_3: 4,
  REVIEW: 5,
} as const

export interface Model {
  id: string
  memberId: string
  questions: {[index: string]: any}
  stepsCompleted: number
  teamAlternateSurrogateIds: string[]
  teamSurrogateIds: string[]
  totalSteps: number
  version?: string
  templateId?: string
  type: Types
}

export enum AppointSurrogateFormFields {
  fullName = 'fullName',
  memberRelationship = 'memberRelationship',
  email = 'email',
  pronouns = 'pronouns',
  phone = 'phone',
  streetAddress = 'streetAddress',
  city = 'city',
  state = 'state',
  country = 'country',
  zipCode = 'zipCode',
  hasAlternate = 'hasAlternate',
  alternateFullName = 'alternateFullName',
  alternatePronouns = 'alternatePronouns',
  alternateMemberRelationship = 'alternateMemberRelationship',
  alternateEmail = 'alternateEmail',
  alternatePhone = 'alternatePhone',
  alternateStreetAddress = 'alternateStreetAddress',
  alternateCity = 'alternateCity',
  alternateState = 'alternateState',
  alternateCountry = 'alternateCountry',
  alternateZipCode = 'alternateZipCode',
  hasSecondAlternate = 'hasSecondAlternate',
  secondAlternateFullName = 'secondAlternateFullName',
  secondAlternatePronouns = 'secondAlternatePronouns',
  secondAlternateMemberRelationship = 'secondAlternateMemberRelationship',
  secondAlternateEmail = 'secondAlternateEmail',
  secondAlternatePhone = 'secondAlternatePhone',
  secondAlternateStreetAddress = 'secondAlternateStreetAddress',
  secondAlternateCity = 'secondAlternateCity',
  secondAlternateState = 'secondAlternateState',
  secondAlternateCountry = 'secondAlternateCountry',
  secondAlternateZipCode = 'secondAlternateZipCode',
}

export enum AppointSurrogateFormErrorFields {
  fullNameError = 'Full name is required',
  pronounsError = 'Pronouns is required',
  memberRelationshipError = 'Please choose a relationship',
  emailError = 'Email is required',
  phoneError = 'Phone number is required',
  streetAddressError = 'Street address is required',
  cityError = 'City is required',
  stateError = 'Please select a state',
  countryError = 'Please select a country',
  zipCodeError = 'Zip Code is required',
  alternateFullNameError = 'Alternate full name is required',
  alternatePronounsError = 'Pronouns is required',
  alternateMemberRelationshipError = 'Alternate relationship is required',
  alternateEmailError = 'Alternate email is required',
  alternatePhoneError = 'Alternate phone number is required',
  alternateStreetAddressError = 'Alternate street address is required',
  alternateCityError = 'Alternate city is required',
  alternateStateError = 'Alternate state is Required',
  alternateCountryError = 'Alternate country is required',
  alternateZipCodeError = 'Alternate Zip/Postal Code is required',
  secondAlternateFullNameError = 'Second Alternate full name is required',
  secondAlternatePronounsError = 'Pronouns is required',
  secondAlternateMemberRelationshipError = 'Second Alternate relationship is required',
  secondAlternateEmailError = 'Second Alternate email is required',
  secondAlternatePhoneError = 'Second Alternate phone number is required',
  secondAlternateStreetAddressError = 'Second Alternate street address is required',
  secondAlternateCityError = 'Second Alternate city is required',
  secondAlternateStateError = 'Second Alternate state is Required',
  secondAlternateCountryError = 'Second Alternate country is required',
  secondAlternateZipCodeError = 'Second Alternate Zip/Postal Code is required',
}

export enum CprFormFields {
  cprRadio1 = 'cprRadio1',
  cprText1 = 'cprText1',
}

export enum MedicalTreatmentFormFields {
  medicalTreatmentRadio1 = 'medicalTreatmentRadio1',
  medicalTreatmentText1 = 'medicalTreatmentText1',
}

export enum NutritionalPreferenceFormFields {
  nutritionRadio1 = 'nutritionRadio1',
}
