import React, { useContext, useEffect, useState } from 'react'
import * as ReactRedux from 'react-redux'
import { Alert,
  AlertIcon,
  Box,
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  SlideFade,
  Stack,
  Text,
} from '@chakra-ui/react'
import HealthConcernForm from './HealthConcernForm'
import MemberInfoForm from './MemberInfoForm'
import { Context as DirtyFormAlertContext } from '../../../../components/DirtyFormAlert'
import { StoreState } from '../../../../redux'
import { textPalette } from '../../../../constants/Colors'
import { useToast } from '../../../../hooks/Toast'

enum Steps {
  MEMBER_INFO = 0,
  HEALTH_CONCERN = 1,
}
type NewMemberModalProps = Omit<ModalProps, 'children'> & { goToMember: (id: string) => void }

const useHooks = ({goToMember, isOpen}: NewMemberModalProps) => {
  const { shallowEqual, useSelector } = ReactRedux
  const toast = useToast()
  const { setDirtyFormAlert } = useContext(DirtyFormAlertContext)
  const [createdUserId, setCreatedUserId] = useState<string>()
  const [errorMessage, setErrorMessage] = useState<string>()
  const [step, setStep] = useState<Steps>()
  const [nextStep, setNextStep] = useState<Steps>()

  const {
    lastCreatedId,
    allUsers,
    currentAccount,
  } = useSelector(({users, accounts}: StoreState) => ({
    lastCreatedId: users.lastCreatedId,
    allUsers: users.records,
    currentAccount: accounts.current,
  }), shallowEqual)

  useEffect(() => {
    if (isOpen) {
      setCreatedUserId(undefined)
      setErrorMessage(undefined)
      setNextStep(Steps.MEMBER_INFO)
    } else {
      setDirtyFormAlert(false)
      setNextStep(undefined)
    }
  }, [isOpen])

  useEffect(() => {
    setCreatedUserId(lastCreatedId)
  }, [lastCreatedId])

  useEffect(() => {
    setTimeout(() => setStep(nextStep), 300)
  }, [nextStep])

  useEffect(() => {
    setErrorMessage(undefined)
  }, [step])

  const onError = () => {
    setErrorMessage('Something went wrong. Please Try again.')
    toast({
      description: 'Member profile was not created; try again.',
      status: 'error',
    })
  }

  const onFinish = () => {
    const createdUser = createdUserId === undefined ? null :  allUsers[createdUserId]
    toast({
      description: `A new profile has been created for ${createdUser?.firstName} ${createdUser?.lastName}.`,
      status: 'success',
      width: '650px',
    })
    setDirtyFormAlert(false)
    goToMember(createdUserId!)
  }

  return {
    createdUserId,
    currentAccount,
    errorMessage,
    lastCreatedId,
    nextStep,
    onError,
    onFinish,
    setNextStep,
    step,
  }
}

const NewMemberModal: React.FC<NewMemberModalProps> = (props) => {
  const { createdUserId, errorMessage, nextStep, onError, onFinish, step, setNextStep, currentAccount } = useHooks(props)

  return (
    <Modal size='xl' closeOnOverlayClick={false} {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader><Center fontSize='26px' fontWeight='normal'>Create New Member</Center></ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack align='center' spacing='20px' margin='0 24px 24px 24px'>
            <Text fontSize='16px' textAlign='center' color={textPalette.light}>Please fill out the section below, this can be updated later.</Text>
            <Box height='0' width='100%'>{errorMessage && (
              <Alert status='error'>
                <AlertIcon />
                <Center flex='1'>{errorMessage}</Center>
              </Alert>
            )}</Box>
            <SlideFade in={step === nextStep}>
              <Box>
                <Center height='100%' minH='240px'>
                  {(() => {
                    switch(step) {
                      case Steps.HEALTH_CONCERN:
                        return (
                          <HealthConcernForm
                            goBack={() => setNextStep(Steps.MEMBER_INFO)}
                            onError={onError}
                            onFinish={onFinish}
                            userId={createdUserId}
                          />
                        )
                      case Steps.MEMBER_INFO:
                        return (
                          <MemberInfoForm
                            onError={onError}
                            onFinish={() => setNextStep(Steps.HEALTH_CONCERN)}
                            userId={createdUserId}
                            accountId={currentAccount.id}
                          />
                        )
                      default: return null
                    }
                  })()}
                </Center>
              </Box>
            </SlideFade>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default NewMemberModal
