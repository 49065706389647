import React from 'react'
import { Box, Flex, useTheme } from '@chakra-ui/react'
import { grays } from '../constants/Colors'

type BoxProps = React.ComponentProps<typeof Box>

type ProgressSegmentsProps = BoxProps & {
  currentStep: number
  emptyBg?: BoxProps['bg']
  filledBg?: BoxProps['bg']
  totalSteps: number
}

const ProgressSegments: React.FC<ProgressSegmentsProps> = (props) => {
  const {
    currentStep,
    emptyBg = grays.light,
    filledBg: filledBgProp,
    height = '12px',
    totalSteps,
    width = '100%',
    ...otherProps
  } = props
  const { colors: { brand } } = useTheme()
  const filledBg = filledBgProp ?? brand[500]
  const widthNumber = parseInt(String(width).replace(/\D+/g, ''), 10)
  const widthUnits = String(width).replace(/\d+/g, '')
  const boxWidth = (widthNumber * 0.99) / totalSteps

  return (
    <Flex height={height} width={width} justifyContent='space-between'>
      {Array(totalSteps).fill(null).map((_, i) => (
        <Box
          {...otherProps}
          bg={currentStep < i + 1 ? emptyBg : filledBg}
          key={i}
          width={`${boxWidth}${widthUnits}`}
        />
      ))}
    </Flex>
  )
}

export default ProgressSegments
