import React from 'react'
import { Center, ChakraComponent, Spinner } from '@chakra-ui/react'


const LoadingPage: ChakraComponent<'div', any> = (props) => {
  const {staticContext, ...rest} = props
  return (
    <Center paddingTop='50px' {...rest}>
      <Spinner size='xl'/>
    </Center>
  )
}

export default LoadingPage
