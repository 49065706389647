import React, { useContext, useEffect } from 'react'
import * as ReactRedux from 'react-redux'
import { Form, Formik } from 'formik'
import {
  Button,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react'
import * as Yup from 'yup'
import { StoreState } from '../../../../redux'
import { isDone, isError } from '../../../../redux/AsyncState'
import {update as updateUser} from '../../../../redux/users/actions'
import {getMany as getHealthConcerns} from '../../../../redux/healthConcerns/actions'
import { Context as DirtyFormAlertContext } from '../../../../components/DirtyFormAlert'
import { textPalette } from '../../../../constants/Colors'
import HealthConcernButtons, {
  HealthConcernValidationSchema,
  getPrimaryHealthConcernsValue,
} from '../../../../utils/HealthConcernButtons'
import useFormikContext from '../../../../hooks/useFormikContext'

enum FormFields {
  otherHealthConcern = 'otherHealthConcern',
  primaryHealthConcerns = 'primaryHealthConcerns',
}

const initialValues = {
  [FormFields.otherHealthConcern]: '',
  [FormFields.primaryHealthConcerns]: [] as string[],
}

type Values = typeof initialValues;
type ContainerProps = { goBack: () => any; onError: () => any; onFinish: () => any; userId?: string }
type HealthConcernFormProps = ContainerProps

const ValidationSchema = Yup.object().shape(HealthConcernValidationSchema)

// visibleForTesting
export const useHooks = ({onError, onFinish}: HealthConcernFormProps) => {
  const { shallowEqual, useDispatch, useSelector } = ReactRedux
  const dispatch = useDispatch()
  const { setDirtyFormAlert } = useContext(DirtyFormAlertContext)
  const form = useFormikContext<Values>()

  const {
    isStoreLoading,
    updatingState,
  } = useSelector(({healthConcerns, users}: StoreState) => ({
    isStoreLoading: !isDone(healthConcerns.loadingState),
    updatingState: users.updatingState,
  }), shallowEqual)

  const isUpdateDone = isDone(updatingState)
  const isUpdateError = isError(updatingState)

  useEffect(() => {
    dispatch(getHealthConcerns())
    form.resetForm()
  }, [])

  useEffect(() => {
    if (isUpdateError) {
      onError()
      form.setSubmitting(false)
    }
  }, [isUpdateError])

  useEffect(() => {
    if (isUpdateDone) {
      form.setSubmitting(false)
    }
  }, [isUpdateDone])

  useEffect(() => {
    if (isUpdateDone && !form.isSubmitting && form.isValid && form.submitCount > 0) {
      onFinish()
    }
  }, [form, isUpdateDone])

  useEffect(() => {
    setDirtyFormAlert(form.dirty)
  }, [form.dirty])

  return {
    form,
    isStoreLoading,
  }
}

const HealthConcernForm: React.FC<HealthConcernFormProps> = (props) => {
  const { form, isStoreLoading } = useHooks(props)
  const { goBack } = props

  if (isStoreLoading) return <Spinner size='xl'/>

  return (
    <Form>
      <Stack align='center' spacing='20px'>
        <Text fontSize='16px' textAlign='center' color={textPalette.light}>Primary Health Concerns:</Text>
        <HealthConcernButtons />
        <Stack direction='row' spacing={4} width='100%'>
          <Button width='100%' isDisabled={form.isSubmitting} variant='outline' onClick={goBack}>
            Back
          </Button>
          <Button width='100%' isLoading={form.isSubmitting} type='submit' onClick={() => form.submitForm()}>
            Done
          </Button>
        </Stack>
      </Stack>
    </Form>
  )
}

const HealthConcernFormContainer: React.FC<ContainerProps> = (props) => {
  const dispatch = ReactRedux.useDispatch()
  const onSubmit = ({otherHealthConcern, primaryHealthConcerns}: Values) => {
    const {
      customValue: customConcernValue,
      selectedValues: selectedConcernValues,
    } = getPrimaryHealthConcernsValue(otherHealthConcern, primaryHealthConcerns)

    dispatch(updateUser({
      primaryHealthConcerns: [customConcernValue, ...selectedConcernValues].filter(Boolean),
    }, props.userId))
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ValidationSchema}
      onSubmit={onSubmit}
    >
      <HealthConcernForm {...props} />
    </Formik>
  )
}

export default HealthConcernFormContainer
