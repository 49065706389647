import ActionIds from '../ActionIds'
import AsyncState from '../AsyncState'
import { Model } from '../../constants/Directive'
import { BaseAction, Records } from '../types'

type ServerErrorData = null | string | Records<string[]>
const initialState = {
  creatingState: AsyncState.NOT_STARTED,
  errorData: {} as {alternateSurrogate?: ServerErrorData; directive?: ServerErrorData; surrogate?: ServerErrorData},
  lastCreatedId: undefined as unknown as string,
  loadingState: AsyncState.NOT_STARTED,
  records: {} as Records<Model>,
  sendingState: AsyncState.NOT_STARTED,
  sharingState: AsyncState.NOT_STARTED,
  updatingState: AsyncState.NOT_STARTED,
  polstState: AsyncState.NOT_STARTED,
}

export type StateType = typeof initialState;

export default function reducer(state = initialState, action: BaseAction): StateType {
  switch (action.type) {
    case ActionIds.DIRECTIVES_GET_ONE_START:
      return { ...state, loadingState: AsyncState.LOADING }
    case ActionIds.DIRECTIVES_UPDATE_START:
      return { ...state, updatingState: AsyncState.LOADING }
    case ActionIds.DIRECTIVES_SEND_START:
      return { ...state, sendingState: AsyncState.LOADING }
    case ActionIds.DIRECTIVES_POLST_SEND_START:
      return { ...state, polstState: AsyncState.LOADING }
    case ActionIds.DIRECTIVES_SHARE_START:
      return { ...state, sharingState: AsyncState.LOADING }
    case ActionIds.DIRECTIVES_UPDATE_SUCCESS: {
      const newDirective = action.payload.data.attributes
      const { records } = state
      return { ...state, records: {...records, [newDirective.id]: newDirective}, updatingState: AsyncState.DONE }
    }
    case ActionIds.DIRECTIVES_CREATE_START:
      return { ...state, creatingState: AsyncState.LOADING }
    case ActionIds.DIRECTIVES_CREATE_SUCCESS: {
      const newDirective = action.payload.data.attributes
      const { records } = state
      return {
        ...state,
        creatingState: AsyncState.DONE,
        lastCreatedId: newDirective.id,
        records: { ...records, [newDirective.id]: newDirective },
      }
    }
    case ActionIds.DIRECTIVES_GET_ONE_SUCCESS: {
      const newDirective = action.payload.data.attributes
      const { records } = state
      return { ...state, records: {...records, [newDirective.id]: newDirective}, loadingState: AsyncState.DONE }
    }
    case ActionIds.DIRECTIVES_SEND_SUCCESS: {
      return { ...state, sendingState: AsyncState.DONE }
    }
    case ActionIds.DIRECTIVES_SHARE_SUCCESS: {
      return { ...state, sharingState: AsyncState.DONE }
    }
    case ActionIds.DIRECTIVES_POLST_SEND_SUCCESS: {
      return { ...state, polstState: AsyncState.DONE }
    }
    case ActionIds.DIRECTIVES_GET_ONE_FAIL:
      return { ...state, loadingState: AsyncState.ERROR }
    case ActionIds.DIRECTIVES_POLST_SEND_FAIL:
      return { ...state, polstState: AsyncState.ERROR }
    case ActionIds.DIRECTIVES_CREATE_FAIL:
      return { ...state, creatingState: AsyncState.ERROR }
    case ActionIds.DIRECTIVES_UPDATE_FAIL:
      return { ...state, errorData: action.payload, updatingState: AsyncState.ERROR }
    case ActionIds.DIRECTIVES_SEND_FAIL:
      return { ...state, sendingState: AsyncState.ERROR }
    case ActionIds.DIRECTIVES_SHARE_FAIL:
      return { ...state, sharingState: AsyncState.ERROR }
    default:
      return state
  }
}
