import { Alert, Box, Button, useToast } from '@chakra-ui/react'
import React, { useCallback, useState } from 'react'
import { axiosAuthed } from '../../../redux/axios'
import { useSelector } from 'react-redux'
import { StoreState } from '../../../redux'
import capitalize from 'lodash.capitalize'
import { Link } from 'react-router-dom'
import { PricingOptions } from '../../../constants/RoutePaths'

const PaymentTab: React.FC = () => {
  const [isFetching, setIsFetching] = useState(false)
  const toast = useToast()

  const account = useSelector((state: StoreState) => state.accounts.current)

  const handleManageSubscription:React.MouseEventHandler<HTMLButtonElement> = useCallback(async (e) => {
    e.preventDefault()

    if (isFetching)
      return

    setIsFetching(true)

    try {
      const res = await axiosAuthed({method: 'post'}, '/stripe_portal_sessions', {
        returnUrl: window.location.href,
      })

      if (typeof res?.data?.portalUrl !== 'string') throw new Error('Invalid response from server')

      window.location.href = res.data.portalUrl
    } catch (err) {
      console.error(err)
      toast({
        title: 'Error',
        description: 'Something went wrong. Please try again or contact support if the problem persists.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      })
    }

    setIsFetching(false)
  }, [isFetching])

  return (
    <Box>
      <Alert
        status={
          !account.stripeSubscriptionStatus
            ? 'error'
            : account.stripeSubscriptionStatus === 'active'
              ? 'success'
              : 'warning'
        }
        mb={4}
      >
        Subscription Status:{' '}
        {capitalize(account.stripeSubscriptionStatus || 'not subscribed')}
      </Alert>
      {account.stripeSubscriptionStatus ? (
        <Button onClick={handleManageSubscription} disabled={isFetching}>
          Manage Subscription
        </Button>
      ) : (
        <Link to={PricingOptions()}>
          <Button>Subscribe</Button>
        </Link>
      )}
    </Box>
  )
}

export default PaymentTab