import { call, put, race, take, takeLatest } from 'redux-saga/effects'
import ActionIds from '../ActionIds'
import { BaseAction } from '../types'
import { deleteOne as deleteApi } from './api'

export default function* watch() {
  yield takeLatest(ActionIds.FILES_DELETE_START, requestDelete)
}

function* requestDelete({payload: {id}}: BaseAction) {
  const { apiResponse: {response, error}, isCanceled } = yield race({
    apiResponse: call(deleteApi, id),
    isCanceled: take(ActionIds.FILES_DELETE_CANCEL),
  })
  if (isCanceled) return

  if (error) {
    yield put({type: ActionIds.FILES_DELETE_FAIL})
    return
  }

  yield put({type: ActionIds.FILES_DELETE_SUCCESS, payload: response.data})
}
