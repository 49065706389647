import { axiosAuthed } from '../axios'

const BASE_ROUTE = '/health_plans'

export const getMany = async (params: any) => {
  try {
    const response = await axiosAuthed({method: 'get'}, BASE_ROUTE, {params})
    return { response }
  } catch(error) {
    return { error }
  }
}
