import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Link, useHistory } from 'react-router-dom'
import * as Yup from 'yup'
import * as ReactRedux from 'react-redux'
import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
  Textarea,
} from '@chakra-ui/react'
import { FormikContextType, FieldInputProps, Formik, Field, Form } from 'formik'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { MemberDirectiveChildProps as BaseProps } from './'
import { Context as DirtyFormAlertContext } from '../../../components/DirtyFormAlert'
import { StoreState } from '../../../redux'
import { isDone, isError } from '../../../redux/AsyncState'
import {update as updateUser} from '../../../redux/users/actions'
import { DATE_FORMAT, getIsoDateFromString, parseDate, parsePhoneNumber, userNameAttrsFromFullName, yupTransformDate } from '../../../utils/Forms'
import { FiAlertTriangle, FiCalendar, FiSave } from 'react-icons/fi'
import { white, textPalette } from '../../../constants/Colors'
import { Types as DirectiveTypes } from '../../../constants/Directive'
import isEmpty from 'lodash.isempty'
import DatePicker from '../../../components/DatePicker'
import { format as formatDate, parseISO, subYears } from 'date-fns'
import HealthConcernButtons, {
  HealthConcernValidationSchema,
  getPrimaryHealthConcernsValue,
}  from '../../../utils/HealthConcernButtons'
import {getMany as getHealthConcerns} from '../../../redux/healthConcerns/actions'
import { getMany as getHealthPlans } from '../../../redux/healthPlans/actions'
import { privateFileUrl } from '../../../utils/Routing'
import { uploadFile } from '../../../redux/axios'
import ImageUpload, { FileValidation } from '../../../components/ImageUpload'
import { useToast } from '../../../hooks/Toast'
import useFormikContext from '../../../hooks/useFormikContext'
import * as RoutePaths from '../../../constants/RoutePaths'

enum PlaceHolders {
  phone = '(888) 888-8888',
  fullName = 'John Doe',
  pronouns = 'He/Him or She/Her or They/Them',
  medicareAdvantagePlan = 'Select',
  birthdate = 'mm/dd/yyyy',
}

enum FormFields {
  avatar = 'avatar',
  fullName = 'fullName',
  pronouns = 'pronouns',
  email = 'email',
  phone = 'phone',
  birthDate = 'birthDate',
  medicareAdvantagePlan = 'medicareAdvantagePlan',
  medicareRecordNumber = 'medicareRecordNumber',
  primaryHealthConcerns = 'primaryHealthConcerns',
  otherHealthConcern = 'otherHealthConcern',
}

const initialValues = {
  avatar: undefined,
  fullName: '',
  pronouns: '',
  email: '',
  phone: '',
  birthDate: '',
  medicareAdvantagePlan: '',
  medicareRecordNumber: '',
  primaryHealthConcerns: [] as string[],
  otherHealthConcern: '',
}

type Values = typeof initialValues;
type ValueTypes = Values[keyof Values];
type FormType = FormikContextType<Values>
type FieldPropType = {field: FieldInputProps<ValueTypes>; form: FormType}
type GeneralInformationFormProps = BaseProps

const ValidationSchema = Yup.object().shape({
  [FormFields.avatar]: FileValidation,
  [FormFields.fullName]: Yup.string().required('First name is required'),
  [FormFields.phone]: Yup.string().required('Phone number is required').matches(/^\([0-9]{3}\) [0-9]{3}-[0-9]{4}?$/, 'Phone number is not valid'),
  [FormFields.birthDate]: Yup.date().transform((v1, v2) => yupTransformDate(v1, v2))
    .typeError('Must be MM/DD/YYYY format')
    .max(new Date(), 'Must be before today'),
  [FormFields.email]: Yup.string().nullable().required('Must not be empty').email('Must be a valid email'),
  ...HealthConcernValidationSchema,
})

export const useHooks = () => {
  const dispatch = useDispatch()
  const toast = useToast()
  const history = useHistory()
  const [shouldQuitOnSave, setShouldQuitOnSave] = useState(false)
  const { setDirtyFormAlert } = useContext(DirtyFormAlertContext)
  const form = useFormikContext<Values>()
  const { id } = useParams<{id: string}>()
  const {
    currentMember,
    errorData,
    updatingState,
  } = useSelector(({ users }: StoreState) => ({
    currentMember: users.records[id],
    errorData: users.errorData,
    updatingState: users.updatingState,
  }), shallowEqual)

  const isUpdatingError = isError(updatingState)
  const isUpdatingDone = isDone(updatingState)

  const onClickContinueLater = useCallback(() => {
    setShouldQuitOnSave(true)
    setDirtyFormAlert(false)
    form.submitForm()
  }, [form, setShouldQuitOnSave])

  const onClickNext = useCallback(() => {
    setDirtyFormAlert(false)
    form.submitForm()
  }, [form])

  useEffect(() => {
    if (currentMember != null) {
      const str_arr = [currentMember.firstName, currentMember.middleName, currentMember.lastName]

      form.reinitialize({
        [FormFields.fullName]: str_arr.filter(Boolean).join(' '),
        [FormFields.email]: currentMember.email || '',
        [FormFields.pronouns]: currentMember.pronouns || '',
        [FormFields.phone]: parsePhoneNumber(currentMember.phoneNumber),
        [FormFields.birthDate]: currentMember.birthday == null ? '' : formatDate(parseISO(currentMember.birthday), DATE_FORMAT) as any,
        [FormFields.medicareRecordNumber]: currentMember.medicareRecordNumber || '',
        [FormFields.medicareAdvantagePlan]: currentMember?.healthPlanName || '',
      })
    }
  }, [currentMember])

  useEffect(() => {
    dispatch(getHealthConcerns())
    dispatch(getHealthPlans())
  }, [])

  useEffect(() => {
    if (!isUpdatingError) return

    if (typeof errorData === 'object' && errorData?.email != null) {
      form.setFieldError(FormFields.email, `Email ${errorData?.email.join(', ')}`)
    }

    form.setSubmitting(false)
    toast({
      description: 'Changes were not saved; try again.',
      status: 'error',
    })
  }, [errorData, isUpdatingError])

  useEffect(() => {
    const isFormDoneSubmitting = isUpdatingDone && form.isValid && form.submitCount > 0
    if (!isFormDoneSubmitting) return

    toast({
      description: 'Changes have been saved.',
      status: 'success',
    })

    if (shouldQuitOnSave) {
      history.push(RoutePaths.MemberDetail(id))
    } else {
      history.push(RoutePaths.MemberDirective(id, DirectiveTypes.HEALTHCARE_PT1_DIRECTIVE))
    }
  }, [id, isUpdatingDone, shouldQuitOnSave])

  useEffect(() => {
    setDirtyFormAlert(form.dirty)
  }, [form.dirty])

  return {
    currentMember,
    form,
    onClickContinueLater,
    onClickNext,
  }
}

const GeneralInformationForm: React.FC<GeneralInformationFormProps> = () => {
  const { currentMember, form, onClickContinueLater, onClickNext } = useHooks()

  return (
    <Box shadow='md' backgroundColor={white} w='730px' mb='160px' padding='48px 95px'>
      <Center>
        <Text fontSize='42px' fontWeight='500'>General Information</Text>
      </Center>
      <Box w='375px' mt='48px'>
        <Stack direction='row' spacing={2} alignSelf='flex-start' alignItems='center'>
          <Field name={FormFields.avatar}>
            {({ field }: FieldPropType) => (
              <FormControl isInvalid={!isEmpty(form.errors.avatar)} width='inherit'>
                <ImageUpload
                  {...field}
                  avatarProps={{ size: 'xl' }}
                  id={field.name}
                  initialValue={privateFileUrl(currentMember?.avatar)}
                  onChange={file => {
                    form.setFieldValue(FormFields.avatar, file)
                  }}
                />
                <FormErrorMessage>{form.errors.avatar}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Stack spacing={1}>
            <Text fontSize='16px'>Profile Picture</Text>
            <Text fontSize='12px'>Click to upload profile picture</Text>
          </Stack>
        </Stack>
      </Box>
      <Box width='540px' mt={4}>
        <Form>
          <Field name={FormFields.fullName}>
            {({ field }: FieldPropType) => (
              <FormControl isInvalid={!isEmpty(form.errors.fullName) && form.touched.fullName}>
                <FormLabel htmlFor={field.name}>Full Name</FormLabel>
                <Input {...field} id={field.name} placeholder={PlaceHolders.fullName} size='lg'  />
                <FormErrorMessage>{form.errors.fullName}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Flex direction='row' w='100%' mt='16px'>
            <Field name={FormFields.pronouns}>
              {({ field }: FieldPropType) => (
                <FormControl isInvalid={!isEmpty(form.errors.pronouns) && form.touched.pronouns}>
                  <FormLabel htmlFor={field.name}>Pronouns</FormLabel>
                  <Input {...field} id={field.name} placeholder={PlaceHolders.pronouns} size='lg'  />
                  <FormErrorMessage>{form.errors.pronouns}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </Flex>
          <Flex direction='row' w='100%' mt='16px'>
            <Field name={FormFields.email}>
              {({ field }: FieldPropType) => (
                <FormControl mr='16px' isInvalid={!isEmpty(form.errors.email) && form.touched.email}>
                  <FormLabel htmlFor={field.name}>Email</FormLabel>
                  <Input {...field} id={field.name} size='lg'  />
                  <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name={FormFields.phone}>
              {({ field }: FieldPropType) => (
                <FormControl isInvalid={!isEmpty(form.errors.phone) && form.touched.phone} >
                  <FormLabel htmlFor={field.name}>Phone</FormLabel>
                  <Input
                    {...field}
                    id={field.name}
                    placeholder={PlaceHolders.phone}
                    onChange={({currentTarget}) => {form.setFieldValue(field.name, parsePhoneNumber(currentTarget.value))}}
                    size='lg'
                  />
                  <FormErrorMessage>{form.errors.phone}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </Flex>
          <Flex  mt='16px' position='relative' zIndex='100'>
            <Field name={FormFields.birthDate}>
              {({ field }: FieldPropType) => (
                <FormControl isInvalid={!isEmpty(form.errors.birthDate) && form.touched.birthDate}>
                  <FormLabel htmlFor={field.name}>Birthday</FormLabel>
                  <InputGroup width='48%'>
                    <DatePicker
                      field={field}
                      initialDate={subYears(new Date(), 73)}
                      inputFormatter={parseDate}
                      maxDate={new Date()}
                      placeholderText='MM/DD/YYYY'
                      showMonthDropdown
                    />
                    <InputRightElement><Icon as={FiCalendar} color={textPalette.lightest}/></InputRightElement>
                  </InputGroup>
                  <FormErrorMessage><FormErrorIcon as={FiAlertTriangle} />{form.errors.birthDate}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </Flex>
          <Flex direction='row' w='100%' mt='16px'>
            <Field name={FormFields.medicareAdvantagePlan}>
              {({ field }: FieldPropType) => (
                <FormControl isInvalid={!isEmpty(form.errors.medicareAdvantagePlan) && form.touched.medicareAdvantagePlan} mr='16px' maxW='262px'>
                  <FormLabel htmlFor={field.name}>Medical Plan</FormLabel>
                  <Textarea {...field} id={field.name} size='lg' value={isEmpty(field.value) ? '' : field.value} backgroundColor={white} />
                </FormControl>
              )}
            </Field>
            <Field name={FormFields.medicareRecordNumber}>
              {({ field }: FieldPropType) => (
                <FormControl isInvalid={!isEmpty(form.errors.medicareRecordNumber) && form.touched.medicareRecordNumber} >
                  <FormLabel htmlFor={field.name}>Medical Record Number</FormLabel>
                  <Textarea {...field} id={field.name} size='lg' value={isEmpty(field.value) ? '' : field.value} backgroundColor={white} />
                  <FormErrorMessage>{form.errors.medicareRecordNumber}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </Flex>
          <Box width='100%' mt='62px'>
            <Text fontSize='16px' lineHeight='24px' mb='24px' color={textPalette.light}>
            Primary Health Concern:
            </Text>
            <HealthConcernButtons initialConcerns={currentMember?.primaryHealthConcernNames}/>
          </Box>
        </Form>
      </Box>
      <Stack align='center' spacing='20px' paddingTop='20px'>
        <Stack direction='row' spacing={4} width='100%'>
          <Button as={Link} width='100%' isDisabled={form.isSubmitting} variant='outline' to={RoutePaths.MemberDetail(currentMember.id)}>
            Back
          </Button>
          <Button width='100%' isLoading={form.isSubmitting} type='submit' onClick={onClickNext}>
            Next
          </Button>
        </Stack>
        <Center width='100%'>
          <Button
            isLoading={form.isSubmitting}
            leftIcon={<Icon as={FiSave} height='20px' width='20px' />}
            onClick={onClickContinueLater}
            variant='link'>
            Save &amp; Continue Later
          </Button>
        </Center>
      </Stack>
    </Box>
  )
}

const GeneralInformationFormContainer: React.FC<GeneralInformationFormProps> = (props) => {
  const dispatch = ReactRedux.useDispatch()
  const { id } = useParams<{id: string}>()

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ValidationSchema}
      onSubmit={({avatar, otherHealthConcern, primaryHealthConcerns, ...values}: Values) => {
        const {
          customValue: customConcernValue,
          selectedValues: selectedConcernValues,
        } = getPrimaryHealthConcernsValue(otherHealthConcern, primaryHealthConcerns)

        uploadFile(avatar).then((avatarId) => {
          dispatch(updateUser({
            ...userNameAttrsFromFullName(values.fullName),
            avatar: avatarId,
            pronouns: values.pronouns,
            phoneNumber: values.phone.match(/\d/g)?.join(''),
            email: values.email,
            birthday: getIsoDateFromString(values.birthDate),
            medicareRecordNumber: values.medicareRecordNumber,
            healthPlanName: values.medicareAdvantagePlan,
            primaryHealthConcerns: [customConcernValue, ...selectedConcernValues].filter(Boolean),
          }, id))
        })
      }}
    >
      <GeneralInformationForm {...props} />
    </Formik>
  )
}


export default GeneralInformationFormContainer