import { Box, ListItem, UnorderedList, Text } from '@chakra-ui/react'
import React from 'react'
import ProgressCircle from '../../../assets/Svgs/ProgressCircle'
import { yellows } from '../../../constants/Colors'


type MissingFieldProps = {
  errors: string[]
}

const MissingFieldsBox: React.FC<MissingFieldProps> = (props) => {
  const {errors} = props

  return (
    <Box width={'100%'} borderWidth={'1px'} borderColor={yellows.missingInfo} borderRadius={'md'} marginBottom={'16px'}>
      <Box width={'100%'} display={'flex'} flexDirection={'row'}  padding={'16px'}>
        <ProgressCircle /> <Text paddingLeft={'16px'}fontWeight={'700'}> Missing Info.</Text>  <Text paddingLeft={'8px'} >The following fields are missing info:</Text>
      </Box>
      <Box marginLeft={'48px'} paddingBottom={'16px'} >
        <UnorderedList>{errors.map((item, index)=><ListItem key={`${index}-missing-ingo`} fontSize='xs'>{item}</ListItem>)}</UnorderedList>
      </Box>
    </Box>
  )
}


export default MissingFieldsBox