import React, { useRef, useState } from 'react'
import { Avatar, AvatarProps, InputProps } from '@chakra-ui/react'
import FileInput, { FileValidationGenerator } from './FileInput'
import { grays } from '../constants/Colors'

export const FileValidation = FileValidationGenerator(Math.pow(1024, 2), [
  // https://developer.mozilla.org/en-US/docs/Web/Media/Formats/Image_types
  'image/apng',
  'image/bmp',
  'image/gif',
  'image/jpeg',
  'image/pjpeg',
  'image/png',
  'image/svg+xml',
  'image/tiff',
  'image/webp',
  'image/x-icon',
])

interface ImageUploadProps extends Omit<InputProps, 'onChange'> {
  avatarProps?: AvatarProps
  initialValue?: string
  onChange: (file: File) => void
}

const ImageUpload: React.FC<ImageUploadProps> = ({avatarProps = {}, initialValue, onChange, ...otherProps}) => {
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [imagePreviewUrl, setImagePreviewUrl] = useState<string>()

  const showFileSelect = () => {
    const currentInput = fileInputRef?.current
    if (currentInput == null) return undefined

    currentInput.click()
  }

  const imageSrc = imagePreviewUrl || initialValue

  return (
    <Avatar
      bg={grays.image}
      borderColor={grays[300]}
      borderStyle='dashed'
      cursor='pointer'
      showBorder={imageSrc == null}
      {...avatarProps}
      name={imageSrc == null ? '+' : undefined}
      onClick={showFileSelect}
      src={imageSrc}
    >
      <FileInput
        accept='image/*'
        {...otherProps}
        onChange={({file, fileUrl}) => {
          setImagePreviewUrl(fileUrl)
          onChange(file)
        }}
        ref={fileInputRef}
      />
    </Avatar>
  )
}

export default ImageUpload
