import React, { useContext, useEffect } from 'react'
import * as ReactRedux from 'react-redux'
import { ChildFormProps as ContainerProps } from '.'
import { update as updateDirective } from '../../../../redux/directives/actions'
import { Context as DirtyFormAlertContext } from '../../../../components/DirtyFormAlert'
import { Field, FieldInputProps, Form, Formik, FormikContextType } from 'formik'
import { isError } from '../../../../redux/AsyncState'
import { StoreState } from '../../../../redux'
import {
  Button,
  Center,
  FormControl,
  Icon,
  ListItem,
  OrderedList,
  Stack,
  Text,
  Textarea,
} from '@chakra-ui/react'
import { textPalette } from '../../../../constants/Colors'
import { FiSave } from 'react-icons/fi'
import isEmpty from 'lodash.isempty'

enum FormFields {
  surrogatePowers1Text1 = 'surrogatePowers1Text1',
}

enum PlaceHolders {
  typeHere = 'Type here...',
}

const initialValues = {
  [FormFields.surrogatePowers1Text1]: '',
}

type Values = typeof initialValues;
type ValueTypes = Values[keyof Values];
type FormType = FormikContextType<Values>
type FieldPropType = { field: FieldInputProps<ValueTypes>; form: FormType }
type SurrogatePowers1FormProps = ContainerProps & { form: FormType }

export const useHooks = ({ form, directive }: SurrogatePowers1FormProps) => {
  const { setDirtyFormAlert } = useContext(DirtyFormAlertContext)
  const { shallowEqual, useSelector } = ReactRedux

  const {
    updatingState,
  } = useSelector(({ directives }: StoreState) => ({
    updatingState: directives.updatingState,
  }), shallowEqual)

  useEffect(() => {
    form.resetForm({
      values: {
        ...form.values,
        [FormFields.surrogatePowers1Text1]: directive.questions[FormFields.surrogatePowers1Text1] ?? '',
      },
    })
  }, [directive])

  const isUpdateError = isError(updatingState)

  useEffect(() => {
    if (isUpdateError) {
      form.setSubmitting(false)
    }
  }, [isUpdateError])

  useEffect(() => {
    setDirtyFormAlert({ blockSearchChanges: true, isDirty: form.dirty })
  }, [form.dirty])
}

const SurrogatePowers1Form: React.FC<SurrogatePowers1FormProps> = (props) => {
  useHooks(props)
  const { continueLater, form, goBack, currentStep, totalSteps } = props

  return (
    <Form>
      <Stack align='center' spacing='20px' maxWidth='540px'>
        <Text fontSize='16px' color={textPalette.light}>Step {currentStep} of {totalSteps}</Text>
        <Text fontSize='25px'>Health Care Agent Powers and Limitations</Text>
        <Text color={textPalette.light} width='100%'>I understand that my health care Agent has the following powers:</Text>
        <OrderedList width='90%' color={textPalette.light} spacing={3}>
          <ListItem>
            Agree to, refuse, or cancel decisions about my health care. This includes tests, medications, surgery, taking out or not putting in tube feedings, and other decisions related to treatments. If treatment has already begun, my agent can continue it or stop it based on my instructions.
          </ListItem>
          <ListItem>
            Interpret any instructions in this document based on their understanding of my wishes, values, and beliefs.
          </ListItem>
          <ListItem>
            Review and release my medical records and personal files as needed for my health care, as stated in the Health Insurance Portability and Accountability Act of 1996 (HIPAA).
          </ListItem>
          <ListItem>
            Arrange for my health care and treatment in my home state, another state, or location they think is appropriate.
          </ListItem>
          <ListItem>
            Decide which health care providers and organizations provide my health care.
          </ListItem>
          <ListItem>
            If I DO NOT want my health care Agent to have a power listed above, OR if I want to LIMIT any power, I MUST say that here:
          </ListItem>
        </OrderedList>

        <Field name={FormFields.surrogatePowers1Text1}>
          {({ field }: FieldPropType) => (
            <FormControl isInvalid={!isEmpty(form.errors.surrogatePowers1Text1) && form.touched.surrogatePowers1Text1}>
              <Textarea {...field} id={field.name} minH='96px' borderRadius='3px' placeholder={PlaceHolders.typeHere} />
            </FormControl>
          )}
        </Field>

        <Stack direction='row' width='100%'>
          <Button width='100%' variant='outline' onClick={goBack}>
            Back
          </Button>
          <Button width='100%' isLoading={form.isSubmitting} type='submit' onClick={() => form.submitForm()}>
            Next
          </Button>
        </Stack>
        <Center width='100%'>
          <Button
            isLoading={form.isSubmitting}
            leftIcon={<Icon as={FiSave} height='20px' width='20px' />}
            onClick={() => continueLater(form, false)}
            variant='link'>
            Save &amp; Continue Later
          </Button>
        </Center>
      </Stack>
    </Form>
  )
}

const SurrogatePowers1FormContainer: React.FC<ContainerProps> = (props) => {
  const dispatch = ReactRedux.useDispatch()
  const { currentStep, directive } = props
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => { dispatch(updateDirective({ ...values, currentStep }, directive.id)) }}
    >{(formik) => <SurrogatePowers1Form {...props} form={formik} />}</Formik>
  )
}

export default SurrogatePowers1FormContainer