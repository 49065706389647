import React, { useContext, useEffect, useState } from 'react'
import * as ReactRedux from 'react-redux'
import { useHistory, useLocation, withRouter, RouteComponentProps } from 'react-router-dom'
import { Alert,
  AlertIcon,
  Box,
  Center,
  SlideFade,
  Stack,
  Text,
} from '@chakra-ui/react'
import AccountInfoForm from './AccountInfoForm'
import PasswordForm from '../../../components/PasswordForm'
import PrivacyPolicy from './PrivacyPolicy'
import Success from './Success'
import { Context as DirtyFormAlertContext } from '../../../components/DirtyFormAlert'
import { StoreState } from '../../../redux'
import { isDone, isError } from '../../../redux/AsyncState'
import { QUERY_PARAM_KEYS, getStepParam, getNewSearchString } from '../../../utils/Routing'
import * as RoutePaths from '../../../constants/RoutePaths'
import { grays, white } from '../../../constants/Colors'
import { useToast } from '../../../hooks/Toast'

enum Steps {
  PASSWORD = 0,
  ACCOUNT_INFO = 1,
  PRIVACY_POLICY = 2,
  SUCCESS = 3,
}
type NewAccountProps = RouteComponentProps<any>

const useHooks = () => {
  const { shallowEqual, useSelector } = ReactRedux
  const { setDirtyFormAlert } = useContext(DirtyFormAlertContext)
  const history = useHistory()
  const toast = useToast()
  const location = useLocation()
  const [hasMounted, setHasMounted] = useState(false)
  const stepParam = parseInt(getStepParam(location) as any, 10)
  const nextStep = isNaN(stepParam) ? undefined : stepParam

  const {
    currentProfile,
    updatingState,
  } = useSelector(({users}: StoreState) => ({
    currentProfile: users.currentProfile,
    updatingState: users.updatingState,
  }), shallowEqual)

  const [step, setStep] = useState(nextStep)

  const isUpdateDone = isDone(updatingState)
  const isUpdateError = isError(updatingState)

  const goToStep = (stepValue: Steps) => history.push({
    pathname: RoutePaths.ProfileCreate(),
    search: getNewSearchString({
      [QUERY_PARAM_KEYS.STEP]: String(stepValue),
    }),
  })
  const goBack = () => goToStep(step! - 1)

  useEffect(() => {
    setHasMounted(true)
  }, [])

  useEffect(() => {
    if (!hasMounted) return undefined

    if (isUpdateDone) {
      setDirtyFormAlert(false)
      goToStep(step! + 1)
    }
  }, [isUpdateDone])

  useEffect(() => {
    if(isUpdateError) {
      toast({
        description: 'Advocate profile was not created; try again.',
        status: 'error',
      })
    }
  }, [isUpdateError])

  useEffect(() => {
    if (nextStep == null) {
      goToStep((() => {
        if (currentProfile.hasAcceptedTerms) return Steps.SUCCESS
        if (!currentProfile.hasSetOwnPassword) return Steps.PASSWORD

        return Steps.ACCOUNT_INFO
      })())
    } else {
      setTimeout(() => setStep(nextStep), 300)
    }
  }, [nextStep])

  return {
    currentProfile,
    goBack,
    isUpdateError,
    nextStep,
    step,
  }
}

const NewAccount: React.FC<NewAccountProps> = (props) => {
  const { isUpdateError, goBack, nextStep, step, currentProfile } = useHooks()

  return (
    <Center height='100%' bg={grays.background}>
      <Stack align='center' spacing='20px'>
        <Text fontSize='42px' fontWeight='500'>Welcome to Thanacare</Text>
        <SlideFade in={nextStep === step}>
          <Box shadow='md' backgroundColor={white} w='730px'>
            <Box height='0'>{isUpdateError && (
              <Alert status='error'>
                <AlertIcon />
                <Center flex='1'>Something went wrong. Please Try again.</Center>
              </Alert>
            )}</Box>
            <Center height='100%' padding='48px 95px'>
              {(() => {
                switch(step) {
                  case Steps.SUCCESS: return <Success {...props} advocateName={`${currentProfile.firstName} ${currentProfile.lastName}`}/>
                  case Steps.PRIVACY_POLICY: return <PrivacyPolicy {...props} goBack={goBack}/>
                  case Steps.ACCOUNT_INFO: return <AccountInfoForm {...props} goBack={goBack}/>
                  case Steps.PASSWORD:
                    // @ts-ignore
                    // eslint-disable-next-line no-fallthrough
                  default: return <PasswordForm {...props}/>
                }
              })()}
            </Center>
          </Box>
        </SlideFade>
      </Stack>
    </Center>
  )
}

export default withRouter(NewAccount)
