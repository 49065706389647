import React, { useEffect } from 'react'
import * as ReactRedux from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { withRouter } from 'react-router-dom'
import { Field, FieldInputProps, Form, Formik, FormikContextType } from 'formik'
import {
  Button,
  Box,
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
  Stack,
  Text,
  useTheme,
} from '@chakra-ui/react'
import { FiAlertTriangle } from 'react-icons/fi'
import * as Yup from 'yup'
import isEmpty from 'lodash.isempty'
import { StoreState } from '../../../redux'
import {isError} from '../../../redux/AsyncState'
import {updateProfile as updateUser} from '../../../redux/users/actions'
import { grays } from '../../../constants/Colors'
import PrivacyAndTerms from '../../../components/PrivacyAndTerms'

const scrollbarCSS = (primaryColor: string, hoverColor: string) => ({
  '&::-webkit-scrollbar': {
    width: '6px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    background: primaryColor,
    borderRadius: '25px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: hoverColor,
  },
})

enum FormFields {
  hasAcceptedTerms = 'hasAcceptedTerms',
}

const initialValues = {
  [FormFields.hasAcceptedTerms]: false,
} as const

type Values = typeof initialValues;
type FormType = FormikContextType<Values>
type FieldPropType = {field: FieldInputProps<Values>; form: FormType}
type ContainerProps = RouteComponentProps<any> & { goBack: () => any }
type PrivacyPolicyProps = ContainerProps & { form: FormType }

const ValidationSchema = Yup.object().shape({
  [FormFields.hasAcceptedTerms]: Yup.boolean().oneOf([true]),
})

// visibleForTesting
export const useHooks = ({form}: PrivacyPolicyProps) => {
  const { shallowEqual, useSelector } = ReactRedux

  const {
    updatingState,
  } = useSelector(({users}: StoreState) => ({
    updatingState: users.updatingState,
  }), shallowEqual)

  const isUpdateError = isError(updatingState)

  useEffect(() => {
    if (isUpdateError) {
      form.setSubmitting(false)
    }
  }, [isUpdateError])

  const onScroll = ({currentTarget}: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const {
      clientHeight,
      scrollHeight,
      scrollTop,
    } = currentTarget

    const isCloseEnoughToBottom = scrollHeight - scrollTop < clientHeight + 10 // allow for inaccurate readings from old IE browsers

    if (!isCloseEnoughToBottom) return

    form.setFieldValue(FormFields.hasAcceptedTerms, true)
  }

  return {
    isUpdateError,
    onScroll,
  }
}

const PrivacyPolicy: React.FC<PrivacyPolicyProps> = (props) => {
  const { onScroll } = useHooks(props)
  const { colors: { brand } } = useTheme()
  const { form, goBack } = props

  return (
    <Form>
      <Stack align='center' spacing='20px' width='540px'>
        <Text fontSize='26px'>Privacy Policy &amp; Terms of Use</Text>
        <Text textAlign='center'>Thanacare's Privacy Policy applies to anyone who interacts directly with our platform.</Text>
        <Box onScroll={onScroll} height='300px' overflow='auto' bg={grays.input} border={`1px solid ${grays.light}`} padding='20px' css={scrollbarCSS(brand[500], brand[300])}>

          <PrivacyAndTerms />
        </Box>
        <Field name={FormFields.hasAcceptedTerms}>
          {(_props: FieldPropType) => (
            <FormControl isInvalid={!isEmpty(form.errors.hasAcceptedTerms) && form.touched.hasAcceptedTerms} marginTop='0' marginBottom='20px'>
              <FormErrorMessage><FormErrorIcon as={FiAlertTriangle} />Please read the entire policy before continuing.</FormErrorMessage>
            </FormControl>
          )}
        </Field>
        <Stack direction='row' spacing={4} width='100%'>
          <Button width='100%' isDisabled={form.isSubmitting} variant='outline' onClick={goBack}>
            Back
          </Button>
          <Button width='100%' isLoading={form.isSubmitting} type='submit' onClick={() => form.submitForm()}>
            Accept
          </Button>
        </Stack>
      </Stack>
    </Form>
  )
}

const PrivacyPolicyContainer: React.FC<ContainerProps> = (props) => {
  const dispatch = ReactRedux.useDispatch()

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ValidationSchema}
      onSubmit={(values) => { dispatch(updateUser(values)) }}
    >{(formik) => <PrivacyPolicy {...props} form={formik} />}</Formik>
  )
}

export default withRouter(PrivacyPolicyContainer)
