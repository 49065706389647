import React from 'react'
import {
  Box,
  Text,
} from '@chakra-ui/react'
import {grays, textPalette} from '../../constants/Colors'

export interface DividerProps {
  title: string
}

const Divider: React.FC<DividerProps> = ({title}) => (
  <Box
    borderTop={`1px solid ${grays.image}`}
    height='40px'
    paddingTop='20px'
    width='100%'>
    <Text
      color={textPalette.light}
      fontSize='12px'
      fontWeight='500'>
      {title}
    </Text>
  </Box>
)

export default Divider
