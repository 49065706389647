import React, { useContext, useEffect, useState } from 'react'
import {
  Box,
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  SlideFade,
  Stack,
  Text,
} from '@chakra-ui/react'
import SentPlane from '../../../../components/SentPlane'
import AdvocateInfoForm from './TeammateInfoForm'
import { Context as DirtyFormAlertContext } from '../../../../components/DirtyFormAlert'
import { textPalette } from '../../../../constants/Colors'
import { useToast } from '../../../../hooks/Toast'
import { Model as UserModel } from '../../../../constants/User'

enum Steps {
  ADVOCATE_INFO = 0,
  SENT = 1,
}
type InviteTeammateModalProps = Omit<ModalProps, 'children'> & { userToEdit: UserModel | undefined }

const useHooks = ({isOpen}: InviteTeammateModalProps) => {
  const toast = useToast()
  const { setDirtyFormAlert } = useContext(DirtyFormAlertContext)
  const [step, setStep] = useState<Steps>()
  const [nextStep, setNextStep] = useState<Steps>()

  useEffect(() => {
    if (isOpen) {
      setNextStep(Steps.ADVOCATE_INFO)
    } else {
      setDirtyFormAlert(false)
      setNextStep(undefined)
    }
  }, [isOpen])

  useEffect(() => {
    setTimeout(() => setStep(nextStep), 300)
  }, [nextStep])


  const onError = (errorMsg) => {
    toast({
      description: errorMsg || 'Something went wrong. Please Try again.',
      status: 'error',
    })
  }

  return {
    nextStep,
    onError,
    setNextStep,
    step,
  }
}

const InviteTeammateModal: React.FC<InviteTeammateModalProps> = (props) => {
  const { nextStep, onError, step, setNextStep } = useHooks(props)
  const { onClose, userToEdit } = props

  return (
    <Modal size='xl' closeOnOverlayClick={false} {...props}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{step === Steps.ADVOCATE_INFO && <Center fontSize='26px' fontWeight='normal'>{userToEdit ? 'Update' : 'Invite New'} Teammate</Center>}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack align='center' spacing='20px' margin='0 24px 24px 24px'>
            { step === Steps.ADVOCATE_INFO &&
                  <Text fontSize='16px' textAlign='center' color={textPalette.light}>{ userToEdit ? 'Update this teammate\'s details below' : 'Invite people by email to join your team!' }</Text>
            }
            <SlideFade in={step === nextStep}>
              <Box>
                <Center height='100%' minH='240px'>
                  {(() => {
                    switch(step) {
                      case Steps.SENT:
                        return <SentPlane onFinish={onClose} text={userToEdit ? 'User Updated' : 'Invite Sent!'}/>
                      case Steps.ADVOCATE_INFO:
                        return <AdvocateInfoForm userToEdit={userToEdit} onError={onError} onFinish={() => {userToEdit ? onClose() : setNextStep(Steps.SENT)}} />
                      default: return null
                    }
                  })()}
                </Center>
              </Box>
            </SlideFade>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default InviteTeammateModal
