import {BaseAction} from '../types'
import ActionIds from '../ActionIds'

export const cancelGetOne = (): BaseAction => ({type: ActionIds.ACCOUNTS_GET_ONE_CANCEL})
export const getOne = (id: string): BaseAction => ({
  type: ActionIds.ACCOUNTS_GET_ONE_START,
  payload: id,
})

export const getCurrent = (): BaseAction => ({
  type: ActionIds.ACCOUNTS_GET_CURRENT_START,
})

export const cancelGetMany = (): BaseAction => ({type: ActionIds.ACCOUNTS_GET_MANY_CANCEL})
export const getMany = (params: any = {}): BaseAction => ({
  type: ActionIds.ACCOUNTS_GET_MANY_START,
  payload: params,
})

export const update = (params, id: string): BaseAction => ({
  type: ActionIds.ACCOUNTS_UPDATE_START,
  payload: {id, params},
})

export const create = (params): BaseAction => ({
  type: ActionIds.ACCOUNTS_CREATE_START,
  payload: params,
})


