import {Button, Flex, FormControl, FormErrorIcon, FormErrorMessage, FormLabel, Textarea} from '@chakra-ui/react'
import { Field } from 'formik'
import React, { useEffect } from 'react'
import { FiAlertTriangle } from 'react-icons/fi'
import { useSelector, shallowEqual } from 'react-redux'
import { StoreState } from '../redux'
import useCustomSelectOption, { OTHER_VALUE, NONE_VALUE, getOptionValues, getValidationSchema } from '../hooks/useCustomSelectOption'
import useFormikContext from '../hooks/useFormikContext'

enum FormFields {
  primaryHealthConcerns = 'primaryHealthConcerns',
  otherHealthConcern = 'otherHealthConcern',
}

interface Values {
  otherHealthConcern: string
  primaryHealthConcerns: string[]
}
type HealthConcernButtonsProps = { initialConcerns?: string[] }
const SPECIAL_CONCERNS = [OTHER_VALUE, NONE_VALUE]

export const getPrimaryHealthConcernsValue = getOptionValues
export const HealthConcernValidationSchema = getValidationSchema({
  customField: FormFields.otherHealthConcern,
  selectionField: FormFields.primaryHealthConcerns,
})

export const useHooks = (props: HealthConcernButtonsProps) => {
  const form = useFormikContext<Values>()
  const { initialConcerns } = props
  const {
    healthConcernRecords,
  } = useSelector(({ healthConcerns }: StoreState) => ({
    healthConcernRecords: healthConcerns.records,
  }), shallowEqual)

  const recordNames = Object.values(healthConcernRecords).map(({name}) => name)
  const {
    initializeOptions,
    isOtherInvalid,
    isOtherOptionSelected,
    onClickOption,
    selectedOptions,
  } = useCustomSelectOption({
    allowNoneOption: true,
    customField: FormFields.otherHealthConcern,
    selectionField: FormFields.primaryHealthConcerns,
  })

  useEffect(() => {
    if (initialConcerns != null && recordNames.length > 0) {
      initializeOptions({
        allOptions: recordNames,
        initialSelection: initialConcerns,
      })
    }
  }, [healthConcernRecords, initialConcerns])

  return {
    form,
    isInputHidden: !isOtherOptionSelected,
    isOtherInvalid,
    onClickOption,
    recordValues: recordNames.concat(SPECIAL_CONCERNS),
    selectedOptions,
  }
}

const HealthConcernButtons: React.FC<HealthConcernButtonsProps> = (props) => {
  const { form, isInputHidden, isOtherInvalid, onClickOption, recordValues, selectedOptions } = useHooks(props)

  return (
    <>
      <Flex direction='row' width='100%' wrap='wrap' justify='flex-start'>
        {recordValues.map((name) => (
          <Button
            colorScheme='gray'
            isActive={selectedOptions[name]}
            key={name}
            margin='2px'
            onClick={() => onClickOption(name)}
            variant='purpleOutline'
          >
            {name}
          </Button>
        ))}
      </Flex>
      <Field name={FormFields.otherHealthConcern}>
        {({ field }) => (
          <FormControl marginTop='0 !important' isInvalid={isOtherInvalid}>
            <FormLabel htmlFor={field.name} hidden={isInputHidden}>Health Concern</FormLabel>
            <Textarea {...field} id={field.name} type='text' size='sm' maxLength={212} hidden={isInputHidden}/>
            <FormErrorMessage><FormErrorIcon as={FiAlertTriangle} />{form.errors.primaryHealthConcerns || form.errors.otherHealthConcern}</FormErrorMessage>
          </FormControl>
        )}
      </Field>
    </>
  )
}

export default HealthConcernButtons