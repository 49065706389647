import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import * as ReactRedux from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { Alert,
  AlertIcon,
  Box,
  Center,
  SlideFade,
} from '@chakra-ui/react'
import { FormikContextType } from 'formik'
import { MemberDirectiveChildProps as BaseProps } from '../'
import PrimaryCareForm from './PrimaryCareForm'
import ReviewStep from '../ReviewStep'
import { totalStepsForDirectiveType } from '../../../../utils/BusinessLogic'
import { Context as DirtyFormAlertContext } from '../../../../components/DirtyFormAlert'
import ProgressSegments from '../../../../components/ProgressSegments'
import { StoreState } from '../../../../redux'
import { isDone, isError } from '../../../../redux/AsyncState'
import { update as updateDirective } from '../../../../redux/directives/actions'
import * as RoutePaths from '../../../../constants/RoutePaths'
import { Model as DirectiveModel, Types as DirectiveTypes, PolstSteps as Steps } from '../../../../constants/Directive'
import { white } from '../../../../constants/Colors'
import ThanksStep from '../ThanksStep'

export const StepTitles = {
  [Steps.PRIMARY_CARE]: 'Primary Care',
  [Steps.REVIEW]: 'Review',
} as const

const TOTAL_STEPS = totalStepsForDirectiveType(DirectiveTypes.POLST)
type PolstDirectiveStepsProps = BaseProps

const useHooks = ({goToStep, goToType, isGivingThanks, isTransitioning, onClickThanks, step}: PolstDirectiveStepsProps) => {
  const { shallowEqual, useDispatch, useSelector } = ReactRedux
  const { setDirtyFormAlert } = useContext(DirtyFormAlertContext)
  const { id } = useParams<{id: string; type: DirectiveTypes}>()
  const dispatch = useDispatch()
  const history = useHistory()
  const [shouldQuitOnSave, setShouldQuitOnSave] = useState(false)
  const [isMounted, setIsMounted] = useState(false)

  const {
    allDirectives,
    member,
    updatingState,
  } = useSelector(({directives, users}: StoreState) => ({
    allDirectives: directives.records,
    member: users.records[id]!,
    updatingState: directives.updatingState,
  }), shallowEqual)

  const directive = allDirectives[member.polstDirectiveId!]
  const isReviewStep = useMemo(() => step === Steps.REVIEW, [step])

  const isUpdateDone = isDone(updatingState)
  const isUpdateError = isError(updatingState)

  const goToNextType = useCallback(() => goToType(RoutePaths.NonDirectiveViews.VIDEO_WISHES, true), [goToType])
  const goBack = useCallback(() => {
    step <= 1 || step > TOTAL_STEPS ? goToStep(TOTAL_STEPS) : goToStep(step - 1) }
  , [goToStep, step])
  const goToMemberDetail = () => history.push(RoutePaths.MemberDetail(id))
  const continueLater = (form?: FormikContextType<any>) => {
    if (form == null) return goToMemberDetail()

    setShouldQuitOnSave(true)
    // skip form validation
    form.setSubmitting(true)
    dispatch(updateDirective(form.values, directive!.id!))
  }

  useEffect(() => {
    setIsMounted(true)
  }, [])

  useEffect(() => {
    if (isUpdateError) {
      setShouldQuitOnSave(false)
    }
  }, [isUpdateError])

  useEffect(() => {
    if (!isMounted || !isUpdateDone) return

    if (shouldQuitOnSave) {
      return setDirtyFormAlert(false,  () => goToMemberDetail())
    }

    if (isReviewStep) return goToNextType()

    setDirtyFormAlert(false,  () => goToStep(step + 1))
  }, [isUpdateDone])

  return {
    continueLater,
    directive,
    goBack,
    isGivingThanks,
    isTransitioning,
    isUpdateError,
    onClickThanks,
    step,
  }
}

export interface ChildFormProps extends PolstDirectiveStepsProps {
  continueLater: (form?: FormikContextType<any>) => void
  currentStep: number
  directive: DirectiveModel
  goBack: () => void
  totalSteps: number
}

const PolstDirectiveSteps: React.FC<PolstDirectiveStepsProps> = (props) => {
  const {
    continueLater,
    directive,
    goBack,
    isGivingThanks,
    isTransitioning,
    isUpdateError,
    onClickThanks,
    step,
  } = useHooks(props)
  const sharedFormProps: ChildFormProps = {
    ...props,
    continueLater,
    currentStep: step,
    directive,
    goBack,
    totalSteps: TOTAL_STEPS,
  }
  const shouldShowProgress = !isGivingThanks

  return (
    <Box>
      {shouldShowProgress && <ProgressSegments currentStep={step} totalSteps={TOTAL_STEPS}/>}
      <SlideFade in={!isTransitioning}>
        <Box shadow='md' backgroundColor={white} w='100%' mb='160px'>
          <Box height='0'>{isUpdateError && (
            <Alert status='error'>
              <AlertIcon />
              <Center flex='1'>Something went wrong. Please Try again.</Center>
            </Alert>
          )}</Box>
          <Center height='100%' padding='48px 95px'>
            {(() => {
              if (isGivingThanks) return (
                <ThanksStep
                  buttonText='Continue to POLST'
                  onClickContinue={onClickThanks}
                  text="The next section of forms are optional and include the member's state POLST and a place upload video wishes."
                  title='Healthcare Directive Part 2 complete!'/>
              )

              switch(step) {
                case Steps.PRIMARY_CARE: return <PrimaryCareForm {...sharedFormProps}/>
                case Steps.REVIEW: // fall-through
                default: return (
                  <ReviewStep
                    {...sharedFormProps}
                    text='Review POLST to make sure it reflects patient instructions and wishes accurately before sending for signatures.'/>
                )
              }
            })()}
          </Center>
        </Box>
      </SlideFade>
    </Box>
  )
}

export default PolstDirectiveSteps
