import find from 'lodash.find'
import {FormikValues} from 'formik'

export type FlexForm = {
  title: string
  questionTemplates: { [key: string]: FlexFormQuestion }
  sections: FlexFormSection[]
}

export type FlexFormSection = {
  navTitle: string
  title: string
  subtitle?: string
  questions: FlexFormQuestion[]
}

export const getInitialValues = (questions: FlexFormQuestion[], savedValues: {[id: string]: string}): { [id: string]: string } => {
  let output = {...savedValues}
  questions.forEach(q => {
    if (output[q.id] !== undefined) {
      return
    }
    output[q.id] = q.initialValue || ''
    if (q.options && find(q.options, (option: FlexFormQuestionOption) => option.includeExplanation) !== undefined) {
      output[`${q.id}_explanation`] = ''
    }
  })
  return output
}

export const getEmptyInitialValues = (questions: FlexFormQuestion[]): { [id: string]: string } => {
  const out = {}
  questions.forEach(q => {
    out[q.id] = ''
  })
  return out
}

export const questionAnswersValid = (questions: FlexFormQuestion[], savedValues: {[id: string]: string}): boolean => {
  return questions.reduce<boolean>((valid, q) => {
    if (!valid) {
      return false
    }
    return !(q.required && (savedValues[q.id] === undefined || savedValues[q.id] === null || savedValues[q.id] === ''))
  }, true)
}

export const getErrors = (section: FlexFormSection, values: FormikValues) => {
  const errors = {}
  section.questions.forEach(q => {
    const matchedAnswer = values[q.id]
    //validate required values
    if (q.required && (matchedAnswer === '' || matchedAnswer === undefined)) {
      errors[q.id] = 'This field is required'
    }
    //validate explanations if matching answer and required
    if (q.formControlType === 'radio') {
      const optionMatch = find(q.options, (option: FlexFormQuestionOption) => option.value === matchedAnswer)
      if (optionMatch !== undefined && optionMatch.explanationRequired) {
        const explanationAnswer = values[`${q.id}_explanation`]
        if (explanationAnswer === '' || explanationAnswer === undefined) {
          errors[`${q.id}_explanation`] = 'An explanation is required.'
        }
      }
    }
  })
  return errors
}

export type FlexFormQuestion = {
  id: string
  initialValue?: string
  label?: string
  description?: string
  formControlType: string
  required: boolean
  options?: FlexFormQuestionOption[]
  compact?: boolean
  questionTemplate?: string
  pt: number
  hidden: boolean
  defaultValue: string
}

export type FlexFormQuestionOption = {
  value: string
  label?: string
  description?: string
  includeExplanation?: boolean
  belowRadioExplanation?: boolean
  explanationRequired?: boolean
  defaultExplanation: string
}
