import { BaseAction } from '../types'
import ActionIds from '../ActionIds'
import { AuthTokens } from './reducer'

export const cancelSignIn = (): BaseAction => ({type: ActionIds.AUTH_POST_CANCEL})
export const signIn = ({email, password}: {email: string; password: string}): BaseAction => ({
  type: ActionIds.AUTH_POST_START,
  payload: {username: email, password},
})

export const signOut = (): BaseAction => ({type: ActionIds.AUTH_DESTROY_START})
export const setTokens = (payload: AuthTokens): BaseAction => ({
  payload,
  type: ActionIds.AUTH_SET_TOKENS,
})

export const unsetAccountId = (): BaseAction => ({
  type: ActionIds.AUTH_UNSET_ACCOUNT_ID,
})
export const setAccountId = ({id}: {id: string}): BaseAction => ({
  type: ActionIds.AUTH_SET_ACCOUNT_ID,
  payload: {id},
})


export const resetPassword = ({ email }: { email: string }): BaseAction => ({
  type: ActionIds.AUTH_PASSWORD_RESET_START,
  payload: { username: email },
})
