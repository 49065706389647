import React, { useContext, useEffect } from 'react'
import * as ReactRedux from 'react-redux'
import { Field, FieldInputProps, Form, Formik, FormikContextType } from 'formik'
import {
  Button,
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Stack,
  Text,
} from '@chakra-ui/react'
import * as Yup from 'yup'
import isEmpty from 'lodash.isempty'
import { FiAlertTriangle } from 'react-icons/fi'
import { Context as DirtyFormAlertContext } from '../../../../components/DirtyFormAlert'
import { StoreState } from '../../../../redux'
import { isDone, isError } from '../../../../redux/AsyncState'
import { create as createPartner } from '../../../../redux/accounts/actions'
import { uploadFile } from '../../../../redux/axios'
import ImageUpload, { FileValidation } from '../../../../components/ImageUpload'
import {getCurrentSubdomain} from '../../../../utils/Routing'

enum FormFields {
  avatar = 'avatar',
  name = 'name',
  subdomainName = 'subdomainName',
}

const SUBDOMAIN_REGEX = /^[a-z0-9-_]+$/i

const ValidationSchema = Yup.object().shape({
  [FormFields.avatar]: FileValidation,
  [FormFields.name]: Yup.string().required('Must not be empty'),
  [FormFields.subdomainName]: Yup.string().matches(SUBDOMAIN_REGEX, 'Can only contain numbers, letters, -, or _'),
})

const initialValues = {
  [FormFields.avatar]: undefined,
  [FormFields.name]: '',
  [FormFields.subdomainName]: '',
}

type Values = typeof initialValues;
type ValueTypes = Values[keyof Values];
type FormType = FormikContextType<Values>
type FieldPropType = {field: FieldInputProps<ValueTypes>; form: FormType}
type ContainerProps = { onCancel: () => any; onError: () => any; onFinish: () => any; userId?: string }
type NewPartnerFormProps = ContainerProps & { form: FormType }

// visibleForTesting
export const useHooks = ({form, onError, onFinish}: NewPartnerFormProps) => {
  const { shallowEqual, useSelector } = ReactRedux
  const { setDirtyFormAlert } = useContext(DirtyFormAlertContext)

  const {
    creatingState,
  } = useSelector(({accounts}: StoreState) => ({
    creatingState: accounts.creatingState,
  }), shallowEqual)

  const isUpdateDone = isDone(creatingState)
  const isUpdateError = isError(creatingState)

  useEffect(() => {
    if (isUpdateError && form.submitCount > 0) {
      onError()
      form.setSubmitting(false)
    }
  }, [isUpdateError])

  useEffect(() => {
    if (isUpdateDone) {
      form.setSubmitting(false)
    }
  }, [isUpdateDone])

  useEffect(() => {
    if (isUpdateDone && !form.isSubmitting && form.isValid && form.submitCount > 0) {
      onFinish()
    }
  }, [form])

  useEffect(() => {
    setDirtyFormAlert(form.dirty)
  }, [form.dirty])
}

const NewPartnerForm: React.FC<NewPartnerFormProps> = (props) => {
  useHooks(props)
  const { form, onCancel } = props

  return (
    <Form>
      <Stack align='center' spacing='20px'>
        <Stack direction='row' spacing={2} alignSelf='flex-start' alignItems='center'>
          <Field name={FormFields.avatar}>
            {({ field }: FieldPropType) => (
              <FormControl isInvalid={!isEmpty(form.errors.avatar)} width='inherit'>
                <ImageUpload
                  {...field}
                  avatarProps={{ size: 'xl' }}
                  id={field.name}
                  onChange={file => {
                    form.setFieldValue(FormFields.avatar, file)
                  }}
                />
                <FormErrorMessage><FormErrorIcon as={FiAlertTriangle} />{form.errors.avatar}</FormErrorMessage>
              </FormControl>
            )}
          </Field>
          <Stack spacing={1}>
            <Text fontSize='16px'>Thumbnail Image</Text>
            <Text fontSize='12px'>Click to upload thumbnail image</Text>
          </Stack>
        </Stack>
        <Field name={FormFields.name}>
          {({ field }: FieldPropType) => (
            <FormControl isInvalid={!isEmpty(form.errors.name) && form.touched.name}>
              <FormLabel htmlFor={field.name}>Partner Name (required)</FormLabel>
              <Input {...field} id={field.name} type='text' maxLength={128}/>
              <FormErrorMessage><FormErrorIcon as={FiAlertTriangle} />{form.errors.name}</FormErrorMessage>
            </FormControl>
          )}
        </Field>
        { getCurrentSubdomain() !== 'app' &&
            <Field name={FormFields.subdomainName}>
              {({ field }: FieldPropType) => (
                <FormControl isInvalid={!isEmpty(form.errors.subdomainName) && form.touched.subdomainName}>
                  <FormLabel htmlFor={field.name}>Subdomain (required)</FormLabel>
                  <InputGroup>
                    <InputLeftAddon children='https://' />
                    <Input {...field} id={field.name} type='text' maxLength={63} placeholder='account'/>
                    <InputRightAddon children='.thanacare.com' />
                  </InputGroup>
                  <FormErrorMessage><FormErrorIcon as={FiAlertTriangle} />{form.errors.subdomainName}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
        }

        <Stack direction='row' spacing={4} width='100%'>
          <Button width='100%' isDisabled={form.isSubmitting} variant='outline' onClick={onCancel}>
            Cancel
          </Button>
          <Button width='100%' isLoading={form.isSubmitting} type='submit' onClick={() => form.submitForm()}>
            Create
          </Button>
        </Stack>
      </Stack>
    </Form>
  )
}

const NewPartnerContainerForm: React.FC<ContainerProps> = (props) => {
  const dispatch = ReactRedux.useDispatch()

  const onSubmit = ({avatar, ...values}) => {
    uploadFile(avatar).then(avatarId => {
      dispatch(createPartner({
        ...values,
        avatar: avatarId,
      }))
    })
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ValidationSchema}
      onSubmit={onSubmit}
    >{(formik) => <NewPartnerForm {...props} form={formik} />}</Formik>
  )
}

export default NewPartnerContainerForm
