import { axiosAuthed } from '../axios'

const BASE_ROUTE = '/files'

export const deleteOne = async (id: string) => {
  try {
    const response = await axiosAuthed({method: 'delete'}, `${BASE_ROUTE}/${id}`)
    return { response }
  } catch(error) {
    return { error }
  }
}
