
import {
  Model as Directive,
  PolstSteps,
  Statuses,
  HealthcarePt1Steps,
  Types as DirectiveTypes,
} from '../constants/Directive'
import { Model as User } from '../constants/User'
import {getFlexDirective} from '../routes/Authenticated/MemberDirective/MemberFlexDirective/getFlexDirective'

const EM_CODES = {
  INCLUDED: 'Included in E/M Code',
  FIRST_45: '99497',
  EVERY_30: '99498',
} as const

export const TeamStatuses = {
  ...Statuses,
  READY_TO_RESEND: 'ready_to_resend',
  READY_TO_SEND: 'ready_to_send',
} as const

export const getCodeMapForTime = (minutes: number) => {
  if (minutes < 16) return {
    [EM_CODES.INCLUDED]: 1,
  }

  const minutesOver45 = minutes - 45
  const baseCode = {
    [EM_CODES.FIRST_45]: 1,
  }
  if (minutesOver45 < 1) return baseCode

  return {
    ...baseCode,
    [EM_CODES.EVERY_30]: Math.ceil(minutesOver45 / 30),
  }
}

export const accountNameInitialsGenerator = (name) => {
  const split = name.split(/[^a-z0-9]/i)
  return split.length === 1 ? split[0].substring(0, 2) : split[0][0] + (split[1][0] || '')
}

export const totalStepsForDirectiveType = (type: Directive['type']) => {
  switch (type) {
    case DirectiveTypes.HEALTHCARE_PT1_DIRECTIVE: return Object.keys(HealthcarePt1Steps).length
    case DirectiveTypes.HEALTHCARE_FLEX_DIRECTIVE: return Object.keys(getFlexDirective().sections).length
    case DirectiveTypes.POLST: return Object.keys(PolstSteps).length
    default: return 0
  }
}

export const isDirectiveComplete = ({stepsCompleted, type}: Pick<Directive, 'stepsCompleted' | 'type'>) => (
  directiveStatus({stepsCompleted, type}) === Statuses.COMPLETED
)

export const isDirectiveInProgress = ({stepsCompleted, type}: Pick<Directive, 'stepsCompleted' | 'type'>) => (
  directiveStatus({stepsCompleted, type}) === (Statuses.IN_PROGRESS)
)

export const directiveStatus = ({stepsCompleted, type}: Pick<Directive, 'stepsCompleted' | 'type'>) => {
  const totalSteps = totalStepsForDirectiveType(type)
  if (totalSteps <= stepsCompleted) return Statuses.COMPLETED
  return Statuses.IN_PROGRESS
}

export const teamStatus = (member?: User) => {
  const incompleteDirectiveTypes = member == null ? [] : [
    {isComplete: isDirectiveComplete({stepsCompleted: member.healthcarePt1DirectiveStepsCompleted!, type: DirectiveTypes.HEALTHCARE_PT1_DIRECTIVE}), type: DirectiveTypes.HEALTHCARE_PT1_DIRECTIVE},
    {isComplete: member.flexDirectiveComplete, type: DirectiveTypes.HEALTHCARE_FLEX_DIRECTIVE},
  ].reduce((memo, {isComplete, type}) => isComplete ? memo : memo.concat(type), [] as DirectiveTypes[])

  if (member == null || incompleteDirectiveTypes.length > 0) return {
    incompleteDirectiveTypes,
    status: TeamStatuses.IN_PROGRESS,
  }

  if (member.finalPdfComplete) return {
    incompleteDirectiveTypes,
    status: TeamStatuses.COMPLETED,
  }

  if (member.generatedPdfComplete) return {
    incompleteDirectiveTypes,
    status: TeamStatuses.SENT,
  }

  return {
    incompleteDirectiveTypes,
    status: TeamStatuses.READY_TO_SEND,
  }
}
