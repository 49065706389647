import {StoreState} from '../redux'
import {Context as SubdomainContext} from '../components/Subdomain'
import * as RoutePaths from '../constants/RoutePaths'
import {Redirect} from 'react-router'
import {shallowEqual, useSelector} from 'react-redux'
import {useContext} from 'react'
import isEmpty from 'lodash.isempty'
import LoadingPage from '../components/LoadingPage'

const RootRedirect: React.FC = () => {
  const {
    currentUser,
  } = useSelector(({ users }: StoreState) => ({
    currentUser: users.currentUser,
  }), shallowEqual)
  const { isPartnerManagement } = useContext(SubdomainContext)
  if (isEmpty(currentUser)) {
    return <LoadingPage />
  }
  const { isSystemAdmin } = currentUser
  return <Redirect to={isSystemAdmin && isPartnerManagement ? RoutePaths.Partners() : RoutePaths.Members()} />

}

export default RootRedirect