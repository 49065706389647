import { Box, HStack, Spacer, useToast as ChakraToast, Text, Icon, UseToastOptions, Flex } from '@chakra-ui/react'
import { FiCheckCircle, FiX, FiAlertTriangle } from 'react-icons/fi'
import { textPalette, toastColors } from '../constants/Colors'

const SUCCESS = 'success'
const ERROR = 'error'

const SUCCESS_APPEND = 'Success!'
const ERROR_APPEND = 'Error.'

const DEFAULT_WIDTH = '350px'

interface ToastOptions extends UseToastOptions {
  width?: string | undefined
}

const getToastColor = (status: string | undefined) => {
  if (status === ERROR) return toastColors.error
  else if (status === SUCCESS) return toastColors.success
}

export function useToast(options?: ToastOptions) {
  const toast = ChakraToast(options)

  function closeAll() {
    toast.closeAll()
  }

  return (props: ToastOptions) => toast ({
    duration: 9000,
    isClosable: true,
    position: 'top',
    width: '350px',
    render: () => (
      <HStack
        p={3}
        bg='white'
        h='fit-content'
        w='fit-content'
        border='2px solid'
        borderColor={getToastColor(props.status)}
        border-radius='3px'
        spacing='16px'
      >
        <Flex direction='row'>
          {props.status === ERROR && <> <Box w='24px' mr='8px'>
            <Icon as={FiAlertTriangle} color={getToastColor(props.status)} display='inline' w={6} h={6} />
          </Box>

          <Box w={props.width !== undefined ? props.width : DEFAULT_WIDTH} >
            <Flex direction='row'>
              <Text color={getToastColor(props.status)} fontWeight='500' mr='4px'>{ERROR_APPEND}</Text>
              <Text lineHeight='24px' fontSize='16px' color={textPalette.light}>{props.description}</Text>
            </Flex>
          </Box>
          <Spacer />
          <Box w='24px' onClick={closeAll}>
            <Icon as={FiX} w={6} h={6}/>
          </Box>
          </>}

          {props.status === SUCCESS && <> <Box w='24px' mr='8px'>
            <Icon as={FiCheckCircle} color={getToastColor(props.status)} display='inline' w={6} h={6} />
          </Box>
          <Box w={props.width !== undefined ? props.width : DEFAULT_WIDTH} >
            <Flex direction='row'>
              <Text color={getToastColor(props.status)} fontWeight='500' mr='4px'>{SUCCESS_APPEND}</Text>
              <Text lineHeight='24px' fontSize='16px' color={textPalette.light}>{props.description}</Text>
            </Flex>
          </Box>
          <Spacer />
          <Box w='24px' onClick={closeAll}>
            <Icon as={FiX} w={6} h={6}/>
          </Box>
          </>}
        </Flex>
      </HStack>
    ),
    ...props,
  })

}
