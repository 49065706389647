import React, {useCallback, useEffect, useState} from 'react'
import {shallowEqual, useDispatch, useSelector} from 'react-redux'
import {RouteComponentProps} from 'react-router'
import {useHistory, useLocation, withRouter} from 'react-router-dom'
import {StoreState} from '../../../redux'
import {getData as getMemberListData, resetData as resetMemberListData} from '../../../redux/views/memberList/actions'
import {
  Avatar,
  Flex, IconButton, Menu, MenuButton, MenuItem, MenuList,
  Stack, Tag, TagLabel,
  useDisclosure,
} from '@chakra-ui/react'
import {purples, grays, textPalette, white} from '../../../constants/Colors'
import {Model as UserModel} from '../../../constants/User'
import {
  Box,
  Container,
  Text,
  Center,
} from '@chakra-ui/react'
import Pagination from '../../../components/Pagination'
import Table from '../../../components/Table'
import {isLoading} from '../../../redux/AsyncState'
import InviteTeammateModal from './InviteTeammateModal'
import * as RoutePaths from '../../../constants/RoutePaths'
import isEmpty from 'lodash.isempty'
import debounce from 'lodash.debounce'
import {
  QUERY_PARAM_KEYS,
  getNewSearchString,
  getPageParam,
  getSearchTermParam,
  privateFileUrl,
} from '../../../utils/Routing'
import {parsePhoneNumber} from '../../../utils/Forms'
import {AccountRoles} from '../../../constants/Account'
import TeamListTop from './TeamListTop'
import {FiMoreVertical} from 'react-icons/fi'
import {getRoleColor} from '../../../utils/Colors'
import WarningModal from '../../../components/WarningModal'
import {deleteOne as deleteOneUser} from '../../../redux/users/actions'

type MemberListProps = RouteComponentProps<any>

const advocateTableDataForUser = (user: UserModel, actionList: ((id: string) => void)[]) => {
  return {
    id: user.id,
    advocateData: (
      <Flex>
        <Center h='64px'>
          <Avatar
            size='md'
            name={`${user.firstName} ${user.lastName}`}
            src={privateFileUrl(user.avatar)}
            margin='32px'
            color={white}
            bg={purples.avatar}
          />
        </Center>
        <Center h='64px'>
          <Text fontSize='16px' fontWeight='400'
            color={textPalette.light}>{`${user.firstName} ${user.lastName}`}</Text>
        </Center>
      </Flex>
    ),
    contactData: (
      <Stack spacing={1} mt='12px'>
        <Text fontSize='12px' lineHeight='18px'
          color={textPalette.light}>{user.phoneNumber ? parsePhoneNumber(user.phoneNumber) : '-'}</Text>
        <Text fontSize='12px' lineHeight='18px' color={textPalette.light}>{user.email || '-'}</Text>
      </Stack>
    ),
    roleData: (
      <Center>
        <Tag borderRadius='full' color={getRoleColor(user.currentAccountRole)}
          variant='outline'><TagLabel>{user.currentAccountRole === AccountRoles.ADVOCATE ? 'editor' : user.currentAccountRole?.toLowerCase() || '-'}</TagLabel></Tag>
      </Center>

    ),
    specialtyData: (
      <Text fontSize='16px' mt={5} lineHeight='24px' color={textPalette.light}>{user.specialty || '-'}</Text>
    ),
    actionData: (
      <Center>
        <Menu>
          <MenuButton as={IconButton} variant='contained' icon={<FiMoreVertical/>}/>
          <MenuList>
            <MenuItem onClick={() => actionList[0](user.id)}>Edit</MenuItem>
            <MenuItem onClick={() => actionList[1](user.id)}>Remove</MenuItem>
          </MenuList>
        </Menu>
      </Center>
    ),
  }
}


const initialRolesFilter = [AccountRoles.OWNER, AccountRoles.ADMIN, AccountRoles.ADVOCATE, AccountRoles.OBSERVER]

function MemberList(_props: MemberListProps) {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const {
    isOpen: isInviteAdvocateModalOpen,
    onClose: closeInviteAdvocateModal,
    onOpen: openInviteAdvocateModal,
  } = useDisclosure()
  const {
    allLoadedUsers,
    isLoadingStore,
    isLoadingDelete,
    orderedMembers,
    totalPages,
  } = useSelector(({memberList, users}: StoreState) => ({
    allLoadedUsers: users.records,
    isLoadingStore: isLoading(memberList.loadingState, users.loadingState),
    isLoadingDelete: isLoading(users.deletingState),
    orderedMembers: memberList.orderedMemberIds.map(id => users.records[id]),
    totalPages: memberList.totalPages,
  }), shallowEqual)
  const [isMounted, setIsMounted] = useState(false)
  const debouncedDispatch = useCallback(debounce(dispatch, 300), [])
  const debouncedUpdateUrlParams = useCallback(debounce((newSearch: string, newPage: number) => history.replace({
    pathname: RoutePaths.AccountAdminUsersPage(),
    search: getNewSearchString({
      [QUERY_PARAM_KEYS.PAGE]: String(newPage),
      [QUERY_PARAM_KEYS.SEARCH_TERM]: String(newSearch),
    }),
  }), 500, {leading: true}), [])

  const [initialLoad, setInitialLoad] = useState(true)
  const currentPageParam = getPageParam(location) || String(1)
  const [currentPage, setCurrentPage] = useState(parseInt(currentPageParam, 10))
  const [searchTerm, setSearchTerm] = useState(getSearchTermParam(location) || '')


  const [rolesFilter, setRoleFilter] = useState(initialRolesFilter)
  const onToggleRole = (name: string): void => {
    if (name === 'ALL') {
      setRoleFilter(initialRolesFilter)
      return
    }
    if (rolesFilter.length === 4) {
      setRoleFilter([name])
      return
    }
    if (rolesFilter.includes(name)) {
      if (rolesFilter.length === 1) {
        setRoleFilter(initialRolesFilter)
        return
      }
      setRoleFilter(rolesFilter.filter(r => r !== name))
      return
    }
    setRoleFilter([...rolesFilter, name])
  }

  const currentPageData = orderedMembers
  const onChangePage = (newPage: number) => setCurrentPage(newPage)
  const onChangeSearch = (newSearch: string) => setSearchTerm(newSearch)

  useEffect(() => {
    setIsMounted(true)
  }, [])

  useEffect(() => {
    debouncedUpdateUrlParams(searchTerm, currentPage)
    return () => debouncedUpdateUrlParams.cancel()
  }, [currentPage, searchTerm])

  useEffect(() => {
    if (isMounted) {
      onChangePage(1)
    }

    const isSearchTermEmpty = isEmpty(searchTerm)
    if (!isSearchTermEmpty && searchTerm.length < 3) return

    debouncedDispatch(getMemberListData({
      role_names: rolesFilter,
      name: isSearchTermEmpty ? null : searchTerm,
      page: currentPage,
    }))
    return () => debouncedDispatch.cancel()
  }, [searchTerm])

  useEffect(() => {

    if (!isLoadingDelete) {
      dispatch(getMemberListData({role_names: rolesFilter, page: currentPage}))
    }

    return () => {
      dispatch(resetMemberListData()) // ensure stale data not used on next mount
    }
  }, [currentPage, isLoadingDelete])

  useEffect(() => {
    // reset page when filter is reset
    if (!initialLoad) {
      dispatch(getMemberListData({role_names: rolesFilter, page: 1}))
    }

    return () => {
      dispatch(resetMemberListData()) // ensure stale data not used on next mount
    }
  }, [rolesFilter])

  const [userToEdit, setUserToEdit] = useState<UserModel>()
  const onEditUser = (id: string) => {
    setUserToEdit(allLoadedUsers[id])
    openInviteAdvocateModal()
  }

  const onInviteModalClose = () => {
    closeInviteAdvocateModal()
    dispatch(getMemberListData({role_names: rolesFilter, page: currentPage}))
    setUserToEdit(undefined)
  }

  const [userToRemove, setUserToRemove] = useState<string>('')
  const onRemoveUser = (id: string) => {
    setUserToRemove(id)
  }

  const onWarningAccept = () => {
    dispatch(deleteOneUser(userToRemove))
    setUserToRemove('')
  }
  const tableData = currentPageData.map((d) => advocateTableDataForUser(d, [onEditUser, onRemoveUser]))

  const AdvocateTableColumnData = [
    {dataKey: 'advocateData', text: 'Name', width: '35%'},
    {dataKey: 'contactData', text: 'Contact', width: '25%'},
    {dataKey: 'roleData', text: 'Role', width: '15%'},
    {dataKey: 'specialtyData', text: 'Specialty', width: '15%'},
    {dataKey: 'actionData', text: '', width: '10%'},
  ]

  useEffect(() => {
    setInitialLoad(false)
  }, [])

  return (
    <Container maxW='1100px' mb='10vh'>
      <InviteTeammateModal userToEdit={userToEdit} isOpen={isInviteAdvocateModalOpen}
        onClose={onInviteModalClose}/>
      <WarningModal
        headerText='Are you sure?'
        onConfirm={onWarningAccept}
        isOpen={userToRemove !== ''}
        onClose={() => setUserToRemove('')}
      >
        <Text>Removing this teammate will immediately remove all access they have in this account.</Text>
      </WarningModal>
      <TeamListTop onClickNew={openInviteAdvocateModal} onSearchUpdate={onChangeSearch} searchTerm={searchTerm}
        selectedRoles={rolesFilter} onToggleRole={onToggleRole}/>
      <Box shadow='lg' backgroundColor='white' border='1px solid' borderColor={grays.light}>
        <Table
          actionIcons={false}
          columns={AdvocateTableColumnData}
          data={tableData}
          emptyProps={{text: 'No results found, refine search and try again'}}
          isLoading={isLoadingStore}
          rowHeight={'64px'}
        />
      </Box>
      <Pagination currentPage={currentPage} onPageChange={onChangePage} totalPages={totalPages}/>
    </Container>
  )
}

export default withRouter(MemberList)
