import { call, put, race, take, takeLatest } from 'redux-saga/effects'
import ActionIds from '../../ActionIds'
import { BaseAction } from '../../types'
import { getMany as getManyUsersApi } from '../../users/api'
import { getMany as getManyUsers } from '../../users/actions'
import {Model as UserModel} from '../../../constants/User'

export default function* watch() {
  yield takeLatest(ActionIds.MEMBER_LIST_GET_DATA_START, requestData)
}

function* requestData({payload: params}: BaseAction) {
  const { apiResponse: {response, error}, isCanceled } = yield race({
    apiResponse: call(getManyUsersApi, {...params}),
    isCanceled: take(ActionIds.MEMBER_LIST_GET_DATA_CANCEL),
  })
  if (isCanceled) return

  if (error) {
    yield put({type: ActionIds.MEMBER_LIST_GET_DATA_FAIL})
    return
  }

  const payload = response.data
  // dispatch new user records to users store
  yield put({type: ActionIds.USERS_GET_MANY_SUCCESS, payload})

  const userIds = (payload.data as Array<{attributes: UserModel}>).reduce((memo, {attributes: { advocateIds, alternateSurrogateIds, primaryCareProviderIds, surrogateIds }}) => ([...new Set([
    ...memo,
    ...(advocateIds || []),
    ...(alternateSurrogateIds || []),
    ...(primaryCareProviderIds || []),
    ...(surrogateIds || []),
  ])]), [] as string[])

  if (userIds.length > 0) {
    yield put(getManyUsers({ids: userIds, perPage: 1000})) // tell users store to fetch member's team of users by id
  }

  // dispatch member records to MemberList view store
  yield put({type: ActionIds.MEMBER_LIST_GET_DATA_SUCCESS, payload})
}
