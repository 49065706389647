import axios from 'axios'
// import queryString from 'query-string'
import { sanitize } from 'dompurify'
import jwtDecode from 'jwt-decode'
import humps from 'humps'
import { DirectUpload } from '@rails/activestorage'
import { StoreState, getStore } from '.'
import ActionIds from './ActionIds'
import { AuthTokens } from './auth/reducer'
import queryString from 'query-string'

export type GetDownloadUrlResponse = {
  url: string
}

export type CreateUploadUrlResponse = {
  url: string
}

const REFRESH_THRESHOLD_SECONDS = 60 // refresh access token if it expires soon

let isRefreshingToken = false

// visible for testing
export const EXTERNALS = {
  getAxios: () => axios,
  getStore: () => getStore(),
}

async function refreshTokensIfInvalid(): Promise<AuthTokens> {
  const store = EXTERNALS.getStore()
  const {
    auth,
  } = store.getState() as StoreState

  if (isTokenValid(auth.accessToken) && isTokenValid(auth.storageToken)) {
    return auth
  }

  let payload: any = {}
  try {
    const response: {data: any} = await axiosAuthed({method: 'post', skipTokenRefresh: true}, '/auth/refresh', {
      refreshToken: auth.refreshToken,
    })
    payload = response.data.data.attributes
  } finally {
    if (payload.accessToken == null) {
      store.dispatch({ type: ActionIds.AUTH_DESTROY_SUCCESS })
      throw {response: {status: 401}} // eslint-disable-line no-throw-literal
    }
  }

  store.dispatch({
    payload,
    type: ActionIds.AUTH_REFRESH_SUCCESS,
  })

  return payload
}

type AxiosFunctions = 'delete' | 'get' | 'patch' | 'post' | 'put'
type AxiosArgs = Parameters<typeof axios.post>
type SuccessHandler = (data: any) => void
interface AxiosAuthedOptions {
  method?: AxiosFunctions
  isFormData?: boolean
  onSuccess?: SuccessHandler
  skipTokenRefresh?: boolean
}

export async function axiosAuthed(options: AxiosAuthedOptions, ...args: AxiosArgs) {
  const store = EXTERNALS.getStore()
  const {
    auth,
  } = store.getState() as StoreState
  const {
    isFormData = false,
    method = 'get',
    onSuccess,
    skipTokenRefresh = false,
  } = options

  let accessToken = undefined as string | undefined
  if (!skipTokenRefresh && isRefreshingToken) {
    // wait for token refresh to finish
    return new Promise(resolve => setTimeout(async () => {
      resolve(await axiosAuthed(options, ...args))
    }, 100))
  } else if (!skipTokenRefresh) {
    try {
      isRefreshingToken = true
      accessToken = (await refreshTokensIfInvalid()).accessToken
    } finally {
      isRefreshingToken = false
    }
  } else {
    accessToken = EXTERNALS.getStore().getState().auth.accessToken
  }

  const axiosInstance = EXTERNALS.getAxios().create({
    baseURL: '/api/',
    headers: {
      Accept: 'application/json',
      'X-Account-Id': auth.accountId,
      Authorization: accessToken == null ? null : `Bearer ${accessToken}`,
      'Content-Type': isFormData ? 'multipart/form-data' : 'application/json',
    },
    paramsSerializer: {
      serialize: (params: object) => queryString.stringify(humps.decamelizeKeys(params) as any, {arrayFormat: 'bracket'}),
    },
    transformRequest: [(data: object) => data == null ? data : JSON.stringify(humps.decamelizeKeys(data))],
    transformResponse: [(data: string) => data.length > 1 ? humps.camelizeKeys(JSON.parse(data)) : data],
    withCredentials: true,
  })
  const response = await (axiosInstance[method] as any)(...args)

  onSuccess && onSuccess(response.data)

  return response
}

type ProgressDetails = {
  file: Blob
  progress: number
}
type ProgressCallback = (details: ProgressDetails) => void

const generateProgressListener = (file: File, progressCallback?: ProgressCallback) => (request: XMLHttpRequest) => {
  if (progressCallback == null) return

  // for event info, see: https://edgeguides.rubyonrails.org/active_storage_overview.html#direct-upload-javascript-events
  request.upload.addEventListener('progress', ({loaded, total}: ProgressEvent) => {
    progressCallback({file, progress: Math.round((loaded / total) * 100)})
  })
}

export const uploadFile = (file?: File, progressCallback?: ProgressCallback) => new Promise<string | void>(async resolve => {
  if (file == null) return resolve()

  const sanitizedFile = file.type === 'image/svg+xml' ? (
    new File([sanitize(await file.text(), {USE_PROFILES: {svg: true}, ADD_ATTR: ['mask-type']})], file.name, {type: file.type})
  ) : file

  const { storageToken } = (await refreshTokensIfInvalid())

  const upload = new DirectUpload(sanitizedFile, `/api/files/${storageToken}`, {
    directUploadWillStoreFileWithXHR: generateProgressListener(sanitizedFile, progressCallback),
  })

  upload.create((error, blob) => {
    if (error) {
      console.error('error: ', error)
      resolve()
    } else {
      resolve(blob.signed_id)
    }
  })
})

function isTokenValid(token?: string) {
  if (token == null) {
    return false
  }

  let expireTime = undefined as unknown as number
  try {
    expireTime = (jwtDecode(token) as {exp: number}).exp
  } catch(_e) {
    return false
  }

  if (expireTime == null) {
    return false
  }

  const timeUntilExpired = expireTime - Date.now() / 1000
  return timeUntilExpired > REFRESH_THRESHOLD_SECONDS
}
