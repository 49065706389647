import { BaseAction } from '../types'
import ActionIds from '../ActionIds'

export const cancelGetOne = (): BaseAction => ({type: ActionIds.DIRECTIVES_GET_ONE_CANCEL})
export const getOne = (id: string): BaseAction => ({
  type: ActionIds.DIRECTIVES_GET_ONE_START,
  payload: id,
})

export const getMany = (params): BaseAction => ({
  type: ActionIds.DIRECTIVES_GET_MANY_START,
  payload: params,
})

export const update = (params, id: string): BaseAction => {
  return (
    {
      type: ActionIds.DIRECTIVES_UPDATE_START,
      payload: {id, params},
    })}

export const sendToDocusign = (memberId: string, signer: string, template: string, witnessEmail1?: string, witnessEmail2?: string): BaseAction => ({
  type: ActionIds.DIRECTIVES_SEND_START,
  payload: {memberId, signer, template, witnessEmail1, witnessEmail2},
})

export const sendPolstToDocusign = (memberId: string, signer: string, template: string): BaseAction => ({
  type: ActionIds.DIRECTIVES_POLST_SEND_START,
  payload: {memberId, signer, template},
})

export const createPdf = (memberId: string, signatureLevel: number, redo: boolean): BaseAction => ({
  type: ActionIds.DIRECTIVES_CREATE_PDF_START,
  payload: {memberId, signatureLevel, redo},
})

export const create = (params): BaseAction => ({
  type: ActionIds.DIRECTIVES_CREATE_START,
  payload: params,
})

export const share = (memberId: string, emails: string | string[]): BaseAction => ({
  type: ActionIds.DIRECTIVES_SHARE_START,
  payload: {emails, memberId},
})
